export default function IconPosition(props: any) {
  return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" fill="currentColor" {...props}>
 <path d="M611.2,260.7c8.3,8.3,8.3,21.9,0,30.2s-21.9,8.3-30.2,0l-59.7-59.7v247.4h247.4L709.1,419c-8.3-8.3-8.3-21.9,0-30.2
   s21.9-8.3,30.2,0l96.1,96.1c8.3,8.3,8.3,21.9,0,30.2l-96.1,96.1c-8.3,8.3-21.9,8.3-30.2,0s-8.3-21.9,0-30.2l59.7-59.7H521.4v247.4
   l59.7-59.7c8.3-8.3,21.9-8.3,30.2,0s8.3,21.9,0,30.2l-96.1,96.1c-8.3,8.3-21.9,8.3-30.2,0l-96.1-96.1c-8.3-8.3-8.3-21.9,0-30.2
   s21.9-8.3,30.2,0l59.7,59.7V521.4H231.2l59.7,59.7c8.3,8.3,8.3,21.9,0,30.2s-21.9,8.3-30.2,0l-96.1-96.1c-8.3-8.3-8.3-21.9,0-30.2
   l96.1-96.1c8.3-8.3,21.9-8.3,30.2,0s8.3,21.9,0,30.2l-59.7,59.7h247.4V231.2L419,290.9c-8.3,8.3-21.9,8.3-30.2,0s-8.3-21.9,0-30.2
   l96.1-96.1c8.3-8.3,21.9-8.3,30.2,0L611.2,260.7z"/>
</svg>;
}
