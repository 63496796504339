import type { PatternTabInterface } from './types';
import { JerseySection, ModelStyle } from './types';
import { useRef, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PatternResponseItem } from './responses';

import {
  faAngleRight,
  faAngleLeft,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faXmark,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Scrollbar } from 'react-scrollbars-custom';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import IconCross from './icons/IconCross';
import IconPlus from './icons/IconPlus';
import IconMinus from './icons/IconMinus';

import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

enum SelectPatternFillColorArea {
  Pattern,
  Bg
}

enum SelectPatternTabMobile {
  Frontside,
  Backside,
  Sleeve
}

export default function JakoPatternTab({
  modelStyle,
  jerseySection,
  possibleDesignAreaId,
  selectedJerseySectionPattern,
  selectedJerseySectionColor,
  patternList,
  colorList,
  onSelectPatternSvg,
  onSelectPatternFill,
  onSelectBgColor,
  onSetJerseySection,
  onPatternScale,
  onPatternMoveOffset,
  isMobile,
  onCloseMobile,
  onCopyPatternSvg
}: PatternTabInterface) {
  const { t } = useTranslation('translations');

  const [selectTab, setSelectTab] = useState<SelectPatternFillColorArea>(SelectPatternFillColorArea.Pattern);
  const [showMoreOption, setShowMoreOption] = useState<{[key: string]: boolean}>({
    [JerseySection.Front]: false,
    [JerseySection.Back]: false,
    [JerseySection.Left]: false,
    [JerseySection.Right]: false,
  });
  const scrollBarRef = useRef(null as any);
  const [hasMoreScrollContainer, setHasMoreScrollContainer] = useState(false);
  const [selectTabMobile, setSelectTabMobile] = useState<SelectPatternTabMobile>(SelectPatternTabMobile.Frontside);
  const [selectCopyPatternSectionDesktop, setSelectCopyPatternSectionDesktop] = useState<string | null>(null);

  const sortedPatternList = useMemo(() => patternList.slice().sort((a, b) => a.sorting - b.sorting), [patternList]);
  const allPatternOptions = useMemo(() => [...sortedPatternList], [sortedPatternList]);

  const uniqueColorList = useMemo(() => {
    // arr.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
    return colorList.filter((v,i,a)=>a.findIndex(v2=>(v2.colorHexCode===v.colorHexCode))===i);
  }, [colorList]);


  let onlyPossibleDesignAreas = useRef({});
  let getOrderedDesignAreaKeys = useRef([] as any);

  if (possibleDesignAreaId.length) {
    const onlyNonCopyItems = possibleDesignAreaId.slice().filter((area) => !area.includes('collar_under') && !area.includes('placket') && !area.includes('neck') && !area.includes('bg-') && !area.includes('copy'));
    onlyNonCopyItems.forEach((item) => {
      const getWordDesignPart = item.split('_')[0]; // design-1, design-2, design-3
      const getWordDesignJerseySectionPart = item.split('_')[1] ? item.split('_')[1] : ''; // front, back, left, right
      const getDesignWord = getWordDesignPart.split('-')[0] + (getWordDesignPart.split('-')[1] ? ' ' + getWordDesignPart.split('-')[1] : '');
      const getAreaName = getDesignWord + ' ' + getWordDesignJerseySectionPart;
      onlyPossibleDesignAreas = {
        ...onlyPossibleDesignAreas,
        [item]: getAreaName
      };
    });
    getOrderedDesignAreaKeys.current = Object.keys(onlyPossibleDesignAreas).sort((aKey: string, bKey: string) => {
      const getWordDesignPartA = aKey.split('_')[0]; // design-1, design-2, design-3
      const getWordDesignPartB = bKey.split('_')[0]; // design-1, design-2, design-3
      const getNumberA = parseInt(getWordDesignPartA.split('-')[1]);
      const getNumberB = parseInt(getWordDesignPartB.split('-')[1]);
      const getJerseySectionA = aKey.split('_')[1];
      const getJerseySectionB = bKey.split('_')[1];
      if (getJerseySectionA === 'front' && getJerseySectionB === 'front') {
        return getNumberA - getNumberB;
      } else if (getJerseySectionA === 'front') {
        return -1;
      } else if (getJerseySectionB === 'front') {
        return 1;
      } else if (getJerseySectionA === 'back' && getJerseySectionB === 'back') {
        return getNumberA - getNumberB;
      } else if (getJerseySectionA === 'back') {
        return -1;
      } else if (getJerseySectionB === 'back') {
        return 1;
      } else if (getJerseySectionA === 'left' && getJerseySectionB === 'left') {
        return getNumberA - getNumberB;
      } else if (getJerseySectionA === 'left') {
        return -1;
      } else if (getJerseySectionB === 'left') {
        return 1;
      } else if (getJerseySectionA === 'right' && getJerseySectionB === 'right') {
        return getNumberA - getNumberB;
      } else if (getJerseySectionA === 'right') {
        return -1;
      } else if (getJerseySectionB === 'right') {
        return 1;
      } else {
        return  getNumberA - getNumberB;
      }
    });
  }

  useEffect(() => {
    if (!scrollBarRef.current) {
      return;
    }

    onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    scrollBarRef.current.getScrollElement()
      .addEventListener("scroll", function(e: any) { onScrollContainerUpdate(e.target); });
  }, []);

  useEffect(() => {
    if (possibleDesignAreaId.length) {
      if (isMobile) {
        Object.keys(getOrderedDesignAreaKeys.current).forEach((itemKey) => {
          setShowMoreOption((prevState: any) => ({
            ...prevState,
            [itemKey]: false
          }));
        });
        setShowMoreOption((prevState: any) => ({
          ...prevState,
          [JerseySection.Front]: true,
          [JerseySection.Back]: true,
          [JerseySection.Left]: true,
          [JerseySection.Right]: true,
        }));
      } else {
        Object.keys(getOrderedDesignAreaKeys.current).forEach((itemKey) => {
          setShowMoreOption((prevState: any) => ({
            ...prevState,
            [itemKey]: false
          }));
        });
      }
    }
  }, [possibleDesignAreaId, isMobile]);

  const frontDesignAreaKeys = useMemo(() => getOrderedDesignAreaKeys.current.slice().filter((key: string) => key.includes('_front')), [getOrderedDesignAreaKeys]);
  const backDesignAreaKeys = useMemo(() => getOrderedDesignAreaKeys.current.slice().filter((key: string) => key.includes('_back')), [getOrderedDesignAreaKeys]);

  function onPatternScaleHandler(e: any) {
    const value = e as number;
    onPatternScale(jerseySection, value);
  }

  function onPatternMoveTopHandler(e: any) {
    const value = e as number;
    onPatternMoveOffset(
      jerseySection,
      {
        top: value,
        left: selectedJerseySectionPattern[jerseySection].left || 0
      }
    );
  }

  function onPatternMoveLeftHandler(e: any) {
    const value = e as number;
    onPatternMoveOffset(
      jerseySection,
      {
        top: selectedJerseySectionPattern[jerseySection].top || 0,
        left: value
      }
    );
  }

  function handleOnSelectPattern(section: string, item: PatternResponseItem | null) {
    if (isMobile) {
      onSetJerseySection(section);
    }
    if (item === null) {
      onSelectPatternSvg(section, null);
      return;
    }
    onSelectPatternSvg(section, item);
  }

  function handleOnSelectPatternFill(section: string, color: string) {
    onSelectPatternFill(section, color);
  }

  function handleOnSelectOption(section: string) {
    onSetJerseySection(section);

    let results = {};
    Object.keys(getOrderedDesignAreaKeys.current).forEach((itemKey) => {
      results = {
        ...results,
        [itemKey]: false
      };
    });
    results = {
      [JerseySection.Front]: false,
      [JerseySection.Back]: false,
      [JerseySection.Left]: false,
      [JerseySection.Right]: false,
      ...results,
      [section]: !showMoreOption[section],
    };

    setShowMoreOption(results);
  }

  function onScrollContainerUpdate(el: any) {
    if (el.clientHeight + el.scrollTop >= el.scrollHeight) {
      setHasMoreScrollContainer(false);
    } else {
      setHasMoreScrollContainer(true);
    }
  }

  function handleOnClickCloseMobile() {
    if (scrollBarRef.current) {
      onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    }
    onCloseMobile();
  }

  function handleCopyPatternSvg(section: string) {
    if (!selectCopyPatternSectionDesktop) {
      return;
    }

    onCopyPatternSvg(selectCopyPatternSectionDesktop, section);
    setSelectCopyPatternSectionDesktop(null);
  }

  function DesktopPatternPreviewSection({ designKey, label }: { designKey: string, label: string }) {
    return <div
      className={
        "colorWrapper colorWrapper--7cols"
        + (showMoreOption[designKey] ? ' mb-10' : '')
      }
    >
      <button
        className="p-3 hover:bg-[#f0f0f0] tooltip bg-[#f0f0f0]"
        onClick={() => handleOnSelectOption(designKey)}
      >
        <label className="tooltipBubble">
          { label }
        </label>
        <div className="relative bg-white w-[34px] h-[34px] border">
          {
            !selectedJerseySectionPattern[designKey]
              ? <div className="absolute top-0 left-1/2 w-0.5 h-full bg-[#EB0000] transform rotate-45" />
              : selectedJerseySectionPattern[designKey].filename === ''
                ? <div className="absolute top-0 left-1/2 w-0.5 h-full bg-[#EB0000] transform rotate-45" />
                : <div
                style={{
                  backgroundImage: `url('${selectedJerseySectionPattern[designKey].filename.replace('https://localhost', 'https://jako-api.dc-test.de')}')`
                }}
                className="absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat"
              />
          }
        </div>
      </button>
      {
        allPatternOptions.slice(0, 5).map((patternOption, index) =>
          <button
            key={'frontPatternOptionPreview' + index}
            className="p-3 hover:bg-[#f0f0f0]"
            onClick={() => handleOnSelectPattern(designKey, patternOption)}
          >
            <div className="relative bg-white w-[34px] h-[34px] border">
              <div
                style={{
                  backgroundImage: `url('${patternOption.filename.replace('https://localhost', 'https://jako-api.dc-test.de')}')`
                }}
                className="absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat"
              />
            </div>
          </button>
        )
      }
      <div
        className="relative flex items-center justify-end max-w-[60px] cursor-pointer"
        onClick={() => handleOnSelectOption(designKey)}
      >
        {
          showMoreOption[designKey]
            ? <div className="flex flex-col w-full h-full items-center justify-center text-[#141414]">
              <IconMinus className="w-auto h-[14px]" />
              <span className="font-semibold text-[14px]">{ t('configurator_3d_less') }</span>
            </div>
            : <div className="flex flex-col w-full h-full items-center justify-center text-[#141414]">
              <IconPlus className="w-auto h-[14px]" />
              <span className="font-semibold text-[14px]">{ t('configurator_3d_more') }</span>
            </div>
        }
      </div>
    </div>;
  }

  function DesktopPatternMoreConfigSection({ designKey }: { designKey: string }) {
    return <div className={showMoreOption[designKey] ? ' block' : ' hidden'}>
      <div
        className={
          "relative border-b border-[#F0F0F0] group"
          + " before:bottom-0 before:left-0 before:w-full before:h-6 before:absolute before:z-10 before:bg-gradient-to-t before:from-white before:to-transparent"
        }
      >
        <Scrollbar
          disableTrackYWidthCompensation
          trackYProps={{ style: { width: '12px', background: 'none' } }}
          className='transition-all !h-[300px] group-hover:!h-[460px]'
        >
          <div className="itemsWrapper--4cols pt-3 pb-8">
            <div
              className={
                "itemTemplate "
                + (selectedJerseySectionPattern[designKey] && selectedJerseySectionPattern[designKey].filename === '' ? " active" : "")
              }
              onClick={() => handleOnSelectPattern(designKey, null)}
            >
              <div className="itemTemplate__image">
                <div className="relative bg-white w-full h-full border">
                  <div className="absolute top-0 left-1/2 w-0.5 h-full bg-[#EB0000] transform rotate-45" />
                </div>
              </div>
            </div>
            {
              allPatternOptions.map((item, index) =>
                <div
                  key={'allPatternOptiondesignKey' + index}
                  className={
                    "itemTemplate"
                    + (selectedJerseySectionPattern[designKey] && selectedJerseySectionPattern[designKey].filename === item.filename ? " active" : "")
                  }
                  onClick={() => handleOnSelectPattern(designKey, item)}
                >
                  <div key={'allUnSelectedPattern.Front' + index} className="itemTemplate__image">
                    <img src={item.filename} alt={item.description} />
                  </div>
                </div>
              )
            }
          </div>
        </Scrollbar>
      </div>

      <div className="pt-[30px] pb-[50px]">
        {
          selectedJerseySectionPattern[designKey] && selectedJerseySectionPattern[designKey].change_size
            ? <>
              {/* // <!-- Pattern Size --> */}
              <div className="form-group borderBottom pattern-range">
                <div className="labelOutputWrapper">
                    <label htmlFor="sliderInputId_03">{t('configurator_3d_size')}</label>
                    <div className="text-right">
                      {
                        selectedJerseySectionPattern[designKey] && selectedJerseySectionPattern[designKey].scale
                          ? ((selectedJerseySectionPattern[designKey].scale || 1) * 100) + '%'
                          : 100+'%'
                      }
                    </div>
                </div>
                <InputRange
                  maxValue={2}
                  minValue={selectedJerseySectionPattern[designKey].tileable ? 0.1 : 1}
                  value={selectedJerseySectionPattern[designKey] ? selectedJerseySectionPattern[designKey].scale || 1 : 1}
                  onChange={onPatternScaleHandler}
                  step={0.01}
                />
              </div>
              {/* // <!-- End of Pattern Size --> */}
            </>
            : <></>
        }
        {
          selectedJerseySectionPattern[designKey] && selectedJerseySectionPattern[designKey].move_vertically
            ? <>
              {/* // <!-- Pattern Top --> */}
              <div className="form-group borderBottom pattern-range">
                <div className="labelOutputWrapper">
                  <label htmlFor="sliderInputId_03">{t('configurator_3d_top')}</label>
                </div>
                <InputRange
                  maxValue={100}
                  minValue={-100}
                  value={selectedJerseySectionPattern[designKey] ? selectedJerseySectionPattern[designKey].top || 0 : 0}
                  onChange={onPatternMoveTopHandler}
                  step={0.01}
                />
              </div>
              {/* // <!-- End of Pattern Top --> */}
            </>
            : <></>
        }
        {
          selectedJerseySectionPattern[designKey] && selectedJerseySectionPattern[designKey].move_horizontally
            ? <>
              {/* // <!-- Pattern Left --> */}
              <div className="form-group borderBottom pattern-range">
                <div className="labelOutputWrapper">
                  <label htmlFor="sliderInputId_03">{t('configurator_3d_left')}</label>
                </div>
                <InputRange
                  maxValue={100}
                  minValue={-100}
                  value={selectedJerseySectionPattern[designKey] ? selectedJerseySectionPattern[designKey].left || 0 : 0}
                  onChange={onPatternMoveLeftHandler}
                  step={0.01}
                />
              </div>
              {/* // <!-- End of Pattern Right --> */}
            </>
            : <></>
        }

        {/* <!-- Pattern Color / Background Color --> */}
        <div className="pattern-color-tab tabs">
          <ul className="tabs__list">
            <li
              className={"tabs__li" + (selectTab === SelectPatternFillColorArea.Pattern ? ' active' : '')}
              onClick={() => setSelectTab(SelectPatternFillColorArea.Pattern)}
            >
              { t('configurator_3d_pattern_color') }
            </li>
            <li
              className={"tabs__li " + (selectTab === SelectPatternFillColorArea.Bg ? ' active' : '')}
              onClick={() => setSelectTab(SelectPatternFillColorArea.Bg)}
            >
              { t('configurator_3d_bg_color') }
            </li>
          </ul>
          {/* <!-- Pattern Color --> */}
          <div
            className={
              "tabs__content "
              + (selectTab === SelectPatternFillColorArea.Pattern ? ' active' : '')
            }
          >
            <div className="colorWrapper colorWrapper--7cols">
              {
                uniqueColorList.map((colorItem, index) =>
                  <div
                    key={'colorListPatternOptions' + index  + colorItem.description}
                    className={
                      "itemColor "
                      + (selectedJerseySectionPattern[designKey] && ('#' + colorItem.colorHexCode === selectedJerseySectionPattern[designKey].fill) ? 'active' : '')
                    }
                    onClick={() => handleOnSelectPatternFill(designKey, '#'+colorItem.colorHexCode)}
                  >
                    <div
                      className="itemColor--bgColor tooltip"
                      data-tooltip={colorItem.description}
                      style={{
                        backgroundColor: '#' + colorItem.colorHexCode
                      }}
                    >
                      <label className="tooltipBubble">
                        {colorItem.description}
                      </label>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
          {/* <!-- End of Pattern Color --> */}

          {/* <!-- Background Color --> */}
          <div
            className={
              "tabs__content "
              + (selectTab === SelectPatternFillColorArea.Bg ? ' active' : '')
            }
          >
            <div className="colorWrapper colorWrapper--7cols">
              {
                uniqueColorList.map((colorItem, index) =>
                  <div
                    key={'colorBgListPatternOptions' + index + colorItem.description}
                    className={
                      "itemColor "
                      + (selectedJerseySectionColor[designKey] && ('#' + colorItem.colorHexCode === selectedJerseySectionColor[designKey]) ? 'active' : '')
                    }
                    onClick={() => onSelectBgColor(designKey, '#' + colorItem.colorHexCode)}
                  >
                    <div
                      className="itemColor--bgColor tooltip"
                      data-tooltip={colorItem.description}
                      style={{
                        backgroundColor: '#' + colorItem.colorHexCode
                      }}
                    >
                      <label className="tooltipBubble">
                        {colorItem.description}
                      </label>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
          {/* <!-- End of Backgrounf Color --> */}
        </div>
        {/* <!-- End of Pattern Color / Background Color --> */}
        <div className="mt-10">
          <button
            className="px-[27px] py-[12px] bg-[#FAFAFA] text-[#5B5B5B]"
            onClick={() => setSelectCopyPatternSectionDesktop(designKey)}
          >
            <span>{ t('configurator_3d_copy') }</span> <span>{t('configurator_3d_pattern')}</span>
          </button>
        </div>
      </div>
    </div>;
  }

  return isMobile
    ? <div className="relative w-full h-[325px] px-6 pt-10 pb-6">
      <button
        className="absolute top-[18px] right-[12px] z-10"
        onClick={handleOnClickCloseMobile}
      >
        <IconCross className="h-[20px]" />
      </button>
      <div className="relative w-full pb-3 flex items-center gap-5 items-center overflow-hidden mb-[10px] before:absolute before:w-full before:h-[2px] before:bg-[#F0F0F0] before:left-0 before:bottom-[1px]">
        {
          ModelStyle.Shirt === modelStyle || ModelStyle.ShirtNoSleeve === modelStyle
            ? <>
              <button
                className={
                  "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-6px] "
                  + (selectTabMobile === SelectPatternTabMobile.Frontside ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
                }
                onClick={() => setSelectTabMobile(SelectPatternTabMobile.Frontside)}
              >
                { t('configurator_3d_front_side') }
              </button>
              <button
                className={
                  "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-6px]  "
                  + (selectTabMobile === SelectPatternTabMobile.Backside ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
                }
                onClick={() => setSelectTabMobile(SelectPatternTabMobile.Backside)}
              >
                { t('configurator_3d_backside') }
              </button>
              {
                ModelStyle.ShirtNoSleeve === modelStyle
                  ? <></>
                  : <button
                    className={
                      "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-6px] "
                      + (selectTabMobile === SelectPatternTabMobile.Sleeve ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
                    }
                    onClick={() => setSelectTabMobile(SelectPatternTabMobile.Sleeve)}
                  >
                    { t('configurator_3d_sleeve') }
                  </button>
              }
            </>
            : <>
              <button
                className={
                  "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-6px] "
                  + (selectTabMobile === SelectPatternTabMobile.Sleeve ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
                }
                onClick={() => setSelectTabMobile(SelectPatternTabMobile.Sleeve)}
              >
                { t('configurator_3d_short') }
              </button>
              </>
        }
      </div>
      <SimpleBar
        ref={scrollBarRef}
        style={{
          width: '100%',
          height: 262
        }}
        autoHide={false}
        onScroll={onScrollContainerUpdate}
      >
        {
          selectTabMobile === SelectPatternTabMobile.Frontside
            ? <>
              <div className="pattern-list-wrapper pl-[8px] pr-[20px] flex flex-col min-h-full mb-[10px]">
                <button
                  className="flex justify-between items-center my-2"
                  onClick={() => setShowMoreOption({
                    ...showMoreOption,
                    [JerseySection.Front]: !showMoreOption[JerseySection.Front]
                  })}
                >
                  <p className="text-[14px] font-semibold">{ t('configurator_3d_front_side') }</p>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className={
                    "icon transform transition-all duration-500"
                    + (showMoreOption[JerseySection.Front] ? ' rotate-90' : ' rotate-0')
                  }
                  />
                </button>
                {
                  showMoreOption[JerseySection.Front]
                    ? <>
                      <SimpleBar
                        style={{
                          height: 74,
                          width: '100%',
                        }}
                        autoHide={false}
                      >
                        <div className="flex gap-4 h-[72px]">
                          <button
                            className={
                              "p-3 hover:bg-[#f0f0f0] p-[11px] h-[66px]"
                              + (selectedJerseySectionPattern[JerseySection.Front] && selectedJerseySectionPattern[JerseySection.Front].filename === '' ? " bg-[#f0f0f0] border-b-2 border-[#008AC9]" : "")
                            }
                            onClick={() => handleOnSelectPattern(JerseySection.Front, null)}
                          >
                            <div className="relative bg-white w-[43px] h-[43px] border">
                              <div className="absolute top-0 left-1/2 w-0.5 h-full bg-[#EB0000] transform rotate-45" />
                            </div>
                          </button>
                          {
                            allPatternOptions.map((item, index) =>
                              <button
                                key={'allPatternOptionJerseySection.Front' + index}
                                className={
                                  "p-3 hover:bg-[#f0f0f0] p-[11px] h-[66px]"
                                  + (selectedJerseySectionPattern[JerseySection.Front] && selectedJerseySectionPattern[JerseySection.Front].filename === item.filename ? " bg-[#f0f0f0] border-b-2 border-[#008AC9]" : "")
                                }
                                onClick={() => handleOnSelectPattern(JerseySection.Front, item)}
                              >
                                <div key={'allUnSelectedPattern.Front' + index} className="relative bg-white w-[43px] h-[43px] border">
                                  <div
                                    style={{
                                      backgroundImage: `url('${item.filename.replace('https://localhost', 'https://jako-api.dc-test.de')}')`
                                    }}
                                    className="absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat"
                                  />
                                </div>
                              </button>
                            )
                          }
                        </div>
                      </SimpleBar>
                      {
                        selectedJerseySectionPattern[JerseySection.Front] && selectedJerseySectionPattern[JerseySection.Front].filename
                          ? <div className="pt-[30px] pb-[50px]">
                            {
                              selectedJerseySectionPattern[JerseySection.Front].change_size
                                ? <>
                                  {/* // <!-- Pattern Size --> */}
                                  <div className="form-group borderBottom pattern-range">
                                    <div className="labelOutputWrapper">
                                        <label htmlFor="sliderInputId_03">{t('configurator_3d_size')}</label>
                                        <div className="text-right">
                                          {
                                            selectedJerseySectionPattern[JerseySection.Front] && selectedJerseySectionPattern[JerseySection.Front].scale
                                              ? (selectedJerseySectionPattern[JerseySection.Front].scale * 100) + '%'
                                              : 100+'%'
                                          }
                                        </div>
                                    </div>
                                    <InputRange
                                      maxValue={2}
                                      minValue={selectedJerseySectionPattern[JerseySection.Front].tileable ? 0.1 : 1}
                                      value={selectedJerseySectionPattern[JerseySection.Front] ? selectedJerseySectionPattern[JerseySection.Front].scale || 1 : 1}
                                      onChange={onPatternScaleHandler}
                                      step={0.01}
                                    />
                                  </div>
                                  {/* // <!-- End of Pattern Size --> */}
                                </>
                                : <></>
                            }
                            {
                              selectedJerseySectionPattern[JerseySection.Front].move_vertically
                                ? <>
                                  {/* // <!-- Pattern Top --> */}
                                  <div className="form-group borderBottom pattern-range">
                                    <div className="labelOutputWrapper">
                                      <label htmlFor="sliderInputId_03">{t('configurator_3d_top')}</label>
                                    </div>
                                    <InputRange
                                      maxValue={100}
                                      minValue={-100}
                                      value={selectedJerseySectionPattern[JerseySection.Front] ? selectedJerseySectionPattern[JerseySection.Front].top || 0 : 0}
                                      onChange={onPatternMoveTopHandler}
                                      step={0.01}
                                    />
                                  </div>
                                  {/* // <!-- End of Pattern Top --> */}
                                </>
                                : <></>
                            }
                            {
                              selectedJerseySectionPattern[JerseySection.Front].move_horizontally
                                ? <>
                                  {/* // <!-- Pattern Left --> */}
                                  <div className="form-group borderBottom pattern-range">
                                    <div className="labelOutputWrapper">
                                      <label htmlFor="sliderInputId_03">{t('configurator_3d_left')}</label>
                                    </div>
                                    <InputRange
                                      maxValue={100}
                                      minValue={-100}
                                      value={selectedJerseySectionPattern[JerseySection.Front] ? selectedJerseySectionPattern[JerseySection.Front].left || 0 : 0}
                                      onChange={onPatternMoveLeftHandler}
                                      step={0.01}
                                    />
                                  </div>
                                  {/* // <!-- End of Pattern Right --> */}
                                </>
                                : <></>
                            }

                            {/* <!-- Pattern Color / Background Color --> */}
                            <div className="pattern-color-tab tabs">
                              <ul className="tabs__list">
                                <li
                                  className={"tabs__li" + (selectTab === SelectPatternFillColorArea.Pattern ? ' text-[#141414]' : '')}
                                  onClick={() => setSelectTab(SelectPatternFillColorArea.Pattern)}
                                >
                                  { t('configurator_3d_pattern_color') }
                                </li>
                                <li
                                  className={"tabs__li " + (selectTab === SelectPatternFillColorArea.Bg ? ' text-[#141414]' : '')}
                                  onClick={() => setSelectTab(SelectPatternFillColorArea.Bg)}
                                >
                                  { t('configurator_3d_bg_color') }
                                </li>
                              </ul>
                              {/* <!-- Pattern Color --> */}
                              <div
                                className={
                                  "tabs__content "
                                  + (selectTab === SelectPatternFillColorArea.Pattern ? ' active' : '')
                                }
                              >
                                <div className="colorWrapper colorWrapper--responsive">
                                  {
                                    uniqueColorList.map((colorItem, index) =>
                                      <div
                                        key={'colorListPatternOptions' + index  + colorItem.description}
                                        className={
                                          "itemColor "
                                          + (selectedJerseySectionPattern[JerseySection.Front] && ('#' + colorItem.colorHexCode === selectedJerseySectionPattern[JerseySection.Front].fill) ? 'active' : '')
                                        }
                                        onClick={() => handleOnSelectPatternFill(JerseySection.Front, '#'+colorItem.colorHexCode)}
                                      >
                                        <div
                                          className="itemColor--bgColor tooltip"
                                          data-tooltip={colorItem.description}
                                          style={{
                                            backgroundColor: '#' + colorItem.colorHexCode
                                          }}
                                        >
                                          <label className="tooltipBubble">
                                            {colorItem.description}
                                          </label>
                                        </div>
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                              {/* <!-- End of Pattern Color --> */}
                              {/* <!-- Background Color --> */}
                              <div
                                className={
                                  "tabs__content "
                                  + (selectTab === SelectPatternFillColorArea.Bg ? ' active' : '')
                                }
                              >
                                <div className="colorWrapper colorWrapper--responsive">
                                  {
                                    uniqueColorList.map((colorItem, index) =>
                                      <div
                                        key={'colorBgListPatternOptions' + index + colorItem.description}
                                        className={
                                          "itemColor "
                                          + (selectedJerseySectionColor[JerseySection.Front] && ('#' + colorItem.colorHexCode === selectedJerseySectionColor[JerseySection.Front]) ? 'active' : '')
                                        }
                                        onClick={() => onSelectBgColor(JerseySection.Front, '#' + colorItem.colorHexCode)}
                                      >
                                        <div
                                          className="itemColor--bgColor tooltip"
                                          data-tooltip={colorItem.description}
                                          style={{
                                            backgroundColor: '#' + colorItem.colorHexCode
                                          }}
                                        >
                                          <label className="tooltipBubble">
                                            {colorItem.description}
                                          </label>
                                        </div>
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                              {/* <!-- End of Backgrounf Color --> */}
                            </div>
                            {/* <!-- End of Pattern Color / Background Color --> */}
                          </div>
                          : <></>
                      }
                    </>
                    : <></>
                }
              </div>
              {
                frontDesignAreaKeys.length
                  ? frontDesignAreaKeys.map((designKey: any, index: number) =>
                    <div
                      key={'frontDesignAreaKeysPatternTrigger' + designKey + index}
                      className="pattern-list-wrapper pl-[8px] pr-[20px] flex flex-col min-h-full mb-[10px]"
                    >
                      <button
                        className="flex justify-between items-center my-2"
                        onClick={() => setShowMoreOption({
                          ...showMoreOption,
                          [designKey]: !showMoreOption[designKey]
                        })}
                      >
                        <p className="text-[14px] font-semibold my-2">{t('configurator_3d_shirt_design_no') + ' ' + (index + 1)}</p>
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className={
                          "icon transform transition-all duration-500"
                          + (showMoreOption[designKey] ? ' rotate-90' : ' rotate-0')
                        }
                        />
                      </button>
                      {
                        showMoreOption[designKey]
                          ? <>
                            <SimpleBar
                              style={{
                                height: 74,
                                width: '100%',
                              }}
                              autoHide={false}
                            >
                              <div className="flex gap-4 h-[72px]">
                                <button
                                  className={
                                    "p-3 hover:bg-[#f0f0f0] p-[11px] h-[66px]"
                                    + (selectedJerseySectionPattern[designKey] && selectedJerseySectionPattern[designKey].filename === '' ? " bg-[#f0f0f0] border-b-2 border-[#008AC9]" : "")
                                  }
                                  onClick={() => handleOnSelectPattern(designKey, null)}
                                >
                                  <div className="relative bg-white w-[43px] h-[43px] border">
                                    <div className="absolute top-0 left-1/2 w-0.5 h-full bg-[#EB0000] transform rotate-45" />
                                  </div>
                                </button>
                                {
                                  allPatternOptions.map((item, index) =>
                                    <button
                                      key={'allPatternOption' + designKey + index}
                                      className={
                                        "p-3 hover:bg-[#f0f0f0] p-[11px] h-[66px]"
                                        + (selectedJerseySectionPattern[designKey] && selectedJerseySectionPattern[designKey].filename === item.filename ? " bg-[#f0f0f0] border-b-2 border-[#008AC9]" : "")
                                      }
                                      onClick={() => handleOnSelectPattern(designKey, item)}
                                    >
                                      <div key={'allUnSelectedPattern.Front' + index} className="relative bg-white w-[43px] h-[43px] border">
                                        <div
                                          style={{
                                            backgroundImage: `url('${item.filename.replace('https://localhost', 'https://jako-api.dc-test.de')}')`
                                          }}
                                          className="absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat"
                                        />
                                      </div>
                                    </button>
                                  )
                                }
                              </div>
                            </SimpleBar>
                            {
                              selectedJerseySectionPattern[designKey] && selectedJerseySectionPattern[designKey].filename
                                ? <div className="pt-[30px] pb-[50px]">
                                  {
                                    selectedJerseySectionPattern[designKey].change_size
                                      ? <>
                                        {/* // <!-- Pattern Size --> */}
                                        <div className="form-group borderBottom pattern-range">
                                          <div className="labelOutputWrapper">
                                              <label htmlFor="sliderInputId_03">{t('configurator_3d_size')}</label>
                                              <div className="text-right">
                                                {
                                                  selectedJerseySectionPattern[designKey] && selectedJerseySectionPattern[designKey].scale
                                                    ? ((selectedJerseySectionPattern[designKey].scale || 1) * 100) + '%'
                                                    : 100+'%'
                                                }
                                              </div>
                                          </div>
                                          <InputRange
                                            maxValue={2}
                                            minValue={selectedJerseySectionPattern[designKey].tileable ? 0.1 : 1}
                                            value={selectedJerseySectionPattern[designKey] ? selectedJerseySectionPattern[designKey].scale || 1 : 1}
                                            onChange={onPatternScaleHandler}
                                            step={0.01}
                                          />
                                        </div>
                                        {/* // <!-- End of Pattern Size --> */}
                                      </>
                                      : <></>
                                  }
                                  {
                                    selectedJerseySectionPattern[designKey].move_vertically
                                      ? <>
                                        {/* // <!-- Pattern Top --> */}
                                        <div className="form-group borderBottom pattern-range">
                                          <div className="labelOutputWrapper">
                                            <label htmlFor="sliderInputId_03">{t('configurator_3d_top')}</label>
                                          </div>
                                          <InputRange
                                            maxValue={100}
                                            minValue={-100}
                                            value={selectedJerseySectionPattern[designKey] ? selectedJerseySectionPattern[designKey].top || 0 : 0}
                                            onChange={onPatternMoveTopHandler}
                                            step={0.01}
                                          />
                                        </div>
                                        {/* // <!-- End of Pattern Top --> */}
                                      </>
                                      : <></>
                                  }
                                  {
                                    selectedJerseySectionPattern[designKey].move_horizontally
                                      ? <>
                                        {/* // <!-- Pattern Left --> */}
                                        <div className="form-group borderBottom pattern-range">
                                          <div className="labelOutputWrapper">
                                            <label htmlFor="sliderInputId_03">{t('configurator_3d_left')}</label>
                                          </div>
                                          <InputRange
                                            maxValue={100}
                                            minValue={-100}
                                            value={selectedJerseySectionPattern[designKey] ? selectedJerseySectionPattern[designKey].left || 0 : 0}
                                            onChange={onPatternMoveLeftHandler}
                                            step={0.01}
                                          />
                                        </div>
                                        {/* // <!-- End of Pattern Right --> */}
                                      </>
                                      : <></>
                                  }

                                  {/* <!-- Pattern Color / Background Color --> */}
                                  <div className="pattern-color-tab tabs">
                                    <ul className="tabs__list">
                                      <li
                                        className={"tabs__li" + (selectTab === SelectPatternFillColorArea.Pattern ? ' text-[#141414]' : '')}
                                        onClick={() => setSelectTab(SelectPatternFillColorArea.Pattern)}
                                      >
                                        { t('configurator_3d_pattern_color') }
                                      </li>
                                      <li
                                        className={"tabs__li " + (selectTab === SelectPatternFillColorArea.Bg ? ' text-[#141414]' : '')}
                                        onClick={() => setSelectTab(SelectPatternFillColorArea.Bg)}
                                      >
                                        { t('configurator_3d_bg_color') }
                                      </li>
                                    </ul>
                                    {/* <!-- Pattern Color --> */}
                                    <div
                                      className={
                                        "tabs__content "
                                        + (selectTab === SelectPatternFillColorArea.Pattern ? ' active' : '')
                                      }
                                    >
                                      <div className="colorWrapper colorWrapper--responsive">
                                        {
                                          uniqueColorList.map((colorItem, index) =>
                                            <div
                                              key={'colorListPatternOptions' + index  + colorItem.description}
                                              className={
                                                "itemColor "
                                                + (selectedJerseySectionPattern[designKey] && ('#' + colorItem.colorHexCode === selectedJerseySectionPattern[designKey].fill) ? 'active' : '')
                                              }
                                              onClick={() => handleOnSelectPatternFill(designKey, '#'+colorItem.colorHexCode)}
                                            >
                                              <div
                                                className="itemColor--bgColor tooltip"
                                                data-tooltip={colorItem.description}
                                                style={{
                                                  backgroundColor: '#' + colorItem.colorHexCode
                                                }}
                                              >
                                                <label className="tooltipBubble">
                                                  {colorItem.description}
                                                </label>
                                              </div>
                                            </div>
                                          )
                                        }
                                      </div>
                                    </div>
                                    {/* <!-- End of Pattern Color --> */}
                                    {/* <!-- Background Color --> */}
                                    <div
                                      className={
                                        "tabs__content "
                                        + (selectTab === SelectPatternFillColorArea.Bg ? ' active' : '')
                                      }
                                    >
                                      <div className="colorWrapper colorWrapper--responsive">
                                        {
                                          uniqueColorList.map((colorItem, index) =>
                                            <div
                                              key={'colorBgListPatternOptions' + index + colorItem.description}
                                              className={
                                                "itemColor "
                                                + (selectedJerseySectionColor[designKey] && ('#' + colorItem.colorHexCode === selectedJerseySectionColor[designKey]) ? 'active' : '')
                                              }
                                              onClick={() => onSelectBgColor(designKey, '#' + colorItem.colorHexCode)}
                                            >
                                              <div
                                                className="itemColor--bgColor tooltip"
                                                data-tooltip={colorItem.description}
                                                style={{
                                                  backgroundColor: '#' + colorItem.colorHexCode
                                                }}
                                              >
                                                <label className="tooltipBubble">
                                                  {colorItem.description}
                                                </label>
                                              </div>
                                            </div>
                                          )
                                        }
                                      </div>
                                    </div>
                                    {/* <!-- End of Backgrounf Color --> */}
                                  </div>
                                  {/* <!-- End of Pattern Color / Background Color --> */}
                                </div>
                                : <></>
                            }
                          </>
                          : <></>
                      }
                    </div>
                  )
                  : <></>
              }
            </>
            : <></>
        }
        {
          selectTabMobile === SelectPatternTabMobile.Backside
            ? <>
              <div className="pattern-list-wrapper pl-[8px] pr-[20px] flex flex-col min-h-full mb-[10px]">
                <button
                  className="flex justify-between items-center my-2"
                  onClick={() => setShowMoreOption({
                    ...showMoreOption,
                    [JerseySection.Back]: !showMoreOption[JerseySection.Back]
                  })}
                >
                  <p className="text-[14px] font-semibold">{ t('configurator_3d_backside') }</p>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className={
                    "icon transform transition-all duration-500"
                    + (showMoreOption[JerseySection.Back] ? ' rotate-90' : ' rotate-0')
                  }
                  />
                </button>
                {
                  showMoreOption[JerseySection.Back]
                    ? <>
                      <SimpleBar
                        style={{
                          height: 74,
                          width: '100%',
                        }}
                        autoHide={false}
                      >
                        <div className="flex gap-4 h-[72px]">
                          <button
                            className={
                              "p-3 hover:bg-[#f0f0f0] p-[11px] h-[66px]"
                              + (selectedJerseySectionPattern[JerseySection.Back] && selectedJerseySectionPattern[JerseySection.Back].filename === '' ? " bg-[#f0f0f0] border-b-2 border-[#008AC9]" : "")
                            }
                            onClick={() => handleOnSelectPattern(JerseySection.Back, null)}
                          >
                            <div className="relative bg-white w-[43px] h-[43px] border">
                              <div className="absolute top-0 left-1/2 w-0.5 h-full bg-[#EB0000] transform rotate-45" />
                            </div>
                          </button>
                          {
                            allPatternOptions.map((item, index) =>
                              <button
                                key={'allPatternOptionJerseySection.Back' + index}
                                className={
                                  "p-3 hover:bg-[#f0f0f0] p-[11px] h-[66px]"
                                  + (selectedJerseySectionPattern[JerseySection.Back] && selectedJerseySectionPattern[JerseySection.Back].filename === item.filename ? " bg-[#f0f0f0] border-b-2 border-[#008AC9]" : "")
                                }
                                onClick={() => handleOnSelectPattern(JerseySection.Back, item)}
                              >
                                <div key={'allUnSelectedPattern.Front' + index} className="relative bg-white w-[43px] h-[43px] border">
                                  <div
                                    style={{
                                      backgroundImage: `url('${item.filename.replace('https://localhost', 'https://jako-api.dc-test.de')}')`
                                    }}
                                    className="absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat"
                                  />
                                </div>
                              </button>
                            )
                          }
                        </div>
                      </SimpleBar>
                      {
                        selectedJerseySectionPattern[JerseySection.Back] && selectedJerseySectionPattern[JerseySection.Back].filename
                          ? <div className="pt-[30px] pb-[50px]">
                            {
                              selectedJerseySectionPattern[JerseySection.Back].change_size
                                ? <>
                                  {/* // <!-- Pattern Size --> */}
                                  <div className="form-group borderBottom pattern-range">
                                    <div className="labelOutputWrapper">
                                        <label htmlFor="sliderInputId_03">{t('configurator_3d_size')}</label>
                                        <div className="text-right">
                                          {
                                            selectedJerseySectionPattern[JerseySection.Back] && selectedJerseySectionPattern[JerseySection.Back].scale
                                              ? (selectedJerseySectionPattern[JerseySection.Back].scale * 100) + '%'
                                              : 100+'%'
                                          }
                                        </div>
                                    </div>
                                    <InputRange
                                      maxValue={2}
                                      minValue={selectedJerseySectionPattern[JerseySection.Back].tileable ? 0.1 : 1}
                                      value={selectedJerseySectionPattern[JerseySection.Back] ? selectedJerseySectionPattern[JerseySection.Back].scale || 1 : 1}
                                      onChange={onPatternScaleHandler}
                                      step={0.01}
                                    />
                                  </div>
                                  {/* // <!-- End of Pattern Size --> */}
                                </>
                                : <></>
                            }
                            {
                              selectedJerseySectionPattern[JerseySection.Back].move_vertically
                                ? <>
                                  {/* // <!-- Pattern Top --> */}
                                    <div className="form-group borderBottom pattern-range">
                                      <div className="labelOutputWrapper">
                                        <label htmlFor="sliderInputId_03">{t('configurator_3d_top')}</label>
                                      </div>
                                      <InputRange
                                        maxValue={100}
                                        minValue={-100}
                                        value={selectedJerseySectionPattern[JerseySection.Back] ? selectedJerseySectionPattern[JerseySection.Back].top || 0 : 0}
                                        onChange={onPatternMoveTopHandler}
                                        step={0.01}
                                      />
                                    </div>
                                  {/* // <!-- End of Pattern Top --> */}
                                </>
                                : <></>
                            }
                            {
                              selectedJerseySectionPattern[JerseySection.Back].move_horizontally
                                ? <>
                                  {/* // <!-- Pattern Left --> */}
                                  <div className="form-group borderBottom pattern-range">
                                    <div className="labelOutputWrapper">
                                      <label htmlFor="sliderInputId_03">{t('configurator_3d_left')}</label>
                                    </div>
                                    <InputRange
                                      maxValue={100}
                                      minValue={-100}
                                      value={selectedJerseySectionPattern[JerseySection.Back] ? selectedJerseySectionPattern[JerseySection.Back].left || 0 : 0}
                                      onChange={onPatternMoveLeftHandler}
                                      step={0.01}
                                    />
                                  </div>
                                  {/* // <!-- End of Pattern Right --> */}
                                </>
                                : <></>
                            }

                            {/* <!-- Pattern Color / Background Color --> */}
                            <div className="pattern-color-tab tabs">
                              <ul className="tabs__list">
                                <li
                                  className={"tabs__li" + (selectTab === SelectPatternFillColorArea.Pattern ? ' text-[#141414]' : '')}
                                  onClick={() => setSelectTab(SelectPatternFillColorArea.Pattern)}
                                >
                                  { t('configurator_3d_pattern_color') }
                                </li>
                                <li
                                  className={"tabs__li " + (selectTab === SelectPatternFillColorArea.Bg ? ' text-[#141414]' : '')}
                                  onClick={() => setSelectTab(SelectPatternFillColorArea.Bg)}
                                >
                                  { t('configurator_3d_bg_color') }
                                </li>
                              </ul>
                              {/* <!-- Pattern Color --> */}
                              <div
                                className={
                                  "tabs__content "
                                  + (selectTab === SelectPatternFillColorArea.Pattern ? ' active' : '')
                                }
                              >
                                <div className="colorWrapper colorWrapper--responsive">
                                  {
                                    uniqueColorList.map((colorItem, index) =>
                                      <div
                                        key={'colorListPatternOptions' + index  + colorItem.description}
                                        className={
                                          "itemColor "
                                          + (selectedJerseySectionPattern[JerseySection.Back] && ('#' + colorItem.colorHexCode === selectedJerseySectionPattern[JerseySection.Back].fill) ? 'active' : '')
                                        }
                                        onClick={() => handleOnSelectPatternFill(JerseySection.Back, '#'+colorItem.colorHexCode)}
                                      >
                                        <div
                                          className="itemColor--bgColor tooltip"
                                          data-tooltip={colorItem.description}
                                          style={{
                                            backgroundColor: '#' + colorItem.colorHexCode
                                          }}
                                        >
                                          <label className="tooltipBubble">
                                            {colorItem.description}
                                          </label>
                                        </div>
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                              {/* <!-- End of Pattern Color --> */}
                              {/* <!-- Background Color --> */}
                              <div
                                className={
                                  "tabs__content "
                                  + (selectTab === SelectPatternFillColorArea.Bg ? ' active' : '')
                                }
                              >
                                <div className="colorWrapper colorWrapper--responsive">
                                  {
                                    uniqueColorList.map((colorItem, index) =>
                                      <div
                                        key={'colorBgListPatternOptions' + index + colorItem.description}
                                        className={
                                          "itemColor "
                                          + (selectedJerseySectionColor[JerseySection.Back] && ('#' + colorItem.colorHexCode === selectedJerseySectionColor[JerseySection.Back]) ? 'active' : '')
                                        }
                                        onClick={() => onSelectBgColor(JerseySection.Back, '#' + colorItem.colorHexCode)}
                                      >
                                        <div
                                          className="itemColor--bgColor tooltip"
                                          data-tooltip={colorItem.description}
                                          style={{
                                            backgroundColor: '#' + colorItem.colorHexCode
                                          }}
                                        >
                                          <label className="tooltipBubble">
                                            {colorItem.description}
                                          </label>
                                        </div>
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                              {/* <!-- End of Backgrounf Color --> */}
                            </div>
                            {/* <!-- End of Pattern Color / Background Color --> */}
                          </div>
                          : <></>
                      }
                    </>
                    : <></>
                }
              </div>
              {
                backDesignAreaKeys.length
                  ? backDesignAreaKeys.map((designKey: any, index: number) =>
                    <div
                      key={'backDesignAreaKeysPatternTrigger' + designKey + index}
                      className="pattern-list-wrapper pl-[8px] pr-[20px] flex flex-col min-h-full mb-[10px]"
                    >
                      <button
                        className="flex justify-between items-center my-2"
                        onClick={() => setShowMoreOption({
                          ...showMoreOption,
                          [designKey]: !showMoreOption[designKey]
                        })}
                      >
                        <p className="text-[14px] font-semibold my-2">{t('configurator_3d_shirt_design_no') + ' ' + (index + 1)}</p>
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className={
                          "icon transform transition-all duration-500"
                          + (showMoreOption[designKey] ? ' rotate-90' : ' rotate-0')
                        }
                        />
                      </button>
                      {
                        showMoreOption[designKey]
                          ? <>
                            <SimpleBar
                              style={{
                                height: 74,
                                width: '100%',
                              }}
                              autoHide={false}
                            >
                              <div className="flex gap-4 h-[72px]">
                                <button
                                  className={
                                    "p-3 hover:bg-[#f0f0f0] p-[11px] h-[66px]"
                                    + (selectedJerseySectionPattern[designKey] && selectedJerseySectionPattern[designKey].filename === '' ? " bg-[#f0f0f0] border-b-2 border-[#008AC9]" : "")
                                  }
                                  onClick={() => handleOnSelectPattern(designKey, null)}
                                >
                                  <div className="relative bg-white w-[43px] h-[43px] border">
                                    <div className="absolute top-0 left-1/2 w-0.5 h-full bg-[#EB0000] transform rotate-45" />
                                  </div>
                                </button>
                                {
                                  allPatternOptions.map((item, index) =>
                                    <button
                                      key={'allPatternOption' + designKey + index}
                                      className={
                                        "p-3 hover:bg-[#f0f0f0] p-[11px] h-[66px]"
                                        + (selectedJerseySectionPattern[designKey] && selectedJerseySectionPattern[designKey].filename === item.filename ? " bg-[#f0f0f0] border-b-2 border-[#008AC9]" : "")
                                      }
                                      onClick={() => handleOnSelectPattern(designKey, item)}
                                    >
                                      <div key={'allUnSelectedPattern.Front' + index} className="relative bg-white w-[43px] h-[43px] border">
                                        <div
                                          style={{
                                            backgroundImage: `url('${item.filename.replace('https://localhost', 'https://jako-api.dc-test.de')}')`
                                          }}
                                          className="absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat"
                                        />
                                      </div>
                                    </button>
                                  )
                                }
                              </div>
                            </SimpleBar>
                            {
                              selectedJerseySectionPattern[designKey] && selectedJerseySectionPattern[designKey].filename
                                ? <div className="pt-[30px] pb-[50px]">
                                  {
                                    selectedJerseySectionPattern[designKey].change_size
                                      ? <>
                                        {/* // <!-- Pattern Size --> */}
                                        <div className="form-group borderBottom pattern-range">
                                          <div className="labelOutputWrapper">
                                              <label htmlFor="sliderInputId_03">{t('configurator_3d_size')}</label>
                                              <div className="text-right">
                                                {
                                                  selectedJerseySectionPattern[designKey] && selectedJerseySectionPattern[designKey].scale
                                                    ? ((selectedJerseySectionPattern[designKey].scale || 1) * 100) + '%'
                                                    : 100+'%'
                                                }
                                              </div>
                                          </div>
                                          <InputRange
                                            maxValue={2}
                                            minValue={selectedJerseySectionPattern[designKey].tileable ? 0.1 : 1}
                                            value={selectedJerseySectionPattern[designKey] ? selectedJerseySectionPattern[designKey].scale || 1 : 1}
                                            onChange={onPatternScaleHandler}
                                            step={0.01}
                                          />
                                        </div>
                                        {/* // <!-- End of Pattern Size --> */}
                                      </>
                                      : <></>
                                  }
                                  {
                                    selectedJerseySectionPattern[designKey].move_vertically
                                      ? <>
                                        {/* // <!-- Pattern Top --> */}
                                        <div className="form-group borderBottom pattern-range">
                                          <div className="labelOutputWrapper">
                                            <label htmlFor="sliderInputId_03">{t('configurator_3d_top')}</label>
                                          </div>
                                          <InputRange
                                            maxValue={100}
                                            minValue={-100}
                                            value={selectedJerseySectionPattern[designKey] ? selectedJerseySectionPattern[designKey].top || 0 : 0}
                                            onChange={onPatternMoveTopHandler}
                                            step={0.01}
                                          />
                                        </div>
                                        {/* // <!-- End of Pattern Top --> */}
                                      </>
                                      : <></>
                                  }
                                  {
                                    selectedJerseySectionPattern[designKey].move_horizontally
                                      ? <>
                                        {/* // <!-- Pattern Left --> */}
                                        <div className="form-group borderBottom pattern-range">
                                          <div className="labelOutputWrapper">
                                            <label htmlFor="sliderInputId_03">{t('configurator_3d_left')}</label>
                                          </div>
                                          <InputRange
                                            maxValue={100}
                                            minValue={-100}
                                            value={selectedJerseySectionPattern[designKey] ? selectedJerseySectionPattern[designKey].left || 0 : 0}
                                            onChange={onPatternMoveLeftHandler}
                                            step={0.01}
                                          />
                                        </div>
                                        {/* // <!-- End of Pattern Right --> */}
                                      </>
                                      : <></>
                                  }
                                  {/* <!-- Pattern Color / Background Color --> */}
                                  <div className="pattern-color-tab tabs">
                                    <ul className="tabs__list">
                                      <li
                                        className={"tabs__li" + (selectTab === SelectPatternFillColorArea.Pattern ? ' text-[#141414]' : '')}
                                        onClick={() => setSelectTab(SelectPatternFillColorArea.Pattern)}
                                      >
                                        { t('configurator_3d_pattern_color') }
                                      </li>
                                      <li
                                        className={"tabs__li " + (selectTab === SelectPatternFillColorArea.Bg ? ' text-[#141414]' : '')}
                                        onClick={() => setSelectTab(SelectPatternFillColorArea.Bg)}
                                      >
                                        { t('configurator_3d_bg_color') }
                                      </li>
                                    </ul>
                                    {/* <!-- Pattern Color --> */}
                                    <div
                                      className={
                                        "tabs__content "
                                        + (selectTab === SelectPatternFillColorArea.Pattern ? ' active' : '')
                                      }
                                    >
                                      <div className="colorWrapper colorWrapper--responsive">
                                        {
                                          uniqueColorList.map((colorItem, index) =>
                                            <div
                                              key={'colorListPatternOptions' + index  + colorItem.description}
                                              className={
                                                "itemColor "
                                                + (selectedJerseySectionPattern[designKey] && ('#' + colorItem.colorHexCode === selectedJerseySectionPattern[designKey].fill) ? 'active' : '')
                                              }
                                              onClick={() => handleOnSelectPatternFill(designKey, '#'+colorItem.colorHexCode)}
                                            >
                                              <div
                                                className="itemColor--bgColor tooltip"
                                                data-tooltip={colorItem.description}
                                                style={{
                                                  backgroundColor: '#' + colorItem.colorHexCode
                                                }}
                                              >
                                                <label className="tooltipBubble">
                                                  {colorItem.description}
                                                </label>
                                              </div>
                                            </div>
                                          )
                                        }
                                      </div>
                                    </div>
                                    {/* <!-- End of Pattern Color --> */}
                                    {/* <!-- Background Color --> */}
                                    <div
                                      className={
                                        "tabs__content "
                                        + (selectTab === SelectPatternFillColorArea.Bg ? ' active' : '')
                                      }
                                    >
                                      <div className="colorWrapper colorWrapper--responsive">
                                        {
                                          uniqueColorList.map((colorItem, index) =>
                                            <div
                                              key={'colorBgListPatternOptions' + index + colorItem.description}
                                              className={
                                                "itemColor "
                                                + (selectedJerseySectionColor[designKey] && ('#' + colorItem.colorHexCode === selectedJerseySectionColor[designKey]) ? 'active' : '')
                                              }
                                              onClick={() => onSelectBgColor(designKey, '#' + colorItem.colorHexCode)}
                                            >
                                              <div
                                                className="itemColor--bgColor tooltip"
                                                data-tooltip={colorItem.description}
                                                style={{
                                                  backgroundColor: '#' + colorItem.colorHexCode
                                                }}
                                              >
                                                <label className="tooltipBubble">
                                                  {colorItem.description}
                                                </label>
                                              </div>
                                            </div>
                                          )
                                        }
                                      </div>
                                    </div>
                                    {/* <!-- End of Backgrounf Color --> */}
                                  </div>
                                  {/* <!-- End of Pattern Color / Background Color --> */}
                                </div>
                                : <></>
                            }
                          </>
                          : <></>
                      }
                    </div>
                  )
                  : <></>
              }
            </>
            : <></>
        }
        {
          selectTabMobile === SelectPatternTabMobile.Sleeve
            ? <>
              <div className="pattern-list-wrapper pl-[8px] pr-[20px] flex flex-col min-h-full mb-[10px]">
                <button
                  className="flex justify-between items-center my-2"
                  onClick={() => setShowMoreOption({
                    ...showMoreOption,
                    [JerseySection.Left]: !showMoreOption[JerseySection.Left]
                  })}
                >
                  <p className="text-[14px] font-semibold">{ t('configurator_3d_sleeve_left') }</p>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className={
                    "icon transform transition-all duration-500"
                    + (showMoreOption[JerseySection.Left] ? ' rotate-90' : ' rotate-0')
                  }
                  />
                </button>
                {
                  showMoreOption[JerseySection.Left]
                    ? <>
                      <SimpleBar
                        style={{
                          height: 74,
                          width: '100%',
                        }}
                        autoHide={false}
                      >
                        <div className="flex gap-4 h-[72px]">
                          <button
                            className={
                              "p-3 hover:bg-[#f0f0f0] p-[11px] h-[66px]"
                              + (selectedJerseySectionPattern[JerseySection.Left] && selectedJerseySectionPattern[JerseySection.Left].filename === '' ? " bg-[#f0f0f0] border-b-2 border-[#008AC9]" : "")
                            }
                            onClick={() => handleOnSelectPattern(JerseySection.Left, null)}
                          >
                            <div className="relative bg-white w-[43px] h-[43px] border">
                              <div className="absolute top-0 left-1/2 w-0.5 h-full bg-[#EB0000] transform rotate-45" />
                            </div>
                          </button>
                          {
                            allPatternOptions.map((item, index) =>
                              <button
                                key={'allPatternOptionJerseySection.Left' + index}
                                className={
                                  "p-3 hover:bg-[#f0f0f0] p-[11px] h-[66px]"
                                  + (selectedJerseySectionPattern[JerseySection.Left] && selectedJerseySectionPattern[JerseySection.Left].filename === item.filename ? " bg-[#f0f0f0] border-b-2 border-[#008AC9]" : "")
                                }
                                onClick={() => handleOnSelectPattern(JerseySection.Left, item)}
                              >
                                <div key={'allUnSelectedPattern.Front' + index} className="relative bg-white w-[43px] h-[43px] border">
                                  <div
                                    style={{
                                      backgroundImage: `url('${item.filename.replace('https://localhost', 'https://jako-api.dc-test.de')}')`
                                    }}
                                    className="absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat"
                                  />
                                </div>
                              </button>
                            )
                          }
                        </div>
                      </SimpleBar>
                      {
                        selectedJerseySectionPattern[JerseySection.Left] && selectedJerseySectionPattern[JerseySection.Left].filename
                          ? <div className="pt-[30px] pb-[50px]">
                            {
                              selectedJerseySectionPattern[JerseySection.Left].change_size
                                ? <>
                                  {/* // <!-- Pattern Size --> */}
                                  <div className="form-group borderBottom pattern-range">
                                    <div className="labelOutputWrapper">
                                        <label htmlFor="sliderInputId_03">{t('configurator_3d_size')}</label>
                                        <div className="text-right">
                                          {
                                            selectedJerseySectionPattern[JerseySection.Left] && selectedJerseySectionPattern[JerseySection.Left].scale
                                              ? ((selectedJerseySectionPattern[JerseySection.Left].scale || 1) * 100) + '%'
                                              : 100+'%'
                                          }
                                        </div>
                                    </div>
                                    <InputRange
                                      maxValue={2}
                                      minValue={selectedJerseySectionPattern[JerseySection.Left].tileable ? 0.1 : 1}
                                      value={selectedJerseySectionPattern[JerseySection.Left] ? selectedJerseySectionPattern[JerseySection.Left].scale || 1 : 1}
                                      onChange={onPatternScaleHandler}
                                      step={0.01}
                                    />
                                  </div>
                                  {/* // <!-- End of Pattern Size --> */}
                                </>
                                : <></>
                            }
                            {
                              selectedJerseySectionPattern[JerseySection.Left].move_vertically
                                ? <>
                                  {/* // <!-- Pattern Top --> */}
                                  <div className="form-group borderBottom pattern-range">
                                    <div className="labelOutputWrapper">
                                      <label htmlFor="sliderInputId_03">{t('configurator_3d_top')}</label>
                                    </div>
                                    <InputRange
                                      maxValue={100}
                                      minValue={-100}
                                      value={selectedJerseySectionPattern[JerseySection.Left] ? selectedJerseySectionPattern[JerseySection.Left].top || 0 : 0}
                                      onChange={onPatternMoveTopHandler}
                                      step={0.01}
                                    />
                                  </div>
                                  {/* // <!-- End of Pattern Top --> */}
                                </>
                                : <></>
                            }
                            {
                              selectedJerseySectionPattern[JerseySection.Left].move_horizontally
                                ? <>
                                  {/* // <!-- Pattern Left --> */}
                                  <div className="form-group borderBottom pattern-range">
                                    <div className="labelOutputWrapper">
                                      <label htmlFor="sliderInputId_03">{t('configurator_3d_left')}</label>
                                    </div>
                                    <InputRange
                                      maxValue={100}
                                      minValue={-100}
                                      value={selectedJerseySectionPattern[JerseySection.Left] ? selectedJerseySectionPattern[JerseySection.Left].left || 0 : 0}
                                      onChange={onPatternMoveLeftHandler}
                                      step={0.01}
                                    />
                                  </div>
                                  {/* // <!-- End of Pattern Right --> */}
                                </>
                                : <></>
                            }
                            {/* <!-- Pattern Color / Background Color --> */}
                            <div className="pattern-color-tab tabs">
                              <ul className="tabs__list">
                                <li
                                  className={"tabs__li" + (selectTab === SelectPatternFillColorArea.Pattern ? ' text-[#141414]' : '')}
                                  onClick={() => setSelectTab(SelectPatternFillColorArea.Pattern)}
                                >
                                  { t('configurator_3d_pattern_color') }
                                </li>
                                <li
                                  className={"tabs__li " + (selectTab === SelectPatternFillColorArea.Bg ? ' text-[#141414]' : '')}
                                  onClick={() => setSelectTab(SelectPatternFillColorArea.Bg)}
                                >
                                  { t('configurator_3d_bg_color') }
                                </li>
                              </ul>
                              {/* <!-- Pattern Color --> */}
                              <div
                                className={
                                  "tabs__content "
                                  + (selectTab === SelectPatternFillColorArea.Pattern ? ' active' : '')
                                }
                              >
                                <div className="colorWrapper colorWrapper--responsive">
                                  {
                                    uniqueColorList.map((colorItem, index) =>
                                      <div
                                        key={'colorListPatternOptions' + index  + colorItem.description}
                                        className={
                                          "itemColor "
                                          + (selectedJerseySectionPattern[JerseySection.Left] && ('#' + colorItem.colorHexCode === selectedJerseySectionPattern[JerseySection.Left].fill) ? 'active' : '')
                                        }
                                        onClick={() => handleOnSelectPatternFill(JerseySection.Left, '#'+colorItem.colorHexCode)}
                                      >
                                        <div
                                          className="itemColor--bgColor tooltip"
                                          data-tooltip={colorItem.description}
                                          style={{
                                            backgroundColor: '#' + colorItem.colorHexCode
                                          }}
                                        >
                                          <label className="tooltipBubble">
                                            {colorItem.description}
                                          </label>
                                        </div>
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                              {/* <!-- End of Pattern Color --> */}
                              {/* <!-- Background Color --> */}
                              <div
                                className={
                                  "tabs__content "
                                  + (selectTab === SelectPatternFillColorArea.Bg ? ' active' : '')
                                }
                              >
                                <div className="colorWrapper colorWrapper--responsive">
                                  {
                                    uniqueColorList.map((colorItem, index) =>
                                      <div
                                        key={'colorBgListPatternOptions' + index + colorItem.description}
                                        className={
                                          "itemColor "
                                          + (selectedJerseySectionColor[JerseySection.Left] && ('#' + colorItem.colorHexCode === selectedJerseySectionColor[JerseySection.Left]) ? 'active' : '')
                                        }
                                        onClick={() => onSelectBgColor(JerseySection.Left, '#' + colorItem.colorHexCode)}
                                      >
                                        <div
                                          className="itemColor--bgColor tooltip"
                                          data-tooltip={colorItem.description}
                                          style={{
                                            backgroundColor: '#' + colorItem.colorHexCode
                                          }}
                                        >
                                          <label className="tooltipBubble">
                                            {colorItem.description}
                                          </label>
                                        </div>
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                              {/* <!-- End of Backgrounf Color --> */}
                            </div>
                            {/* <!-- End of Pattern Color / Background Color --> */}
                          </div>
                          : <></>
                      }
                    </>
                    : <></>
                }
              </div>
              <div className="pattern-list-wrapper pl-[8px] pr-[20px] flex flex-col min-h-full mb-[10px]">
                <button
                  className="flex justify-between items-center my-2"
                  onClick={() => setShowMoreOption({
                    ...showMoreOption,
                    [JerseySection.Right]: !showMoreOption[JerseySection.Right]
                  })}
                >
                  <p className="text-[14px] font-semibold">{ t('configurator_3d_sleeve_right') }</p>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className={
                    "icon transform transition-all duration-500"
                    + (showMoreOption[JerseySection.Right] ? ' rotate-90' : ' rotate-0')
                  }
                  />
                </button>
                {
                  showMoreOption[JerseySection.Right]
                    ? <>
                      <SimpleBar
                        style={{
                          height: 74,
                          width: '100%',
                        }}
                        autoHide={false}
                      >
                        <div className="flex gap-4 h-[72px]">
                          <button
                            className={
                              "p-3 hover:bg-[#f0f0f0] p-[11px] h-[66px]"
                              + (selectedJerseySectionPattern[JerseySection.Right] && selectedJerseySectionPattern[JerseySection.Right].filename === '' ? " bg-[#f0f0f0] border-b-2 border-[#008AC9]" : "")
                            }
                            onClick={() => handleOnSelectPattern(JerseySection.Right, null)}
                          >
                            <div className="relative bg-white w-[43px] h-[43px] border">
                              <div className="absolute top-0 left-1/2 w-0.5 h-full bg-[#EB0000] transform rotate-45" />
                            </div>
                          </button>
                          {
                            allPatternOptions.map((item, index) =>
                              <button
                                key={'allPatternOptionJerseySection.Right' + index}
                                className={
                                  "p-3 hover:bg-[#f0f0f0] p-[11px] h-[66px]"
                                  + (selectedJerseySectionPattern[JerseySection.Right] && selectedJerseySectionPattern[JerseySection.Right].filename === item.filename ? " bg-[#f0f0f0] border-b-2 border-[#008AC9]" : "")
                                }
                                onClick={() => handleOnSelectPattern(JerseySection.Right, item)}
                              >
                                <div key={'allUnSelectedPattern.Front' + index} className="relative bg-white w-[43px] h-[43px] border">
                                  <div
                                    style={{
                                      backgroundImage: `url('${item.filename.replace('https://localhost', 'https://jako-api.dc-test.de')}')`
                                    }}
                                    className="absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat"
                                  />
                                </div>
                              </button>
                            )
                          }
                        </div>
                      </SimpleBar>
                      {
                        selectedJerseySectionPattern[JerseySection.Right] && selectedJerseySectionPattern[JerseySection.Right].filename
                          ? <div className="pt-[30px] pb-[50px]">
                            {
                              selectedJerseySectionPattern[JerseySection.Right].change_size
                                ? <>
                                  {/* // <!-- Pattern Size --> */}
                                  <div className="form-group borderBottom pattern-range">
                                    <div className="labelOutputWrapper">
                                        <label htmlFor="sliderInputId_03">{t('configurator_3d_size')}</label>
                                        <div className="text-right">
                                          {
                                            selectedJerseySectionPattern[JerseySection.Right] && selectedJerseySectionPattern[JerseySection.Right].scale
                                              ? ((selectedJerseySectionPattern[JerseySection.Right].scale || 1) * 100) + '%'
                                              : 100+'%'
                                          }
                                        </div>
                                    </div>
                                    <InputRange
                                      maxValue={2}
                                      minValue={selectedJerseySectionPattern[JerseySection.Right].tileable ? 0.1 : 1}
                                      value={selectedJerseySectionPattern[JerseySection.Right] ? selectedJerseySectionPattern[JerseySection.Right].scale || 1 : 1}
                                      onChange={onPatternScaleHandler}
                                      step={0.01}
                                    />
                                  </div>
                                  {/* // <!-- End of Pattern Size --> */}
                                </>
                                : <></>
                            }
                            {
                              selectedJerseySectionPattern[JerseySection.Right].move_vertically
                                ? <>
                                  {/* // <!-- Pattern Top --> */}
                                  <div className="form-group borderBottom pattern-range">
                                    <div className="labelOutputWrapper">
                                      <label htmlFor="sliderInputId_03">{t('configurator_3d_top')}</label>
                                    </div>
                                    <InputRange
                                      maxValue={100}
                                      minValue={-100}
                                      value={selectedJerseySectionPattern[JerseySection.Right] ? selectedJerseySectionPattern[JerseySection.Right].top || 0 : 0}
                                      onChange={onPatternMoveTopHandler}
                                      step={0.01}
                                    />
                                  </div>
                                  {/* // <!-- End of Pattern Top --> */}
                                </>
                                : <></>
                            }
                            {
                              selectedJerseySectionPattern[JerseySection.Right].move_horizontally
                                ? <>
                                  {/* // <!-- Pattern Left --> */}
                                  <div className="form-group borderBottom pattern-range">
                                    <div className="labelOutputWrapper">
                                      <label htmlFor="sliderInputId_03">{t('configurator_3d_left')}</label>
                                    </div>
                                    <InputRange
                                      maxValue={100}
                                      minValue={-100}
                                      value={selectedJerseySectionPattern[JerseySection.Right] ? selectedJerseySectionPattern[JerseySection.Right].left || 0 : 0}
                                      onChange={onPatternMoveLeftHandler}
                                      step={0.01}
                                    />
                                  </div>
                                  {/* // <!-- End of Pattern Right --> */}
                                </>
                                : <></>
                            }
                            {/* <!-- Pattern Color / Background Color --> */}
                            <div className="pattern-color-tab tabs">
                              <ul className="tabs__list">
                                <li
                                  className={"tabs__li" + (selectTab === SelectPatternFillColorArea.Pattern ? ' text-[#141414]' : '')}
                                  onClick={() => setSelectTab(SelectPatternFillColorArea.Pattern)}
                                >
                                  { t('configurator_3d_pattern_color') }
                                </li>
                                <li
                                  className={"tabs__li " + (selectTab === SelectPatternFillColorArea.Bg ? ' text-[#141414]' : '')}
                                  onClick={() => setSelectTab(SelectPatternFillColorArea.Bg)}
                                >
                                  { t('configurator_3d_bg_color') }
                                </li>
                              </ul>
                              {/* <!-- Pattern Color --> */}
                              <div
                                className={
                                  "tabs__content "
                                  + (selectTab === SelectPatternFillColorArea.Pattern ? ' active' : '')
                                }
                              >
                                <div className="colorWrapper colorWrapper--responsive">
                                  {
                                    uniqueColorList.map((colorItem, index) =>
                                      <div
                                        key={'colorListPatternOptions' + index  + colorItem.description}
                                        className={
                                          "itemColor "
                                          + (selectedJerseySectionPattern[JerseySection.Right] && ('#' + colorItem.colorHexCode === selectedJerseySectionPattern[JerseySection.Right].fill) ? 'active' : '')
                                        }
                                        onClick={() => handleOnSelectPatternFill(JerseySection.Right, '#'+colorItem.colorHexCode)}
                                      >
                                        <div
                                          className="itemColor--bgColor tooltip"
                                          data-tooltip={colorItem.description}
                                          style={{
                                            backgroundColor: '#' + colorItem.colorHexCode
                                          }}
                                        >
                                          <label className="tooltipBubble">
                                            {colorItem.description}
                                          </label>
                                        </div>
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                              {/* <!-- End of Pattern Color --> */}
                              {/* <!-- Background Color --> */}
                              <div
                                className={
                                  "tabs__content "
                                  + (selectTab === SelectPatternFillColorArea.Bg ? ' active' : '')
                                }
                              >
                                <div className="colorWrapper colorWrapper--responsive">
                                  {
                                    uniqueColorList.map((colorItem, index) =>
                                      <div
                                        key={'colorBgListPatternOptions' + index + colorItem.description}
                                        className={
                                          "itemColor "
                                          + (selectedJerseySectionColor[JerseySection.Right] && ('#' + colorItem.colorHexCode === selectedJerseySectionColor[JerseySection.Right]) ? 'active' : '')
                                        }
                                        onClick={() => onSelectBgColor(JerseySection.Right, '#' + colorItem.colorHexCode)}
                                      >
                                        <div
                                          className="itemColor--bgColor tooltip"
                                          data-tooltip={colorItem.description}
                                          style={{
                                            backgroundColor: '#' + colorItem.colorHexCode
                                          }}
                                        >
                                          <label className="tooltipBubble">
                                            {colorItem.description}
                                          </label>
                                        </div>
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                              {/* <!-- End of Backgrounf Color --> */}
                            </div>
                            {/* <!-- End of Pattern Color / Background Color --> */}
                          </div>
                          : <></>
                      }
                    </>
                    : <></>
                }
              </div>
            </>
            : <></>
        }
      </SimpleBar>
      {
        hasMoreScrollContainer
          ? <div
            className={
              "absolute w-full h-[40px] bottom-[-1px] left-0 bg-gradient-to-b from-transparent to-white transition-all duration-200"
              + (hasMoreScrollContainer ? ' opacity-100' : ' opacity-0')
            }
          />
          : <></>
      }
    </div>
    : <>
      <div className="h1 text-center">{ t('configurator_3d_add_pattern') }</div>
      <div className="text-center marginBottom--small">{ t('configurator_3d_add_pattern_text') }</div>
      <div className="pattern-tab slideContent">
        {
          ModelStyle.Shirt === modelStyle || ModelStyle.ShirtNoSleeve === modelStyle
            ? <div className="py-4">
              <div className="slideContainer pb-4">
                <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                  { t('configurator_3d_front_side') }
                </p>
                <ul className="!p-0 list-none">
                  <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                    <p className="text-[14px] text-[#141414] mb-4">
                      <span className="font-semibold">
                      { t('configurator_3d_front_side') }
                      </span>
                    </p>
                    <DesktopPatternPreviewSection designKey={JerseySection.Front} label={t('configurator_3d_front_side')} />
                    <DesktopPatternMoreConfigSection designKey={JerseySection.Front} />
                  </li>
                  {
                    frontDesignAreaKeys.length
                      ? frontDesignAreaKeys.map((designKey: any, index: number) =>
                        <li
                          key={'frontDesignAreaKeys' + index}
                          className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]"
                        >
                          <p className="text-[14px] text-[#141414] mb-4">
                            <span className="font-semibold">
                            { t('configurator_3d_shirt_design_no') + ' ' + (index + 1) }
                            </span>
                          </p>
                          <DesktopPatternPreviewSection designKey={designKey} label={t('configurator_3d_shirt_design_no') + ' ' + (index + 1)} />
                          <DesktopPatternMoreConfigSection designKey={designKey} />
                        </li>
                      )
                      : <></>
                  }
                </ul>
              </div>

              <div className="slideContainer pb-4 mt-10">
                <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                  { t('configurator_3d_backside') }
                </p>
                <ul className="!p-0 list-none">
                  <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                    <p className="text-[14px] text-[#141414] mb-4">
                      <span className="font-semibold">
                      { t('configurator_3d_backside') }
                      </span>
                    </p>
                    <DesktopPatternPreviewSection designKey={JerseySection.Back} label={t('configurator_3d_backside')} />
                    <DesktopPatternMoreConfigSection designKey={JerseySection.Back} />
                  </li>
                  {
                    backDesignAreaKeys.length
                      ? backDesignAreaKeys.map((designKey: any, index: number) =>
                        <li
                          key={'backDesignAreaKeys' + index}
                          className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]"
                        >
                          <p className="text-[14px] text-[#141414] mb-4">
                            <span className="font-semibold">
                            { t('configurator_3d_shirt_design_no') + ' ' + (index + 1) }
                            </span>
                          </p>
                          <DesktopPatternPreviewSection designKey={designKey} label={t('configurator_3d_shirt_design_no') + ' ' + (index + 1)} />
                          <DesktopPatternMoreConfigSection designKey={designKey} />
                        </li>
                      )
                      : <></>
                  }
                </ul>
              </div>
              {
                ModelStyle.ShirtNoSleeve === modelStyle
                  ? <></>
                  : <div className="slideContainer pb-4 mt-10">
                    <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                      { t('configurator_3d_sleeve') }
                    </p>
                    <ul className="!p-0 list-none">
                      <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                        <p className="text-[14px] text-[#141414] mb-4">
                          <span className="font-semibold">
                          { t('configurator_3d_sleeve_left') }
                          </span>
                        </p>
                        <DesktopPatternPreviewSection designKey={JerseySection.Left} label={t('configurator_3d_sleeve_left')} />
                        <DesktopPatternMoreConfigSection designKey={JerseySection.Left} />
                      </li>
                      <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                        <p className="text-[14px] text-[#141414] mb-4">
                          <span className="font-semibold">
                          { t('configurator_3d_sleeve_right') }
                          </span>
                        </p>
                        <DesktopPatternPreviewSection designKey={JerseySection.Right} label={t('configurator_3d_sleeve_right')} />
                        <DesktopPatternMoreConfigSection designKey={JerseySection.Right} />
                      </li>
                    </ul>
                  </div>
              }
            </div>
            : <div className="py-4">
              <div className="slideContainer pb-4">
                <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                  { t('configurator_3d_short') }
                </p>
                <ul className="!p-0 list-none">
                  <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                    <p className="text-[14px] text-[#141414] mb-4">
                      <span className="font-semibold">
                      { t('configurator_3d_sleeve_left') }
                      </span>
                    </p>
                    <DesktopPatternPreviewSection designKey={JerseySection.Left} label={t('configurator_3d_sleeve_right')} />
                    <DesktopPatternMoreConfigSection designKey={JerseySection.Left} />
                  </li>
                  <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                    <p className="text-[14px] text-[#141414] mb-4">
                      <span className="font-semibold">
                      { t('configurator_3d_sleeve_right') }
                      </span>
                    </p>
                    <DesktopPatternPreviewSection designKey={JerseySection.Right} label={t('configurator_3d_sleeve_right')} />
                    <DesktopPatternMoreConfigSection designKey={JerseySection.Right} />
                  </li>
                </ul>
              </div>
            </div>
        }
      </div>
      {
        selectCopyPatternSectionDesktop
          && <div className="absolute inset-0 bg-white p-[20px] z-20">
            <div className="flex flex-col h-full">
              <div className="h1 text-center">{ t('configurator_3d_select_copy_position') }</div>
              <div className="text-center marginBottom--small">{ t('configurator_3d_select_copy_position_text') }</div>

              <div className="pattern-tab slideContent">
                {
                  ModelStyle.Shirt === modelStyle || ModelStyle.ShirtNoSleeve === modelStyle
                    ? <div className="py-4">
                      <div className="slideContainer pb-4">
                        <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                          { t('configurator_3d_front_side') }
                        </p>
                        <ul className="!p-0 list-none">
                          <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                            <p className="text-[14px] text-[#141414] mb-4">
                              <span className="font-semibold">
                                { t('configurator_3d_front_side') }
                              </span>
                            </p>
                            <button className="button_primary" onClick={() => handleCopyPatternSvg(JerseySection.Front)}>
                              { t('configurator_3d_copy') }
                            </button>
                          </li>
                          {
                            frontDesignAreaKeys.length
                              ? frontDesignAreaKeys.map((designKey: any, index: number) =>
                                <li
                                  key={'frontDesignAreaKeys' + index}
                                  className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]"
                                >
                                  <p className="text-[14px] text-[#141414] mb-4">
                                    <span className="font-semibold">
                                    { t('configurator_3d_shirt_design_no') + ' ' + (index + 1) }
                                    </span>
                                  </p>
                                  <button className="button_primary" onClick={() => handleCopyPatternSvg(designKey)}>
                                    { t('configurator_3d_copy') }
                                  </button>
                                </li>
                              )
                              : <></>
                          }
                        </ul>
                      </div>

                      <div className="slideContainer pb-4 mt-10">
                        <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                          { t('configurator_3d_backside') }
                        </p>
                        <ul className="!p-0 list-none">
                          <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                            <p className="text-[14px] text-[#141414] mb-4">
                              <span className="font-semibold">
                              { t('configurator_3d_backside') }
                              </span>
                            </p>
                            <button className="button_primary" onClick={() => handleCopyPatternSvg(JerseySection.Back)}>
                              { t('configurator_3d_copy') }
                            </button>
                          </li>
                          {
                            backDesignAreaKeys.length
                              ? backDesignAreaKeys.map((designKey: any, index: number) =>
                                <li
                                  key={'backDesignAreaKeys' + index}
                                  className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]"
                                >
                                  <p className="text-[14px] text-[#141414] mb-4">
                                    <span className="font-semibold">
                                    { t('configurator_3d_shirt_design_no') + ' ' + (index + 1) }
                                    </span>
                                  </p>
                                  <button className="button_primary" onClick={() => handleCopyPatternSvg(designKey)}>
                                    { t('configurator_3d_copy') }
                                  </button>
                                </li>
                              )
                              : <></>
                          }
                        </ul>
                      </div>
                      {
                        ModelStyle.ShirtNoSleeve === modelStyle
                          ? <></>
                          : <div className="slideContainer pb-4 mt-10">
                            <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                              { t('configurator_3d_sleeve') }
                            </p>
                            <ul className="!p-0 list-none">
                              <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                                <p className="text-[14px] text-[#141414] mb-4">
                                  <span className="font-semibold">
                                  { t('configurator_3d_sleeve_left') }
                                  </span>
                                </p>
                                <button className="button_primary" onClick={() => handleCopyPatternSvg(JerseySection.Left)}>
                                  { t('configurator_3d_copy') }
                                </button>
                              </li>
                              <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                                <p className="text-[14px] text-[#141414] mb-4">
                                  <span className="font-semibold">
                                  { t('configurator_3d_sleeve_right') }
                                  </span>
                                </p>
                                <button className="button_primary" onClick={() => handleCopyPatternSvg(JerseySection.Right)}>
                                  { t('configurator_3d_copy') }
                                </button>
                              </li>
                            </ul>
                          </div>
                      }
                    </div>
                    : <div className="py-4">
                      <div className="slideContainer pb-4">
                        <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                          { t('configurator_3d_short') }
                        </p>
                        <ul className="!p-0 list-none">
                          <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                            <p className="text-[14px] text-[#141414] mb-4">
                              <span className="font-semibold">
                              { t('configurator_3d_sleeve_left') }
                              </span>
                            </p>
                            <button className="button_primary" onClick={() => handleCopyPatternSvg(JerseySection.Left)}>
                              { t('configurator_3d_copy') }
                            </button>
                          </li>
                          <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                            <p className="text-[14px] text-[#141414] mb-4">
                              <span className="font-semibold">
                              { t('configurator_3d_sleeve_right') }
                              </span>
                            </p>
                            <button className="button_primary" onClick={() => handleCopyPatternSvg(JerseySection.Right)}>
                              { t('configurator_3d_copy') }
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                }
              </div>
              <button className="outlineButton" type="button" onClick={() => setSelectCopyPatternSectionDesktop(null)}>{ t('configurator_3d_cancel') }</button>
            </div>
          </div>
      }
    </>;
}