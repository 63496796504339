import {
  faRulerTriangle,
  faPencil,
  faBrush,
  faObjectUngroup,
  faScreenUsers,
  faListOl,
  faUserPen,
  faFont,
  faSwatchbook,
  faShirt
} from '@fortawesome/pro-light-svg-icons';

import type { TabOption } from './types';
import {
  FunctionTab,
  LogoAddPosition,
  NameInitialAddPosition,
  NumberAddPosition,
  TeamnameAddPosition,
  TextAddPosition,
  JerseySection,
} from './types';

export const CM_FACTOR = 16.557045130970653;
export const BASE_JAKO_LOGO_HEIGHT_CM = 1.8;
export const BASE_END_EDGE_SLEEVE_POS = 599.0285;
export const MAX_TEXT_SIZE_HEIGHT_CM = 30;
export const MAX_LOGO_SIZE_HEIGHT_CM = 40;

export const FONT_OPTIONS = [
  {
    fontFamily: 'Arial Black',
src: require('../assets/jako/Arial.woff2'),
  },
  {
    fontFamily: 'Bebas Neue',
src: require('../assets/jako/BebasNeue.woff2'),
  },
  {
    fontFamily: 'CG Omega',
src: require('../assets/jako/CGOmega.woff2'),
  },
  {
    fontFamily: 'Oswald Stencil',
src: require('../assets/jako/OswaldStencil.woff2'),
  },
  {
    fontFamily: 'Swiss921 BT',
src: require('../assets/jako/Swiss921BT.woff2'),
  },
  {
    fontFamily: 'Akhand Black',
src: require('../assets/jako/AkhandBlack.otf'),
  },
  {
    fontFamily: 'American Captain',
src: require('../assets/jako/AmericanCaptain.ttf'),
  },
  {
    fontFamily: 'Carbon Black',
src: require('../assets/jako/CarbonBlack.ttf'),
  },
  {
    fontFamily: 'CruzAzul',
src: require('../assets/jako/CruzAzul.ttf'),
  },
  {
    fontFamily: 'Komikazoom',
src: require('../assets/jako/Komikazoom.ttf'),
  },
  {
    fontFamily: 'Norwester',
src: require('../assets/jako/Norwester.otf'),
  },
  {
    fontFamily: 'TCCB',
src: require('../assets/jako/TCCB.ttf'),
  },
];

export const TAB_ORDER: TabOption[] = [
  {
    functionTab: FunctionTab.Cut,
    icon: faShirt,
    label: 'configurator_3d_cutting_form'
  },
  {
    functionTab: FunctionTab.Design,
    icon: faPencil,
    label: 'configurator_3d_design'
  },
  {
    functionTab: FunctionTab.Pattern,
    icon: faSwatchbook,
    label: 'configurator_3d_pattern'
  },
  {
    functionTab: FunctionTab.Color,
    icon: faBrush,
    label: 'configurator_3d_color'
  },
  {
    functionTab: FunctionTab.Logo,
    icon: faObjectUngroup,
    label: 'configurator_3d_logo',
    fieldCode: '3D_LOGO'
  },
  {
    functionTab: FunctionTab.Teamname,
    icon: faScreenUsers,
    label: 'configurator_3d_teamname',
    fieldCode: '3D_VEREINSNAME'
  },
  {
    functionTab: FunctionTab.Number,
    icon: faListOl,
    label: 'configurator_3d_number',
    fieldCode: '3D_NUMMER'
  },
  {
    functionTab: FunctionTab.Name,
    icon: faUserPen,
    label: 'configurator_3d_name',
    fieldCode: '3D_NAME'
  },
  {
    functionTab: FunctionTab.Text,
    icon: faFont,
    label: 'configurator_3d_text',
    fieldCode: '3D_TEXT'
  },
];

export const ALL_ELEMENT_TABS = [
  {
    functionTab: FunctionTab.Logo,
    fieldCode: '3D_LOGO'
  },
  {
    functionTab: FunctionTab.Teamname,
    fieldCode: '3D_VEREINSNAME'
  },
  {
    functionTab: FunctionTab.Number,
    fieldCode: '3D_NUMMER'
  },
  {
    functionTab: FunctionTab.Initial,
    fieldCode: '3D_INITIALEN'
  },
  {
    functionTab: FunctionTab.Name,
    fieldCode: '3D_NAME'
  },
  {
    functionTab: FunctionTab.Text,
    fieldCode: '3D_TEXT'
  },
];

export const RESERVED_JERSEY_AREAS_ID = [
  // Shirt
  'back_main', 'front_main', 'right_main', 'left_main', 'collar_main', 'collar_left', 'collar_right', 'cuff_right', 'cuff_left', 'elbow_right', 'elbow_left', 'stripe_right', 'stripe_left',
  // Short
  'elastic_main', 'elastic_in', 'elastic_right_out', 'elastic_left_out', 'elastic_right_in', 'elastic_left_in', 'elastic_left_main', 'elastic_right_main', 'right_main-2', 'left_main-2', 'stripe_right', 'stripe_left'
];


export const DEFAULT_ENABLE_INSIDE_COLOR_PART_KEYS = ['collar'];

export const ENABLE_INSIDE_COLOR_PART_KEYS_BY_CUTTING_CODE: { [key: string]: string[] } = {
  'K4151': ['collar', 'cuff_left,', 'cuff_right'],
  'K4152': ['collar', 'cuff_left', 'cuff_right']
};

export const LANGUAGE_OPTIONS = [
  {
    id: "de",
    name: "DE",
    flagimg: require('../assets/flags/de.svg').default,
  },
  {
    id: "en",
    name: "EN",
    flagimg: require('../assets/flags/en.svg').default,
  },
  {
    id: "fr",
    name: "FR",
    flagimg: require('../assets/flags/fr.svg').default,
  },
  {
    id: "NLB",
    name: "NLB",
    flagimg: require('../assets/flags/nl.svg').default,
  },
];

export const LOGO_ADD_POSITION: {
  [key: string]: {
    position: LogoAddPosition,
    jerseySection: JerseySection,
    x: number,
    y: number,
    maxWidth: number,
    maxHeight: number,
    initWidhth: number,
    initHeight: number,
    thumbnail: string,
    label: string,
    thumbnailXPostionOption?: string,
    thumbnailYPostionOption?: string
  }
} = {
  [LogoAddPosition.FrontTopLeft]: {
    position: LogoAddPosition.FrontTopLeft,
    jerseySection: JerseySection.Front,
    x: 0,
    y: 0,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/logo/Brust_links.jpg'),
    label: 'configurator_3d_front_top_left',
    thumbnailYPostionOption: '-25px'
  },
  [LogoAddPosition.FrontMiddleFull]: {
    position: LogoAddPosition.FrontMiddleFull,
    jerseySection: JerseySection.Front,
    x: 0,
    y: 12,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/logo/Brust_mittig.jpg'),
    label: 'configurator_3d_front_middle_full',
    thumbnailYPostionOption: '-25px'
  },
  [LogoAddPosition.FrontTopCenter]: {
    position: LogoAddPosition.FrontTopCenter,
    jerseySection: JerseySection.Front,
    x: 0,
    y: 0,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/logo/Brust_zentriert.jpg'),
    label: 'configurator_3d_front_top_center',
    thumbnailYPostionOption: '-25px'
  },
  [LogoAddPosition.FrontUnderLogoFull]: {
    position: LogoAddPosition.FrontUnderLogoFull,
    jerseySection: JerseySection.Front,
    x: 0,
    y: 8,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/logo/Brust_oben.jpg'),
    label: 'configurator_3d_front_under_logo_full',
    thumbnailYPostionOption: '-25px'
  },
  [LogoAddPosition.BackMiddleFull]: {
    position: LogoAddPosition.BackMiddleFull,
    jerseySection: JerseySection.Back,
    x: 0,
    y: 12,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/logo/Rucken_mittig.jpg'),
    label: 'configurator_3d_back_middle_full',
    thumbnailYPostionOption: '-25px'
  },
  [LogoAddPosition.FrontBottomFull]: {
    position: LogoAddPosition.FrontBottomFull,
    jerseySection: JerseySection.Front,
    x: 0,
    y: 45,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/logo/Brust_unten.jpg'),
    label: 'configurator_3d_front_bottom_full',
    thumbnailYPostionOption: '-25px'
  },
  [LogoAddPosition.BackTopFull]: {
    position: LogoAddPosition.BackTopFull,
    jerseySection: JerseySection.Back,
    x: 0,
    y: -6,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/logo/Rucken_oben.jpg'),
    label: 'configurator_3d_back_top_full',
    thumbnailYPostionOption: '-25px'
  },
  [LogoAddPosition.BackBottomFull]: {
    position: LogoAddPosition.BackBottomFull,
    jerseySection: JerseySection.Back,
    x: 0,
    y: 40,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/logo/Rucken_unten.jpg'),
    label: 'configurator_3d_back_bottom_full',
    thumbnailYPostionOption: '-56px'
  },
  [LogoAddPosition.LeftArm]: {
    position: LogoAddPosition.LeftArm,
    jerseySection: JerseySection.Left,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/logo/Armel_links.jpg'),
    label: 'configurator_3d_left_sleeve',
    thumbnailYPostionOption: '-25px'
  },
  [LogoAddPosition.RightArm]: {
    position: LogoAddPosition.RightArm,
    jerseySection: JerseySection.Right,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/logo/Armel_rechts.jpg'),
    label: 'configurator_3d_right_sleeve',
    thumbnailYPostionOption: '-25px'
  },
  [LogoAddPosition.Neck]: {
    position: LogoAddPosition.Neck,
    jerseySection: JerseySection.Back,
    x: 0,
    y: -10,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/logo/Nacken.jpg'),
    label: 'configurator_3d_back_neck',
    thumbnailYPostionOption: '-25px'
  },
  [LogoAddPosition.ShortBottomLeft]: {
    position: LogoAddPosition.ShortBottomLeft,
    jerseySection: JerseySection.Left,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_left.png'),
    label: 'configurator_3d_short_bottom_left',
    thumbnailXPostionOption: '-4px',
    thumbnailYPostionOption: '-55px',
  },
  [LogoAddPosition.ShortBottomRight]: {
    position: LogoAddPosition.ShortBottomRight,
    jerseySection: JerseySection.Right,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_right.png'),
    label: 'configurator_3d_short_bottom_right',
    thumbnailXPostionOption: '-44px',
    thumbnailYPostionOption: '-55px'
  },
  [LogoAddPosition.ShortUpLeft]: {
    position: LogoAddPosition.ShortUpLeft,
    jerseySection: JerseySection.Left,
    x: 0,
    y: -17,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_left-up.png'),
    label: 'configurator_3d_short_up_left',
    thumbnailXPostionOption: '-4px',
    thumbnailYPostionOption: '-55px',
  },
  [LogoAddPosition.ShortUpRight]: {
    position: LogoAddPosition.ShortUpRight,
    jerseySection: JerseySection.Right,
    x: 0,
    y: -17,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_right-up.png'),
    label: 'configurator_3d_short_up_right',
    thumbnailXPostionOption: '-44px',
    thumbnailYPostionOption: '-55px'
  }
};

export const NUMBER_ADD_POSITION: {
  [key in NumberAddPosition]: {
    position: NumberAddPosition,
    jerseySection: JerseySection,
    x: number,
    y: number,
    maxWidth: number,
    maxHeight: number,
    initWidhth: number,
    initHeight: number,
    thumbnail: string,
    label: string,
    thumbnailXPostionOption?: string,
    thumbnailYPostionOption?: string
  }
} = {
  [NumberAddPosition.BackMiddleFull]: {
    position: NumberAddPosition.BackMiddleFull,
    jerseySection: JerseySection.Back,
    x: 0,
    y: 12,
    maxWidth: 0,
    maxHeight: 19,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/number/Rucken_mittig.jpg'),
    label: 'configurator_3d_back_middle_full',
    thumbnailYPostionOption: '-25px'
  },
  [NumberAddPosition.FrontTopLeft]: {
    position: NumberAddPosition.FrontTopLeft,
    jerseySection: JerseySection.Front,
    x: 0,
    y: 0,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/number/Brust_links.jpg'),
    label: 'configurator_3d_front_top_left',
    thumbnailYPostionOption: '-25px'
  },
  [NumberAddPosition.FrontTopCenter]: {
    position: NumberAddPosition.FrontTopCenter,
    jerseySection: JerseySection.Front,
    x: 0,
    y: 0,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/number/Brust_zentriert.jpg'),
    label: 'configurator_3d_front_top_center',
    thumbnailYPostionOption: '-25px'
  },
  [NumberAddPosition.LeftArm]: {
    position: NumberAddPosition.LeftArm,
    jerseySection: JerseySection.Left,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/number/Armel_links.jpg'),
    label: 'configurator_3d_left_sleeve',
    thumbnailYPostionOption: '-25px'
  },
  [NumberAddPosition.RightArm]: {
    position: NumberAddPosition.RightArm,
    jerseySection: JerseySection.Right,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/number/Armel_rechts.jpg'),
    label: 'configurator_3d_right_sleeve',
    thumbnailYPostionOption: '-25px'
  },
  [NumberAddPosition.ShortBottomLeft]: {
    position: NumberAddPosition.ShortBottomLeft,
    jerseySection: JerseySection.Left,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_left.png'),
    label: 'configurator_3d_short_bottom_left',
    thumbnailXPostionOption: '-4px',
    thumbnailYPostionOption: '-55px',
  },
  [NumberAddPosition.ShortBottomRight]: {
    position: NumberAddPosition.ShortBottomRight,
    jerseySection: JerseySection.Right,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_right.png'),
    label: 'configurator_3d_short_bottom_right',
    thumbnailXPostionOption: '-44px',
    thumbnailYPostionOption: '-55px'
  },
  [NumberAddPosition.ShortUpLeft]: {
    position: NumberAddPosition.ShortUpLeft,
    jerseySection: JerseySection.Left,
    x: 0,
    y: -17,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_left-up.png'),
    label: 'configurator_3d_short_up_left',
    thumbnailXPostionOption: '-4px',
    thumbnailYPostionOption: '-55px',
  },
  [NumberAddPosition.ShortUpRight]: {
    position: NumberAddPosition.ShortUpRight,
    jerseySection: JerseySection.Right,
    x: 0,
    y: -17,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_right-up.png'),
    label: 'configurator_3d_short_up_right',
    thumbnailXPostionOption: '-44px',
    thumbnailYPostionOption: '-55px'
  }
};

export const TEAMNAME_ADD_POSITION: {
  [key in TeamnameAddPosition]: {
    position: TeamnameAddPosition,
    jerseySection: JerseySection,
    x: number,
    y: number,
    maxWidth: number,
    maxHeight: number,
    initWidhth: number,
    initHeight: number,
    thumbnail: string,
    label: string,
    thumbnailXPostionOption?: string,
    thumbnailYPostionOption?: string
  }
} = {
  [TeamnameAddPosition.BackTopFull]: {
    position: TeamnameAddPosition.BackTopFull,
    jerseySection: JerseySection.Back,
    x: 0,
    y: -6,
    maxWidth: 0,
    maxHeight: 7.5,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/teamname/Rucken_oben.jpg'),
    label: 'configurator_3d_back_top_full',
    thumbnailYPostionOption: '-25px'
  },
  [TeamnameAddPosition.BackBottomFull]: {
    position: TeamnameAddPosition.BackBottomFull,
    jerseySection: JerseySection.Back,
    x: 0,
    y: 30,
    maxWidth: 0,
    maxHeight: 7.5,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/teamname/Rucken_unten.jpg'),
    label: 'configurator_3d_back_bottom_full',
    thumbnailYPostionOption: '-25px'
  },
  [TeamnameAddPosition.ShortBottomLeft]: {
    position: TeamnameAddPosition.ShortBottomLeft,
    jerseySection: JerseySection.Left,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_left.png'),
    label: 'configurator_3d_short_bottom_left',
    thumbnailXPostionOption: '-4px',
    thumbnailYPostionOption: '-55px',
  },
  [TeamnameAddPosition.ShortBottomRight]: {
    position: TeamnameAddPosition.ShortBottomRight,
    jerseySection: JerseySection.Right,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_right.png'),
    label: 'configurator_3d_short_bottom_right',
    thumbnailXPostionOption: '-44px',
    thumbnailYPostionOption: '-55px'
  },
  [TeamnameAddPosition.ShortUpLeft]: {
    position: TeamnameAddPosition.ShortUpLeft,
    jerseySection: JerseySection.Left,
    x: 0,
    y: -17,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_left-up.png'),
    label: 'configurator_3d_short_up_left',
    thumbnailXPostionOption: '-4px',
    thumbnailYPostionOption: '-55px',
  },
  [TeamnameAddPosition.ShortUpRight]: {
    position: TeamnameAddPosition.ShortUpRight,
    jerseySection: JerseySection.Right,
    x: 0,
    y: -17,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_right-up.png'),
    label: 'configurator_3d_short_up_right',
    thumbnailXPostionOption: '-44px',
    thumbnailYPostionOption: '-55px'
  }
};

export const TEXT_ADD_POSITION: {
  [key in TextAddPosition]: {
    position: TextAddPosition,
    jerseySection: JerseySection,
    x: number,
    y: number,
    maxWidth: number,
    maxHeight: number,
    initWidhth: number,
    initHeight: number,
    thumbnail: string,
    label: string,
    thumbnailXPostionOption?: string,
    thumbnailYPostionOption?: string
  }
} = {
  [TextAddPosition.LeftArm]: {
    position: TextAddPosition.LeftArm,
    jerseySection: JerseySection.Left,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/text/Armel_links.jpg'),
    label: 'configurator_3d_left_sleeve',
    thumbnailYPostionOption: '-25px'
  },
  [TextAddPosition.RightArm]: {
    position: TextAddPosition.RightArm,
    jerseySection: JerseySection.Right,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/text/Armel_rechts.jpg'),
    label: 'configurator_3d_right_sleeve',
    thumbnailYPostionOption: '-25px'
  },
  [TextAddPosition.FrontUnderLogoFull]: {
    position: TextAddPosition.FrontUnderLogoFull,
    jerseySection: JerseySection.Front,
    x: 0,
    y: 8,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/text/Brust_oben.jpg'),
    label: 'configurator_3d_front_under_logo_full',
    thumbnailYPostionOption: '-25px'
  },
  [TextAddPosition.FrontBottomFull]: {
    position: TextAddPosition.FrontBottomFull,
    jerseySection: JerseySection.Front,
    x: 0,
    y: 45,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/text/Brust_unten.jpg'),
    label: 'configurator_3d_front_bottom_full',
    thumbnailYPostionOption: '-25px'
  },
  [TextAddPosition.BackTopFull]: {
    position: TextAddPosition.BackTopFull,
    jerseySection: JerseySection.Back,
    x: 0,
    y: -6,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/text/Rucken_oben.jpg'),
    label: 'configurator_3d_back_top_full',
    thumbnailYPostionOption: '-25px'
  },
  [TextAddPosition.BackBottomFull]: {
    position: TextAddPosition.BackBottomFull,
    jerseySection: JerseySection.Back,
    x: 0,
    y: 40,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/text/Rucken_unten.jpg'),
    label: 'configurator_3d_back_bottom_full',
    thumbnailYPostionOption: '-25px'
  },
  [TextAddPosition.Neck]: {
    position: TextAddPosition.Neck,
    jerseySection: JerseySection.Back,
    x: 0,
    y: -10,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/logo/Nacken.jpg'),
    label: 'configurator_3d_back_neck',
    thumbnailYPostionOption: '-25px'
  },
  [TextAddPosition.ShortBottomLeft]: {
    position: TextAddPosition.ShortBottomLeft,
    jerseySection: JerseySection.Left,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_left.png'),
    label: 'configurator_3d_short_bottom_left',
    thumbnailXPostionOption: '-4px',
    thumbnailYPostionOption: '-55px',
  },
  [TextAddPosition.ShortBottomRight]: {
    position: TextAddPosition.ShortBottomRight,
    jerseySection: JerseySection.Right,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_right.png'),
    label: 'configurator_3d_short_bottom_right',
    thumbnailXPostionOption: '-44px',
    thumbnailYPostionOption: '-55px'
  },
  [TextAddPosition.ShortUpLeft]: {
    position: TextAddPosition.ShortUpLeft,
    jerseySection: JerseySection.Left,
    x: 0,
    y: -17,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_left-up.png'),
    label: 'configurator_3d_short_up_left',
    thumbnailXPostionOption: '-4px',
    thumbnailYPostionOption: '-55px',
  },
  [TextAddPosition.ShortUpRight]: {
    position: TextAddPosition.ShortUpRight,
    jerseySection: JerseySection.Right,
    x: 0,
    y: -17,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_right-up.png'),
    label: 'configurator_3d_short_up_right',
    thumbnailXPostionOption: '-44px',
    thumbnailYPostionOption: '-55px'
  }
};

export const NAME_ADD_POSITION: {
  [key: string]: {
    position: NameInitialAddPosition,
    jerseySection: JerseySection,
    x: number,
    y: number,
    maxWidth: number,
    maxHeight: number,
    initWidhth: number,
    initHeight: number,
    thumbnail: string,
    label: string,
    thumbnailXPostionOption?: string
    thumbnailYPostionOption?: string
  }
} = {
  [NameInitialAddPosition.BackTopFull]: {
    position: NameInitialAddPosition.BackTopFull,
    jerseySection: JerseySection.Back,
    x: 0,
    y: -6,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/nameInitial/Rucken_oben.jpg'),
    label: 'configurator_3d_back_top_full',
    thumbnailYPostionOption: '-25px'
  },
  [NameInitialAddPosition.BackSuperBottomFull]: {
    position: NameInitialAddPosition.BackSuperBottomFull,
    jerseySection: JerseySection.Back,
    x: 0,
    y: 45,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/nameInitial/Rucken_super_unten.jpg'),
    label: 'configurator_3d_back_super_bottom_full',
    thumbnailYPostionOption: '-25px'
  },
  [NameInitialAddPosition.ShortBottomLeft]: {
    position: NameInitialAddPosition.ShortBottomLeft,
    jerseySection: JerseySection.Left,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_left.png'),
    label: 'configurator_3d_short_bottom_left',
    thumbnailXPostionOption: '-4px',
    thumbnailYPostionOption: '-55px',
  },
  [NameInitialAddPosition.ShortBottomRight]: {
    position: NameInitialAddPosition.ShortBottomRight,
    jerseySection: JerseySection.Right,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_right.png'),
    label: 'configurator_3d_short_bottom_right',
    thumbnailXPostionOption: '-44px',
    thumbnailYPostionOption: '-55px'
  },
  [NameInitialAddPosition.ShortUpLeft]: {
    position: NameInitialAddPosition.ShortUpLeft,
    jerseySection: JerseySection.Left,
    x: 0,
    y: -17,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_left-up.png'),
    label: 'configurator_3d_short_up_left',
    thumbnailXPostionOption: '-4px',
    thumbnailYPostionOption: '-55px',
  },
  [NameInitialAddPosition.ShortUpRight]: {
    position: NameInitialAddPosition.ShortUpRight,
    jerseySection: JerseySection.Right,
    x: 0,
    y: -17,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_right-up.png'),
    label: 'configurator_3d_short_up_right',
    thumbnailXPostionOption: '-44px',
    thumbnailYPostionOption: '-55px'
  }
};

export const INITIAL_ADD_POSITION: {
  [key: string]: {
    position: NameInitialAddPosition,
    jerseySection: JerseySection,
    x: number,
    y: number,
    maxWidth: number,
    maxHeight: number,
    initWidhth: number,
    initHeight: number,
    thumbnail: string,
    label: string,
    thumbnailXPostionOption?: string
    thumbnailYPostionOption?: string
  }
} = {
  [NameInitialAddPosition.FrontTopCenter]: {
    position: NameInitialAddPosition.FrontTopCenter,
    jerseySection: JerseySection.Front,
    x: 0,
    y: 0,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/nameInitial/Brust_zentriert.jpg'),
    label: 'configurator_3d_front_top_center',
    thumbnailYPostionOption: '-25px'
  },
  [NameInitialAddPosition.FrontTopLeft]: {
    position: NameInitialAddPosition.FrontTopLeft,
    jerseySection: JerseySection.Front,
    x: 0,
    y: 0,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/nameInitial/Brust_links.jpg'),
    label: 'configurator_3d_front_top_left',
    thumbnailYPostionOption: '-25px'
  },
  [NameInitialAddPosition.LeftArm]: {
    position: NameInitialAddPosition.LeftArm,
    jerseySection: JerseySection.Left,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/nameInitial/Armel_links.jpg'),
    label: 'configurator_3d_left_sleeve',
    thumbnailYPostionOption: '-25px'
  },
  [NameInitialAddPosition.RightArm]: {
    position: NameInitialAddPosition.RightArm,
    jerseySection: JerseySection.Right,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 0,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/nameInitial/Armel_rechts.jpg'),
    label: 'configurator_3d_right_sleeve',
    thumbnailYPostionOption: '-25px'
  },
  [NameInitialAddPosition.ShortBottomLeft]: {
    position: NameInitialAddPosition.ShortBottomLeft,
    jerseySection: JerseySection.Left,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_left.png'),
    label: 'configurator_3d_short_bottom_left',
    thumbnailXPostionOption: '-4px',
    thumbnailYPostionOption: '-55px',
  },
  [NameInitialAddPosition.ShortBottomRight]: {
    position: NameInitialAddPosition.ShortBottomRight,
    jerseySection: JerseySection.Right,
    x: 0,
    y: -5,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_right.png'),
    label: 'configurator_3d_short_bottom_right',
    thumbnailXPostionOption: '-44px',
    thumbnailYPostionOption: '-55px'
  },
  [NameInitialAddPosition.ShortUpLeft]: {
    position: NameInitialAddPosition.ShortUpLeft,
    jerseySection: JerseySection.Left,
    x: 0,
    y: -17,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_left-up.png'),
    label: 'configurator_3d_short_up_left',
    thumbnailXPostionOption: '-4px',
    thumbnailYPostionOption: '-55px',
  },
  [NameInitialAddPosition.ShortUpRight]: {
    position: NameInitialAddPosition.ShortUpRight,
    jerseySection: JerseySection.Right,
    x: 0,
    y: -17,
    maxWidth: 0,
    maxHeight: 4,
    initWidhth: 0,
    initHeight: 0,
    thumbnail: require('../assets/jakoImages/position/short/short_right-up.png'),
    label: 'configurator_3d_short_up_right',
    thumbnailXPostionOption: '-44px',
    thumbnailYPostionOption: '-55px'
  }
};
