export default function IconSize(props: any) {
  return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" fill="currentColor" {...props}>
	<path d="M751.9,228.7c10.7,0,19.4,8.7,19.4,19.4v155c0,10.7-8.7,19.4-19.4,19.4s-19.4-8.7-19.4-19.4V294.9L527.4,500l205.1,205.1
		V596.9c0-10.7,8.7-19.4,19.4-19.4s19.4,8.7,19.4,19.4v155c0,10.7-8.7,19.4-19.4,19.4h-155c-10.7,0-19.4-8.7-19.4-19.4
		s8.7-19.4,19.4-19.4h108.3L500,527.4L294.9,732.5h108.3c10.7,0,19.4,8.7,19.4,19.4s-8.7,19.4-19.4,19.4h-155
		c-10.7,0-19.4-8.7-19.4-19.4v-155c0-10.7,8.7-19.4,19.4-19.4s19.4,8.7,19.4,19.4v108.3L472.6,500L267.5,294.9v108.3
		c0,10.7-8.7,19.4-19.4,19.4s-19.4-8.7-19.4-19.4v-155c0-10.7,8.7-19.4,19.4-19.4h155c10.7,0,19.4,8.7,19.4,19.4
		c0,10.7-8.7,19.4-19.4,19.4H294.9L500,472.6l205.1-205.1H596.9c-10.7,0-19.4-8.7-19.4-19.4c0-10.7,8.7-19.4,19.4-19.4H751.9z"/>
</svg>;
}
