export default function IconMoveBackward(props: any) {
  return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000"  fill="currentColor" {...props}>
	<path d="M228.7,267.5v271.3c0,21.4,17.3,38.8,38.8,38.8H345v38.8h-77.5c-42.7,0-77.5-34.8-77.5-77.5V267.5
		c0-42.7,34.8-77.5,77.5-77.5h271.3c42.7,0,77.5,34.8,77.5,77.5V345h-38.8v-77.5c0-21.4-17.3-38.8-38.8-38.8H267.5
		C246.1,228.7,228.7,246.1,228.7,267.5z M538.8,345H500v-38.8H306.2V500H345v38.8h-38.8c-21.4,0-38.8-17.3-38.8-38.8V306.2
		c0-21.4,17.3-38.8,38.8-38.8H500c21.4,0,38.8,17.3,38.8,38.8V345z M461.2,383.7h271.3c42.7,0,77.5,34.8,77.5,77.5v271.3
		c0,42.7-34.8,77.5-77.5,77.5H461.2c-42.7,0-77.5-34.8-77.5-77.5V461.2C383.7,418.5,418.5,383.7,461.2,383.7z M422.5,461.2v271.3
		c0,21.4,17.3,38.8,38.8,38.8h271.3c21.4,0,38.8-17.3,38.8-38.8V461.2c0-21.4-17.3-38.8-38.8-38.8H461.2
		C439.8,422.5,422.5,439.8,422.5,461.2z"/>
</svg>;
}
