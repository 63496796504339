export default function findBaseUrl(): string {
  const searchString = '/wc/configurator.js';
  let root = document.getRootNode() as HTMLElement;
  for (const script of root.querySelectorAll('script')) {
    const src = script.getAttribute('src');
    if (src?.endsWith(searchString)) {
      return src.slice(0, searchString.length * -1);
    }
  }
  return ''; // demo mode
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars