import type { DesignOption } from './types';
import type { CustomerDesignResponseItem } from './responses';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext, useMemo, useRef } from 'react';
import {
  faCheckCircle
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import { JakoServices } from '../helpers/services';
import { BaseContext } from '../contexts/BaseContext';

import IconCross from './icons/IconCross';

enum DesignSelectTab {
  Design,
  Customer
}

export default function JakoDesignTab({
  designOptions,
  selectedDesign,
  onSelectDesign,
  onSelectCustomerDesign,
  isMobile,
  onCloseMobile,
  activateDesignOnBack,
  hasBackDesign,
  onTriggerBackDesign,
  selectedCuttingSportType
}: {
  designOptions: {
    name: string,
    file: string,
    previewFile: string
  }[]
  selectedDesign: DesignOption | null,
  onSelectDesign: (design: DesignOption) => void,
  onSelectCustomerDesign: (design: CustomerDesignResponseItem) => void,
  isMobile: boolean,
  onCloseMobile: () => void,
  onTriggerBackDesign: () => void,
  hasBackDesign: boolean,
  activateDesignOnBack: boolean,
  selectedCuttingSportType: string
}) {
  const { t, i18n } = useTranslation('translations');
  const [jakoDesignOptions, setJakoDesignOptionsData] = useState<CustomerDesignResponseItem[]>([]);
  const [selectTab, setSelectTab] = useState<DesignSelectTab>(DesignSelectTab.Design);
  const { correlationId, company, shopCode, languagecode } = useContext(BaseContext);

  const sortJakoDesignOptions = useMemo(() => jakoDesignOptions.sort((a, b) => a.configurationName.localeCompare(b.configurationName)), [jakoDesignOptions]);
  const sortStaticDesignOptions = useMemo(() =>
    designOptions.sort((a, b) => {
      if (a.name.toLowerCase().includes('blanko') && !b.name.toLowerCase().includes('blanko')) {
        return -1;
      }
      if (!a.name.toLowerCase().includes('blanko') && b.name.toLowerCase().includes('blanko')) {
        return 1;
      }
      return a.name.toString().localeCompare(b.name.toString(), undefined, { numeric: true, sensitivity: 'base' });
    })
  , [designOptions]);

  const [hasMoreScrollContainer, setHasMoreScrollContainer] = useState(false);
  const scrollBarRef = useRef(null as any);

  useEffect(() => {
    if (!scrollBarRef.current) {
      return;
    }

    onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    scrollBarRef.current.getScrollElement()
      .addEventListener("scroll", function(e: any) { onScrollContainerUpdate(e.target); });
  }, []);

  useEffect(() => {
    if (jakoDesignOptions.length) {
      return;
    }
    const payloadCorrelationId = correlationId || 'a6767fc135b792723ec27fd055a2cb8c';
    const payloadCompany = company || 'JAKO AG';
    const payloadShopCode = shopCode || 'B2C';
    JakoServices.getDesigns({
      correlationId: payloadCorrelationId,
      company: payloadCompany,
      shopCode: payloadShopCode,
      languageCode: i18n.language ? i18n.language.toUpperCase() : languagecode,
    }).then((res) => {
      setJakoDesignOptionsData(res.data);
    });
    // eslint-disable-next-line
  }, [i18n.language, designOptions]);

  function onScrollContainerUpdate(el: any) {
    if (el.clientHeight + el.scrollTop >= el.scrollHeight) {
      setHasMoreScrollContainer(false);
    } else {
      setHasMoreScrollContainer(true);
    }
  }

  function handleOnClickCloseMobile() {
    if (scrollBarRef.current) {
      onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    }
    onCloseMobile();
  }

  function handleOnSelectCustomerDesign(design: CustomerDesignResponseItem) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div id="jako-configurator-3d-wc" className="confirm-popup-container">
             <h5 className="confirm-popup-container__title">
              { t('configurator_3d_confirm_select_custom_design') }
            </h5>
            <div className="confirm-popup__func-container">
              <button className="outlineButton" type="button" onClick={onClose}>
                { t('configurator_3d_cancel') }
              </button>
              <button className="button_primary" onClick={() => {
                onSelectCustomerDesign(design);
                handleOnClickCloseMobile();
                onClose();
              }}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          </div>
        );
      }
    });
  }

  if (!selectedDesign) {
    return <></>;
  }

  return isMobile
  ? <div className="relative w-full px-6 pt-10 pb-6">
    <button
      className="absolute top-[28px] right-[7px] z-20"
      onClick={handleOnClickCloseMobile}
    >
      <IconCross className="h-[20px]" />
    </button>
    {
      (selectedCuttingSportType === 'SOCCER' || selectedCuttingSportType === 'HANDBALL')
        ? <ul className="tabs__list mr-[18px]">
          <li
            className={
              "tabs__li"
              + (selectTab === DesignSelectTab.Design ? ' active' : '')
            }
            onClick={() => setSelectTab(DesignSelectTab.Design)}
          >
            { t('configurator_3d_design_templates') }
          </li>
          <li
            className={
              "tabs__li"
              + (selectTab === DesignSelectTab.Customer ? ' active' : '')
            }
            onClick={() => setSelectTab(DesignSelectTab.Customer)}
          >
            { t('configurator_3d_customer_templates') }
          </li>
        </ul>
        : <></>
    }
    {
      hasBackDesign
        && selectTab === DesignSelectTab.Design
        ? <button
          className="flex items-center w-full py-4"
          onClick={onTriggerBackDesign}
        >
          <div className={"checkbox-circle" + (activateDesignOnBack ? ' active' : '')} />
          <p className="ml-4 leading-none">{ t('configurator_3d_design_front_and_back') }</p>
        </button>
        : <></>
    }
    <SimpleBar
      ref={scrollBarRef}
      style={{
        width: '100%',
        height: 220
      }}
      autoHide={false}
      onScroll={onScrollContainerUpdate}
    >
      <div
        className={
          "tabs__content"
          + (selectTab === DesignSelectTab.Design ? ' active' : '')
        }
      >
        {
          designOptions.length
            ? <div className="grid grid-cols-2 gap-x-5 gap-y-4">
              {
                sortStaticDesignOptions.map((design, index) =>
                  <button
                    key={'designOption' + design.file + index}
                    className="flex flex-col items-center"
                    onClick={() => onSelectDesign(design)}
                  >
                    <div
                      className={
                        "relative border-2 border-[#D4D4D4] mt-2 overflow-hidden"
                          + (selectedDesign.file === design.file
                            ? ' !border-b-[3px] !border-b-[#008AC9]'
                            : ''
                          )
                      }
                    >
                      {
                        selectedDesign.file === design.file
                          ? <span className="absolute top-1 right-1 text-[#008AC9]">
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                          : <></>
                      }
                      <img
                        src={design.previewFile}
                        alt={design.name}
                        className="w-[125px] h-[150px] object-contain"
                      />
                    </div>
                  </button>
                )
              }
            </div>
            : <p>{ t('configurator_3d_no_design_available') }</p>
        }
      </div>
    </SimpleBar>
    {
      hasMoreScrollContainer
        ? <div
          className={
            "absolute w-full h-[40px] bottom-[13px] left-0 bg-gradient-to-b from-transparent to-white transition-all duration-200"
            + (hasMoreScrollContainer ? ' opacity-100' : ' opacity-0')
          }
        />
        : <></>
    }
  </div>
  : <>
    <div className="h1 text-center">{ t('configurator_3d_choose_design') }</div>
    <div className="text-center marginBottom--small">{ t('configurator_3d_choose_design_text') }</div>
    <div className="design-tab tabs">
      {
        (selectedCuttingSportType === 'SOCCER' || selectedCuttingSportType === 'HANDBALL')
          ? <ul className="tabs__list">
            <li
              className={
                "tabs__li"
                + (selectTab === DesignSelectTab.Design ? ' active' : '')
              }
              onClick={() => setSelectTab(DesignSelectTab.Design)}
            >
              { t('configurator_3d_design_templates') }
            </li>
            <li
              className={
                "tabs__li"
                + (selectTab === DesignSelectTab.Customer ? ' active' : '')
              }
              onClick={() => setSelectTab(DesignSelectTab.Customer)}
            >
              { t('configurator_3d_customer_templates') }
            </li>
          </ul>
          : <></>
      }
      {
        hasBackDesign
          && selectTab === DesignSelectTab.Design
          ? <button
            className="flex items-center w-full py-4"
            onClick={onTriggerBackDesign}
          >
            <div className={"checkbox-circle" + (activateDesignOnBack ? ' active' : '')} />
            <p className="ml-4 leading-none">{ t('configurator_3d_design_front_and_back') }</p>
          </button>
          : <></>
      }
      <div
        className={
          "tabs__content"
          + (selectTab === DesignSelectTab.Design ? ' active' : '')
        }
      >
        {
          designOptions.length
            ? <div className="itemsWrapper--2cols">
              {
                sortStaticDesignOptions.map((design, index) =>
                  <div
                    key={'designOption' + design.file + index}
                    className={
                      "itemTemplate "
                      + (selectedDesign.file === design.file ? "active" : "")
                    }
                    onClick={() => onSelectDesign(design)}
                  >
                    <div
                      className="itemTemplate__image"
                    >
                      <img src={design.previewFile} alt={design.name} className="w-auto h-[132px] object-contain" />
                    </div>
                    <div className="itemTemplate__description" />

                    {/* <div className="itemTemplate__description">
                      {design.name}
                    </div> */}
                  </div>
                )
              }
            </div>
            : <p>{ t('configurator_3d_no_design_available') }</p>
        }
      </div>
      <div
        className={
          "tabs__content"
          + (selectTab === DesignSelectTab.Customer ? ' active' : '')
        }
      >
        <div className="itemsWrapper--2cols">
          {
            sortJakoDesignOptions.map((customDesign, index) =>
              <div
                key={'designOption' + customDesign.designHeaderId}
                className="itemTemplate"
                onClick={() => handleOnSelectCustomerDesign(customDesign)}
              >
                <div
                  className="itemTemplate__image"
                >
                  <img
                    src={customDesign.previewImages[0].image} alt={customDesign.configurationName}
                    className="w-auto h-[132px] object-contain"
                  />
                </div>
                <div className="itemTemplate__description" />
              </div>
            )
          }
        </div>
      </div>
    </div>
  </>;
}