import type {Ref} from "react";
import { Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {CameraControls, Environment, PerspectiveCamera} from "@react-three/drei";
import type {RootState} from "@react-three/fiber";
import {Canvas} from "@react-three/fiber";
import {debounce} from "lodash";
import type {Vector3, WebGLRenderer} from "three";
import {MathUtils} from "three";
import {useTranslation} from "react-i18next";
import {Transition} from "@headlessui/react";
import {NoToneMapping} from "three/src/constants";
import { decodeJwt } from 'jose';
import {
  faFloppyDisk,
  faCircleMinus,
  faCirclePlus,
  faArrowLeft,
  faArrowRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faSave,
  faCartShopping
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import JakoCuttingTab from "./JakoCuttingTab";
import JakoDesignTab from "./JakoDesignTab";
import JakoPatternTab from "./JakoPatternTab";
import JakoColorTab from "./JakoColorTab";
import JakoColorTabSocks from "./JakoColorTabSocks";
import type {JakoTeamnameTabHandler} from "./JakoTeamnameTab";
import JakoTeamnameTab from "./JakoTeamnameTab";
import type {JakoTextTabHandler} from "./JakoTextTab";
import JakoTextTab from "./JakoTextTab";
import type {JakoNumberTabHandler} from "./JakoNumberTab";
import JakoNumberTab from "./JakoNumberTab";
import type {JakoNameTabHandler} from "./JakoNameTab";
import JakoNameTab from "./JakoNameTab";
import type {JakoLogoTabHandler} from "./JakoLogoTab";
import JakoLogoTab from "./JakoLogoTab";

import IconSave from "./icons/IconSave";
import IconAngle from "./icons/IconAngle";
import IconCircleArea from "./icons/IconCircleArea";
import IconCartCircleCheck from "./icons/IconCartCircleCheck";
import IconClone from "./icons/IconClone";
import IconShirt from "./icons/IconShirt";

import type { TDesktopCustomScrollbarFunctionHandler } from "./DesktopCustomScrollbar";
import DesktopCustomScrollbar from "./DesktopCustomScrollbar";

// This is the data to change something on the shirt. There is no API. Only passing the data via the props.
// In the future, these data should come from the API.
import {
  BASE_END_EDGE_SLEEVE_POS,
  CM_FACTOR,
  INITIAL_ADD_POSITION,
  LANGUAGE_OPTIONS,
  LOGO_ADD_POSITION,
  MAX_LOGO_SIZE_HEIGHT_CM,
  MAX_TEXT_SIZE_HEIGHT_CM,
  NAME_ADD_POSITION,
  NUMBER_ADD_POSITION,
  RESERVED_JERSEY_AREAS_ID,
  TAB_ORDER,
  ALL_ELEMENT_TABS,
  TEAMNAME_ADD_POSITION,
  TEXT_ADD_POSITION
} from "./constants";

import hdri from "../assets/hdri/studio_s_02_1k.hdr";


import type { IConfiguratorFabricHandler } from "../three-components/ConfiguratorModelFabric";
import { ConfiguratorModelFabric } from "../three-components/ConfiguratorModelFabric";

import type {
  AddedLogoItems,
  AddedPatternJerseySectionItems,
  AddedTextItems,
  AddImageInterface,
  AddTextInterface,
  AreaSurface,
  AreaElement,
  AreaElementPattern,
  BasicAddPosition,
  ConfigElementsOrm,
  CuttingOptionData,
  DesignOption,
  DesignSurfaceDefinition,
  LogoUploadObject,
  SaveConfigState
} from "./types";
import {
  ControlArea,
  CutMaterial,
  FunctionTab,
  IndividualisationViewCode,
  JakoLogoPosition,
  JerseySection,
  LogoAddPosition,
  ModelStyle,
  NameInitialAddPosition,
  NumberAddPosition,
  TeamnameAddPosition,
  TextAddPosition
} from "./types";
import type {fabric} from "fabric";

import type {
  ColorResponseItem,
  CustomerDesignResponseItem,
  CuttingFileTemplateResponse,
  CuttingOptionResponseItem,
  CuttingResponseItem,
  PatternResponseItem,
  TranslationResponseItem,
  JakoPricesResponse
} from "./responses";
import {JakoServices, JfnetServices} from "../helpers/services";

import {BaseContext} from "../contexts/BaseContext";
import {Base64} from 'js-base64';


import {confirmAlert} from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

import "../styles/shadow.css";
import "../styles/root.css";

// 3D lightings config
function StudioSoLights() {
  return (
    <>
      <Environment
        background={false}
        files={hdri}
      />
      <ambientLight intensity={0.5} castShadow={false} />
      <spotLight
        position={[0, 0, 5]}
        intensity={0.2}
        decay={1}
        castShadow={false}
      />
      <spotLight
        position={[0, 0, -5]}
        intensity={0.2}
        decay={1}
        castShadow={false}
      />
    </>
  );
}

function SidebarContentWrapper({
  scrollBarRef,
  showScrollDown,
  sidebarScrollTopPos,
  sidebarScrollBoundingRect,
  children,
  isMobile,
  setSelectTabBack,
  setSelectTabNext,
}: {
  scrollBarRef: Ref<TDesktopCustomScrollbarFunctionHandler>;
  showScrollDown: boolean;
  sidebarScrollTopPos: number;
  sidebarScrollBoundingRect: DOMRect | undefined;
  children: string | JSX.Element | JSX.Element[];
  isMobile?: boolean;
  setSelectTabNext: () => void;
  setSelectTabBack: () => void;
}) {
  const { t } = useTranslation('translations', { useSuspense: false });
  const isScrollToBottom = useMemo(() => {
    if (sidebarScrollTopPos > 0) {
      const shadowRootContainer = document.querySelector('jako-configurator');
      const shadowRoot = shadowRootContainer ? shadowRootContainer.shadowRoot : null;
      const sidebarEl = shadowRoot ? shadowRoot.querySelector('.configurator__sidebarContent') : document.querySelector('.configurator__sidebarContent');
      if (sidebarEl && sidebarEl.clientHeight) {
        return (sidebarEl.scrollHeight - sidebarEl.scrollTop) - 10 > sidebarEl.clientHeight;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, [sidebarScrollTopPos]);

  return isMobile
    ? <div className="mobile__sidebarContent absolute w-full left-0 bottom-[130px] bg-white rounded-t-2xl z-20 shadow-[0_-12px_15px_-4px_rgba(0,0,0,0.1)]">
      { children }
    </div>
    : <DesktopCustomScrollbar ref={scrollBarRef} className="configurator__sidebarContent p-[20px]">
      { children }
      <div className="flex justify-between mt-20">
        <button
          className="px-[27px] py-[12px] bg-[#FAFAFA] text-[#5B5B5B]"
          onClick={setSelectTabBack}
        >
          <div className="flex items-center">
            <FontAwesomeIcon
              className="h-[14px] mr-2"
              icon={faAngleLeft}
            />
            <span className='relative top-[-1px]'>{ t('configurator_3d_back') }</span>
          </div>
        </button>
        <button
          className="px-[27px] py-[12px] bg-[#008AC9] text-[#FFFFFF]"
          onClick={setSelectTabNext}
        >
          <div className="flex items-center">
            <span className='relative top-[-1px]'>{ t('configurator_3d_basket_next') }</span>
            <FontAwesomeIcon
              className="h-[14px] ml-2"
              icon={faAngleRight}
            />
          </div>
        </button>
      </div>
    <div className="scroll-down-icon-area absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[5rem] w-[50px] h-[50px] opacity-0 invisible">
      <FontAwesomeIcon icon={faAngleDown} className="icon absolute top-0 left-0" />
    </div>
    <div
      className={
        "scroll-down-icon fixed transition-all duration-200 text-[5rem] w-[50px] h-[50px] z-20"
        + (showScrollDown && isScrollToBottom ? " opacity-100 visible" : " opacity-0 invisible")
      }
      style={{
        top: sidebarScrollBoundingRect?.top,
        left: sidebarScrollBoundingRect?.left
      }}
    >
      <FontAwesomeIcon icon={faAngleDown} className="icon absolute top-0 left-0 text-[#c3c3c3]" />
    </div>
  </DesktopCustomScrollbar>;
}

function JakoReactComponent() {
  const {
    correlationId: contextCorrelationId,
    visitorId: contextVisitorId,
    company: contextCompany,
    shopCode: contextShopCode,
    cuttingFormCode: contextCuttingFormCode,
    basketUrl: contextBasketUrl,
    languagecode: contextLanguageCode,
    designheaderid: contextDesignHeaderId,
    itemno: contextItemno,
    currencycode,
    onclicksubmit,
    onclicksave,
    isLoadingCustomFields,
    customFields
  } = useContext(BaseContext);
  const initLangSettingFromWc = useMemo(() => LANGUAGE_OPTIONS.find((item) => item.name.toUpperCase() === contextLanguageCode.toUpperCase()), [contextLanguageCode]);

  const currentCanvasGLRef = useRef<WebGLRenderer>();
  const cameraControlsRef = useRef<CameraControls | null>(null);
  const canvasRef = useRef<HTMLDivElement>(null);
  const configuratorRef = useRef<IConfiguratorFabricHandler>(null);
  const sidebarScrollRef = useRef<TDesktopCustomScrollbarFunctionHandler>(null);
  const jakoLogoTabRef = useRef<JakoLogoTabHandler>(null);
  const jakoTeamnameTabRef = useRef<JakoTeamnameTabHandler>(null);
  const jakoNumberTabRef = useRef<JakoNumberTabHandler>(null);
  const jakoNameTabRef = useRef<JakoNameTabHandler>(null);
  const jakoTextTabRef = useRef<JakoTextTabHandler>(null);
  const memoizedCurveTexts = useRef<{ [key: string]: string }>({});

  const [yPosition, setYPosition] = useState(0);

  const startDistance = useRef(0);
  const distanceCount = useRef(0);
  const endDistance = useRef(0);

  const { t, i18n} = useTranslation('translations', { useSuspense: false });

  const [isLoadingPrices, setIsLoadingPrices] = useState(false);
  const [jakoPrices, setJakoPrices] = useState<JakoPricesResponse | null>(null);

  const [showScrollDownSidebar, setShowScrollDownSidebar] = useState(false);
  const [sidebarScrollTopPos, setSidebarScrollTopPos] = useState(0);
  const [sidebarScrollBoundingRect, setSidebarScrollBoundingRect] = useState<DOMRect | undefined>(undefined);

  const [endEdgeSleevePosition, setEndEdgeSleevePosition] = useState(BASE_END_EDGE_SLEEVE_POS);
  const [basedCmFactor, setBaseCmFactor] = useState(CM_FACTOR);
  const [jakoLogoLeftPos, setJakoLogoLeftPos] = useState({} as any);
  const [jakoLogoRightPos, setJakoLogoRightPos] = useState({} as any);
  const [isAddingState, setIsAddingState] = useState<FunctionTab | null>(null);
  const [isCopyingTextState, setIsCopyingTextState] = useState<FunctionTab | null>(null);
  const [isCopyingLogoState, setIsCopyingLogoState] = useState<FunctionTab | null>(null);

  const [selectTab, setSelectTab] = useState<FunctionTab | null>(null);
  const [selectTabIndex, setSelectTabIndex] = useState(0);

  const [selectedDesign, setSelectedDesign] = useState<DesignOption | null>(null);
  const [activateDesignOnBack, setActivateDesignOnBack] = useState(false);
  const [hasBackDesign, setHasBackDesign] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [isLoadingOverlayUI, setIsLoadingOverlayUI] = useState(false);
  const [possibleDesignAreaId, setPossibleDesignAreaId] = useState<string[]>([]);

  const [subsportType, setSubsportType] = useState<string | null>(null);
  const [subsportItemNo, setSubsportItemNo] = useState<string | null>(null);

  const [printcutCoords, setPrintcutCoords] = useState<{
    "name": string,
    "x": number,
    "y": number,
    "coefficientX": number,
    "coefficientY": number
  }[]>([]);

  const [jakoLogoPosition, setJakoLogoPosition] = useState<JakoLogoPosition>(JakoLogoPosition.Right);
  const jakoLogoPositionRef = useRef<JakoLogoPosition>(JakoLogoPosition.Right);

  // ---------- Text State --------------
  const showAddTextInputRef = useRef<HTMLInputElement>(null);
  const showEditTextInputRef = useRef<HTMLInputElement>(null);
  const [addedText, setAddText] = useState('');
  const [addingTextCurved, setAddingTextCurved] = useState(0);
  const [addedFabricTextObjects, setAddedFabricTextObjects] = useState({} as AddedTextItems);
  const addedFabricTextObjectsRef = useRef({} as AddedTextItems);
  const [selectedAddedTextKey, setSelectedAddedTextKey] = useState<string | null>(null);
  // ---------- End of Text State -----------

  // ---------- Image State --------------
  const [selectedAddedLogoKey, setSelectedAddedLogoKey] = useState<string | null>(null);
  const [addedLogo, setAddLogo] = useState<LogoUploadObject | null>(null);
  const [addedFabricLogoObjects, setAddedFabricLogoObjects] = useState({} as AddedLogoItems);
  const addedFabricLogoObjectsRef = useRef({} as AddedLogoItems);
  // ---------- End of Image State -----------

  const [selectedFontFamily, setSelectedFontFamily] = useState('Komikazoom');
  const [selectedTextFill, setSelectedTextFill] = useState('');

  // ---------- Color State -------------
  const [selectedJerseySectionColor, setSelectedJerseySectionColor] = useState<any>({});
  const [colorList, setColorList] = useState<ColorResponseItem[]>([]);
  const sortedColorList = useMemo(() => colorList.slice().sort((a, b) => {
    if (a.isPrimary && b.isPrimary) {
      return a.sorting - b.sorting;
    } else if (a.isPrimary) {
      return -1;
    } else if (b.isPrimary) {
      return 1;
    } else {
      return a.sorting - b.sorting;
    }
  }), [colorList]);

  const onlyPossibleDesignAreas = useMemo(() => {
    let results: { [key: string]: string } = {};
    if (possibleDesignAreaId.length) {
      const onlyNonCopyItems = possibleDesignAreaId.slice().filter((area) => !area.includes('collar_under') && !area.includes('placket') && !area.includes('neck') && !area.includes('bg-') && !area.includes('copy'));
      onlyNonCopyItems.forEach((item) => {
        const getWordDesignPart = item.split('_')[0]; // design-1, design-2, design-3
        const getWordDesignJerseySectionPart = item.split('_')[1] ? item.split('_')[1] : ''; // front, back, left, right
        const getDesignWord = getWordDesignPart.split('-')[0] + (getWordDesignPart.split('-')[1] ? ' ' + getWordDesignPart.split('-')[1] : '');
        const getAreaName = getDesignWord + ' ' + getWordDesignJerseySectionPart;
        results = {
          ...results,
          [item]: getAreaName
        };
      });
      return results;
    } else {
      return null;
    }
  }, [possibleDesignAreaId]);

  // const hasStripe = useMemo(() => possibleDesignAreaId.some((area) => area.includes('stripe')) , [possibleDesignAreaId]);
  // console.log('hasStripe');

  const sortedColorListRef = useRef<ColorResponseItem[]>([]);
  const colorLogoHexRef = useRef('');
  const colorDotsLeftHexRef = useRef('');
  const colorDotsRightHexRef = useRef('');
  const colorInvertedBgRef = useRef('');

  const mainSchwarzMemo = useMemo(() => sortedColorListRef.current.find((item) => item.code === '3D_SCHWARZ'), [sortedColorListRef]);
  const selectedLang = useRef(initLangSettingFromWc || LANGUAGE_OPTIONS[0]);

  const [selectedJerseySectionPattern, setSelectedJerseySectionPattern] = useState({} as AddedPatternJerseySectionItems);
  const [patternJerseySection, setPatternJerseySection] = useState<string>('');
  const [patternList, setPatternList] = useState<PatternResponseItem[]>([]);
  // ---------- End of Pattern State -------------

  const addSectionPartRef = useRef<JerseySection>(JerseySection.Front);
  const addSectionPositionRef = useRef<BasicAddPosition | NameInitialAddPosition | NumberAddPosition | TeamnameAddPosition | TextAddPosition | LogoAddPosition | null>(null);
  const initialSaveConfigDataRef = useRef<SaveConfigState | null>(null);
  const updateSaveConfigDataRef = useRef<SaveConfigState | null>(null);
  const designHeaderIdJWTRef = useRef<string | null>(null);

  const [initialized, setInitialized] = useState(false);

  const cuttingFormResponseDataRef = useRef<CuttingResponseItem[]>([]);
  const [jakoCurrentCuttingFormCode, setJakoCurrentCuttingFormCode] = useState(contextCuttingFormCode);

  const jfnetCuttingFormCode = useMemo(() => jakoCurrentCuttingFormCode.slice().replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', ''), [jakoCurrentCuttingFormCode]);

  const selectModelStyle = useMemo<ModelStyle>(() => {
    if (jakoCurrentCuttingFormCode.length < 9) {
      return ModelStyle.Short;
    } else if (!jakoCurrentCuttingFormCode.includes('LS') && !jakoCurrentCuttingFormCode.includes('SS')) {
      return ModelStyle.ShirtNoSleeve;
    } else {
      return ModelStyle.Shirt;
    }
  }, [jakoCurrentCuttingFormCode]);

  const currentCuttingFormItemNoRef = useRef('');
  const [cuttingFileModelData, setCuttingFileModelData] = useState<CuttingFileTemplateResponse | null>(null);

  const cuttingFormLinksResponseRef = useRef<CuttingOptionResponseItem[]>([]);
  const cuttingOptions = useRef<CuttingOptionData[]>([]);
  const [currentSportCuttingFormOption, setCurrentSportCuttingFormOption] = useState<CuttingOptionResponseItem | null>(null);
  const selectedCuttingSportType = useMemo(() => currentSportCuttingFormOption ? currentSportCuttingFormOption.value : '', [currentSportCuttingFormOption]);
  const activateStripe = useMemo(() => selectedCuttingSportType ? !selectedCuttingSportType.includes('BASKETBALL') : true, [selectedCuttingSportType]);

  const [isMobile, setIsMobile] = useState<boolean | null>(null);
  const [isMobileStartState, setIsMobileStartState] = useState<boolean | null>(null);
  const [isSavingScreenshot, setIsSavingScreenshot] = useState(false);
  const closeFunctionMobileChange = useRef(null as any);


  const isEditedMemo = useMemo(
    () => (Object.keys(addedFabricTextObjects).length > 0
      || Object.keys(addedFabricLogoObjects).length > 0
      || Object.keys(selectedJerseySectionPattern).length > 0)
      || (selectedDesign ? selectedDesign.name !== 'Blanko' : false),
    [selectedDesign, addedFabricTextObjects, addedFabricLogoObjects, selectedJerseySectionPattern]
  );

  const hasUnprocessableImage = useMemo(
    () => Object.keys(addedFabricLogoObjects).length > 0 && Object.keys(addedFabricLogoObjects).some((key) => addedFabricLogoObjects[key].isUnprocessable),
    [addedFabricLogoObjects]
  );
  const screenshotCountIndex = useRef(0);

  const canShowCuttingTab = useMemo(
    () => {
      return ModelStyle.Short !== selectModelStyle && !jakoCurrentCuttingFormCode.includes('K6081');
    },
    [selectModelStyle, jakoCurrentCuttingFormCode]
  );
  const menuTabFiltered = useMemo(() => TAB_ORDER.slice().filter((tabItem) => {
    if (canShowCuttingTab) {
      if (tabItem.fieldCode) {
        return customFields.find((field) => tabItem.fieldCode && field.code.includes(tabItem.fieldCode));
      } else {
        return true;
      }
    } else {
      return tabItem.functionTab !== FunctionTab.Cut;
    }
  }), [customFields, canShowCuttingTab]);

  const isCustomerDesign = useRef(false);

  useEffect(() => {
    function handleWindowResize() {
      setShowScrollDownSidebarHandler();
      const vh = window.innerHeight;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      if (window.innerWidth < 1024) {
        setIsMobile(true);
      } else if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i
          .test(navigator.userAgent)
          || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i
            .test(navigator.userAgent.substr(0, 4))
      ) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        configuratorRef.current?.updateObjectPosition('up');
      } else if (event.key === 'ArrowLeft') {
        event.preventDefault();
        configuratorRef.current?.updateObjectPosition('left');
      } else if (event.key === 'ArrowRight') {
        event.preventDefault();
        configuratorRef.current?.updateObjectPosition('right');
      } else if (event.key === 'ArrowDown') {
        event.preventDefault();
        configuratorRef.current?.updateObjectPosition('down');
      }
    };
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    setSidebarOnScrollHandler();
    document.addEventListener('keydown', onKeyDown);

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!updateSaveConfigDataRef.current) {
        return;
      }

      // Recommended
      event.preventDefault();
      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true;

      setTimeout(() => {
        const target = event.currentTarget as any;
        if (target) {
          target.alert(t('configurator_3d_on_close_tab'));
        }
      }, 500);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      document.removeEventListener('keydown', onKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectModelStyle === ModelStyle.Short) {
      setYPosition(-0.2);
      setJakoLogoPosition(JakoLogoPosition.Left);
      jakoLogoPositionRef.current = JakoLogoPosition.Left;
    } else {
      setYPosition(0);
      setJakoLogoPosition(JakoLogoPosition.Right);
      jakoLogoPositionRef.current = JakoLogoPosition.Right;
    }
  }, [selectModelStyle]);

  useEffect(() => {
    setTimeout(() => {
      moveCameraToFrontSide(true);
    }, 500);
    // eslint-disable-next-line
  }, [isMobile]);

  useEffect(() => {
    if (isMobile) {
      setSelectTab(null);
    } else {
      setSelectTab(menuTabFiltered[0].functionTab);
    }
    setSelectTabIndex(0);
    // eslint-disable-next-line
  }, [menuTabFiltered]);

  useEffect(() => {
    if (isMobile === null) {
      return;
    }
    if (isMobileStartState === null) {
      setIsMobileStartState(isMobile);
      return;
    }
    if (isLoading || isLoadingInitial) {
      return;
    }
    if (closeFunctionMobileChange.current && isMobileStartState === isMobile) {
      closeFunctionMobileChange.current();
      return;
    }
    if (isMobileStartState !== isMobile) {
      confirmAlert({
        customUI: ({ onClose }) => {
          closeFunctionMobileChange.current = onClose;
          return (
            <div id="jako-configurator-3d-wc" className="confirm-popup-container">
              <h5 className="confirm-popup-container__title">
                { t('configurator_3d_different_screen_change_need_refresh') }
              </h5>
              <div className="confirm-popup__refresh-func-container">
                <button className="button_primary" onClick={() => handleSaveConfiguration(false, true)}>
                  { t('configurator_3d_save_before_refresh') }
                </button>
                <button className="outlineButton" onClick={handleOnScreenChange}>
                  { t('configurator_3d_refresh') }
                </button>
              </div>
            </div>
          );
        },
        closeOnClickOutside: false,
        closeOnEscape: false
      });
      return;
    }
    closeFunctionMobileChange.current = null;
    // eslint-disable-next-line
  }, [isMobileStartState, isMobile]);

  function handleOnScreenChange() {
    window.location.reload();
  }

  function setSidebarOnScrollHandler() {
    setSidebarScrollTopPos(0);
    const shadowRootContainer = document.querySelector('jako-configurator');
    const shadowRoot = shadowRootContainer ? shadowRootContainer.shadowRoot : null;
    const sidebarContentEl = shadowRoot ? shadowRoot.querySelector('.configurator__sidebarContent') : document.querySelector('.configurator__sidebarContent');
    if (!sidebarContentEl) {
      return;
    }

    sidebarContentEl.addEventListener('scroll', function() {
      setSidebarScrollTopPos(sidebarContentEl.scrollTop);
    });
  }

  function setShowScrollDownSidebarHandler() {
    const shadowRootContainer = document.querySelector('jako-configurator');
    const shadowRoot = shadowRootContainer ? shadowRootContainer.shadowRoot : null;
    const sidebarContentEl = shadowRoot ? shadowRoot.querySelector('.configurator__sidebarContent') : document.querySelector('.configurator__sidebarContent');
    if (sidebarContentEl) {
      const hasVerticalScrollbar = sidebarContentEl.scrollHeight > sidebarContentEl.clientHeight;
      setShowScrollDownSidebar(hasVerticalScrollbar);

      setTimeout(() => {
        const shadowRootContainer = document.querySelector('jako-configurator');
        const shadowRoot = shadowRootContainer ? shadowRootContainer.shadowRoot : null;
        const scrollDownButtonAreaEl = shadowRoot ? shadowRoot.querySelector('.scroll-down-icon-area') : document.querySelector('.scroll-down-icon-area');
        if (scrollDownButtonAreaEl) {
          setSidebarScrollBoundingRect(scrollDownButtonAreaEl.getBoundingClientRect());
        }
      }, 100);
    } else {
      setShowScrollDownSidebar(false);
    }
  }

  const onChangeLanguageHandler = useCallback((lang?: string, isInit?: boolean) => {
    if (!isInit) {
      setIsLoadingInitial(true);
    }
    const selectLang = lang?.toUpperCase() || 'DE';
    const correlationId = contextCorrelationId || '111';
    const company = contextCompany || 'JAKO AG';
    const forParam = 1;
    JakoServices.getTranslations({
      correlationId,
      company,
      selectLang,
      forParam
    }).then((response) => {
      const translationData = response.data as TranslationResponseItem[];
      let mapIntoTranslationData = {};
      translationData.forEach((item) => {
        mapIntoTranslationData = {
          ...mapIntoTranslationData,
          [item.tag]: item.translation
        };
      });
      i18n.addResourceBundle(selectLang.toLowerCase(), 'translations', mapIntoTranslationData);
      i18n.changeLanguage(selectLang.toLowerCase())
        .finally(() => {
          if (!isInit) {
            setIsLoadingInitial(false);
          }
        });
    });
    // eslint-disable-next-line
  }, [i18n]);

  useEffect(() => {
    if (!i18n.language) {
      return;
    }
    if (i18n.language.toUpperCase() === contextLanguageCode.toUpperCase()) {
      return;
    }
    initColorList();
    // eslint-disable-next-line
  }, [contextLanguageCode, i18n.language]);

  useEffect(() => {
    if (!initialized) {
      fetchCuttingFormData()
        .then((jakoCuttingResponse) => {
          const correlationId = contextCorrelationId  || '111';
          const company = contextCompany || 'JAKO AG';
          const shopCode = contextShopCode || 'B2C';
          const languageCode = selectedLang.current.name || contextLanguageCode;
          JakoServices.getCuttingsOptions({
            correlationId,
            company,
            shopCode,
            languageCode,
          }).then((resCuttingOptions) => {
            cuttingFormLinksResponseRef.current = resCuttingOptions.data;
            const foundedCuttingFormLinkItemOfSportart = resCuttingOptions.data.find((item) => item.item_no === contextItemno && item.code === '3D_SPORTART');
            if (foundedCuttingFormLinkItemOfSportart) {
              setSubsportType(foundedCuttingFormLinkItemOfSportart.value);
              setSubsportItemNo(foundedCuttingFormLinkItemOfSportart.item_no);
            }
            // [Prices only available in user URL]
            // setIsLoadingPrices(true);
            // JakoServices.getPrices({
            //   correlationId: contextCorrelationId || '1',
            //   itemNo: contextItemno,
            //   cuttingFormCode: contextCuttingFormCode,
            //   currencyCode: currencycode || 'DE',
            // }).then((res) => {
            //   if (res.status === 200) {
            //     if (
            //       res.data
            //       && res.data.prices
            //       && Array.isArray(res.data.prices)
            //       && res.data.prices.length > 0
            //     ) {
            //       setJakoPrices(res.data);
            //     }
            //   }
            // }).finally(() => {
            //   setIsLoadingPrices(false);
            // });
            // JfnetServices.getPrintcutCoords(jfnetCuttingFormCode)
            //   .then((res) => {
            //     if (res.status === 200) {
            //       setPrintcutCoords(res.data);
            //     }
            //   });
            setCuttingFormOptions(contextCuttingFormCode);
            onChangeLanguageHandler(contextLanguageCode.toUpperCase(), true);
            initialSaveConfigState(jakoCuttingResponse)
              .then(() => {
                setInitialized(true);
              });
          });
        });
    }
    // eslint-disable-next-line
  }, [initialized, selectedLang]);

    /**
   * This function set the possible cutting options in the CuttingTab from the selected cuttingFormCode
   *
   * All dynamic options are based on the returning data of JAKO DC API [Route: /cuttingFormLinks]
   * Important note:
   * - 3D_SPORTART is the mandatory field of the /cuttingFormLinks. If this is not existed then this will not working at all.
   * - Based on this issue https://gitlab.jfnet.de/Jfnetwork/jako/api/-/issues/164, 1 cutting form code can have mulitple SPORTART then the itemno need to always sent in the webcomponent prop to dispaly the correct cutting form code result
   *
   * Results will be set to cuttingOptions ref
   * @param selectCuttingFormCode
   */
  function setCuttingFormOptions(selectCuttingFormCode: string) {
    // First get the option of the selected cuttingFormCode from JAKO DC /cuttingFormLinks response
    const getOptionsOfSelectedCutting = cuttingFormLinksResponseRef.current.slice().filter((item) => item.code !== '3D_MATERIAL' && item.cuttingFormCode === selectCuttingFormCode);
    const currentItemNoToCheckSubsportType = subsportItemNo || contextItemno;
    const foundedSportType = getOptionsOfSelectedCutting.find((item) => item.code === '3D_SPORTART' && item.item_no === currentItemNoToCheckSubsportType);
    if (!foundedSportType) {
      return;
    }
    setCurrentSportCuttingFormOption(foundedSportType);

    // -------------- Get list of possible cutting form codes for each cutting option --------------
    // 3D_KRAGENFORM, 3D_SCHNITTFORM, 3D_SCHNITTFORM_AERMEL, 3D_KRAGENABSCHLUSS, 3D_AERMELABSCHLUSS, 3D_SPORTART

    // Find the COLLAR(3D_KRAGENFORM) of the selected cuttingFormCode
    const foundedCollarCutting = getOptionsOfSelectedCutting.find((item: any) => item.code === '3D_KRAGENFORM');
    // Get only list of COLLAR(3D_KRAGENFORM) of the selected cuttingFormCode from the JAKO DC /cuttingFormLinks response
    const onlySelectedCollarCuttingItems = foundedCollarCutting
      ? cuttingFormLinksResponseRef.current.slice().filter((item: any) => item.value === foundedCollarCutting.value)
      : [];
    // Get only unique cuttingFormCode values from the onlySelectedCollarCuttingItems
    const possibleCuttingFormCodesOfSelectedCollarCutting = [...new Set(onlySelectedCollarCuttingItems.map((item: any) => item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))];

    // Find the SLEEVE(3D_SCHNITTFORM) of the selected cuttingFormCode
    const foundedSleeveCutting = getOptionsOfSelectedCutting.find((item: any) => item.code === '3D_SCHNITTFORM');
    // Get only list of SLEEVE(3D_SCHNITTFORM) of the selected cuttingFormCode from the JAKO DC /cuttingFormLinks response
    const onlySelectedSleeveCuttingItems = foundedSleeveCutting
      ? cuttingFormLinksResponseRef.current.slice().filter((item: any) => item.value === foundedSleeveCutting.value)
      : [];
    // Get only unique cuttingFormCode values from the onlySelectedSleeveCuttingItems
    const possibleCuttingFormCodesOfSelectedSleeveCutting = [...new Set(onlySelectedSleeveCuttingItems.map((item: any) => item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))];

    // Find the SLEEVE SHAPE eg., Raglan, Set-in (3D_SCHNITTFORM_AERMEL) of the selected cuttingFormCode
    const foundedSleeveShape = getOptionsOfSelectedCutting.find((item: any) => item.code === '3D_SCHNITTFORM_AERMEL');
    // Get only list of SLEEVE SHAPE eg., Raglan, Set-in (3D_SCHNITTFORM_AERMEL) of the selected cuttingFormCode from the JAKO DC /cuttingFormLinks response
    const onlySelectedSleeveShapeItems = foundedSleeveShape
      ? cuttingFormLinksResponseRef.current.slice().filter((item: any) => item.value === foundedSleeveShape.value)
      : [];
    // Get only unique cuttingFormCode values from the onlySelectedSleeveShapeItems
    const possibleCuttingFormCodesOfSelectedSleeveShape = [...new Set(onlySelectedSleeveShapeItems.map((item: any) => item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))];

    // Find the COLLAR MATERIAL(3D_KRAGENABSCHLUSS) of the selected cuttingFormCode
    const foundedCollarMaterial = getOptionsOfSelectedCutting.find((item: any) => item.code === '3D_KRAGENABSCHLUSS');
    // Get only list of COLLAR MATERIAL(3D_KRAGENABSCHLUSS) of the selected cuttingFormCode from the JAKO DC /cuttingFormLinks response
    const onlySelectedCollarMaterialItems = foundedCollarMaterial
      ? cuttingFormLinksResponseRef.current.slice().filter((item: any) => item.value === foundedCollarMaterial.value)
      : [];
    // Get only unique cuttingFormCode values from the onlySelectedCollarMaterialItems
    const possibleCuttingFormCodesOfSelectedCollarMaterial = [...new Set(onlySelectedCollarMaterialItems.map((item: any) => item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))];

    // Find the CUFF MATERIAL(3D_AERMELABSCHLUSS) of the selected cuttingFormCode
    const foundedCuffMaterial = getOptionsOfSelectedCutting.find((item: any) => item.code === '3D_AERMELABSCHLUSS');
    // Get only list of CUFF MATERIAL(3D_AERMELABSCHLUSS) of the selected cuttingFormCode from the JAKO DC /cuttingFormLinks response
    const onlySelectedCuffMaterialItems = foundedCuffMaterial
      ? cuttingFormLinksResponseRef.current.slice().filter((item: any) => item.value === foundedCuffMaterial.value)
      : [];
    // Get only unique cuttingFormCode values from the onlySelectedCuffMaterialItems
    const possibleCuttingFormCodesOfSelectedCuffMaterial = [...new Set(onlySelectedCuffMaterialItems.map((item: any) => item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))];

    // Get only list of SPORT(3D_SPORTART) of the selected cuttingFormCode from the JAKO DC /cuttingFormLinks response
    const onlySelectedSportItems = cuttingFormLinksResponseRef.current.slice().filter((item: any) => item.value === foundedSportType.value);
    // Get only unique cuttingFormCode values from the onlySelectedSportItems
    const possibleCuttingFormCodesOfSelectedSport = [...new Set(onlySelectedSportItems.map((item: any) => item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))];
    // -------------- End get list of possible cutting form codes for each cutting option --------------

    // Loop each possible option title(code)
    // - 3D_KRAGENFORM
    // - 3D_SCHNITTFORM
    // - 3D_SCHNITTFORM_AERMEL
    // - 3D_AERMELABSCHLUSS
    // - 3D_KRAGENABSCHLUSS
    const mappedPossibleOptions = getOptionsOfSelectedCutting.map((optItem) => {
      const getPossibleOptionsForEachTitle = cuttingFormLinksResponseRef.current.slice().filter((item) =>
        optItem.code === item.code
        && possibleCuttingFormCodesOfSelectedSport.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', ''))
      );

      // Filter
      const filteredPossibleOptions = getPossibleOptionsForEachTitle.slice().filter((item) => {
        if (item.code === '3D_KRAGENFORM') {
          return (possibleCuttingFormCodesOfSelectedCollarMaterial.length === 0 || possibleCuttingFormCodesOfSelectedCollarMaterial.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))
            && (possibleCuttingFormCodesOfSelectedCuffMaterial.length === 0 || possibleCuttingFormCodesOfSelectedCuffMaterial.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))
            && (possibleCuttingFormCodesOfSelectedSleeveCutting.length === 0 || possibleCuttingFormCodesOfSelectedSleeveCutting.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))
            && (possibleCuttingFormCodesOfSelectedSleeveShape.length === 0 || (possibleCuttingFormCodesOfSelectedSleeveShape.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', ''))));
        } else if (item.code === '3D_SCHNITTFORM') {
          return (possibleCuttingFormCodesOfSelectedCollarCutting.length === 0 || possibleCuttingFormCodesOfSelectedCollarCutting.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))
            && (possibleCuttingFormCodesOfSelectedCuffMaterial.length === 0 || possibleCuttingFormCodesOfSelectedCuffMaterial.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))
            && (possibleCuttingFormCodesOfSelectedCollarMaterial.length === 0 || possibleCuttingFormCodesOfSelectedCollarMaterial.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))
            && (possibleCuttingFormCodesOfSelectedSleeveShape.length === 0 || possibleCuttingFormCodesOfSelectedSleeveShape.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')));
        } else if (item.code === '3D_SCHNITTFORM_AERMEL') {
          return (possibleCuttingFormCodesOfSelectedCollarCutting.length === 0 || possibleCuttingFormCodesOfSelectedCollarCutting.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))
            && (possibleCuttingFormCodesOfSelectedCuffMaterial.length === 0 || possibleCuttingFormCodesOfSelectedCuffMaterial.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))
            && (possibleCuttingFormCodesOfSelectedCollarMaterial.length === 0 || possibleCuttingFormCodesOfSelectedCollarMaterial.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))
            && (possibleCuttingFormCodesOfSelectedSleeveCutting.length === 0 || possibleCuttingFormCodesOfSelectedSleeveCutting.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')));
        } else if (item.code === '3D_KRAGENABSCHLUSS') {
          return (possibleCuttingFormCodesOfSelectedCollarCutting.length === 0 || possibleCuttingFormCodesOfSelectedCollarCutting.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))
            && (possibleCuttingFormCodesOfSelectedCuffMaterial.length === 0 || possibleCuttingFormCodesOfSelectedCuffMaterial.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))
            && (possibleCuttingFormCodesOfSelectedSleeveShape.length === 0 || possibleCuttingFormCodesOfSelectedSleeveShape.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))
            && (possibleCuttingFormCodesOfSelectedSleeveCutting.length === 0 || possibleCuttingFormCodesOfSelectedSleeveCutting.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')));
        } else if (item.code === '3D_AERMELABSCHLUSS') {
          return (possibleCuttingFormCodesOfSelectedCollarCutting.length === 0 || possibleCuttingFormCodesOfSelectedCollarCutting.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))
            && (possibleCuttingFormCodesOfSelectedCollarMaterial.length === 0 || possibleCuttingFormCodesOfSelectedCollarMaterial.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))
            && (possibleCuttingFormCodesOfSelectedSleeveShape.length === 0 || possibleCuttingFormCodesOfSelectedSleeveShape.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')))
            && (possibleCuttingFormCodesOfSelectedSleeveCutting.length === 0 || possibleCuttingFormCodesOfSelectedSleeveCutting.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')));
        } else {
          return (possibleCuttingFormCodesOfSelectedCollarCutting.length === 0 || possibleCuttingFormCodesOfSelectedCollarCutting.includes(item.cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '')));
        }
      });

      const mappedItems = [
        ...filteredPossibleOptions
          .reduce((reducer: any, obj: any) => {
            const key = obj.code + '_' + obj.value; // group by groupId
            let item;
            item = reducer.get(key) ? // If not found same key then create a new one
              {
                ...reducer.get(key),
                groupId: key,
                items: reducer.get(key).items ?
                  [
                    ...reducer.get(key).items,
                    obj,
                  ] :
                  [
                    reducer.get(key), // first item of the set
                    obj,
                  ],
              } :
              {
                ...obj,
              };
            return reducer.set(key, item);
          }, new Map()).values(),
      ].sort((a, b) => a.sorting - b.sorting);

      const allPossibleItems = [
        ...getPossibleOptionsForEachTitle
          .reduce((reducer: any, obj: any) => {
            const key = obj.code + '_' + obj.value; // group by groupId
            let item;
            item = reducer.get(key) ? // If not found same key then create a new one
              {
                ...reducer.get(key),
                groupId: key,
                items: reducer.get(key).items ?
                  [
                    ...reducer.get(key).items,
                    obj,
                  ] :
                  [
                    reducer.get(key), // first item of the set
                    obj,
                  ],
              } :
              {
                ...obj,
              };
            return reducer.set(key, item);
          }, new Map()).values(),
      ].sort((a, b) => a.sorting - b.sorting);

      return {
        ...optItem,
        mappedItems,
        allPossibleItems
      };
    });
    const mappedPossibleOptionsFilterByItemNoOfSelectSubsport = mappedPossibleOptions.slice().filter((item) => item.item_no === foundedSportType.item_no);
    cuttingOptions.current = mappedPossibleOptionsFilterByItemNoOfSelectSubsport;
  }

  function setInitItemNoState(jakoCuttingResponseData: any) {
    if (contextItemno) {
      currentCuttingFormItemNoRef.current = contextItemno;
    } else {
      const foundedCuttingFormData: CuttingResponseItem = jakoCuttingResponseData.find((item: CuttingResponseItem) => item.code === jakoCurrentCuttingFormCode);
      currentCuttingFormItemNoRef.current = foundedCuttingFormData.itemNo;
    }
  }

  async function initialConfiguratorBlankoData(cuttingFileTemplateResponseData: CuttingFileTemplateResponse, jakoCuttingResponseData: any) {
      // [Prices only available in user URL]
    // setIsLoadingPrices(true);
    // JakoServices.getPrices({
    //   correlationId: contextCorrelationId || '1',
    //   itemNo: contextItemno,
    //   cuttingFormCode: jakoCurrentCuttingFormCode,
    //   currencyCode: currencycode || 'DE',
    // }).then((res) => {
    //   if (res.status === 200) {
    //     if (
    //       res.data
    //       && res.data.prices
    //       && Array.isArray(res.data.prices)
    //       && res.data.prices.length > 0
    //     ) {
    //       setJakoPrices(res.data);
    //     }
    //   }
    // }).finally(() => {
    //   setIsLoadingPrices(false);
    // });
    // JfnetServices.getPrintcutCoords(jfnetCuttingFormCode)
    //   .then((res) => {
    //     if (res.status === 200) {
    //       setPrintcutCoords(res.data);
    //     }
    //   });
    setCuttingFormOptions(jakoCurrentCuttingFormCode);

    cuttingFormResponseDataRef.current = jakoCuttingResponseData;
    const blankoDesign = cuttingFileTemplateResponseData.designs.find((item) => item.name === 'Blanko');
    if (blankoDesign) {
      setSelectedDesign(blankoDesign);
    } else {
      setSelectedDesign(cuttingFileTemplateResponseData.designs[0]);
    }
    setInitItemNoState(jakoCuttingResponseData);

    await delay(500);
    setCuttingFileModelData(cuttingFileTemplateResponseData);
  }
  function initialConfiguratorFromSaveData(cuttingFileTemplateResponseData: CuttingFileTemplateResponse, jakoCuttingResponseData: any, saveConfigStateData: SaveConfigState) {
    setJakoCurrentCuttingFormCode(saveConfigStateData.selectedModel.cuttingFormCode);
    // [Prices only available in user URL]
    // setIsLoadingPrices(true);
    // JakoServices.getPrices({
    //   correlationId: contextCorrelationId || '1',
    //   itemNo: contextItemno,
    //   cuttingFormCode: jakoCurrentCuttingFormCode,
    //   currencyCode: currencycode || 'DE',
    // }).then((res) => {
    //   if (res.status === 200) {
    //     if (
    //       res.data
    //       && res.data.prices
    //       && Array.isArray(res.data.prices)
    //       && res.data.prices.length > 0
    //     ) {
    //       setJakoPrices(res.data);
    //     }
    //   }
    // }).finally(() => {
    //   setIsLoadingPrices(false);
    // });
    JfnetServices.getPrintcutCoords(jfnetCuttingFormCode)
      .then((res) => {
        if (res.status === 200) {
          setPrintcutCoords(res.data);
        }
      });
    setCuttingFormOptions(saveConfigStateData.selectedModel.cuttingFormCode);

    cuttingFormResponseDataRef.current = jakoCuttingResponseData;
    setInitItemNoState(jakoCuttingResponseData);

    initialSaveConfigDataRef.current = saveConfigStateData;
    updateSaveConfigDataRef.current = saveConfigStateData;
    colorLogoHexRef.current = saveConfigStateData.colorLogoHexRef;
    colorDotsLeftHexRef.current = saveConfigStateData.colorDotsLeftHexRef;
    colorDotsRightHexRef.current  = saveConfigStateData.colorDotsRightHexRef;
    colorInvertedBgRef.current = saveConfigStateData.colorInvertedBgRef;
    let tmpCuttingFileTemplateResponseData = cuttingFileTemplateResponseData;
    if (saveConfigStateData.selectedModel) {
      tmpCuttingFileTemplateResponseData = {
        ...tmpCuttingFileTemplateResponseData,
        ...saveConfigStateData.selectedModel
      };
    }
    setAddedFabricLogoObjects(saveConfigStateData.addedFabricLogoObjects);
    addedFabricLogoObjectsRef.current = saveConfigStateData.addedFabricLogoObjects;
    setAddedFabricTextObjects(saveConfigStateData.addedFabricTextObjects);
    addedFabricTextObjectsRef.current = saveConfigStateData.addedFabricTextObjects;
    jakoLogoPositionRef.current = saveConfigStateData.jakoLogoPosition;
    setJakoLogoPosition(saveConfigStateData.jakoLogoPosition);
    if (saveConfigStateData.selectedDesign) {
      // check design and set jersey color and pattern on start
      return onSelectDesignStart(
        tmpCuttingFileTemplateResponseData,
        saveConfigStateData.selectedDesign,
        saveConfigStateData.selectedJerseySectionColor,
        saveConfigStateData.selectedJerseySectionPattern
      );
    } else {
      const blankoDesign = tmpCuttingFileTemplateResponseData.designs.find((item) => item.name === 'Blanko');
      if (blankoDesign) {
        return onSelectDesignStart(
          tmpCuttingFileTemplateResponseData,
          blankoDesign,
          saveConfigStateData.selectedJerseySectionColor,
          saveConfigStateData.selectedJerseySectionPattern
        );
      } else {
        return onSelectDesignStart(
          tmpCuttingFileTemplateResponseData,
          tmpCuttingFileTemplateResponseData.designs[0],
          saveConfigStateData.selectedJerseySectionColor,
          saveConfigStateData.selectedJerseySectionPattern
        );
      }
    }
  }
  function onConfiguratorInit() {
    configuratorRef.current?.loadSvgDesignToFabric(true);
  }

  function initialSaveConfigState(jakoCuttingResponse: any) {
    if (!jakoCuttingResponse.data) {
      setIsLoading(false);
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="confirm-popup-container">
              <h5 className="confirm-popup-container__title">
                { t('configurator_3d_undefined_cutting_data_response') }
              </h5>
              <button className="button_primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      return Promise.resolve();
    }
    if (jakoCuttingResponse.data.length === 0) {
      setIsLoading(false);
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="confirm-popup-container">
              <h5 className="confirm-popup-container__title">
                { t('configurator_3d_empty_cutting_data_response') }
              </h5>
              <button className="button_primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      return Promise.resolve();
    }

    const urlParams = new URLSearchParams(window.location.search);
    const testGetSaveId = urlParams.get('testGetSaveId');
    // const signature = urlParams.get('signature');
    if (testGetSaveId) {
      const isJwt = testGetSaveId.toString().length > 10;
      if (isJwt) {
        const joseDecoded = decodeJwt(testGetSaveId);
        if (!joseDecoded) {
          return Promise.reject();
        }
        if (!joseDecoded.designHeaderId) {
          return Promise.reject();
        }
        const designHeaderIdFromJWT = joseDecoded.designHeaderId as string;
        return JfnetServices.getConfigsWithJwt(designHeaderIdFromJWT, testGetSaveId)
          .then((res) => {
            const stringDecoded = Base64.decode(res.data.data);
            const saveConfigStateData = JSON.parse(stringDecoded) as SaveConfigState;
            if (res.data.data.length < 25) {
              return JfnetServices.getCuttingFiles(saveConfigStateData.selectedModel.templateCode)
                .then((cuttingRes) => initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data));
            }
            if (!(saveConfigStateData.hasBackDesign === null ||  saveConfigStateData.hasBackDesign === undefined) && saveConfigStateData.hasBackDesign) {
              handleTriggerDesignOnBack(saveConfigStateData.activateDesignOnBack);
            }
            if (!Object.keys(saveConfigStateData).length) {
              return JfnetServices.getCuttingFiles(saveConfigStateData.selectedModel.templateCode)
                .then((cuttingRes) => initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data));
            }
            if (!testGetSaveId) {
              return JfnetServices.getCuttingFiles(saveConfigStateData.selectedModel.templateCode)
                .then((cuttingRes) =>  initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data));
            }
            if (!saveConfigStateData.designHeaderId) {
              return JfnetServices.getCuttingFiles(saveConfigStateData.selectedModel.templateCode)
                .then((cuttingRes) => initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data));
            }
            if (parseInt(testGetSaveId.toString()) !== parseInt(saveConfigStateData.designHeaderId.toString())) {
              return JfnetServices.getCuttingFiles(saveConfigStateData.selectedModel.templateCode)
                .then((cuttingRes) => initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data));
            }
            return JfnetServices.getCuttingFiles(saveConfigStateData.selectedModel.templateCode)
              .then((cuttingRes) => initialConfiguratorFromSaveData(cuttingRes.data, jakoCuttingResponse.data, saveConfigStateData));
          })
          .catch(() => {
            setIsLoadingInitial(false);
            setIsLoadingPrices(false);
            setIsLoading(false);
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                    <h5 className="confirm-popup-container__title">
                      { t('configurator_3d_error_something_wrong') }
                    </h5>
                    <button className="button_primary" onClick={() => { window.location.href = '/'; }}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
            });
          });
      }
      return JfnetServices.getConfigs(testGetSaveId)
        .then((res) => {
          const stringDecoded = Base64.decode(res.data.data);
          const saveConfigStateData = JSON.parse(stringDecoded) as SaveConfigState;
          if (res.data.data.length < 25) {
            return JfnetServices.getCuttingFiles(saveConfigStateData.selectedModel.templateCode)
              .then((cuttingRes) => initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data));
          }
          if (!(saveConfigStateData.hasBackDesign === null ||  saveConfigStateData.hasBackDesign === undefined) && saveConfigStateData.hasBackDesign) {
            handleTriggerDesignOnBack(saveConfigStateData.activateDesignOnBack);
          }
          if (!Object.keys(saveConfigStateData).length) {
            return JfnetServices.getCuttingFiles(saveConfigStateData.selectedModel.templateCode)
              .then((cuttingRes) => initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data));
          }
          if (!testGetSaveId) {
            return JfnetServices.getCuttingFiles(saveConfigStateData.selectedModel.templateCode)
              .then((cuttingRes) =>  initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data));
          }
          if (!saveConfigStateData.designHeaderId) {
            return JfnetServices.getCuttingFiles(saveConfigStateData.selectedModel.templateCode)
              .then((cuttingRes) => initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data));
          }
          if (parseInt(testGetSaveId.toString()) !== parseInt(saveConfigStateData.designHeaderId.toString())) {
            return JfnetServices.getCuttingFiles(saveConfigStateData.selectedModel.templateCode)
              .then((cuttingRes) => initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data));
          }
          return JfnetServices.getCuttingFiles(saveConfigStateData.selectedModel.templateCode)
            .then((cuttingRes) => initialConfiguratorFromSaveData(cuttingRes.data, jakoCuttingResponse.data, saveConfigStateData));
        })
        .catch(() => {
          setIsLoadingInitial(false);
          setIsLoadingPrices(false);
          setIsLoading(false);
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                  <h5 className="confirm-popup-container__title">
                    { t('configurator_3d_error_something_wrong') }
                  </h5>
                  <button className="button_primary" onClick={() => { window.location.href = '/'; }}>
                    { t('configurator_3d_ok') }
                  </button>
                </div>
              );
            }
          });
        });
    }

    const foundedModelJfnet = jakoCuttingResponse.data.find((item: any) => contextCuttingFormCode === item.code);
    let initCuttingFormCode = foundedModelJfnet ? foundedModelJfnet.model : jfnetCuttingFormCode;

    if (!contextDesignHeaderId) {
      return JfnetServices.getCuttingFiles(initCuttingFormCode)
        .then((cuttingRes) => {
          if (!cuttingRes.data) {
            setIsLoadingInitial(false);
            setIsLoadingPrices(false);
            setIsLoading(false);
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                    <h5 className="confirm-popup-container__title">
                      { t('configurator_3d_error_something_wrong') }
                    </h5>
                    <button className="button_primary" onClick={onClose}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
            });
            return Promise.resolve();
          }
          return initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data);
        });
    }
    if (contextDesignHeaderId.toString() === '0') {
      return JfnetServices.getCuttingFiles(initCuttingFormCode)
        .then((cuttingRes) => {
          if (!cuttingRes.data) {
            setIsLoadingInitial(false);
            setIsLoadingPrices(false);
            setIsLoading(false);
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                    <h5 className="confirm-popup-container__title">
                      { t('configurator_3d_error_something_wrong') }
                    </h5>
                    <button className="button_primary" onClick={onClose}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
            });
            return Promise.resolve();
          }
          return initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data);
        });
    }
    const isJwt = contextDesignHeaderId.toString().length > 10;
    if (isJwt) {
      const joseDecoded = decodeJwt(contextDesignHeaderId.toString());
      if (!joseDecoded) {
        return Promise.reject();
      }
      if (!joseDecoded.designHeaderId) {
        return Promise.reject();
      }
      designHeaderIdJWTRef.current = contextDesignHeaderId.toString();
      const designHeaderIdFromJWT = joseDecoded.designHeaderId as string;
      return JfnetServices.getConfigsWithJwt(designHeaderIdFromJWT, contextDesignHeaderId.toString())
        .then((res) => {
          const stringDecoded = Base64.decode(res.data.data);
          const saveConfigStateData = JSON.parse(stringDecoded) as SaveConfigState;
          if (saveConfigStateData && saveConfigStateData.selectedModel) {
            initCuttingFormCode = saveConfigStateData.selectedModel.templateCode;
          } else {
            initCuttingFormCode = jfnetCuttingFormCode;
          }
          if (res.data.data.length < 25) {
            return JfnetServices.getCuttingFiles(initCuttingFormCode)
              .then((cuttingRes) => {
                if (!cuttingRes.data) {
                  setIsLoadingInitial(false);
                  setIsLoadingPrices(false);
                  setIsLoading(false);
                  confirmAlert({
                    customUI: ({ onClose }) => {
                      return (
                        <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                          <h5 className="confirm-popup-container__title">
                            { t('configurator_3d_error_something_wrong') }
                          </h5>
                          <button className="button_primary" onClick={onClose}>
                            { t('configurator_3d_ok') }
                          </button>
                        </div>
                      );
                    }
                  });
                  return Promise.resolve();
                }
                return initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data);
              });
          }
          if (!(saveConfigStateData.hasBackDesign === null ||  saveConfigStateData.hasBackDesign === undefined) && saveConfigStateData.hasBackDesign) {
            handleTriggerDesignOnBack(saveConfigStateData.activateDesignOnBack);
          }
          if (!Object.keys(saveConfigStateData).length) {
            return JfnetServices.getCuttingFiles(initCuttingFormCode)
              .then((cuttingRes) => {
                if (!cuttingRes.data) {
                  setIsLoadingInitial(false);
                  setIsLoadingPrices(false);
                  setIsLoading(false);
                  confirmAlert({
                    customUI: ({ onClose }) => {
                      return (
                        <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                          <h5 className="confirm-popup-container__title">
                            { t('configurator_3d_error_something_wrong') }
                          </h5>
                          <button className="button_primary" onClick={onClose}>
                            { t('configurator_3d_ok') }
                          </button>
                        </div>
                      );
                    }
                  });
                  return Promise.resolve();
                }
                initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data);
              });
          }
          if (!contextDesignHeaderId) {
            return JfnetServices.getCuttingFiles(initCuttingFormCode)
              .then((cuttingRes) => {
                if (!cuttingRes.data) {
                  setIsLoadingInitial(false);
                  setIsLoadingPrices(false);
                  setIsLoading(false);
                  confirmAlert({
                    customUI: ({ onClose }) => {
                      return (
                        <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                          <h5 className="confirm-popup-container__title">
                            { t('configurator_3d_error_something_wrong') }
                          </h5>
                          <button className="button_primary" onClick={onClose}>
                            { t('configurator_3d_ok') }
                          </button>
                        </div>
                      );
                    }
                  });
                  return;
                }
                return initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data);
              });
          }
          if (!saveConfigStateData.designHeaderId) {
            return JfnetServices.getCuttingFiles(initCuttingFormCode)
              .then((cuttingRes) => {
                if (!cuttingRes.data) {
                  setIsLoadingInitial(false);
                  setIsLoadingPrices(false);
                  setIsLoading(false);
                  confirmAlert({
                    customUI: ({ onClose }) => {
                      return (
                        <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                          <h5 className="confirm-popup-container__title">
                            { t('configurator_3d_error_something_wrong') }
                          </h5>
                          <button className="button_primary" onClick={onClose}>
                            { t('configurator_3d_ok') }
                          </button>
                        </div>
                      );
                    }
                  });
                  return Promise.resolve();
                }
                return initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data);
              });
          }
          if (parseInt(designHeaderIdFromJWT) !== parseInt(saveConfigStateData.designHeaderId.toString())) {
            return JfnetServices.getCuttingFiles(initCuttingFormCode)
              .then((cuttingRes) => {
                if (!cuttingRes.data) {
                  setIsLoadingInitial(false);
                  setIsLoadingPrices(false);
                  setIsLoading(false);
                  confirmAlert({
                    customUI: ({ onClose }) => {
                      return (
                        <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                          <h5 className="confirm-popup-container__title">
                            { t('configurator_3d_error_something_wrong') }
                          </h5>
                          <button className="button_primary" onClick={onClose}>
                            { t('configurator_3d_ok') }
                          </button>
                        </div>
                      );
                    }
                  });
                  return Promise.resolve();
                }
                return initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data);
              });
          }
          return JfnetServices.getCuttingFiles(initCuttingFormCode)
            .then((cuttingRes) => {
              if (!cuttingRes.data) {
                setIsLoadingInitial(false);
                setIsLoadingPrices(false);
                setIsLoading(false);
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                        <h5 className="confirm-popup-container__title">
                          { t('configurator_3d_error_something_wrong') }
                        </h5>
                        <button className="button_primary" onClick={onClose}>
                          { t('configurator_3d_ok') }
                        </button>
                      </div>
                    );
                  }
                });
                return Promise.resolve();
              }
              return initialConfiguratorFromSaveData(cuttingRes.data, jakoCuttingResponse.data, saveConfigStateData);
            });
        })
        .catch(() => {
          confirmAlert({
            customUI: () => {
              return (
                <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                  <h5 className="confirm-popup-container__title">
                    { t('configurator_3d_error_unauthorized') }
                  </h5>
                  <button className="button_primary" onClick={() => { window.location.href = window.location.origin; }}>
                    { t('configurator_3d_return_back_to_homepage') }
                  </button>
                </div>
              );
            }
          });
        });
    }
    return JfnetServices.getConfigs(contextDesignHeaderId.toString())
      .then((res) => {
        const stringDecoded = Base64.decode(res.data.data);
        const saveConfigStateData = JSON.parse(stringDecoded) as SaveConfigState;
        if (saveConfigStateData && saveConfigStateData.selectedModel) {
          initCuttingFormCode = saveConfigStateData.selectedModel.templateCode;
        } else {
          initCuttingFormCode = jfnetCuttingFormCode;
        }
        if (res.data.data.length < 25) {
          return JfnetServices.getCuttingFiles(initCuttingFormCode)
            .then((cuttingRes) => {
              if (!cuttingRes.data) {
                setIsLoadingInitial(false);
                setIsLoadingPrices(false);
                setIsLoading(false);
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                        <h5 className="confirm-popup-container__title">
                          { t('configurator_3d_error_something_wrong') }
                        </h5>
                        <button className="button_primary" onClick={onClose}>
                          { t('configurator_3d_ok') }
                        </button>
                      </div>
                    );
                  }
                });
                return Promise.resolve();
              }
              return initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data);
            });
        }
        if (!(saveConfigStateData.hasBackDesign === null ||  saveConfigStateData.hasBackDesign === undefined) && saveConfigStateData.hasBackDesign) {
          handleTriggerDesignOnBack(saveConfigStateData.activateDesignOnBack);
        }
        if (!Object.keys(saveConfigStateData).length) {
          return JfnetServices.getCuttingFiles(initCuttingFormCode)
            .then((cuttingRes) => {
              if (!cuttingRes.data) {
                setIsLoadingInitial(false);
                setIsLoadingPrices(false);
                setIsLoading(false);
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                        <h5 className="confirm-popup-container__title">
                          { t('configurator_3d_error_something_wrong') }
                        </h5>
                        <button className="button_primary" onClick={onClose}>
                          { t('configurator_3d_ok') }
                        </button>
                      </div>
                    );
                  }
                });
                return Promise.resolve();
              }
              initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data);
            });
        }
        if (!contextDesignHeaderId) {
          return JfnetServices.getCuttingFiles(initCuttingFormCode)
            .then((cuttingRes) => {
              if (!cuttingRes.data) {
                setIsLoadingInitial(false);
                setIsLoadingPrices(false);
                setIsLoading(false);
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                        <h5 className="confirm-popup-container__title">
                          { t('configurator_3d_error_something_wrong') }
                        </h5>
                        <button className="button_primary" onClick={onClose}>
                          { t('configurator_3d_ok') }
                        </button>
                      </div>
                    );
                  }
                });
                return;
              }
              return initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data);
            });
        }
        if (!saveConfigStateData.designHeaderId) {
          return JfnetServices.getCuttingFiles(initCuttingFormCode)
            .then((cuttingRes) => {
              if (!cuttingRes.data) {
                setIsLoadingInitial(false);
                setIsLoadingPrices(false);
                setIsLoading(false);
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                        <h5 className="confirm-popup-container__title">
                          { t('configurator_3d_error_something_wrong') }
                        </h5>
                        <button className="button_primary" onClick={onClose}>
                          { t('configurator_3d_ok') }
                        </button>
                      </div>
                    );
                  }
                });
                return Promise.resolve();
              }
              return initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data);
            });
        }
        if (parseInt(contextDesignHeaderId.toString()) !== parseInt(saveConfigStateData.designHeaderId.toString())) {
          return JfnetServices.getCuttingFiles(initCuttingFormCode)
            .then((cuttingRes) => {
              if (!cuttingRes.data) {
                setIsLoadingInitial(false);
                setIsLoadingPrices(false);
                setIsLoading(false);
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                        <h5 className="confirm-popup-container__title">
                          { t('configurator_3d_error_something_wrong') }
                        </h5>
                        <button className="button_primary" onClick={onClose}>
                          { t('configurator_3d_ok') }
                        </button>
                      </div>
                    );
                  }
                });
                return Promise.resolve();
              }
              return initialConfiguratorBlankoData(cuttingRes.data, jakoCuttingResponse.data);
            });
        }
        return JfnetServices.getCuttingFiles(initCuttingFormCode)
          .then((cuttingRes) => {
            if (!cuttingRes.data) {
              setIsLoadingInitial(false);
              setIsLoadingPrices(false);
              setIsLoading(false);
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                      <h5 className="confirm-popup-container__title">
                        { t('configurator_3d_error_something_wrong') }
                      </h5>
                      <button className="button_primary" onClick={onClose}>
                        { t('configurator_3d_ok') }
                      </button>
                    </div>
                  );
                }
              });
              return Promise.resolve();
            }
            return initialConfiguratorFromSaveData(cuttingRes.data, jakoCuttingResponse.data, saveConfigStateData);
          });
      });
  }

  function onCanvasCreated(state: RootState) {
    state.gl.localClippingEnabled = true;
    currentCanvasGLRef.current = state.gl;
  }

  function onCameraUpdate(e: any) {
    if (isSavingScreenshot) {
      return;
    }
    if (cameraControlsRef.current) {
      if (distanceCount.current > 4) {
        startDistance.current = e.target.distance;
        distanceCount.current = 0;
      } else {
        distanceCount.current += 1;
      }
      handleOnZoom(e.target.distance);
    }
  }

  function fetchCuttingFormData() {
    const correlationId = contextCorrelationId  || '111';
    const company = contextCompany || 'JAKO AG';
    const shopCode = contextShopCode || 'B2C';
    const languageCode = selectedLang.current.name || contextLanguageCode;
    return JakoServices.getCuttings({
      correlationId,
      company,
      shopCode,
      languageCode,
    });
  }

  function onSelectCutMaterial(material: CutMaterial) {
    if (jakoCurrentCuttingFormCode.includes('BE') && material === CutMaterial.Int) {
      setJakoCurrentCuttingFormCode(jakoCurrentCuttingFormCode.replace('BE', 'INT'));
    } else {
      setJakoCurrentCuttingFormCode(jakoCurrentCuttingFormCode.replace('INT', 'BE'));
    }
  }

  function onSelectCut(cuttingFormCode: string, material: CutMaterial) {
    const removeMaterialCuttingFormCode = cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '');
    if (removeMaterialCuttingFormCode === jfnetCuttingFormCode) {
      return;
    }
    changeCuttingForm(cuttingFormCode, removeMaterialCuttingFormCode, material);
  }

  function changeCuttingForm(foundedCuttingFormCode: string, jfnetFoundedCuttingFormCode: string, material: CutMaterial) {
    setIsLoadingInitial(true);
    setIsLoadingPrices(true);
    JfnetServices.getPrintcutCoords(jfnetFoundedCuttingFormCode)
      .then((res) => {
        if (res.status === 200) {
          setPrintcutCoords(res.data);
        }
      });
    JfnetServices.getCuttingFiles(jfnetFoundedCuttingFormCode)
      .then((res) => {
        if (!res.data) {
          setIsLoadingInitial(false);
          setIsLoadingPrices(false);
          setIsLoading(false);
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                  <h5 className="confirm-popup-container__title">
                    { t('configurator_3d_error_something_wrong') }
                  </h5>
                  <button className="button_primary" onClick={onClose}>
                    { t('configurator_3d_ok') }
                  </button>
                </div>
              );
            }
          });
          return;
        }

        const foundedCuttingFormData = cuttingFormResponseDataRef.current.find((item) => item.model === jfnetFoundedCuttingFormCode);

        function setCuttingFormData(code: string) {
          if (code.includes('BE') && material === CutMaterial.Int) {
            setJakoCurrentCuttingFormCode(code.replace('BE', 'INT'));
          } else if (code.includes('INT') && material === CutMaterial.Be) {
            setJakoCurrentCuttingFormCode(code.replace('INT', 'BE'));
          } else {
            setJakoCurrentCuttingFormCode(code);
          }
          // If it has the selected sub sport type from the previous initial itemNo
          if (foundedCuttingFormData && subsportType) {
            const foundedCuttingFormLinkItemOfSportart = cuttingFormLinksResponseRef.current.find((item) => item.code === '3D_SPORTART' && item.value === subsportType);
            currentCuttingFormItemNoRef.current = foundedCuttingFormLinkItemOfSportart ? foundedCuttingFormLinkItemOfSportart.item_no : foundedCuttingFormData.itemNo;
          } else {
            currentCuttingFormItemNoRef.current = contextItemno;
          }
        }

        setCuttingFormOptions(foundedCuttingFormCode);

        if (!isCustomerDesign.current) {
          const blankoDesign = res.data.designs.find((item) => item.name === 'Blanko');
          if (blankoDesign) {
            setSelectedDesign(blankoDesign);
          } else {
            setSelectedDesign(res.data.designs[0]);
          }
          resetAllData();
          // Handle Selected Material
          if (foundedCuttingFormData) {
            setCuttingFormData(foundedCuttingFormData.code);
          } else {
            setCuttingFormData(foundedCuttingFormCode);
          }
          setCuttingFileModelData(res.data);
          return;
        }

        const previousSelectedDesign = res.data.designs.find((item) => item.name === selectedDesign?.name);
        if (previousSelectedDesign) {
          setSelectedDesign(previousSelectedDesign);
        } else {
          setSelectedDesign(res.data.designs[0]);
        }
        setCuttingFileModelData(res.data);

        // [Prices only available in user URL]
        // JakoServices.getPrices({
        //   correlationId: contextCorrelationId || '1',
        //   itemNo: currentCuttingFormItemNoRef.current,
        //   cuttingFormCode: foundedCuttingFormCode,
        //   currencyCode: currencycode || 'DE',
        // }).then((res) => {
        //   if (res.status === 200) {
        //     if (
        //       res.data
        //       && res.data.prices
        //       && Array.isArray(res.data.prices)
        //       && res.data.prices.length > 0
        //     ) {
        //       setJakoPrices(res.data);
        //     }
        //   }
        // }).finally(() => {
        //   setIsLoadingPrices(false);
        // });
      });
  }

  function resetAllData() {
    setIsAddingState(null);
    setIsCopyingTextState(null);
    setIsCopyingLogoState(null);
    setAddText('');
    setAddingTextCurved(0);
    setAddedFabricTextObjects({});
    addedFabricTextObjectsRef.current = {};
    setSelectedAddedTextKey(null);
    setSelectedAddedLogoKey(null);
    setAddLogo(null);
    setAddedFabricLogoObjects({});
    addedFabricLogoObjectsRef.current = {};
    setSelectedJerseySectionPattern({});
    setPatternJerseySection('');
    addSectionPartRef.current = JerseySection.Front;
    addSectionPositionRef.current = null;

    // Reset Cam to Front
    moveCameraToFrontSide(true);
  }

  const zoomOut = debounce(function() {
    if (!cameraControlsRef.current) {
      return;
    }
    endDistance.current = 0;
    cameraControlsRef.current.setTarget(0, 0, 0, true);
    cameraControlsRef.current.dolly(-10, true);
  }, 100);

  const handleOnZoom = function(distance: any) {
    if (distance > startDistance.current) {
      endDistance.current = startDistance.current;
    }
    if (endDistance.current !== 0 && (distance > endDistance.current && distance - endDistance.current > 0.5)) {
      zoomOut();
    }
  };

  function onFocusCamera(vec: Vector3) {
    cameraControlsRef.current?.setPosition(vec.x, vec.y, vec.z, true);
  }

  function onSelectObjectItemFromFabric(name: string) {
    if (!name) {
      onSelectLogoFromFabric(null);
      onSelectTextFromFabric(null);
      return;
    }
    const getTabName = name.split('_')[0];
    const getFunctionTab = parseInt(getTabName.split('tab')[1]) as FunctionTab;
    if (getFunctionTab === FunctionTab.Logo) {
      onSelectLogoFromFabric(name);
    } else {
      onSelectTextFromFabric(name, getFunctionTab);
    }
    setTimeout(() => {
      setSelectTab(getFunctionTab);
    }, 100);
  }

  function onDeleteFromFabric(name: string) {
    const getTabName = name.split('_')[0];
    const getFunctionTab = parseInt(getTabName.split('tab')[1]) as FunctionTab;
    if (getFunctionTab === FunctionTab.Logo) {
      onLogoTabBack();
      setTimeout(() => {
        const updatedAddedFabricLogoObjects = {...addedFabricLogoObjectsRef.current};
        delete updatedAddedFabricLogoObjects[name];
        setAddedFabricLogoObjects(updatedAddedFabricLogoObjects);
        addedFabricLogoObjectsRef.current = updatedAddedFabricLogoObjects;
        setSelectedAddedLogoKey(null);
      }, 100);
      return;
    }

    onTextTabBack();
    setTimeout(() => {
      setSelectedAddedTextKey(null);
      const updatedAddedFabricTextObjects = {...addedFabricTextObjectsRef.current};
      delete updatedAddedFabricTextObjects[name];
      setAddedFabricTextObjects(updatedAddedFabricTextObjects);
      addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
    }, 100);
  }

  // function toBase64(file: File | Blob): Promise<string | ArrayBuffer | null> {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = error => reject(error);
  //   });
  // }

  function onControlAreaClick(controlArea: ControlArea) {
    if (controlArea === ControlArea.ZoomIn) {
      cameraControlsRef.current?.dolly(  1, true );
    }
    if (controlArea === ControlArea.ZoomOut) {
      cameraControlsRef.current?.dolly(  -1, true );
    }
    if (controlArea === ControlArea.RotateLeft) {
      cameraControlsRef.current?.rotate(  45 * MathUtils.DEG2RAD, 0, true );
    }
    if (controlArea === ControlArea.RotateRight) {
      cameraControlsRef.current?.rotate(  -45 * MathUtils.DEG2RAD, 0, true );
    }
  }

  // ---- Left side UI handler ----------
  function onSelectAddNamePosition(part: JerseySection, position: NameInitialAddPosition) {
    addSectionPartRef.current = part;
    addSectionPositionRef.current = position;
    // back
    if (position === NameInitialAddPosition.BackSuperBottomFull || position === NameInitialAddPosition.BackTopFull) {
      moveCameraToBackSide();
    }
    // front
    else if (position === NameInitialAddPosition.FrontMiddleFull || position === NameInitialAddPosition.FrontTopCenter || position === NameInitialAddPosition.FrontTopLeft) {
      moveCameraToFrontSide();
    }
    // right
    else if (position === NameInitialAddPosition.RightArm) {
      moveCameraToRightSide();
    }
    // left
    else if (position === NameInitialAddPosition.LeftArm) {
      moveCameraToLeftSide();
    }

    else if (position === NameInitialAddPosition.ShortBottomLeft || position === NameInitialAddPosition.ShortUpLeft) {
      moveCameraToLeftSideShort();
    }

    else if (position === NameInitialAddPosition.ShortBottomRight || position === NameInitialAddPosition.ShortUpRight) {
      moveCameraToRightSideShort();
    }
  }

  function onSelectAddLogoPosition(part: JerseySection, position: LogoAddPosition, onlyCamera?: boolean) {
    if (!onlyCamera) {
      addSectionPartRef.current = part;
      addSectionPositionRef.current = position;
    }
    // back
    if (position === LogoAddPosition.BackBottomFull
      || position === LogoAddPosition.BackTopFull
      || position === LogoAddPosition.BackMiddleFull
      || position === LogoAddPosition.Neck
    ) {
      moveCameraToBackSide();
    }
    // front
    else if (position === LogoAddPosition.FrontMiddleFull
      || position === LogoAddPosition.FrontTopCenter
      || position === LogoAddPosition.FrontTopLeft
      || position === LogoAddPosition.FrontUnderLogoFull
      || position === LogoAddPosition.FrontBottomFull) {
      moveCameraToFrontSide();
    }
    // right
    else if (position === LogoAddPosition.RightArm) {
      moveCameraToRightSide();
    }
    // left
    else if (position === LogoAddPosition.LeftArm) {
      moveCameraToLeftSide();
    }

    else if (position === LogoAddPosition.ShortBottomLeft || position === LogoAddPosition.ShortUpLeft) {
      moveCameraToLeftSideShort();
    }

    else if (position === LogoAddPosition.ShortBottomRight || position === LogoAddPosition.ShortUpRight) {
      moveCameraToRightSideShort();
    }
  }

  function onSelectAddNumberPosition(part: JerseySection, position: NumberAddPosition,  onlyCamera?: boolean) {
    if (!onlyCamera) {
      addSectionPartRef.current = part;
      addSectionPositionRef.current = position;
    }
    // back
    if (position === NumberAddPosition.BackMiddleFull) {
      moveCameraToBackSide();
    }
    // front
    else if (position === NumberAddPosition.FrontTopCenter || position === NumberAddPosition.FrontTopLeft) {
      moveCameraToFrontSide();
    }
    // right
    else if (position === NumberAddPosition.RightArm) {
      moveCameraToRightSide();
    }
    // left
    else if (position === NumberAddPosition.LeftArm) {
      moveCameraToLeftSide();
    }

    else if (position === NumberAddPosition.ShortBottomLeft || position === NumberAddPosition.ShortUpLeft) {
      moveCameraToLeftSideShort();
    }

    else if (position === NumberAddPosition.ShortBottomRight || position === NumberAddPosition.ShortUpRight) {
      moveCameraToRightSideShort();
    }
  }

  function onSelectAddTeamnamePosition(part: JerseySection, position: TeamnameAddPosition, onlyCamera?: boolean) {
    if (!onlyCamera) {
      addSectionPartRef.current = part;
      addSectionPositionRef.current = position;
    }

    // back
    if (position === TeamnameAddPosition.BackBottomFull || position === TeamnameAddPosition.BackTopFull) {
      moveCameraToBackSide();
    }

    else if (position === TeamnameAddPosition.ShortBottomLeft || position === TeamnameAddPosition.ShortUpLeft) {
      moveCameraToLeftSideShort();
    }

    else if (position === TeamnameAddPosition.ShortBottomRight || position === TeamnameAddPosition.ShortUpRight) {
      moveCameraToRightSideShort();
    }
  }

  function onSelectAddTextPosition(part: JerseySection, position: TextAddPosition, onlyCamera?: boolean) {
    if (!onlyCamera) {
      addSectionPartRef.current = part;
      addSectionPositionRef.current = position;
    }
    // back
    if (position === TextAddPosition.BackBottomFull
      || position === TextAddPosition.BackTopFull
      || position === TextAddPosition.Neck
    ) {
      moveCameraToBackSide();
    }
    // front
    else if (position === TextAddPosition.FrontBottomFull || position === TextAddPosition.FrontUnderLogoFull) {
      moveCameraToFrontSide();
    }
    // right
    else if (position === TextAddPosition.RightArm) {
      moveCameraToRightSide();
    }
    // left
    else if (position === TextAddPosition.LeftArm) {
      moveCameraToLeftSide();
    }

    else if (position === TextAddPosition.ShortBottomLeft || position === TextAddPosition.ShortUpLeft) {
      moveCameraToLeftSideShort();
    }

    else if (position === TextAddPosition.ShortBottomRight || position === TextAddPosition.ShortUpRight) {
      moveCameraToRightSideShort();
    }
  }

  function onTextTabBack() {
    setIsAddingState(null);
    setIsCopyingTextState(null);
    setIsCopyingLogoState(null);
    onSelectText(null);
  }

  function onLogoTabBack() {
    setIsAddingState(null);
    onSelectLogo(null);
  }

  function onSelectJakoLogoPosition(position: JakoLogoPosition) {
    jakoLogoPositionRef.current = position;
    setJakoLogoPosition(position);
  }

  function onAddLogoInput(files: {
    fileName: string,
    src: string,
    isUnprocessable: boolean,
    reference: string
  }) {
    setIsAddingState(FunctionTab.Logo);
    setAddLogo(files);
  }

  function onSubmitAddTextInputFromTab(functionTab: FunctionTab) {
    if (!showAddTextInputRef.current) {
      return;
    }
    const textValue = showAddTextInputRef.current.value;
    setAddText(textValue);
    setIsAddingState(functionTab);
  }

  function onSubmitEditTextInput(name: string, e: any, functionTab: FunctionTab, isNotEditReplaceAll?: boolean) {
    e.preventDefault();
    if (!showEditTextInputRef.current) {
      return;
    }
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    setIsAddingState(FunctionTab.ResetStart);
    const textValue = showEditTextInputRef.current.value;
    JfnetServices.fontToSvg({
      text: textValue,
      font: addedFabricTextObjectsRef.current[name].fontFamily || selectedFontFamily
    }).then((res) => {
      setTimeout(() => {
        configuratorRef.current?.editTextHandler({
          textName: name,
          text: textValue,
          svgData: res.data
        }).then((activeEditTextData) => {
          if (isNotEditReplaceAll) {
            configuratorRef.current?.updateTextsNewTextValueHandler(name, [name], textValue)
              .then((res) => {
                if (!res.length) {
                  return;
                }
                const updatedAddedFabricTextObjects: AddedTextItems = {
                  ...addedFabricTextObjectsRef.current,
                  [name]: {
                    ...addedFabricTextObjectsRef.current[name],
                    text: textValue,
                    reference: undefined,
                    scaledHeight: activeEditTextData.scaledHeight,
                    scaledWidth: activeEditTextData.scaledWidth,
                    svgString: activeEditTextData.svgString,
                  }
                };
                setAddedFabricTextObjects(updatedAddedFabricTextObjects);
                addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
              });
            return;
          }

          const addedItemsOfSelectedTab = Object.keys(addedFabricTextObjectsRef.current).slice()
            .filter((itemKey) => addedFabricTextObjectsRef.current[itemKey].functionTab === functionTab);
          let updatedAddedFabricTextObjects: AddedTextItems = addedFabricTextObjectsRef.current;

          configuratorRef.current?.updateTextsNewTextValueHandler(name, addedItemsOfSelectedTab, textValue)
            .then((res) => {
              // Then it means that we have only single added text of selected tab.
              if (!res.length) {
                updatedAddedFabricTextObjects = {
                  ...updatedAddedFabricTextObjects,
                  [name]: {
                    ...updatedAddedFabricTextObjects[name],
                    text: textValue,
                    reference: undefined,
                    scaledHeight: activeEditTextData.scaledHeight,
                    scaledWidth: activeEditTextData.scaledWidth,
                    svgString: activeEditTextData.svgString,
                  }
                };
                setAddedFabricTextObjects(updatedAddedFabricTextObjects);
                addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
                return;
              }
              res.forEach((resItem) => {
                updatedAddedFabricTextObjects = {
                  ...updatedAddedFabricTextObjects,
                  [resItem.itemName]: {
                    ...updatedAddedFabricTextObjects[resItem.itemName],
                    text: textValue,
                    reference: undefined,
                    scaledHeight: resItem.scaledHeight,
                    scaledWidth: resItem.scaledWidth,
                    svgString: resItem.svgString,
                  }
                };
              });
              updatedAddedFabricTextObjects = {
                ...updatedAddedFabricTextObjects,
                [name]: {
                  ...updatedAddedFabricTextObjects[name],
                  text: textValue,
                  reference: undefined,
                  scaledHeight: activeEditTextData.scaledHeight,
                  scaledWidth: activeEditTextData.scaledWidth,
                  svgString: activeEditTextData.svgString,
                }
              };
              setAddedFabricTextObjects(updatedAddedFabricTextObjects);
              addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
            });
        });
      }, 100);
    });
  }

  function onAddTextPositionStart({ adding, object, textSize }: AddTextInterface) {
    const textEditorObj = object as any;
    if (textEditorObj.name) {
      let getFieldCode = '3D_TEXT';
      if (adding === FunctionTab.Number) {
        getFieldCode = '3D_NUMMER';
      } else if (adding === FunctionTab.Teamname) {
        getFieldCode = '3D_VEREINSNAME';
      } else if (adding === FunctionTab.Initial) {
        getFieldCode = '3D_INITIALEN';
      } else if (adding === FunctionTab.Name) {
        getFieldCode = '3D_NAME';
      }

      if (!configuratorRef.current) {
        return;
      }

      const designSvgPatternObjectsBounding = configuratorRef.current.designSvgPatternObjectsBounding.current;

      const onlyMainAreaKeys = Object.keys(designSvgPatternObjectsBounding);
      const activeObjTop = textEditorObj.aCoords ? textEditorObj.aCoords.tl.y : 0;
      const activeObjWidth = textEditorObj.aCoords ? textEditorObj.aCoords.tr.x - textEditorObj.aCoords.tl.x : 0;
      const activeObjLeft = textEditorObj.aCoords ? textEditorObj.aCoords.tl.x : 0;
      const activeObjHeight = textEditorObj.aCoords ? textEditorObj.aCoords.bl.y - textEditorObj.aCoords.tl.y : 0;
      const foundedIntersect = onlyMainAreaKeys.find((key) => {
        const sectionObject = designSvgPatternObjectsBounding[key];
        const isOverflowX = ((activeObjWidth + activeObjLeft) > (sectionObject.pathOffset.x + (isMobile ? sectionObject.width / 4 : sectionObject.width) / 2)) || ((activeObjWidth + activeObjLeft) < (sectionObject.pathOffset.x - (isMobile ? sectionObject.width / 4 : sectionObject.width) / 2));
        const isOverflowY = ((activeObjHeight + activeObjTop) > (sectionObject.pathOffset.y + (isMobile ? sectionObject.height / 4 : sectionObject.height) / 2)) || ((activeObjHeight + activeObjTop) < (sectionObject.pathOffset.y - (isMobile ? sectionObject.height / 4 : sectionObject.height) / 2));
        return !isOverflowX && !isOverflowY;
      });
      let foundedPositionPart = 'front_main';
      if (foundedIntersect) {
        foundedPositionPart = foundedIntersect;
      }

      addedFabricTextObjectsRef.current = {
        ...addedFabricTextObjectsRef.current,
        [textEditorObj.name]: {
          name: textEditorObj.name,
          text: textEditorObj.text,
          width: textEditorObj.width,
          height: textEditorObj.height,
          x: textEditorObj.left,
          y: textEditorObj.top,
          fontFamily: textEditorObj.fontFamily,
          fill: textEditorObj.fill,
          fontSize: textEditorObj.fontSize,
          rotation: textEditorObj.angle,
          fieldCode: getFieldCode,
          functionTab: adding,
          foundedPositionPart: foundedPositionPart || 'front_main',
          initialX: textEditorObj.left,
          initialY: textEditorObj.top,
          maxHeight: textSize.maxHeight,
          curved: textEditorObj.curved,
          svgString: textEditorObj.svgString,
        }
      };
      setAddedFabricTextObjects((prevState) => ({
        ...prevState,
        [textEditorObj.name]: {
          name: textEditorObj.name,
          text: textEditorObj.text,
          width: textEditorObj.width,
          height: textEditorObj.height,
          x: textEditorObj.left,
          y: textEditorObj.top,
          fontFamily: textEditorObj.fontFamily,
          fill: textEditorObj.fill,
          fontSize: textEditorObj.fontSize,
          rotation: textEditorObj.angle,
          fieldCode: getFieldCode,
          functionTab: adding,
          foundedPositionPart: foundedPositionPart || 'front_main',
          initialX: textEditorObj.left,
          initialY: textEditorObj.top,
          textSize: textSize,
          maxHeight: textSize.maxHeight,
          curved: textEditorObj.curved,
          svgString: textEditorObj.svgString,
          // scaledHeight: textSize.scaledHeight,
          // scaledWidth: textSize.scaledWidth
        }
      }));
    }
    setIsAddingState(null);
    setIsCopyingTextState(null);
    setIsCopyingLogoState(null);
    onSelectText(textEditorObj.name, true);
  }

  function onUpdateTextFontSize(name: string, data: {
    width: number,
    height: number,
  }) {
    addedFabricTextObjectsRef.current = {
      ...addedFabricTextObjectsRef.current,
      [name]: {
        ...addedFabricTextObjectsRef.current[name],
        ...data,
        scaledWidth: data.width,
        scaledHeight: data.height,
      }
    };
    setAddedFabricTextObjects({
      ...addedFabricTextObjectsRef.current,
      [name]: {
        ...addedFabricTextObjectsRef.current[name],
        ...data,
        scaledWidth: data.width,
        scaledHeight: data.height,
      }
    });
  }

  function onUpdateTextCurved(name: string, curved: number) {
    const updatedAddedFabricTextObjects: AddedTextItems = {
      ...addedFabricTextObjectsRef.current,
      [name]: {
        ...addedFabricTextObjectsRef.current[name],
        curved,
      }
    };
    setAddedFabricTextObjects(updatedAddedFabricTextObjects);
    addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
  }

  function onSetNewTextCurved(name: string, curved: number) {
    const textValue = addedFabricTextObjectsRef.current[name].text;
    function handleEditCurveText(svgData: any) {
      configuratorRef.current?.editTextHandler({
        textName: name,
        text: textValue,
        svgData,
        curved
      }).then((activeEditTextData) => {
        configuratorRef.current?.updateTextsNewTextValueHandler(name, [name], textValue)
          .then((res) => {
            if (!res.length) {
              return;
            }
            const updatedAddedFabricTextObjects: AddedTextItems = {
              ...addedFabricTextObjectsRef.current,
              [name]: {
                ...addedFabricTextObjectsRef.current[name],
                text: textValue,
                reference: undefined,
                scaledHeight: activeEditTextData.scaledHeight,
                scaledWidth: activeEditTextData.scaledWidth,
                svgString: activeEditTextData.svgString,
              }
            };
            setAddedFabricTextObjects(updatedAddedFabricTextObjects);
            addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
          });
        });
    }
    const loadedSvgData = memoizedCurveTexts.current[`${textValue}_${curved}`];
    if (loadedSvgData) {
      handleEditCurveText(loadedSvgData);
      return;
    }

    JfnetServices.fontToSvg({
      text: textValue,
      font: addedFabricTextObjectsRef.current[name].fontFamily || selectedFontFamily,
      curved
    }).then((res) => {
      if (!res.data) {
        return;
      }

      const svgData = res.data;
      memoizedCurveTexts.current[`${textValue}_${curved}`] = svgData;
      handleEditCurveText(svgData);
    });
  }

  function onChangeTextCurved(name: string, curved: number) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }

    onUpdateTextCurved(name, curved);
  }

  function copyText(tab: FunctionTab, textKey: string) {
    if (!addedFabricTextObjectsRef.current[textKey]) {
      return;
    }

    const textData = addedFabricTextObjectsRef.current[textKey];
    if (textData.functionTab !== tab) {
      return;
    }

    setAddText(textData.text);
    setAddingTextCurved(textData.curved);
    setIsCopyingTextState(tab);
  }

  function copyLogo(tab: FunctionTab, logoKey: string) {
    if (!addedFabricLogoObjectsRef.current[logoKey]) {
      return;
    }

    const logoData = addedFabricLogoObjectsRef.current[logoKey];
    setAddLogo({
      fileName: logoData.fileName,
      src: logoData.src,
      isUnprocessable: logoData.isUnprocessable,
      reference: logoData.reference || '',
    });
    setIsCopyingLogoState(tab);
  }

  function onUpdateLogoSize(name: string, data: {
    width: number,
    height: number
  }) {
    addedFabricLogoObjectsRef.current = {
      ...addedFabricLogoObjectsRef.current,
      [name]: {
        ...addedFabricLogoObjectsRef.current[name],
        ...data,
        scaledWidth: data.width,
        scaledHeight: data.height,
      }
    };
    setAddedFabricLogoObjects({
      ...addedFabricLogoObjectsRef.current,
      [name]: {
        ...addedFabricLogoObjectsRef.current[name],
        ...data,
        scaledWidth: data.width,
        scaledHeight: data.height,
      }
    });
  }

  function rgbToHex(r: number, g: number, b: number) {
    return "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
  }

  function onAddImagePositionStart({ object, imageObj, logoSize }: AddImageInterface) {
    const logoEditorObj = object as any;
    if (logoEditorObj.name && configuratorRef.current) {
      const designSvgPatternObjectsBounding = configuratorRef.current.designSvgPatternObjectsBounding.current;
      const foundedPositionPart = Object.keys(designSvgPatternObjectsBounding).find((itemKey) => {
        const partWidth = designSvgPatternObjectsBounding[itemKey].width;
        const partHeight = designSvgPatternObjectsBounding[itemKey].height;
        const centerX = designSvgPatternObjectsBounding[itemKey].pathOffset.x;
        const centerY = designSvgPatternObjectsBounding[itemKey].pathOffset.y;

        const leftEdgeX = Math.abs(centerX - (partWidth / 2));
        const rightEdgeX = (centerX + (partWidth / 2));
        const topEdgeY = Math.abs(centerY - (partHeight / 2));
        const bottomEdgeY = (centerY + (partHeight / 2));
        return logoEditorObj.left >= leftEdgeX
          && logoEditorObj.left <= rightEdgeX
          && logoEditorObj.top >= topEdgeY
          && logoEditorObj.top <= bottomEdgeY;
      });

      let rgbAndHexList: {
        colorHexCode: string,
        colorInfoText: string
      }[] = [];
      if (logoEditorObj && (logoEditorObj.fill || logoEditorObj.stroke)) {
        const item = logoEditorObj;
        if (item.fill) {
          if (item.fill.colorStops && item.fill.colorStops.length) {
            const itemFillSpecial: {
              colorStops: {
                offset: number,
                color: string,
                opacity: number
              }[],
              id: string,
            } = item.fill;
            itemFillSpecial.colorStops.forEach((insideFillColor) => {
              const colorInfoText = itemFillSpecial.id && insideFillColor.offset
                ? `${itemFillSpecial.id}_offset[${insideFillColor.offset}]`
                : imageObj.fileName;
              if (insideFillColor.color.toString().charAt(0) === '#') {
                rgbAndHexList.push({
                  colorHexCode: insideFillColor.color.replace('#', ''),
                  colorInfoText,
                });
              } else if (insideFillColor.color.toString().includes('rgb')) {
                const rgbArray = insideFillColor.color.replace(/\s/g, '').split('(')[1].split(')')[0].split(',');
                rgbAndHexList.push({
                  colorHexCode: rgbToHex(parseInt(rgbArray[0]), parseInt(rgbArray[1]), parseInt(rgbArray[2])).replace('#', ''),
                  colorInfoText,
                });
              } else {
                rgbAndHexList.push({
                  colorHexCode: insideFillColor.color.replace('#', ''),
                  colorInfoText,
                });
              }
            });
          } else if (item.fill.toString().charAt(0) === '#') {
            rgbAndHexList.push({
              colorHexCode: item.fill.replace('#', ''),
              colorInfoText: imageObj.fileName,
            });
          } else if (item.fill && item.fill.toString().includes('rgb')) {
            const rgbArray = item.fill.replace(/\s/g, '').split('(')[1].split(')')[0].split(',');
            rgbAndHexList.push({
              colorHexCode: rgbToHex(parseInt(rgbArray[0]), parseInt(rgbArray[1]), parseInt(rgbArray[2])).replace('#', ''),
              colorInfoText: imageObj.fileName,
            });
          } else {
            rgbAndHexList.push({
              colorHexCode: item.fill.replace('#', ''),
              colorInfoText: imageObj.fileName,
            });
          }
        } else if (item.stroke) {
          if (item.stroke.colorStops && item.stroke.colorStops.length) {
            const itemStrokeSpecial: {
              colorStops: {
                offset: number,
                color: string,
                opacity: number
              }[],
              id: string,
            } = item.stroke;
            itemStrokeSpecial.colorStops.forEach((insideStrokeColor) => {
              const colorInfoText = itemStrokeSpecial.id && insideStrokeColor.offset
                ? `${itemStrokeSpecial.id}_offset[${insideStrokeColor.offset}]`
                : imageObj.fileName;
              if (insideStrokeColor.color.toString().charAt(0) === '#') {
                rgbAndHexList.push({
                  colorHexCode: insideStrokeColor.color.replace('#', ''),
                  colorInfoText,
                });
              } else if (insideStrokeColor.color.toString().includes('rgb')) {
                const rgbArray = insideStrokeColor.color.replace(/\s/g, '').split('(')[1].split(')')[0].split(',');
                rgbAndHexList.push({
                  colorHexCode: rgbToHex(parseInt(rgbArray[0]), parseInt(rgbArray[1]), parseInt(rgbArray[2])).replace('#', ''),
                  colorInfoText,
                });
              } else {
                rgbAndHexList.push({
                  colorHexCode: insideStrokeColor.color.replace('#', ''),
                  colorInfoText,
                });
              }
            });
          } else if (item.fill.toString().charAt(0) === '#') {
            rgbAndHexList.push({
              colorHexCode: item.fill.replace('#', ''),
              colorInfoText: imageObj.fileName,
            });
          } else if (item.fill && item.fill.toString().includes('rgb')) {
            const rgbArray = item.fill.replace(/\s/g, '').split('(')[1].split(')')[0].split(',');
            rgbAndHexList.push({
              colorHexCode: rgbToHex(parseInt(rgbArray[0]), parseInt(rgbArray[1]), parseInt(rgbArray[2])).replace('#', ''),
              colorInfoText: imageObj.fileName,
            });
          } else {
            rgbAndHexList.push({
              colorHexCode: item.fill.replace('#', ''),
              colorInfoText: imageObj.fileName,
            });
          }
        }
      }
      if (logoEditorObj._objects) {
        logoEditorObj._objects.forEach((item: any) => {
          if (item.fill) {
            if (item.fill.colorStops && item.fill.colorStops.length) {
              const itemFillSpecial: {
                colorStops: {
                  offset: number,
                  color: string,
                  opacity: number
                }[],
                id: string,
              } = item.fill;
              itemFillSpecial.colorStops.forEach((insideFillColor) => {
                const colorInfoText = itemFillSpecial.id && insideFillColor.offset
                  ? `${itemFillSpecial.id}_offset[${insideFillColor.offset}]`
                  : imageObj.fileName;
                if (insideFillColor.color.toString().charAt(0) === '#') {
                  rgbAndHexList.push({
                    colorHexCode: insideFillColor.color.replace('#', ''),
                    colorInfoText,
                  });
                } else if (insideFillColor.color.toString().includes('rgb')) {
                  const rgbArray = insideFillColor.color.replace(/\s/g, '').split('(')[1].split(')')[0].split(',');
                  rgbAndHexList.push({
                    colorHexCode: rgbToHex(parseInt(rgbArray[0]), parseInt(rgbArray[1]), parseInt(rgbArray[2])).replace('#', ''),
                    colorInfoText,
                  });
                } else {
                  rgbAndHexList.push({
                    colorHexCode: insideFillColor.color.replace('#', ''),
                    colorInfoText,
                  });
                }
              });
            } else if (item.fill.toString().charAt(0) === '#') {
              rgbAndHexList.push({
                colorHexCode: item.fill.replace('#', ''),
                colorInfoText: item.id || imageObj.fileName,
              });
            } else if (item.fill && item.fill.toString().includes('rgb')) {
              const rgbArray = item.fill.replace(/\s/g, '').split('(')[1].split(')')[0].split(',');
              rgbAndHexList.push({
                colorHexCode: rgbToHex(parseInt(rgbArray[0]), parseInt(rgbArray[1]), parseInt(rgbArray[2])).replace('#', ''),
                colorInfoText: item.id || imageObj.fileName,
              });
            } else {
              rgbAndHexList.push({
                colorHexCode: item.fill.replace('#', ''),
                colorInfoText: item.id || imageObj.fileName,
              });
            }
          } else if (item.stroke) {
            if (item.stroke.colorStops && item.stroke.colorStops.length) {
              const itemStrokeSpecial: {
                colorStops: {
                  offset: number,
                  color: string,
                  opacity: number
                }[],
                id: string,
              } = item.stroke;
              itemStrokeSpecial.colorStops.forEach((insideStrokeColor) => {
                const colorInfoText = itemStrokeSpecial.id && insideStrokeColor.offset
                  ? `${itemStrokeSpecial.id}_offset[${insideStrokeColor.offset}]`
                  : imageObj.fileName;
                if (insideStrokeColor.color.toString().charAt(0) === '#') {
                  rgbAndHexList.push({
                    colorHexCode: insideStrokeColor.color.replace('#', ''),
                    colorInfoText,
                  });
                } else if (insideStrokeColor.color.toString().includes('rgb')) {
                  const rgbArray = insideStrokeColor.color.replace(/\s/g, '').split('(')[1].split(')')[0].split(',');
                  rgbAndHexList.push({
                    colorHexCode: rgbToHex(parseInt(rgbArray[0]), parseInt(rgbArray[1]), parseInt(rgbArray[2])).replace('#', ''),
                    colorInfoText,
                  });
                } else {
                  rgbAndHexList.push({
                    colorHexCode: insideStrokeColor.color.replace('#', ''),
                    colorInfoText,
                  });
                }
              });
            } else if (item.fill.toString().charAt(0) === '#') {
              rgbAndHexList.push({
                colorHexCode: item.fill.replace('#', ''),
                colorInfoText: item.id || imageObj.fileName,
              });
            } else if (item.fill && item.fill.toString().includes('rgb')) {
              const rgbArray = item.fill.replace(/\s/g, '').split('(')[1].split(')')[0].split(',');
              rgbAndHexList.push({
                colorHexCode: rgbToHex(parseInt(rgbArray[0]), parseInt(rgbArray[1]), parseInt(rgbArray[2])).replace('#', ''),
                colorInfoText: item.id || imageObj.fileName,
              });
            } else {
              rgbAndHexList.push({
                colorHexCode: item.fill.replace('#', ''),
                colorInfoText: item.id || imageObj.fileName,
              });
            }
          }
        });
      }
      const reduceRgbAndHexList =[
        ...rgbAndHexList.slice()
          .reduce((reducer, obj) => {
            const key = obj.colorHexCode;
            const item = {...obj};
            return reducer.set(key, item);
          }, new Map()).values()
      ];
      addedFabricLogoObjectsRef.current = {
        ...addedFabricLogoObjectsRef.current,
        [logoEditorObj.name]: {
          // position,
          name: object.name,
          fileName: imageObj.fileName,
          src: imageObj.src,
          x: object.left,
          y: object.top,
          width: object.getScaledWidth(),
          height: object.getScaledHeight(),
          rotation: object.angle,
          fieldCode: '3D_LOGO',
          foundedPositionPart: foundedPositionPart || 'front_main',
          isUnprocessable: imageObj.isUnprocessable,
          initialX: object.left,
          initialY: object.top,
          fill: object.fill,
          possibleImageColors: reduceRgbAndHexList.length
            ? reduceRgbAndHexList
            : [{
              colorHexCode: '',
              colorInfoText: ''
            }],
          reference: imageObj.reference,
          maxHeight: logoSize.maxHeight,
          scaledHeight: logoSize.scaledHeight,
          scaledWidth: logoSize.scaledWidth
        }
      };
      setAddedFabricLogoObjects((prevState) => ({
        ...prevState,
        [logoEditorObj.name]: {
          // position,
          name: object.name,
          fileName: imageObj.fileName,
          src: imageObj.src,
          x: object.left,
          y: object.top,
          width: object.getScaledWidth(),
          height: object.getScaledHeight(),
          rotation: object.angle,
          fieldCode: '3D_LOGO',
          foundedPositionPart: foundedPositionPart || 'front_main',
          isUnprocessable: imageObj.isUnprocessable,
          initialX: object.left,
          initialY: object.top,
          fill: object.fill,
          possibleImageColors: reduceRgbAndHexList.length
            ? reduceRgbAndHexList
            : [{
              colorHexCode: '',
              colorInfoText: ''
            }],
          reference: imageObj.reference,
          maxHeight: logoSize.maxHeight,
          scaledHeight: logoSize.scaledHeight,
          scaledWidth: logoSize.scaledWidth
        }
      }));
    }
    setIsAddingState(null);
    onSelectLogo(logoEditorObj.name);
    setTimeout(() => {
      onSetIsLoadingOverlayUI(false);
    }, 200);
  }

  function onAddImageLogoInitSrc(objects: any[]) {
    let updatedAddedFabricLogoObjects: AddedLogoItems = {
      ...addedFabricLogoObjectsRef.current,
    };
    objects.forEach((obj) => {
      updatedAddedFabricLogoObjects = {
        ...updatedAddedFabricLogoObjects,
        [obj.name]: {
          ...updatedAddedFabricLogoObjects[obj.name],
          src: obj.src
        }
      };
    });
    setAddedFabricLogoObjects(updatedAddedFabricLogoObjects);
    addedFabricLogoObjectsRef.current = updatedAddedFabricLogoObjects;
  }

  function onClickTextLock(eKey: string) {
    const updatedAddedFabricTextObjects: AddedTextItems = {
      ...addedFabricTextObjects,
      [eKey]: {
        ...addedFabricTextObjects[eKey],
        isLock: !addedFabricTextObjects[eKey].isLock
      }
    };

    setAddedFabricTextObjects(updatedAddedFabricTextObjects);
    addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
    setSelectedAddedTextKey(eKey);

    if (addedFabricTextObjectsRef.current[eKey].isLock) {
      configuratorRef.current?.lockObject(eKey);
    } else {
      configuratorRef.current?.unlockObject(eKey);
    }
  }
  function onClickDeleteText(eKey: string) {
    setSelectedAddedTextKey(null);
    configuratorRef.current?.deleteObject(eKey);
    onTextTabBack();

    setTimeout(() => {
      const updatedAddedFabricTextObjects = {...addedFabricTextObjects};
      delete updatedAddedFabricTextObjects[eKey];
      setAddedFabricTextObjects(updatedAddedFabricTextObjects);
      addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
    }, 100);

  }
  function onSelectText(eKey: string | null, isInit?: boolean) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      configuratorRef.current?.handleUnprocessableIconInControl();
      return;
    }
    if (!eKey) {
      setSelectedAddedTextKey(null);
      configuratorRef.current?.handleUnprocessableIconInControl();
      configuratorRef.current?.fabricCanvas.current?.discardActiveObject();
      configuratorRef.current?.fabricCanvas.current?.requestRenderAll();
      configuratorRef.current?.updateCanvasTextureMap();
      return;
    }

    setTimeout(() => {
      if (!configuratorRef.current) {
        return;
      }
      if (!configuratorRef.current.fabricCanvas.current) {
        return;
      }
      if (!addedFabricTextObjectsRef.current[eKey]) {
        return;
      }

      configuratorRef.current?.handleUnprocessableIconInControl();
      const fabricObjects = configuratorRef.current.fabricCanvas.current._objects;
      const foundedSelectedText = fabricObjects.find((item) => item.name === eKey);
      if (!foundedSelectedText) {
        return;
      }
      if (isInit) {
        setSelectedAddedTextKey(eKey);
        configuratorRef.current?.setActiveObjectHandler(foundedSelectedText);
        return;
      }

      if (ModelStyle.Shirt === selectModelStyle) {
        if (addedFabricTextObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('back')) {
          moveCameraToBackSide();
        }
        // front
        else if (addedFabricTextObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('front')) {
          moveCameraToFrontSide();
        }
        // right
        else if (addedFabricTextObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('right')) {
          moveCameraToRightSide();
        }
        // left
        else if (addedFabricTextObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('left')) {
          moveCameraToLeftSide();
        }
      } else if (ModelStyle.ShirtNoSleeve === selectModelStyle) {
        if (addedFabricTextObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('back')) {
          moveCameraToBackSide();
        }
        // front
        else if (addedFabricTextObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('front')) {
          moveCameraToFrontSide();
        }
      } else {
        if (addedFabricTextObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('right')) {
          moveCameraToRightSideShort();
        }
        else if (addedFabricTextObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('left')) {
          moveCameraToLeftSideShort();
        }
      }
      setSelectedAddedTextKey(eKey);
      configuratorRef.current?.setActiveObjectHandler(foundedSelectedText);
    }, 100);
  }
  function onSelectTextFromFabric(eKey: string | null, functionTab?: FunctionTab) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      configuratorRef.current.handleUnprocessableIconInControl();
      return;
    }
    if (!eKey) {
      setSelectedAddedTextKey(null);
      configuratorRef.current?.handleUnprocessableIconInControl();
      configuratorRef.current?.fabricCanvas.current?.discardActiveObject();
      configuratorRef.current?.fabricCanvas.current?.requestRenderAll();
      configuratorRef.current?.updateCanvasTextureMap();
      return;
    }
    setTimeout(() => {
      if (!configuratorRef.current) {
        return;
      }
      if (!configuratorRef.current.fabricCanvas.current) {
        return;
      }
      configuratorRef.current?.handleUnprocessableIconInControl();
      setSelectedAddedTextKey(eKey);
      setTimeout(() => {
        if (functionTab === FunctionTab.Teamname) {
          jakoTeamnameTabRef.current?.openOptionByName(eKey);
        } else if (functionTab === FunctionTab.Number) {
          jakoNumberTabRef.current?.openOptionByName(eKey);
        } else if (functionTab === FunctionTab.Name || functionTab === FunctionTab.Initial) {
          jakoNameTabRef.current?.openOptionByName(eKey);
        } else if (functionTab === FunctionTab.Text) {
          jakoTextTabRef.current?.openOptionByName(eKey);
        }
      }, 100);
    }, 100);
  }
  function onChangeTextColor(color: string) {
    if (!selectedAddedTextKey) {
      return;
    }
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }

    const updatedAddedFabricTextObjects: AddedTextItems = {
      ...addedFabricTextObjects,
      [selectedAddedTextKey]: {
        ...addedFabricTextObjects[selectedAddedTextKey],
        fill: color
      }
    };

    setAddedFabricTextObjects(updatedAddedFabricTextObjects);
    addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
    setSelectedTextFill(color);

    configuratorRef.current?.updateTextColor(selectedAddedTextKey, color);
  }
  function onChangeFontFamily(name: string, font: string) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }

    const textValue = addedFabricTextObjectsRef.current[name].text;
    JfnetServices.fontToSvg({
      text: textValue,
      font: font
    }).then((res) => {
      setTimeout(() => {
        configuratorRef.current?.editTextHandler({
          textName: name,
          text: textValue,
          svgData: res.data,
          fontFamily: font,
        }).then((data) => {
          const updatedAddedFabricTextObjects: AddedTextItems = {
            ...addedFabricTextObjectsRef.current,
            [name]: {
              ...addedFabricTextObjectsRef.current[name],
              fontFamily: font,
              reference: undefined,
              scaledHeight: data.scaledHeight,
              scaledWidth: data.scaledWidth,
              svgString: data.svgString,
            }
          };
          addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
          setAddedFabricTextObjects(updatedAddedFabricTextObjects);
          setSelectedFontFamily(font);
          configuratorRef.current?.updateTextFontFamily(name, font);
        });
      }, 100);
    });
  }

  function onChangeFontSizeWidth(name: string, width: number) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    configuratorRef.current?.updateTextFontSizeWidth(name, width);
  }

  function onChangeFontSizeHeight(name: string, height: number) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }

    configuratorRef.current?.updateTextFontSizeHeight(name, height);
  }

  function onSendToFront(name: string) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    configuratorRef.current?.bringToFront(name);
  }

  function onSendToBack(name: string) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    configuratorRef.current?.sendToBack(name);
  }

  function onChangeLogoPosition(name: string, position: string, isInit?: boolean) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }

    let fabricFrontObjCenterX = 0,
    // fabricFrontObjCenterY = 0,
    fabricBackObjCenterX = 0,
    // fabricBackObjCenterY = 0,
    fabricLeftObjCenterX = 0,
    // fabricLeftObjCenterY = 0,
    fabricRightObjCenterX = 0;
    // fabricRightObjCenterY = 0;

    if (configuratorRef.current.designSvgPatternObjectsBounding.current.front_main) {
      fabricFrontObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.front_main.pathOffset.x);
      // fabricFrontObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.front_main.pathOffset.y);
    }

    if (configuratorRef.current.designSvgPatternObjectsBounding.current.back_main) {
      fabricBackObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.back_main.pathOffset.x);
      // fabricBackObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.back_main.pathOffset.y);
    }

    if (configuratorRef.current.designSvgPatternObjectsBounding.current.left_main) {
      fabricLeftObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.left_main.pathOffset.x);
      // fabricLeftObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.left_main.pathOffset.y);
    }

    if (configuratorRef.current.designSvgPatternObjectsBounding.current.right_main) {
      fabricRightObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.right_main.pathOffset.x);
      // fabricRightObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.right_main.pathOffset.y);
    }

    const logoObj = configuratorRef.current.fabricCanvas.current._objects.find((obj: any) => obj.id === 'logo_front') as any;
    const isLongArmShirt = cuttingOptions.current.length && cuttingOptions.current.find((item) => item.code === '3D_SCHNITTFORM' && item.value === 'LANGARM');

    let axis: {
      left: number,
      top: number
    } = {
      left: 0,
      top: 0
    };

    let maxHeight = MAX_LOGO_SIZE_HEIGHT_CM;
    let startHeight = 4.5;
    let startWidth = 0;
    const adjustedBasedCmFactorForMovePosition = isMobile ? basedCmFactor * 4 : basedCmFactor;

    if (position === LogoAddPosition.Neck) {
      axis = {
        left: fabricBackObjCenterX,
        top: logoObj.pathOffset.y + (LOGO_ADD_POSITION[LogoAddPosition.Neck].y * adjustedBasedCmFactorForMovePosition)
      };
    } else if (position === LogoAddPosition.BackBottomFull) {
      axis = {
        left: fabricBackObjCenterX,
        top: logoObj.pathOffset.y + (LOGO_ADD_POSITION[LogoAddPosition.BackBottomFull].y * adjustedBasedCmFactorForMovePosition)
      };
    } else if (position === LogoAddPosition.BackMiddleFull) {
      axis = {
        left: fabricBackObjCenterX,
        top: logoObj.pathOffset.y + (LOGO_ADD_POSITION[NumberAddPosition.BackMiddleFull].y * adjustedBasedCmFactorForMovePosition),
      };
    } else if (position === LogoAddPosition.BackTopFull) {
      axis = {
        left: fabricBackObjCenterX,
        top: logoObj.pathOffset.y + (LOGO_ADD_POSITION[LogoAddPosition.BackTopFull].y * adjustedBasedCmFactorForMovePosition)
      };
    } else if (position === LogoAddPosition.FrontBottomFull) {
      axis = {
        left: fabricFrontObjCenterX,
        top: logoObj.pathOffset.y + (LOGO_ADD_POSITION[LogoAddPosition.FrontBottomFull].y * adjustedBasedCmFactorForMovePosition)
      };
      startWidth = 30;
    } else if (position === LogoAddPosition.FrontTopCenter) {
      axis = {
        left: fabricFrontObjCenterX,
        top: logoObj.pathOffset.y,
      };
      startHeight = 7;
    } else if (position === LogoAddPosition.FrontTopLeft) {
      axis = {
        left: jakoLogoLeftPos.pathOffset.x,
        top: logoObj.pathOffset.y,
      };
      startHeight = 7;
    } else if (position === LogoAddPosition.FrontUnderLogoFull) {
      axis = {
        left: fabricFrontObjCenterX,
        top: logoObj.pathOffset.y + (LOGO_ADD_POSITION[LogoAddPosition.FrontUnderLogoFull].y * adjustedBasedCmFactorForMovePosition)
      };
      startWidth = 30;
    } else if (position === LogoAddPosition.FrontMiddleFull) {
      axis = {
        left: fabricFrontObjCenterX,
        top: logoObj.pathOffset.y + (LOGO_ADD_POSITION[LogoAddPosition.FrontMiddleFull].y * adjustedBasedCmFactorForMovePosition)
      };
      startWidth = 30;
    } else if (position === LogoAddPosition.RightArm) {
      axis = {
        left: fabricRightObjCenterX,
        top: endEdgeSleevePosition - (10 * adjustedBasedCmFactorForMovePosition) + (TEXT_ADD_POSITION[TextAddPosition.RightArm].y * adjustedBasedCmFactorForMovePosition) - (isLongArmShirt ? 40 * adjustedBasedCmFactorForMovePosition : 0),
      };
    } else if (position === LogoAddPosition.LeftArm) {
      axis = {
        left: fabricLeftObjCenterX,
        top: endEdgeSleevePosition - (10 * adjustedBasedCmFactorForMovePosition) + (TEXT_ADD_POSITION[TextAddPosition.LeftArm].y * adjustedBasedCmFactorForMovePosition) - (isLongArmShirt ? 40 * adjustedBasedCmFactorForMovePosition : 0),
      };
    } else if (position === LogoAddPosition.ShortBottomLeft) {
      axis.left = jakoLogoLeftPos.pathOffset.x;
      axis.top = logoObj.pathOffset.y + (LOGO_ADD_POSITION[LogoAddPosition.ShortBottomLeft].y * adjustedBasedCmFactorForMovePosition);
    } else if (position === LogoAddPosition.ShortBottomRight) {
      axis.left = jakoLogoRightPos.pathOffset.x;
      axis.top = logoObj.pathOffset.y + (LOGO_ADD_POSITION[LogoAddPosition.ShortBottomRight].y * adjustedBasedCmFactorForMovePosition);
    } else if (position === LogoAddPosition.ShortUpLeft) {
      axis.left = jakoLogoLeftPos.pathOffset.x;
      axis.top = logoObj.pathOffset.y + (LOGO_ADD_POSITION[LogoAddPosition.ShortUpLeft].y * adjustedBasedCmFactorForMovePosition);
    } else if (position === LogoAddPosition.ShortUpRight) {
      axis.left = jakoLogoRightPos.pathOffset.x;
      axis.top = logoObj.pathOffset.y + (LOGO_ADD_POSITION[LogoAddPosition.ShortUpRight].y * adjustedBasedCmFactorForMovePosition);
    }

    const addedPosition = position as LogoAddPosition;
    const selectedObj = configuratorRef.current.fabricCanvas.current._objects.find((obj: any) => obj.name === name) as any;
    if (startWidth) {
      configuratorRef.current?.updateLogoSizeWidth(name, startWidth * basedCmFactor);
    } else {
      configuratorRef.current?.updateLogoSizeHeight(name, startHeight * basedCmFactor);
    }

    setTimeout(() => {
      let updatedAddedFabricLogoObjects: AddedLogoItems = {
        ...addedFabricLogoObjectsRef.current,
        [name]: {
          ...addedFabricLogoObjectsRef.current[name],
          position: addedPosition,
          initialX: axis.left,
          initialY: axis.top,
          maxHeight: maxHeight,
          scaledHeight: selectedObj.getScaledHeight() / basedCmFactor,
          scaledWidth: selectedObj.getScaledWidth() / basedCmFactor
        }
      };
      setTimeout(() => {
        if (position === LogoAddPosition.ShortBottomLeft || position === LogoAddPosition.ShortUpLeft) {
          if (logoObj.getScaledWidth() < selectedObj.getScaledWidth()) {
            axis.left = logoObj.pathOffset.x - (selectedObj.getScaledWidth() / 2 - logoObj.getScaledWidth() / 2);
          } else {
            axis.left = logoObj.aCoords.tr.x - selectedObj.getScaledWidth() / 2;
          }
        }
        addedFabricLogoObjectsRef.current = updatedAddedFabricLogoObjects;
        setAddedFabricLogoObjects(updatedAddedFabricLogoObjects);
        configuratorRef.current?.updateObjectPosition(position, name, axis);
      }, 100);
    }, 300);

    if (isInit) {
      if (startWidth) {
        configuratorRef.current?.updateLogoSizeWidth(name, startWidth * basedCmFactor);
      } else {
        configuratorRef.current?.updateLogoSizeHeight(name, startHeight * basedCmFactor);
      }

      setTimeout(() => {
        if (position === LogoAddPosition.ShortBottomLeft || position === LogoAddPosition.ShortUpLeft) {
          if (logoObj.getScaledWidth() < selectedObj.getScaledWidth()) {
            axis.left = logoObj.pathOffset.x - (selectedObj.getScaledWidth() / 2 - logoObj.getScaledWidth() / 2);
          } else {
            axis.left = logoObj.aCoords.tr.x - selectedObj.getScaledWidth() / 2;
          }
        }
        configuratorRef.current?.updateObjectPosition(position, name, axis);
      }, 200);
    } else {
      if (position === LogoAddPosition.ShortBottomLeft || position === LogoAddPosition.ShortUpLeft) {
        if (logoObj.getScaledWidth() < selectedObj.getScaledWidth()) {
          axis.left = logoObj.pathOffset.x - (selectedObj.getScaledWidth() / 2 - logoObj.getScaledWidth() / 2);
        } else {
          axis.left = logoObj.aCoords.tr.x - selectedObj.getScaledWidth() / 2;
        }
      }
      const addedPosition = position as LogoAddPosition;
      let updatedAddedFabricLogoObjects: AddedLogoItems = {
        ...addedFabricLogoObjectsRef.current,
        [name]: {
          ...addedFabricLogoObjectsRef.current[name],
          position: addedPosition,
          initialX: axis.left,
          initialY: axis.top,
          maxHeight: maxHeight,
        }
      };
      updatedAddedFabricLogoObjects = {
        ...updatedAddedFabricLogoObjects,
        [name]: {
          ...updatedAddedFabricLogoObjects[name],
          fill: colorInvertedBgRef.current,
        }
      };
      setTimeout(() => {
        addedFabricLogoObjectsRef.current = updatedAddedFabricLogoObjects;
        setAddedFabricLogoObjects(updatedAddedFabricLogoObjects);
        configuratorRef.current?.updateObjectPosition(position, name, axis);
      }, 200);
    }
  }

  function onChangeTextPosition(name: string, position: string, section?: JerseySection, isInit?: boolean) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }

    let fabricFrontObjCenterX = 0,
    // fabricFrontObjCenterY = 0,
    fabricBackObjCenterX = 0,
    // fabricBackObjCenterY = 0,
    fabricLeftObjCenterX = 0,
    // fabricLeftObjCenterY = 0,
    fabricRightObjCenterX = 0;
    // fabricRightObjCenterY = 0;

    if (configuratorRef.current.designSvgPatternObjectsBounding.current.front_main) {
      fabricFrontObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.front_main.pathOffset.x);
      // fabricFrontObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.front_main.pathOffset.y);
    }

    if (configuratorRef.current.designSvgPatternObjectsBounding.current.back_main) {
      fabricBackObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.back_main.pathOffset.x);
      // fabricBackObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.back_main.pathOffset.y);
    }

    if (configuratorRef.current.designSvgPatternObjectsBounding.current.left_main) {
      fabricLeftObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.left_main.pathOffset.x);
      // fabricLeftObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.left_main.pathOffset.y);
    }

    if (configuratorRef.current.designSvgPatternObjectsBounding.current.right_main) {
      fabricRightObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.right_main.pathOffset.x);
      // fabricRightObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.right_main.pathOffset.y);
    }

    let fabricSockLegRight2ObjCenterX = 0;
    let fabricSockLegLeft2ObjCenterX = 0;

    if (configuratorRef.current.designSvgPatternObjectsBounding.current.leg_left_2) {
      fabricSockLegLeft2ObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.leg_left_2.pathOffset.x);
      // fabricRightObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.right_main.pathOffset.y);
    }
    if (configuratorRef.current.designSvgPatternObjectsBounding.current.leg_right_2) {
      fabricSockLegRight2ObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.leg_right_2.pathOffset.x);
      // fabricRightObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.right_main.pathOffset.y);
    }

    const logoObj = configuratorRef.current.fabricCanvas.current._objects.find((obj: any) => obj.id === 'logo_front') as any;
    const selectedObj = configuratorRef.current.fabricCanvas.current._objects.find((obj: any) => obj.name === name) as any;
    const selectedTab = parseInt(name.charAt(3));
    const isLongArmShirt = cuttingOptions.current.length && cuttingOptions.current.find((item) => item.code === '3D_SCHNITTFORM' && item.value === 'LANGARM');

    let axis: {
      left: number,
      top: number
    } = {
      left: 0,
      top: 0
    };

    let maxHeight = MAX_TEXT_SIZE_HEIGHT_CM;
    let startHeight = 4.5;
    let startWidth = 0;
    const adjustedBasedCmFactorForMovePosition = isMobile ? basedCmFactor * 4 : basedCmFactor;

    if (jfnetCuttingFormCode === 'K3800') {
      startHeight = 2;
    }

    if (selectedTab === FunctionTab.Teamname) {
      startWidth = 28;
      if (jfnetCuttingFormCode === 'K3800') {
        startWidth = 5;
      }
      if (position === TeamnameAddPosition.BackBottomFull) {
        axis.left = fabricBackObjCenterX;
        axis.top = logoObj.pathOffset.y + (TEAMNAME_ADD_POSITION[TeamnameAddPosition.BackBottomFull].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === TeamnameAddPosition.BackTopFull) {
        axis.left = fabricBackObjCenterX;
        axis.top = logoObj.pathOffset.y + (TEAMNAME_ADD_POSITION[TeamnameAddPosition.BackTopFull].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === TeamnameAddPosition.ShortBottomLeft) {
        axis.left = jakoLogoLeftPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y + (TEAMNAME_ADD_POSITION[TeamnameAddPosition.ShortBottomLeft].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === TeamnameAddPosition.ShortBottomRight) {
        axis.left = jakoLogoRightPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y + (TEAMNAME_ADD_POSITION[TeamnameAddPosition.ShortBottomRight].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === TeamnameAddPosition.ShortUpLeft) {
        axis.left = jakoLogoLeftPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y + (TEAMNAME_ADD_POSITION[TeamnameAddPosition.ShortUpLeft].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === TeamnameAddPosition.ShortUpRight) {
        axis.left = jakoLogoRightPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y + (TEAMNAME_ADD_POSITION[TeamnameAddPosition.ShortUpRight].y * adjustedBasedCmFactorForMovePosition);
      }
    }

    if (selectedTab === FunctionTab.Number) {
      startHeight = 5;
      if (position === NumberAddPosition.FrontTopCenter) {
        axis.left = fabricFrontObjCenterX;
        axis.top = logoObj.pathOffset.y;
      } else if (position === NumberAddPosition.FrontTopLeft) {
        axis.left = jakoLogoLeftPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y;
      } else if (position === NumberAddPosition.BackMiddleFull) {
        axis.left = fabricBackObjCenterX;
        axis.top = logoObj.pathOffset.y + (NUMBER_ADD_POSITION[NumberAddPosition.BackMiddleFull].y * adjustedBasedCmFactorForMovePosition);
        startHeight = 25;
      } else if (position === NumberAddPosition.LeftArm) {
        axis.left = fabricLeftObjCenterX;
        axis.top = endEdgeSleevePosition - (10 * adjustedBasedCmFactorForMovePosition) + (NUMBER_ADD_POSITION[NumberAddPosition.LeftArm].y * adjustedBasedCmFactorForMovePosition) - (isLongArmShirt ? 40 * adjustedBasedCmFactorForMovePosition : 0);
      } else if (position === NumberAddPosition.RightArm) {
        axis.left = fabricRightObjCenterX;
        axis.top = endEdgeSleevePosition - (10 * adjustedBasedCmFactorForMovePosition) + (NUMBER_ADD_POSITION[NumberAddPosition.RightArm].y * adjustedBasedCmFactorForMovePosition) - (isLongArmShirt ? 40 * adjustedBasedCmFactorForMovePosition : 0);
      } else if (position === NumberAddPosition.ShortBottomLeft) {
        axis.left = jakoLogoLeftPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y + (NUMBER_ADD_POSITION[NumberAddPosition.ShortBottomLeft].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === NumberAddPosition.ShortBottomRight) {
        axis.left = jakoLogoRightPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y + (NUMBER_ADD_POSITION[NumberAddPosition.ShortBottomRight].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === NumberAddPosition.ShortUpLeft) {
        axis.left = jakoLogoLeftPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y + (NUMBER_ADD_POSITION[NumberAddPosition.ShortUpLeft].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === NumberAddPosition.ShortUpRight) {
        axis.left = jakoLogoRightPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y + (NUMBER_ADD_POSITION[NumberAddPosition.ShortUpRight].y * adjustedBasedCmFactorForMovePosition);
      }
    }

    if (selectedTab === FunctionTab.Name || selectedTab === FunctionTab.Initial) {
      if (position === NameInitialAddPosition.FrontTopCenter) {
        axis.left = fabricFrontObjCenterX;
        axis.top = logoObj.pathOffset.y;
      } else if (position === NameInitialAddPosition.FrontTopLeft) {
        axis.left = jakoLogoLeftPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y;
      } else if (position === NameInitialAddPosition.LeftArm) {
        axis.left = fabricLeftObjCenterX;
        axis.top = endEdgeSleevePosition - (10 * adjustedBasedCmFactorForMovePosition) + (INITIAL_ADD_POSITION[NameInitialAddPosition.LeftArm].y * adjustedBasedCmFactorForMovePosition) - (isLongArmShirt ? 40 * adjustedBasedCmFactorForMovePosition : 0);
      } else if (position === NameInitialAddPosition.RightArm) {
        axis.left = fabricRightObjCenterX;
        axis.top = endEdgeSleevePosition - (10 * adjustedBasedCmFactorForMovePosition) + (INITIAL_ADD_POSITION[NameInitialAddPosition.RightArm].y * adjustedBasedCmFactorForMovePosition) - (isLongArmShirt ? 40 * adjustedBasedCmFactorForMovePosition : 0);
      }else if (position === NameInitialAddPosition.BackSuperBottomFull) {
        axis.left = fabricBackObjCenterX;
        axis.top = logoObj.pathOffset.y + (NAME_ADD_POSITION[NameInitialAddPosition.BackSuperBottomFull].y * adjustedBasedCmFactorForMovePosition);
        startWidth = 28;
      } else if (position === NameInitialAddPosition.BackTopFull) {
        axis.left = fabricBackObjCenterX;
        axis.top = logoObj.pathOffset.y + (NAME_ADD_POSITION[NameInitialAddPosition.BackTopFull].y * adjustedBasedCmFactorForMovePosition);
        startWidth = 28;
      } else if (position === NameInitialAddPosition.ShortBottomLeft) {
        axis.left = jakoLogoLeftPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y + (NAME_ADD_POSITION[NameInitialAddPosition.ShortBottomLeft].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === NameInitialAddPosition.ShortBottomRight) {
        axis.left = jakoLogoRightPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y + (NAME_ADD_POSITION[NameInitialAddPosition.ShortBottomRight].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === NameInitialAddPosition.ShortUpLeft) {
        axis.left = jakoLogoLeftPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y + (NAME_ADD_POSITION[NameInitialAddPosition.ShortUpLeft].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === NameInitialAddPosition.ShortUpRight) {
        axis.left = jakoLogoRightPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y + (NAME_ADD_POSITION[NameInitialAddPosition.ShortUpRight].y * adjustedBasedCmFactorForMovePosition);
      }
    }

    if (selectedTab === FunctionTab.Text) {
      if (position === TextAddPosition.FrontBottomFull) {
        axis.left = fabricFrontObjCenterX;
        axis.top = logoObj.pathOffset.y + (TEXT_ADD_POSITION[TextAddPosition.FrontBottomFull].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === TextAddPosition.FrontUnderLogoFull) {
        axis.left = fabricFrontObjCenterX;
        axis.top = logoObj.pathOffset.y + (TEXT_ADD_POSITION[TextAddPosition.FrontUnderLogoFull].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === TextAddPosition.BackTopFull) {
        axis.left = fabricBackObjCenterX;
        axis.top = logoObj.pathOffset.y + (TEXT_ADD_POSITION[TextAddPosition.BackTopFull].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === TextAddPosition.BackBottomFull) {
        axis.left = fabricBackObjCenterX;
        axis.top = logoObj.pathOffset.y + (TEXT_ADD_POSITION[TextAddPosition.BackBottomFull].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === TextAddPosition.LeftArm) {
        axis.left = fabricLeftObjCenterX;
        axis.top = endEdgeSleevePosition - (10 * adjustedBasedCmFactorForMovePosition) + (TEXT_ADD_POSITION[TextAddPosition.LeftArm].y * adjustedBasedCmFactorForMovePosition) - (isLongArmShirt ? 40 * adjustedBasedCmFactorForMovePosition : 0);
      } else if (position === TextAddPosition.RightArm) {
        axis.left = fabricRightObjCenterX;
        axis.top = endEdgeSleevePosition - (10 * adjustedBasedCmFactorForMovePosition) + (TEXT_ADD_POSITION[TextAddPosition.RightArm].y * adjustedBasedCmFactorForMovePosition) - (isLongArmShirt ? 40 * adjustedBasedCmFactorForMovePosition : 0);
      } else if (position === TextAddPosition.Neck) {
        axis.left = fabricBackObjCenterX;
        axis.top = logoObj.pathOffset.y + (TEXT_ADD_POSITION[TextAddPosition.Neck].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === TextAddPosition.ShortBottomLeft) {
        axis.left = jakoLogoLeftPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y + (TEXT_ADD_POSITION[TextAddPosition.ShortBottomLeft].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === TextAddPosition.ShortBottomRight) {
        axis.left = jakoLogoRightPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y + (TEXT_ADD_POSITION[TextAddPosition.ShortBottomRight].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === TextAddPosition.ShortUpLeft) {
        axis.left = jakoLogoLeftPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y + (TEXT_ADD_POSITION[TextAddPosition.ShortUpLeft].y * adjustedBasedCmFactorForMovePosition);
      } else if (position === TextAddPosition.ShortUpRight) {
        axis.left = jakoLogoRightPos.pathOffset.x;
        axis.top = logoObj.pathOffset.y + (TEXT_ADD_POSITION[TextAddPosition.ShortUpRight].y * adjustedBasedCmFactorForMovePosition);
      }
    }

    if (section) {
      const positionColor = selectedJerseySectionColor[section];
      const foundedColorFromList = colorList.find((colorItem) => positionColor.includes(colorItem.colorHexCode));
      if (foundedColorFromList) {
        colorInvertedBgRef.current = '#' + foundedColorFromList.colorLogoHex;
      }
    }

    const addedPosition = position as TeamnameAddPosition | NumberAddPosition | NameInitialAddPosition | TextAddPosition;
    configuratorRef.current?.updateTextColor(name, selectedTextFill ? selectedTextFill : colorInvertedBgRef.current);

    if (startWidth) {
      configuratorRef.current?.updateTextFontSizeWidth(name, startWidth * basedCmFactor);
    } else {
      configuratorRef.current?.updateTextFontSizeHeight(name, startHeight * basedCmFactor);
    }

    setTimeout(() => {
      let updatedAddedFabricTextObjects: AddedTextItems = {
        ...addedFabricTextObjectsRef.current,
        [name]: {
          ...addedFabricTextObjectsRef.current[name],
          position: addedPosition,
          initialX: axis.left,
          initialY: axis.top,
          maxHeight: maxHeight,
          scaledHeight: selectedObj.getScaledHeight() / basedCmFactor,
          scaledWidth: selectedObj.getScaledWidth() / basedCmFactor
        }
      };

      if (isInit) {
        updatedAddedFabricTextObjects = {
          ...updatedAddedFabricTextObjects,
          [name]: {
            ...updatedAddedFabricTextObjects[name],
            fill: selectedTextFill ? selectedTextFill : colorInvertedBgRef.current,
          }
        };
        setTimeout(() => {
          if (position === NameInitialAddPosition.ShortBottomLeft || position === NameInitialAddPosition.ShortUpLeft) {
            if (jfnetCuttingFormCode === 'K3800' && fabricSockLegLeft2ObjCenterX) {
              axis.left = fabricSockLegLeft2ObjCenterX;
            } else {
              const rightEdgeOfLogo = jakoLogoLeftPos.pathOffset.x + jakoLogoLeftPos.width / 2;
              axis.left = rightEdgeOfLogo - selectedObj.getScaledWidth() / 2;
            }
          } else if (position === NameInitialAddPosition.ShortBottomRight || position === NameInitialAddPosition.ShortUpRight) {
            if (jfnetCuttingFormCode === 'K3800' && fabricSockLegRight2ObjCenterX) {
              axis.left = fabricSockLegRight2ObjCenterX;
            } else {
              const leftEdgeOfLogo = jakoLogoRightPos.pathOffset.x - jakoLogoLeftPos.width / 2;
              axis.left = leftEdgeOfLogo + selectedObj.getScaledWidth() / 2;
            }
          }
          addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
          setAddedFabricTextObjects(updatedAddedFabricTextObjects);
          configuratorRef.current?.updateObjectPosition(position, name, axis);
        }, 100);
      } else {
        addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
        setAddedFabricTextObjects(updatedAddedFabricTextObjects);
        if (position === NameInitialAddPosition.ShortBottomLeft || position === NameInitialAddPosition.ShortUpLeft) {
          if (jfnetCuttingFormCode === 'K3800') {
            axis.left = jakoLogoLeftPos.pathOffset.x;
          } else {
            const rightEdgeOfLogo = jakoLogoLeftPos.pathOffset.x + jakoLogoLeftPos.width / 2;
            axis.left = rightEdgeOfLogo - selectedObj.getScaledWidth() / 2;
          }
        } else if (position === NameInitialAddPosition.ShortBottomRight || position === NameInitialAddPosition.ShortUpRight) {
          if (jfnetCuttingFormCode === 'K3800') {
            axis.left = jakoLogoRightPos.pathOffset.x;
          } else {
            const leftEdgeOfLogo = jakoLogoRightPos.pathOffset.x - jakoLogoLeftPos.width / 2;
            axis.left = leftEdgeOfLogo + selectedObj.getScaledWidth() / 2;
          }
        }
        setTimeout(() => {
          configuratorRef.current?.updateObjectPosition(position, name, axis);
        }, 100);
      }
    }, 300);
  }

  function resetDefaultPositionText() {
    if (!selectedAddedTextKey) {
      return;
    }
    configuratorRef.current?.resetDefaultPosition(selectedAddedTextKey, addedFabricTextObjects[selectedAddedTextKey].initialX, addedFabricTextObjects[selectedAddedTextKey].initialY);
  }

  function resetDefaultPositionLogo() {
    if (!selectedAddedLogoKey) {
      return;
    }
    configuratorRef.current?.resetDefaultPosition(selectedAddedLogoKey, addedFabricLogoObjects[selectedAddedLogoKey].initialX, addedFabricLogoObjects[selectedAddedLogoKey].initialY);
  }

  function onChangeLogoWidthSize(name: string, width: number) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    configuratorRef.current?.updateLogoSizeWidth(name, width);
  }

  function onChangeLogoHeightSize(name: string, height: number) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    configuratorRef.current?.updateLogoSizeHeight(name, height);
  }
  function onChangeLogoColor(color: string) {
    if (!selectedAddedLogoKey) {
      return;
    }
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }

    const updatedAddedFabricLogoObjects: AddedLogoItems = {
      ...addedFabricLogoObjects,
      [selectedAddedLogoKey]: {
        ...addedFabricLogoObjects[selectedAddedLogoKey],
        fill: color
      }
    };

    setAddedFabricLogoObjects(updatedAddedFabricLogoObjects);
    addedFabricLogoObjectsRef.current = updatedAddedFabricLogoObjects;

    configuratorRef.current?.updateLogoColor(selectedAddedLogoKey, color);
  }
  function onSelectLogo(eKey: string | null) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      configuratorRef.current?.handleUnprocessableIconInControl();
      return;
    }
    if (!eKey) {
      setSelectedAddedLogoKey(null);
      configuratorRef.current?.handleUnprocessableIconInControl();
      configuratorRef.current?.fabricCanvas.current?.discardActiveObject();
      configuratorRef.current?.fabricCanvas.current?.requestRenderAll();
      configuratorRef.current?.updateCanvasTextureMap();
      return;
    }

    setTimeout(() => {
      if (!configuratorRef.current) {
        return;
      }
      if (!configuratorRef.current.fabricCanvas.current) {
        return;
      }
      const fabricObjects = configuratorRef.current.fabricCanvas.current._objects;
      const foundedSelectedLogo = fabricObjects.find((item) => item.name === eKey) as fabric.Object;
      if (!foundedSelectedLogo) {
        configuratorRef.current?.handleUnprocessableIconInControl();
        return;
      }
      if (ModelStyle.Shirt === selectModelStyle) {
        if (addedFabricLogoObjectsRef.current[eKey] && addedFabricLogoObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('back')) {
          moveCameraToBackSide();
        }
        // front
        else if (addedFabricLogoObjectsRef.current[eKey] && addedFabricLogoObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('front')) {
          moveCameraToFrontSide();
        }
        // right
        else if (addedFabricLogoObjectsRef.current[eKey] && addedFabricLogoObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('right')) {
          moveCameraToRightSide();
        }
        // left
        else if (addedFabricLogoObjectsRef.current[eKey] && addedFabricLogoObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('left')) {
          moveCameraToLeftSide();
        }
      } else if (ModelStyle.ShirtNoSleeve === selectModelStyle) {
        if (addedFabricLogoObjectsRef.current[eKey] && addedFabricLogoObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('back')) {
          moveCameraToBackSide();
        }
        // front
        else if (addedFabricLogoObjectsRef.current[eKey] && addedFabricLogoObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('front')) {
          moveCameraToFrontSide();
        }
      } else {
        // right
        if (addedFabricLogoObjectsRef.current[eKey] && addedFabricLogoObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('left')) {
          moveCameraToLeftSideShort();
        }
        // left
        else if (addedFabricLogoObjectsRef.current[eKey] && addedFabricLogoObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('right')) {
          moveCameraToRightSideShort();
        }
      }
      configuratorRef.current?.handleUnprocessableIconInControl(eKey);
      setSelectedAddedLogoKey(eKey);
      configuratorRef.current?.setActiveObjectHandler(foundedSelectedLogo);
    }, 100);
  }

  function onSelectLogoFromFabric(eKey: string | null) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      configuratorRef.current?.handleUnprocessableIconInControl();
      return;
    }
    if (!eKey) {
      setSelectedAddedLogoKey(null);
      configuratorRef.current?.handleUnprocessableIconInControl();
      configuratorRef.current?.fabricCanvas.current?.discardActiveObject();
      configuratorRef.current?.fabricCanvas.current?.requestRenderAll();
      configuratorRef.current?.updateCanvasTextureMap();
      return;
    }

    setTimeout(() => {
      if (!configuratorRef.current) {
        return;
      }
      if (!configuratorRef.current.fabricCanvas.current) {
        configuratorRef.current?.handleUnprocessableIconInControl();
        return;
      }
      setSelectedAddedLogoKey(eKey);
      configuratorRef.current?.handleUnprocessableIconInControl(eKey);
      setTimeout(() => {
        jakoLogoTabRef.current?.openLogoOptionByName(eKey);
      }, 100);
    }, 100);
  }
  function onClickLogoLock(eKey: string) {
    const updatedAddedFabricLogoObjects: AddedLogoItems = {
      ...addedFabricLogoObjects,
      [eKey]: {
        ...addedFabricLogoObjects[eKey],
        isLock: !addedFabricLogoObjects[eKey].isLock
      }
    };
    setAddedFabricLogoObjects(updatedAddedFabricLogoObjects);
    addedFabricLogoObjectsRef.current = updatedAddedFabricLogoObjects;
    setSelectedAddedLogoKey(eKey);

    if (addedFabricLogoObjectsRef.current[eKey].isLock) {
      configuratorRef.current?.lockObject(eKey);
    } else {
      configuratorRef.current?.unlockObject(eKey);
    }
  }
  function onClickDeleteLogo(eKey: string) {
    configuratorRef.current?.deleteObject(eKey);
    onLogoTabBack();

    setTimeout(() => {
      const updatedAddedFabricLogoObjects = {...addedFabricLogoObjects};
      delete updatedAddedFabricLogoObjects[eKey];
      setAddedFabricLogoObjects(updatedAddedFabricLogoObjects);
      addedFabricLogoObjectsRef.current = updatedAddedFabricLogoObjects;
    }, 100);
  }

  function onConfiguratorEnableCameraControl() {
    if (cameraControlsRef.current && !cameraControlsRef.current.enabled) {
      cameraControlsRef.current.enabled = true;
    }
  }
  function onConfiguratorDisableCameraControl() {
    if (cameraControlsRef.current && cameraControlsRef.current.enabled) {
      cameraControlsRef.current.enabled = false;
    }
  }

  function onSetTabName(tab: FunctionTab) {
    setSelectTab(tab);
  }

  function onSetSelectTabHandler(tab: FunctionTab, index: number) {
    if (tab === FunctionTab.Pattern && patternList.length === 0) {
      setIsLoadingInitial(true);
      const correlationId = contextCorrelationId  || '111';
      const company = contextCompany || 'JAKO AG';
      const languageCode = selectedLang.current.name || contextLanguageCode;
      if (patternList.length) {
        onSelectText(null);
        setSelectTab(tab);
        setSelectTabIndex(index);
        setIsLoadingInitial(false);
        setTimeout(() => {
          setShowScrollDownSidebarHandler();
          setSidebarOnScrollHandler();
        }, 100);
        return;
      }
      JakoServices.getPatterns({
        correlationId,
        company,
        languageCode
      }).then((response) => {
          const patternResponse = response.data as PatternResponseItem[];
          setPatternList(patternResponse);
          onSelectText(null);
          setSelectTab(tab);
          setSelectTabIndex(index);
          setIsLoadingInitial(false);
          setTimeout(() => {
            setShowScrollDownSidebarHandler();
            setSidebarOnScrollHandler();
          }, 100);
        });
    } else {
      onSelectText(null);
      setSelectedAddedLogoKey(null);
      setSelectTab(tab);
      setSelectTabIndex(index);
      setTimeout(() => {
        setShowScrollDownSidebarHandler();
        setSidebarOnScrollHandler();
      }, 100);
    }
  }

  function setSelectTabBack() {
    let tabIndex = selectTabIndex;
    if (tabIndex === 0) {
      tabIndex = menuTabFiltered.length - 1;
    } else {
      tabIndex = selectTabIndex - 1;
    }
    setSelectTabIndex(tabIndex);
    setSelectTab(menuTabFiltered[tabIndex].functionTab);
  }
  function setSelectTabNext() {
    let tabIndex = selectTabIndex;
    if (tabIndex === menuTabFiltered.length - 1) {
      tabIndex = 0;
    } else {
      tabIndex = selectTabIndex + 1;
    }
    setSelectTabIndex(tabIndex);
    setSelectTab(menuTabFiltered[tabIndex].functionTab);
  }
  function setInitSelectedJerseySectionColors(colors: any, isWithDesign?: boolean) {
    let filteredColorResult = {};
    if (isWithDesign) {
      Object.keys(colors).forEach((key) => {
        filteredColorResult = {
          ...filteredColorResult,
          [key]: colors[key]
        };
      });
    } else {
      const noDesignColorKeys = Object.keys(colors).filter((key) => !key.includes('design-'));
      for (let key of noDesignColorKeys) {
        filteredColorResult = {
          ...filteredColorResult,
          [key]: colors[key]
        };
      }
    }
    setSelectedJerseySectionColor(filteredColorResult);
  }
  async function setInitSelectedJerseySectionPatterns(patterns: any, isWithDesign?: boolean) {
    let filteredPatternResult = {};
    if (isWithDesign) {
      for (let key of Object.keys(patterns)) {
        if (patterns[key].filename) {
          // const response = await fetch(patterns[key].filename);
          // for local development case
          const response = await fetch(patterns[key].filename.replace('https://jakob2b-test.dc-test.de', 'http://localhost:8010/proxy'));
          const textString = await response.text();
          filteredPatternResult = {
            ...filteredPatternResult,
            [key]: {
              ...patterns[key],
              svg: textString
            }
          };
        }
      }
    } else {
      const noDesignPatternKeys = Object.keys(patterns).filter((key) => !key.includes('design-'));
      for (let key of noDesignPatternKeys) {
        if (patterns[key].filename) {
          // const response = await fetch(patterns[key].filename);
          const response = await fetch(patterns[key].filename.replace('https://jakob2b-test.dc-test.de', 'http://localhost:8010/proxy'));
          const textString = await response.text();
          filteredPatternResult = {
            ...filteredPatternResult,
            [key]: {
              ...patterns[key],
              svg: textString
            }
          };
        }
      }
    }
    setSelectedJerseySectionPattern(filteredPatternResult);
  }

  async function setNewCuttingFormModelAndDesignOfCustomerDesign(saveConfigStateData: SaveConfigState, cuttingRes: any, customerDesign: CustomerDesignResponseItem, cuttingFormJfnet: string) {
    if (!saveConfigStateData.selectedDesign) {
      return Promise.resolve();
    }
    if (saveConfigStateData.selectedDesign.name?.toString().toLowerCase().includes('blanko')) {
      setInitSelectedJerseySectionColors(saveConfigStateData.selectedJerseySectionColor);
      await setInitSelectedJerseySectionPatterns(saveConfigStateData.selectedJerseySectionPattern);
    } else {
      setInitSelectedJerseySectionColors(saveConfigStateData.selectedJerseySectionColor, true);
      await setInitSelectedJerseySectionPatterns(saveConfigStateData.selectedJerseySectionPattern, true);
    }

    let cuttingFormCodeTmp = customerDesign.cuttingForm;
    setSelectedDesign(saveConfigStateData.selectedDesign);
    return delay(500).then(() => {
      if (!saveConfigStateData.selectedDesign) {
        return;
      }
      handleOnCloseMobile();
      const foundedCuttingFormData: CuttingResponseItem | undefined = cuttingFormResponseDataRef.current
        .find((item: CuttingResponseItem) => item.code === customerDesign.cuttingForm);
      currentCuttingFormItemNoRef.current = foundedCuttingFormData ? foundedCuttingFormData.itemNo : contextItemno;
      let cuttingFilePayload: any = {};
      if (saveConfigStateData && saveConfigStateData.selectedModel) {
        cuttingFormCodeTmp = saveConfigStateData.selectedModel.cuttingFormCode;
        cuttingFilePayload = {
          ...cuttingRes.data,
          ...saveConfigStateData.selectedModel
        };
      } else {
        cuttingFilePayload = cuttingRes.data;
      }
      setCuttingFileModelData(cuttingFilePayload);
      setJakoCurrentCuttingFormCode(cuttingFormCodeTmp);
      if (!configuratorRef.current) {
        return;
      }

      // If there's the case that the same model file of the customer design and current design the same.
      if (cuttingFilePayload.glbFile === cuttingFileModelData?.glbFile) {
        onConfiguratorInit();
      }
    });
  }
  async function onSelectDesignStart(cuttingFileTemplateResponseData: CuttingFileTemplateResponse, design: DesignOption, saveColors: any, savePatterns: any) {
    if (design.name?.toString().toLowerCase().includes('blanko')) {
      setInitSelectedJerseySectionColors(saveColors);
      await setInitSelectedJerseySectionPatterns(savePatterns);
    } else {
      setInitSelectedJerseySectionColors(saveColors, true);
      await setInitSelectedJerseySectionPatterns(savePatterns, true);
    }
    setSelectedDesign(design);
    return delay(500).then(() => {
      handleOnCloseMobile();
      setCuttingFileModelData(cuttingFileTemplateResponseData);
    });
  }
  async function setSelectCustomerDesignHandler(design: DesignOption, saveColors: any, savePatterns: any) {
    if (design.name?.toString().toLowerCase().includes('blanko')) {
      setInitSelectedJerseySectionColors(saveColors);
      await setInitSelectedJerseySectionPatterns(savePatterns);
    } else {
      setInitSelectedJerseySectionColors(saveColors, true);
      await setInitSelectedJerseySectionPatterns(savePatterns, true);
    }
    setSelectedDesign(design);
    return delay(500).then(() => {
      handleOnCloseMobile();
      configuratorRef.current?.loadSvgDesignToFabric();
    });
  }
  async function onSelectDesign(design: DesignOption) {
    const urlParams = new URLSearchParams(window.location.search);
    const isScreenShot = urlParams.get('screenshotDevJfnet') && urlParams.get('testCutting');
    if (isScreenShot) {
      setSelectedDesign(design);
      return delay(300).then(() => {
        handleOnCloseMobile();
        configuratorRef.current?.loadSvgDesignToFabric();
      });
    }
    if (design.name?.toString().toLowerCase().includes('blanko')) {
      setInitSelectedJerseySectionColors(selectedJerseySectionColor);
      await setInitSelectedJerseySectionPatterns(selectedJerseySectionPattern);
    } else {
      setInitSelectedJerseySectionColors(selectedJerseySectionColor, true);
      await setInitSelectedJerseySectionPatterns(selectedJerseySectionPattern, true);
    }
    setSelectedDesign(design);
    return delay(500).then(() => {
      handleOnCloseMobile();
      configuratorRef.current?.loadSvgDesignToFabric();
    });
  }
  function onSelectCustomerDesign(customerDesign: CustomerDesignResponseItem) {
    isCustomerDesign.current = true;
    setIsLoading(true);

    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    const fabricCanvas = configuratorRef.current.fabricCanvas.current;
    if (!fabricCanvas) {
      return;
    }

    resetAllData();
    Object.keys(addedFabricTextObjectsRef.current).forEach((key) => {
      const foundedObj = fabricCanvas._objects.find((obj) => obj.name === key) as any;
      if (foundedObj) {
        fabricCanvas.remove(foundedObj);
      }
    });
    Object.keys(addedFabricLogoObjectsRef.current).forEach((key) => {
      const foundedObj = fabricCanvas._objects.find((obj) => obj.name === key) as any;
      if (foundedObj) {
        fabricCanvas.remove(foundedObj);
      }
    });

    configuratorRef.current.initNewConfigurator();
    const cuttingFormCodeJfnet = customerDesign.model;

    JfnetServices.getConfigs(customerDesign.designHeaderId.toString())
      .then(async (designResult) => {
        setCuttingFormOptions(jakoCurrentCuttingFormCode);

        const stringDecoded = Base64.decode(designResult.data.data);
        const saveConfigStateData = JSON.parse(stringDecoded) as SaveConfigState;
        if (!(saveConfigStateData.hasBackDesign === null ||  saveConfigStateData.hasBackDesign === undefined) && saveConfigStateData.hasBackDesign) {
          handleTriggerDesignOnBack(saveConfigStateData.activateDesignOnBack);
        }
        updateSaveConfigDataRef.current = {
          ...saveConfigStateData,
          isFromCustomDesign: true
        };
        colorLogoHexRef.current = saveConfigStateData.colorLogoHexRef;
        colorDotsLeftHexRef.current = saveConfigStateData.colorDotsLeftHexRef;
        colorDotsRightHexRef.current  = saveConfigStateData.colorDotsRightHexRef;
        colorInvertedBgRef.current = saveConfigStateData.colorInvertedBgRef;
        setAddedFabricLogoObjects(saveConfigStateData.addedFabricLogoObjects);
        addedFabricLogoObjectsRef.current = saveConfigStateData.addedFabricLogoObjects;
        setAddedFabricTextObjects(saveConfigStateData.addedFabricTextObjects);
        addedFabricTextObjectsRef.current = saveConfigStateData.addedFabricTextObjects;
        jakoLogoPositionRef.current = saveConfigStateData.jakoLogoPosition;
        setJakoLogoPosition(saveConfigStateData.jakoLogoPosition);
        if (saveConfigStateData.selectedDesign && cuttingFileModelData) {
          const foundedCurrentCuttingDesign = cuttingFileModelData.designs.find((item) => {
            const normalizedCurrentCuttingName = item.name.replaceAll(' ', '').toLowerCase();
            if (saveConfigStateData.selectedDesign) {
              if (saveConfigStateData.selectedDesign.name?.includes('_')) {
                const normalizedName = saveConfigStateData.selectedDesign.name.split('_').slice(3).join('').toLowerCase();
                return normalizedCurrentCuttingName === normalizedName;
              } else {
                const normalizedName = saveConfigStateData.selectedDesign.name?.replaceAll(' ', '').toLowerCase();
                return normalizedCurrentCuttingName === normalizedName;
              }
            } else {
              return false;
            }
          });

          // If the design of the selected customer design exists in this cutting form design options
          if (foundedCurrentCuttingDesign) {
            await setSelectCustomerDesignHandler(foundedCurrentCuttingDesign, saveConfigStateData.selectedJerseySectionColor, saveConfigStateData.selectedJerseySectionPattern);
          }
          // If not then fetch and load the cutting form model from the customer design
          else {
            JfnetServices.getCuttingFiles(cuttingFormCodeJfnet)
              .then(async (newCuttingFormRes) => {
                if (!newCuttingFormRes.data) {
                  setIsLoadingInitial(false);
                  setIsLoadingPrices(false);
                  setIsLoading(false);
                  confirmAlert({
                    customUI: ({ onClose }) => {
                      return (
                        <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                          <h5 className="confirm-popup-container__title">
                            { t('configurator_3d_error_something_wrong') }
                          </h5>
                          <button className="button_primary" onClick={onClose}>
                            { t('configurator_3d_ok') }
                          </button>
                        </div>
                      );
                    }
                  });
                  return;
                }

                if (saveConfigStateData.selectedDesign) {
                  await setNewCuttingFormModelAndDesignOfCustomerDesign(saveConfigStateData, newCuttingFormRes, customerDesign, cuttingFormCodeJfnet);
                }
              }).catch(() => {
                setIsLoading(false);
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                        <h5 className="confirm-popup-container__title">
                          { t('configurator_3d_error_something_wrong') }
                        </h5>
                        <button className="button_primary" onClick={onClose}>
                          { t('configurator_3d_ok') }
                        </button>
                      </div>
                    );
                  }
                });
              });
          }
        }
      }).catch(() => {
        setIsLoading(false);
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                <h5 className="confirm-popup-container__title">
                  { t('configurator_3d_error_something_wrong') }
                </h5>
                <button className="button_primary" onClick={onClose}>
                  { t('configurator_3d_ok') }
                </button>
              </div>
            );
          }
        });
      });
  }
  function handleTriggerDesignOnBack(status: boolean) {
    setActivateDesignOnBack(status);
    setTimeout(() => {
      configuratorRef.current?.loadSvgDesignToFabric();
    }, 100);
  }
  function onSetIsLoadingOverlayUI(e: boolean) {
    setIsLoadingOverlayUI(e);
  }
  function onSetLoadingInitial(e: boolean) {
    setIsLoadingInitial(e);
  }
  async function onSetLoading(e: boolean) {
    if (!e) {
      setIsLoadingInitial(false);
    }
    setIsLoading(e);
    // localDevelopmentTest to get all screenshot
    const urlParams = new URLSearchParams(window.location.search);
    const isScreenShot = urlParams.get('screenshotDevJfnet') && urlParams.get('testCutting');
    if (isScreenShot && isSavingScreenshot === false && screenshotCountIndex.current === 0) {
      setIsLoadingInitial(true);
      screenshotCountIndex.current = 1;
      await localDevelopmentTest_automateGetDesignScreenShots();
    }
  }

  function onSetBaseCmFactor(value: number) {
    if (value) {
      setBaseCmFactor(value);
    } else {
      setBaseCmFactor(CM_FACTOR);
    }
  }

  function onSetEndEdgeSleevePos(pos: number) {
    if (pos) {
      setEndEdgeSleevePosition(pos);
    } else {
      setEndEdgeSleevePosition(BASE_END_EDGE_SLEEVE_POS);
    }
  }

  function onSetLogoLeftPosition(logoData: any) {
    setJakoLogoLeftPos(logoData);
  }
  function onSetLogoRightPosition(logoData: any) {
    setJakoLogoRightPos(logoData);
  }

  function onSelectColor(section: any, color: string) {
    if (section.toString().includes('back')) {
      moveCameraToBackSide();
    } else if (section.toString().includes('front')) {
      moveCameraToFrontSide();
    }
    let selectedColors: any = {...selectedJerseySectionColor};
    console.log('section', section);
    console.log('selectModelStyle', selectModelStyle);
    if (section === 'front_main') {
      if (ModelStyle.Shirt === selectModelStyle || ModelStyle.ShirtNoSleeve === selectModelStyle) {
        const foundedColorFromList = colorList.find((colorItem) => color.includes(colorItem.colorHexCode));
        if (foundedColorFromList) {
          colorLogoHexRef.current = '#' + foundedColorFromList.colorLogoHex;
        }
      }
      if (section.includes('left_shoulder') || section.includes('right_shoulder')) {
        selectedColors= {
          ...selectedColors,
          [JerseySection.Front]: color,
          left_shoulder: color,
          right_shoulder: color,
        };
      } else {
        selectedColors= {
          ...selectedColors,
          [JerseySection.Front]: color,

        };
      }
    } else if (section === 'back_main') {
      selectedColors= {
        ...selectedColors,
        [JerseySection.Back]: color,
        'inside_rib_neck': color
      };
    } else if (section.includes('collar')) {
      if (section.includes('collar_main')) {
        selectedColors= {
          ...selectedColors,
          [section]: color,
          'collar_under': color,
          'collar_left': color,
          'collar_right': color,
          'bg-collar_left': color,
          'bg-collar_right': color,
          'copy-collar_left': color,
          'copy-collar_right': color,
          'placket_right': color,
          'placket_left': color,
          'bg-collar_under': color,
          'bg-placket_right': color,
          'bg-placket_left': color,
          'copy-collar_under': color,
          'copy-placket_right': color,
          'copy-placket_left': color,
        };
      } else {
        selectedColors= {
          ...selectedColors,
          'collar_under': selectedColors['collar_main'] || color,
          'collar_left': selectedColors['collar_main'] || color,
          'collar_right': selectedColors['collar_main'] || color,
          'bg-collar_left': selectedColors['collar_main'] || color,
          'bg-collar_right': selectedColors['collar_main'] || color,
          'copy-collar_left': selectedColors['collar_main'] || color,
          'copy-collar_right': selectedColors['collar_main'] || color,
          'placket_right': selectedColors['collar_main'] || color,
          'placket_left': selectedColors['collar_main'] || color,
          'bg-collar_under': selectedColors['collar_main'] || color,
          'bg-placket_right': selectedColors['collar_main'] || color,
          'bg-placket_left': selectedColors['collar_main'] || color,
          'copy-collar_under': selectedColors['collar_main'] || color,
          'copy-placket_right': selectedColors['collar_main'] || color,
          'copy-placket_left': selectedColors['collar_main'] || color,
        };
      }
    } else if (section.includes('left_main')) {
      if (ModelStyle.Short === selectModelStyle && JakoLogoPosition.Left === jakoLogoPositionRef.current) {
        const foundedColorFromList = colorList.find((colorItem) => color.includes(colorItem.colorHexCode));
        if (foundedColorFromList) {
          colorLogoHexRef.current = '#' + foundedColorFromList.colorLogoHex;
        }
      }
      const foundedColorFromList = colorList.find((colorItem) => color.includes(colorItem.colorHexCode));
      if (foundedColorFromList) {
        colorDotsLeftHexRef.current = '#' + foundedColorFromList.colorLogoHex;
      }
      if (ModelStyle.Short === selectModelStyle) {
        selectedColors= {
          ...selectedColors,
          [section]: color,
          'left_main-2': color,
        };
        if (selectedColors['elastic_left_in']) {
          selectedColors= {
            ...selectedColors,
            [section]: color,
            'elastic_left_in': selectedColors['left_main'],
            'elastic_left_out': selectedColors['left_main'],
            'elastic_left_main': selectedColors['left_main'],
          };
        }
        if (selectedColors['leg_left_2']) {
          selectedColors= {
            ...selectedColors,
            [section]: color,
            'leg_left_1': selectedColors['left_main'],
            'leg_left_2': selectedColors['left_main'],
            'foot_left_1': selectedColors['left_main'],
            'foot_left_2': selectedColors['left_main'],
            'foot_left_3': selectedColors['left_main'],
            'foot_left_4': selectedColors['left_main'],
            'foot_left_5': selectedColors['left_main'],
            'foot_left_6': selectedColors['left_main'],
            'heel_left_1': selectedColors['left_main'],
            'heel_left_2': selectedColors['left_main'],
            'heel_left_3': selectedColors['left_main'],
            'heel_left_4': selectedColors['left_main'],
          };
        }
      } else {
        selectedColors= {
          ...selectedColors,
          [section]: color,
        };
      }
    } else if (section.includes('right_main')) {
      if (ModelStyle.Short === selectModelStyle && JakoLogoPosition.Right === jakoLogoPositionRef.current) {
        const foundedColorFromList = colorList.find((colorItem) => color.includes(colorItem.colorHexCode));
        if (foundedColorFromList) {
          colorLogoHexRef.current = '#' + foundedColorFromList.colorLogoHex;
        }
      }
      const foundedColorFromList = colorList.find((colorItem) => color.includes(colorItem.colorHexCode));
      if (foundedColorFromList) {
        colorDotsRightHexRef.current = '#' + foundedColorFromList.colorLogoHex;
      }
      if (ModelStyle.Short === selectModelStyle) {
        selectedColors= {
          ...selectedColors,
          [section]: color,
          'right_main-2': color,
        };
        if (selectedColors['elastic_right_in']) {
          selectedColors= {
            ...selectedColors,
            [section]: color,
            'elastic_right_in': selectedColors['right_main'],
            'elastic_right_out': selectedColors['right_main'],
            'elastic_right_main': selectedColors['right_main'],
          };
        }
        if (selectedColors['leg_right_2']) {
          selectedColors= {
            ...selectedColors,
            [section]: color,
            'leg_right_1': selectedColors['right_main'],
            'leg_right_2': selectedColors['right_main'],
            'foot_right_1': selectedColors['right_main'],
            'foot_right_2': selectedColors['right_main'],
            'foot_right_3': selectedColors['right_main'],
            'foot_right_4': selectedColors['right_main'],
            'foot_right_5': selectedColors['right_main'],
            'foot_right_6': selectedColors['right_main'],
            'heel_right_1': selectedColors['right_main'],
            'heel_right_2': selectedColors['right_main'],
            'heel_right_3': selectedColors['right_main'],
            'heel_right_4': selectedColors['right_main'],
          };
        }
      } else {
        selectedColors= {
          ...selectedColors,
          [section]: color,
        };
      }
    } else if (section.includes('elastic_main')) {
      selectedColors= {
        ...selectedColors,
        'elastic_main': color,
        'elastic_in': color,
      };
    } else if (ModelStyle.Short === selectModelStyle && section.includes('short_stripe')) {
      selectedColors= {
        ...selectedColors,
        'stripe_right': color,
        'stripe_left': color,
      };
    } else if (ModelStyle.ShirtNoSleeve === selectModelStyle && section.includes('cuff_left'))  {
      selectedColors= {
        ...selectedColors,
        'cuff_left': color,
        'bg-cuff_left': color,
        'copy-cuff_left': color,
      };
    } else if (ModelStyle.ShirtNoSleeve === selectModelStyle && section.includes('cuff_right'))  {
      selectedColors= {
        ...selectedColors,
        'cuff_right': color,
        'bg-cuff_right': color,
        'copy-cuff_right': color,
      };
    } else {
      if (
        selectedDesign
        && selectedDesign.name
        && selectedDesign.name.toString().toLowerCase() === 'harlekin'
      ) {
        selectedColors= {
          ...selectedColors,
          [section]: color,
          [`${section}-duplicate`]: color,
          'elastic_right_in': color,
          'elastic_right_out': color,
          'elastic_right_main': color,
        };
      } else {
        selectedColors= {
          ...selectedColors,
          [section]: color,
          [`${section}-duplicate`]: color,
        };
      }
    }
    setSelectedJerseySectionColor(selectedColors);
    configuratorRef.current?.changeColor(selectedColors);
  }

  function initJerseySectionColorRestart(possibleDesignAreas: string[]): Promise<any> {
    return new Promise((resolve) => {
      initColorList()
        .finally(() => {
          let result: any = {};
          let possibleDesignAreasObj = {} as any;
          let initJerseySectionColorObj = {} as any;

          if (!possibleDesignAreas.length) {
            return;
          }

          const mainWhite =  sortedColorListRef.current.find((item) => item.code === '3D_WEISS');
          const mainJakoBlue = sortedColorListRef.current.find((item) => item.code === '3D_JAKO_BLAU');
          const mainMarine = sortedColorListRef.current.find((item) => item.code === '3D_MARINE');
          const mainSchwarz = sortedColorListRef.current.find((item) => item.code === '3D_SCHWARZ');

          const colorInitWhite =  mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0';
          const colorInitJakoBlue = mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB';
          const colorInitSchwarz = mainSchwarz ? '#'+mainSchwarz.colorHexCode : '#26282D';
          const colorInitMarine = mainMarine ? '#'+mainMarine.colorHexCode : '#131E43';

          const selectedColorData = isCustomerDesign.current
            ? selectedJerseySectionColor
            : updateSaveConfigDataRef.current
              ? updateSaveConfigDataRef.current.selectedJerseySectionColor
              : null;
          if (sortedColorListRef.current.length) {
            // set initial color main part
            initJerseySectionColorObj = {
              [JerseySection.Front]: selectedColorData
                ? selectedColorData[JerseySection.Front] || colorInitWhite
                : colorInitWhite,
              [JerseySection.Back]: selectedColorData
                ? selectedColorData[JerseySection.Back] || colorInitWhite
                : colorInitWhite,
              [JerseySection.Left]: selectedColorData
                ? selectedColorData[JerseySection.Left] || colorInitJakoBlue
                : colorInitJakoBlue,
              [JerseySection.Right]: selectedColorData
                ? selectedColorData[JerseySection.Right] || colorInitJakoBlue
                : colorInitJakoBlue,
            };
            if (!isCustomerDesign.current) {
              if (updateSaveConfigDataRef.current && updateSaveConfigDataRef.current.colorLogoHexRef) {
                colorLogoHexRef.current = updateSaveConfigDataRef.current.colorLogoHexRef;
              } else if (ModelStyle.Shirt === selectModelStyle || ModelStyle.ShirtNoSleeve === selectModelStyle) {
                colorLogoHexRef.current = colorInitSchwarz;
              } else if (ModelStyle.Short === selectModelStyle) {
                colorLogoHexRef.current = colorInitWhite;
              } else {
                colorLogoHexRef.current = colorInitSchwarz;
              }
              colorDotsLeftHexRef.current = updateSaveConfigDataRef.current
                ? updateSaveConfigDataRef.current.colorDotsLeftHexRef || colorInitWhite
                : colorInitWhite;
              colorDotsRightHexRef.current = updateSaveConfigDataRef.current
                ? updateSaveConfigDataRef.current.colorDotsRightHexRef || colorInitWhite
                : colorInitWhite;
            }
          }

          possibleDesignAreas.slice().forEach((area) => {
            let designKeyWithNumber = '';
            let designNumber = 0;
            let designArea = area;
            if (area.includes('bg-')) {
              designArea = area.split('bg-')[1];
              designKeyWithNumber = area.split('bg-')[1].split('_')[0];
            } else if (area.includes('copy-')) {
              designArea = area.split('copy-')[1];
              designKeyWithNumber = area.split('copy-')[1].split('_')[0];
            } else {
              designArea = area;
              designKeyWithNumber = area.split('_')[0];
            }
            const number = parseInt(designKeyWithNumber.split('-')[1]);
            designNumber = number ? number : 0;
            if (area === 'cuff_left' || area === 'cuff_right') {
              possibleDesignAreasObj = {
                ...possibleDesignAreasObj,
                [area]: selectedColorData
                  ? selectedColorData[area] || (ModelStyle.ShirtNoSleeve === selectModelStyle ? colorInitJakoBlue :  colorInitWhite)
                  : ModelStyle.ShirtNoSleeve === selectModelStyle ? colorInitJakoBlue :  colorInitWhite,
              };
            } else if (area === 'left_shoulder' || area === 'right_shoulder') {
              const color = selectedColorData
                ? selectedColorData[JerseySection.Front] || (selectedJerseySectionColor[JerseySection.Front] || initJerseySectionColorObj[JerseySection.Front])
                : selectedJerseySectionColor[JerseySection.Front] || initJerseySectionColorObj[JerseySection.Front];
              possibleDesignAreasObj = {
                ...possibleDesignAreasObj,
                [area]: color,
              };
            } else if (area === 'collar_main') {
              if (!selectedJerseySectionColor[designArea]) {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: selectedColorData
                    ? selectedColorData[area] || colorInitJakoBlue
                    : colorInitJakoBlue,
                };
              } else {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]:  selectedColorData
                  ? selectedColorData[designArea] || selectedJerseySectionColor[designArea]
                  : selectedJerseySectionColor[designArea],
                };
              }
            } else if (area.includes('collar_under')) {
              if (selectedJerseySectionColor['collar_main']) {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: selectedColorData
                    ? selectedColorData[area] || selectedJerseySectionColor['collar_main']
                    : selectedJerseySectionColor['collar_main'],
                };
              } else {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: selectedColorData
                    ? selectedColorData[area] || colorInitJakoBlue
                    : colorInitJakoBlue,
                };
              }
            } else if (area.includes('inside_rib_neck')) {
              if (selectedJerseySectionColor[JerseySection.Back]) {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: selectedColorData
                    ? selectedColorData[area] || selectedJerseySectionColor[JerseySection.Back]
                    : selectedJerseySectionColor[JerseySection.Back],
                };
              } else {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: selectedColorData
                    ? selectedColorData[area] || colorInitWhite
                    : colorInitWhite,
                };
              }
            } else if (area.includes('placket_left')) {
              if (selectedJerseySectionColor['collar_main']) {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: selectedColorData
                    ? selectedColorData[area] || selectedJerseySectionColor['collar_main']
                    : selectedJerseySectionColor['collar_main'],
                };
              } else {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: selectedColorData
                    ? selectedColorData[area] || colorInitJakoBlue
                    : colorInitJakoBlue,
                };
              }
            } else if (area.includes('placket_right')) {
              if (selectedJerseySectionColor['collar_main']) {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: selectedColorData
                    ? selectedColorData[area] || selectedJerseySectionColor['collar_main']
                    : selectedJerseySectionColor['collar_main'],
                };
              } else {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: selectedColorData
                    ? selectedColorData[area] || colorInitJakoBlue
                    : colorInitJakoBlue,
                };
              }
            } else {
              if (area.includes('stripe_')) {
                if (selectModelStyle === ModelStyle.Short) {
                  const color = selectedJerseySectionColor[designArea] || initJerseySectionColorObj[designArea];
                  possibleDesignAreasObj = {
                    ...possibleDesignAreasObj,
                    [area]: selectedColorData
                      ? selectedColorData[area] || colorInitWhite
                      : color ? color : '#F0F5F0',
                  };
                } else {
                  const color = selectedJerseySectionColor[designArea] || initJerseySectionColorObj[designArea];
                  possibleDesignAreasObj = {
                    ...possibleDesignAreasObj,
                    [area]: selectedColorData
                      ? selectedColorData[area] || colorInitWhite
                      : color ? color : '#F0F5F0',
                  };
                }
              } else {
                const color = selectedJerseySectionColor[designArea] || initJerseySectionColorObj[designArea];
                const initColorForDesign = designArea.includes('-duplicate') || designNumber === 1
                  ? colorInitMarine
                  : colorInitJakoBlue;
                const selectedColor = selectedColorData
                  ? selectedColorData[area] || initColorForDesign
                  : color ? color : initColorForDesign;
                if (
                  selectedDesign
                  && selectedDesign.name
                  && selectedDesign.name.toString().toLowerCase() === 'harlekin'
                ) {
                  possibleDesignAreasObj = {
                    ...possibleDesignAreasObj,
                    [area]: selectedColor,
                    'elastic_right_in': selectedColor,
                    'elastic_right_out': selectedColor,
                    'elastic_right_main': selectedColor,
                  };
                } else {
                  possibleDesignAreasObj = {
                    ...possibleDesignAreasObj,
                    [area]: selectedColor,
                  };
                }
              }
            }
          });
          result = {
            ...result,
            ...initJerseySectionColorObj,
            ...possibleDesignAreasObj
          };
          if (ModelStyle.Shirt === selectModelStyle || ModelStyle.ShirtNoSleeve === selectModelStyle) {
            const foundedColorFrontColor = result['front_main'] && colorList.find((colorItem) => result['front_main'].slice(1).includes(colorItem.colorHexCode));
            if (foundedColorFrontColor) {
              colorLogoHexRef.current = '#' + foundedColorFrontColor.colorLogoHex;
            }
          } else {
            if (JakoLogoPosition.Right === jakoLogoPositionRef.current) {
              const foundedColor = result['left_main'] && colorList.find((colorItem) => result['left_main'].slice(1).includes(colorItem.colorHexCode));
              if (foundedColor) {
                colorLogoHexRef.current = '#' + foundedColor.colorLogoHex;
              }
            } else {
              const foundedColor = result['right_main'] && colorList.find((colorItem) => result['right_main'].slice(1).includes(colorItem.colorHexCode));
              if (foundedColor) {
                colorLogoHexRef.current = '#' + foundedColor.colorLogoHex;
              }
            }
          }
          const foundedColorLeftColor = result['left_main'] && colorList.find((colorItem) => result['left_main'].slice(1).includes(colorItem.colorHexCode));
          if (foundedColorLeftColor) {
            colorDotsLeftHexRef.current = '#' + foundedColorLeftColor.colorLogoHex;
          }
          const foundedColorRightColor = result['right_main'] && colorList.find((colorItem) => result['right_main'].slice(1).includes(colorItem.colorHexCode));
          if (foundedColorRightColor) {
            colorDotsRightHexRef.current = '#' + foundedColorRightColor.colorLogoHex;
          }
          setSelectedJerseySectionColor(result);
          resolve(result);
        });
    });
  }

  function initJerseySectionColor(possibleDesignAreas: string[]): Promise<any> {
    return new Promise((resolve) => {
      initColorList()
        .finally(() => {
          let result: any = {};
          let possibleDesignAreasObj = {} as any;
          let initJerseySectionColorObj = {} as any;
          if (!possibleDesignAreas.length) {
            return;
          }

          const mainWhite =  sortedColorListRef.current.find((item) => item.code === '3D_WEISS');
          const mainJakoBlue = sortedColorListRef.current.find((item) => item.code === '3D_JAKO_BLAU');
          const mainMarine = sortedColorListRef.current.find((item) => item.code === '3D_MARINE');
          const mainSchwarz = sortedColorListRef.current.find((item) => item.code === '3D_SCHWARZ');

          const colorInitWhite =  mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0';
          const colorInitJakoBlue = mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB';
          const colorInitSchwarz = mainSchwarz ? '#'+mainSchwarz.colorHexCode : '#26282D';
          if (sortedColorListRef.current.length) {
            // set initial color main part
            initJerseySectionColorObj = {
              [JerseySection.Front]: colorInitWhite,
              [JerseySection.Back]: colorInitWhite,
              [JerseySection.Left]: colorInitJakoBlue,
              [JerseySection.Right]: colorInitJakoBlue,
            };
            if (ModelStyle.Shirt === selectModelStyle || ModelStyle.ShirtNoSleeve === selectModelStyle) {
              colorLogoHexRef.current = colorInitSchwarz;
            } else if (ModelStyle.Short === selectModelStyle) {
              colorLogoHexRef.current = colorInitWhite;
            } else {
              colorLogoHexRef.current = colorInitSchwarz;
            }
            colorDotsLeftHexRef.current = colorInitWhite;
            colorDotsRightHexRef.current = colorInitWhite;
          }
          possibleDesignAreas.slice().forEach((area) => {
            let designKeyWithNumber = '';
            let designNumber = 0;
            let designArea = area;
            if (area.includes('bg-')) {
              designArea = area.split('bg-')[1];
              designKeyWithNumber = area.split('bg-')[1].split('_')[0];
            } else if (area.includes('copy-')) {
              designArea = area.split('copy-')[1];
              designKeyWithNumber = area.split('copy-')[1].split('_')[0];
            } else {
              designArea = area;
              designKeyWithNumber = area.split('_')[0];
            }
            const number = parseInt(designKeyWithNumber.split('-')[1]);
            designNumber = number ? number : 0;
            if (area === 'cuff_left' || area === 'cuff_right') {
              possibleDesignAreasObj = {
                ...possibleDesignAreasObj,
                [area]: ModelStyle.ShirtNoSleeve === selectModelStyle ? colorInitJakoBlue :  colorInitWhite,
              };
            } else if (area === 'left_shoulder' || area === 'right_shoulder') {
              const color = selectedJerseySectionColor[JerseySection.Front] || initJerseySectionColorObj[JerseySection.Front];
              possibleDesignAreasObj = {
                ...possibleDesignAreasObj,
                [area]: color,
              };
            } else if (area === 'collar_main') {
              if (!selectedJerseySectionColor[designArea]) {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: colorInitJakoBlue,
                  'placket_right': colorInitJakoBlue,
                  'placket_left': colorInitJakoBlue,
                };
              } else {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: selectedJerseySectionColor[designArea],
                  'placket_right': selectedJerseySectionColor[designArea],
                  'placket_left': selectedJerseySectionColor[designArea],
                };
              }
            } else if (area.includes('collar_under')) {
              if (selectedJerseySectionColor['collar_main']) {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: selectedJerseySectionColor['collar_main'],
                };
              } else {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: colorInitJakoBlue,
                };
              }
            } else if (area.includes('inside_rib_neck')) {
              if (selectedJerseySectionColor[JerseySection.Back]) {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: selectedJerseySectionColor[JerseySection.Back],
                };
              } else {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: colorInitWhite,
                };
              }
            } else if (area.includes('placket_left')) {
              if (selectedJerseySectionColor['collar_main']) {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: selectedJerseySectionColor['collar_main'],
                };
              } else {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: colorInitJakoBlue,
                };
              }
            } else if (area.includes('placket_right')) {
              if (selectedJerseySectionColor['collar_main']) {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: selectedJerseySectionColor['collar_main'],
                };
              } else {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: colorInitJakoBlue,
                };
              }
            } else {
              if (selectModelStyle === ModelStyle.Short && area.includes('stripe_')) {
                const color = selectedJerseySectionColor[designArea] || initJerseySectionColorObj[designArea];
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: color ? color : '#F0F5F0',
                };
              } else {
                const color = selectedJerseySectionColor[designArea] || initJerseySectionColorObj[designArea];
                const initColorForDesign = designArea.includes('-duplicate') || designNumber === 1
                  ? (mainMarine ? '#'+mainMarine.colorHexCode : '#131E43')
                  : colorInitJakoBlue;
                const selectedColor =  color ? color : initColorForDesign;

                if (
                  selectedDesign
                  && selectedDesign.name
                  && selectedDesign.name.toString().toLowerCase() === 'harlekin'
                ) {
                  possibleDesignAreasObj = {
                    ...possibleDesignAreasObj,
                    [area]: selectedColor,
                    'elastic_right_in': selectedColor,
                    'elastic_right_out': selectedColor,
                    'elastic_right_main': selectedColor,
                  };
                } else {
                  possibleDesignAreasObj = {
                    ...possibleDesignAreasObj,
                    [area]: selectedColor,
                  };
                }
              }
            }
          });
          result = {
            ...result,
            ...initJerseySectionColorObj,
            ...possibleDesignAreasObj
          };
          if (ModelStyle.Shirt === selectModelStyle || ModelStyle.ShirtNoSleeve === selectModelStyle) {
            const foundedColorFrontColor = result['front_main'] && colorList.find((colorItem) => result['front_main'].slice(1).includes(colorItem.colorHexCode));
            if (foundedColorFrontColor) {
              colorLogoHexRef.current = '#' + foundedColorFrontColor.colorLogoHex;
            }
          } else {
            if (JakoLogoPosition.Right === jakoLogoPositionRef.current) {
              const foundedColor = result['left_main'] && colorList.find((colorItem) => result['left_main'].slice(1).includes(colorItem.colorHexCode));
              if (foundedColor) {
                colorLogoHexRef.current = '#' + foundedColor.colorLogoHex;
              }
            } else {
              const foundedColor = result['right_main'] && colorList.find((colorItem) => result['right_main'].slice(1).includes(colorItem.colorHexCode));
              if (foundedColor) {
                colorLogoHexRef.current = '#' + foundedColor.colorLogoHex;
              }
            }
          }
          const foundedColorLeftColor = result['left_main'] && colorList.find((colorItem) => result['left_main'].slice(1).includes(colorItem.colorHexCode));
          if (foundedColorLeftColor) {
            colorDotsLeftHexRef.current = '#' + foundedColorLeftColor.colorLogoHex;
          }
          const foundedColorRightColor = result['right_main'] && colorList.find((colorItem) => result['right_main'].slice(1).includes(colorItem.colorHexCode));
          if (foundedColorRightColor) {
            colorDotsRightHexRef.current = '#' + foundedColorRightColor.colorLogoHex;
          }
          setSelectedJerseySectionColor(result);
          resolve(result);
        });
    });
  }

  function onCopyPatternSvg(copySection: string, section: string) {
    if (section.toString().includes('back')) {
      moveCameraToBackSide();
    } else if (section.toString().includes('front')) {
      moveCameraToFrontSide();
    }
    let result: AddedPatternJerseySectionItems = {};
    if (selectedJerseySectionPattern[copySection]) {
      const selectedCopyItem = selectedJerseySectionPattern[copySection];
      result = {
        ...selectedJerseySectionPattern,
        [section]: {
          ...selectedCopyItem,
        }
      };
      setSelectedJerseySectionPattern(result);
      configuratorRef.current?.changePattern(result);
    }
  }
  function onSelectPatternSvg(section: string, item: PatternResponseItem | null) {
    if (section.toString().includes('back')) {
      moveCameraToBackSide();
    } else if (section.toString().includes('front')) {
      moveCameraToFrontSide();
    }
    let result: AddedPatternJerseySectionItems = {};
    if (item) {
      // fetch(item.filename)
      // for local development case
      fetch(item.filename.replace('https://localhost', 'http://localhost:8010/proxy'))
        .then((response) => {
          return response.text();
        })
        .then((textString) => {
          // const mainWhite =  sortedColorListRef.current.find((item) => item.code === '3D_WEISS');
          // const mainJakoBlue = sortedColorListRef.current.find((item) => item.code === '3D_JAKO_BLAU');
          // const mainMarine = sortedColorListRef.current.find((item) => item.code === '3D_MARINE');
          const mainSchwarz = sortedColorListRef.current.find((item) => item.code === '3D_SCHWARZ');
          result = {
            ...selectedJerseySectionPattern,
            [section]: {
              ...selectedJerseySectionPattern[section],
              scale: 1,
              top: 0,
              left: 0,
              fill: selectedJerseySectionPattern[section] ? selectedJerseySectionPattern[section].fill || (mainSchwarz ? '#'+mainSchwarz.colorHexCode : '#26282D') : (mainSchwarz ? '#'+mainSchwarz.colorHexCode : '#26282D'),
              svg: textString,
              filename: item.filename,
              tileable: item.tileable,
              change_size: item.change_size,
              move_horizontally: item.move_horizontally,
              move_vertically: item.move_vertically,
              description: item.description
            }
          };
          setSelectedJerseySectionPattern(result);
          configuratorRef.current?.changePattern(result);
        });
    } else {
      // const mainWhite =  sortedColorListRef.current.find((item) => item.code === '3D_WEISS');
      // const mainJakoBlue = sortedColorListRef.current.find((item) => item.code === '3D_JAKO_BLAU');
      // const mainMarine = sortedColorListRef.current.find((item) => item.code === '3D_MARINE');
      const mainSchwarz = sortedColorListRef.current.find((item) => item.code === '3D_SCHWARZ');
      result = {
        ...selectedJerseySectionPattern,
        [section]: {
          ...selectedJerseySectionPattern[section],
          scale: 1,
          top: 0,
          left: 0,
          fill: (mainSchwarz ? '#'+mainSchwarz.colorHexCode : '#26282D'),
          svg: '',
          filename: '',
          tileable: false,
          description: ''
        }
      };
      setSelectedJerseySectionPattern(result);
      configuratorRef.current?.changePattern(result);
    }
  }
  function onSelectPatternFill(section: string, fill: string) {
    if (section.toString().includes('back')) {
      moveCameraToBackSide();
    } else if (section.toString().includes('front')) {
      moveCameraToFrontSide();
    }

    let result: AddedPatternJerseySectionItems = {};
    result = {
      ...selectedJerseySectionPattern,
      [section]: {
        ...selectedJerseySectionPattern[section],
        fill: fill,
      }
    };
    setSelectedJerseySectionPattern(result);
    configuratorRef.current?.changePattern(result);
  }
  function onPatternScale(section: string , e: number) {
    let result: AddedPatternJerseySectionItems = {};
    result = {
      ...selectedJerseySectionPattern,
      [section]: {
        ...selectedJerseySectionPattern[section],
        scale: e,
      }
    };
    setSelectedJerseySectionPattern(result);
    configuratorRef.current?.changePattern(result);
  }
  function onPatternMoveOffset(section: string, offset: {
    top: number,
    left: number
  }) {
    let result: AddedPatternJerseySectionItems = {};
    result = {
      ...selectedJerseySectionPattern,
      [section]: {
        ...selectedJerseySectionPattern[section],
        ...offset,
      }
    };
    setSelectedJerseySectionPattern(result);
    configuratorRef.current?.changePattern(result);
  }
  function onPatternRotate(section: string ,e: number) {
    let result: AddedPatternJerseySectionItems = {};
    result = {
      ...selectedJerseySectionPattern,
      [section]: {
        ...selectedJerseySectionPattern[section],
        rotate: e,
      }
    };
    setSelectedJerseySectionPattern(result);
    configuratorRef.current?.changePattern(result);
  }
  function onSetPatternJerseySection(e: string) {
    if (ModelStyle.Shirt === selectModelStyle) {
      // back
      if (e.includes('back')) {
        moveCameraToBackSide();
      }
      // front
      else if (e.includes('front')) {
        moveCameraToFrontSide();
      }
      // right
      else if (e.includes('right')) {
        moveCameraToRightSide();
      }
      // left
      else if (e.includes('left')) {
        moveCameraToLeftSide();
      }
    } else if (ModelStyle.ShirtNoSleeve === selectModelStyle) {
      // back
      if (e.includes('back')) {
        moveCameraToBackSide();
      }
      // front
      else if (e.includes('front')) {
        moveCameraToFrontSide();
      }
    } else {
      // right
      if (e.includes('right')) {
        moveCameraToRightSideShort();
      }
      // left
      else if (e.includes('left')) {
        moveCameraToLeftSideShort();
      }
    }
    setPatternJerseySection(e);
  }

  function onSetPossibleDesignAreaId(e: string[], reset?: boolean) {
    const possibleColorArea = e.map((v) => v.toString());
    const getPossibleDesignAreas = possibleColorArea.slice().filter((v) => !RESERVED_JERSEY_AREAS_ID.includes(v));
    setPossibleDesignAreaId(getPossibleDesignAreas);
    if (reset) {
      return initJerseySectionColorRestart(e);
    }
    return initJerseySectionColor(e);
  }

  async function getSvgResultFile() {
    return configuratorRef.current?.getRasterizeSVGFile();
  }

  function onConfirmCompletePopup() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div id="jako-configurator-3d-wc" className="confirm-popup-container">
            <i className="confirm-popup-container__icon">
              <IconCartCircleCheck />
            </i>
             <label htmlFor="saveEnterName" className="confirm-popup-container__title">
              { t('configurator_3d_add_cart_sucess') }
            </label>
            <div className="confirm-popup__func-container">
              <button className="outlineButton" type="button" onClick={onClose}>
                { t('configurator_3d_continue_config') }
              </button>
              <button className="button_primary" onClick={() => {window.location.href = contextBasketUrl;}}>
                { t('configurator_3d_go_to_cart') }
              </button>
            </div>
          </div>
        );
      },
      closeOnClickOutside: false,
      closeOnEscape: false
    });
  }

  function localDevelopmentTest_completeConfiguration() {
    localDevelopmentTest_handleSaveConfiguration(true);
  }
  async function completeConfiguration() {
    setTimeout(() => {
      // initialSaveConfigDataRef is the loaded save data at the initial step
      // If it's null it means that this is a new config
      if (!initialSaveConfigDataRef.current || !updateSaveConfigDataRef.current) {
        handleSaveConfiguration(true);
        return;
      }

      let adjustedDesignHeaderId = updateSaveConfigDataRef.current.designHeaderId;
      if (updateSaveConfigDataRef.current.isFromCustomDesign) {
        adjustedDesignHeaderId = initialSaveConfigDataRef.current.designHeaderId;
      }

      setIsLoading(true);
      const response = updateJakoIndividualisation(adjustedDesignHeaderId, true);
      if (response) {
        response
          .then((res) => {
            const correlationId = contextCorrelationId  || '111';
            const wc = document.querySelector('jako-configurator');
            const wcVisitorId = wc
              ? wc.getAttribute('visitorId')
              : null;
            const visitorId = wcVisitorId
              ? wcVisitorId
              : contextVisitorId || '222';
            const newUpdateDesignHeaderId = res?.data.designHeaderId.toString();
            JakoServices.basketAddItems({
              correlationId,
              visitorId: parseInt(visitorId),
              basketLines: [
                {
                  designHeaderId: parseInt(newUpdateDesignHeaderId),
                  itemNo: currentCuttingFormItemNoRef.current,
                  quantity: 1
                }
              ]
            })
              .then(() => {
                if (updateSaveConfigDataRef.current !== null) {
                  saveConfiguration({
                    designHeaderId: newUpdateDesignHeaderId,
                    configurationName: updateSaveConfigDataRef.current.configurationName,
                    isSaveBeforeSubmitAddToCart: true
                  }).catch(() => {
                    confirmAlert({
                      customUI: ({ onClose }) => {
                        return (
                          <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                            <h5 className="confirm-popup-container__title">
                              { t('configurator_3d_error_something_wrong') }
                            </h5>
                            <button className="button_primary" onClick={onClose}>
                              { t('configurator_3d_ok') }
                            </button>
                          </div>
                        );
                      }
                    });
                    onSetLoading(false);
                  }).finally(() => {
                    onSetLoading(false);
                  });
                }
                onConfirmCompletePopup();
              })
              .catch((err) => {
                onSetLoading(false);
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                        <h5 className="confirm-popup-container__title">
                          { t('configurator_3d_error_something_wrong') }
                        </h5>
                        <button className="button_primary" onClick={onClose}>
                          { t('configurator_3d_ok') }
                        </button>
                      </div>
                    );
                  }
                });
              });
          })
          .catch((err) => {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                    <h5 className="confirm-popup-container__title">
                      { t('configurator_3d_error_something_wrong') }
                    </h5>
                    <button className="button_primary" onClick={onClose}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
            });
          })
          .finally(() => {
            onSetLoading(false);
          });
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                <h5 className="confirm-popup-container__title">
                  { t('configurator_3d_error_something_wrong') }
                </h5>
                <button className="button_primary" onClick={onClose}>
                  { t('configurator_3d_ok') }
                </button>
              </div>
            );
          }
        });
        onSetLoading(false);
      }
    }, 1000);
  }

  function localDevelopmentTest_onClickSaveConfiguration() {
    localDevelopmentTest_handleSaveConfiguration();
  }

  function onClickSaveConfiguration() {
    // onclicksave
    if (onclicksave) {
      onclicksave(handleSaveConfiguration);
    } else {
      handleSaveConfiguration();
    }
  }

  function localDevelopmentTest_onCompleteConfiguration() {
    localDevelopmentTest_completeConfiguration();
  }
  function onCompleteConfiguration() {
    // onclicksubmit
    if (onclicksubmit) {
      onclicksubmit(completeConfiguration);
    } else {
      completeConfiguration();
    }
  }

  function moveCameraToFrontSide(reset?: boolean) {
    if (isMobile) {
      if (selectModelStyle === ModelStyle.Short) {
        cameraControlsRef.current?.setPosition(-0.22215543323018355, 0.4619976595384762, 6.859830184793832, true);
      } else {
        cameraControlsRef.current?.setPosition(-0.22215543323018355, 1.3760002904351893, 6.859830184793832, true);
      }
    } else {
      if (selectModelStyle === ModelStyle.Short) {
        cameraControlsRef.current?.setPosition(-0.18716965860240095, 0.33, 5.896806530160, true);
      } else {
        cameraControlsRef.current?.setPosition(-0.18716965860240095, 1.1828048040289152, 5.89680653016052, true);
      }
    }
    cameraControlsRef.current?.saveState();
    cameraControlsRef.current?.setTarget(0, 0, 0, true);
    if (reset) {
      setTimeout(() => {
        cameraControlsRef.current?.reset(true);
      }, 200);
    }
  }

  function moveCameraToBackSide() {
    if (isMobile) {
      cameraControlsRef.current?.setPosition(-0.22215543323018355, 1.3760002904351893, -6.859830184793832, true);
    } else {
      cameraControlsRef.current?.setPosition(-0.18716965860240095, 1.1828048040289152, -5.89680653016052, true);
    }
    cameraControlsRef.current?.setTarget(0, 0, 0, true);
  }

  function moveCameraToRightSide() {
    if (isMobile) {
      cameraControlsRef.current?.setPosition(-6.842482817302935, 1.3838126537547903, 0.5151324105956326, true);
    } else {
      cameraControlsRef.current?.setPosition(-5.999257226979, 0.44966400094376696, 0.11446375179260178, true);
    }
    cameraControlsRef.current?.setTarget(0, 0, 0, true);
  }

  function moveCameraToLeftSide() {
    if (isMobile) {
      cameraControlsRef.current?.setPosition(6.842482817302935, 1.3838126537547903, 0.5151324105956326, true);
    } else {
      cameraControlsRef.current?.setPosition(5.999257226979, 0.44966400094376696, 0.11446375179260178, true);
    }
    cameraControlsRef.current?.setTarget(0, 0, 0, true);
  }

  function moveCameraToLeftSideShort() {
    if (isMobile) {
      if (jfnetCuttingFormCode === 'K3800') {
        cameraControlsRef.current?.setPosition(1.8, 0, 5.5, true);
      }
      else {
        cameraControlsRef.current?.setPosition(5.239677233436536, 0.9903925829485761, 2.7877037074851128, true);
      }
    } else {
      if (jfnetCuttingFormCode === 'K3800') {
        cameraControlsRef.current?.setPosition(1.8, 0, 5.5, true);
      }
      else {
        cameraControlsRef.current?.setPosition(5.239677233436536, 0.9903925829485761, 2.7877037074851128, true);
      }
    }
    cameraControlsRef.current?.setTarget(0, 0, 0, true);
  }
  function moveCameraToRightSideShort() {
    if (isMobile) {
      if (jfnetCuttingFormCode === 'K3800') {
        cameraControlsRef.current?.setPosition(-1.8, 0, 5.5, true);
      }
      else {
        cameraControlsRef.current?.setPosition(-5.239677233436536, 0.9903925829485761, 2.7877037074851128, true);
      }
    } else {
      if (jfnetCuttingFormCode === 'K3800') {
        cameraControlsRef.current?.setPosition(-1.8, 0, 5.5, true);
      }
      else {
        cameraControlsRef.current?.setPosition(-5.239677233436536, 0.9903925829485761, 2.7877037074851128, true);
      }
    }
    cameraControlsRef.current?.setTarget(0, 0, 0, true);
  }

  function initColorList() {
    const correlationId = contextCorrelationId  || '111';
    const company = contextCompany || 'JAKO AG';
    const languageCode = selectedLang.current.name || contextLanguageCode;
    return new Promise((resolve, reject) => {
      JakoServices.getColors({
        correlationId,
        company,
        languageCode,
      }).then((response) => {
        const colorResponse = response.data as ColorResponseItem[];
        sortedColorListRef.current = colorResponse.slice().sort((a, b) => {
          if (a.isPrimary && b.isPrimary) {
            return a.sorting - b.sorting;
          } else if (a.isPrimary) {
            return -1;
          } else if (b.isPrimary) {
            return 1;
          } else {
            return a.sorting - b.sorting;
          }
        });
        setColorList(colorResponse);
        resolve(colorResponse);
      });
    });
  }

  const onConfiguratorModified = debounce(function(type: string, id: string) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    if (type === 'logo') {
      const foundedLogo = configuratorRef.current.fabricCanvas.current._objects.find((item) => item.name === id) as any;
      const designSvgPatternObjectsBounding = configuratorRef.current.designSvgPatternObjectsBounding.current;
      const foundedPositionPart = Object.keys(designSvgPatternObjectsBounding).find((itemKey) => {
        const partWidth = isMobile ? designSvgPatternObjectsBounding[itemKey].width / 4 : designSvgPatternObjectsBounding[itemKey].width;
        const partHeight = isMobile ? designSvgPatternObjectsBounding[itemKey].height / 4 : designSvgPatternObjectsBounding[itemKey].height;
        const centerX = isMobile ? designSvgPatternObjectsBounding[itemKey].pathOffset.x / 4 : designSvgPatternObjectsBounding[itemKey].pathOffset.x;
        const centerY = isMobile ? designSvgPatternObjectsBounding[itemKey].pathOffset.y / 4 : designSvgPatternObjectsBounding[itemKey].pathOffset.y;

        const leftEdgeX = Math.abs(centerX - (partWidth / 2));
        const rightEdgeX = (centerX + (partWidth / 2));
        const topEdgeY = Math.abs(centerY - (partHeight / 2));
        const bottomEdgeY = (centerY + (partHeight / 2));
        return foundedLogo.left >= leftEdgeX
          && foundedLogo.left <= rightEdgeX
          && foundedLogo.top >= topEdgeY
          && foundedLogo.top <= bottomEdgeY;
      });
      if (foundedLogo) {
        addedFabricLogoObjectsRef.current = {
          ...addedFabricLogoObjectsRef.current,
          [id]: {
            ...addedFabricLogoObjectsRef.current[id],
            x: isMobile ? foundedLogo.left * 4 : foundedLogo.left,
            y: isMobile ? foundedLogo.top * 4 : foundedLogo.top,
            rotation: foundedLogo.angle,
            foundedPositionPart: foundedPositionPart || 'front_main',
          }
        };
        setAddedFabricLogoObjects(addedFabricLogoObjectsRef.current);
      }
    } else {
      const foundedText = configuratorRef.current.fabricCanvas.current._objects.find((item) => item.name === id) as any;
      const designSvgPatternObjectsBounding = configuratorRef.current.designSvgPatternObjectsBounding.current;
      const foundedPositionPart = Object.keys(designSvgPatternObjectsBounding).find((itemKey) => {
        const partWidth = isMobile ? designSvgPatternObjectsBounding[itemKey].width / 4 : designSvgPatternObjectsBounding[itemKey].width;
        const partHeight = isMobile ? designSvgPatternObjectsBounding[itemKey].height / 4 : designSvgPatternObjectsBounding[itemKey].height;
        const centerX = isMobile ? designSvgPatternObjectsBounding[itemKey].pathOffset.x / 4 : designSvgPatternObjectsBounding[itemKey].pathOffset.x;
        const centerY = isMobile ? designSvgPatternObjectsBounding[itemKey].pathOffset.y / 4 : designSvgPatternObjectsBounding[itemKey].pathOffset.y;

        const leftEdgeX = Math.abs(centerX - (partWidth / 2));
        const rightEdgeX = (centerX + (partWidth / 2));
        const topEdgeY = Math.abs(centerY - (partHeight / 2));
        const bottomEdgeY = (centerY + (partHeight / 2));
        return foundedText.left >= leftEdgeX
          && foundedText.left <= rightEdgeX
          && foundedText.top >= topEdgeY
          && foundedText.top <= bottomEdgeY;
      });
      if (foundedText) {
        addedFabricTextObjectsRef.current = {
          ...addedFabricTextObjectsRef.current,
          [id]: {
            ...addedFabricTextObjectsRef.current[id],
            fontSize: foundedText.fontSize,
            x: isMobile ? foundedText.left * 4 : foundedText.left,
            y: isMobile ? foundedText.top * 4 : foundedText.top,
            fill: foundedText.fill,
            text: foundedText.text,
            fontFamily: foundedText.fontFamily,
            rotation: foundedText.angle,
            foundedPositionPart: foundedPositionPart || 'front_main'
          }
        };
        setAddedFabricTextObjects(addedFabricTextObjectsRef.current);
      }
    }
  }, 200);

  function handleOnSetHasBackDesign(e: boolean) {
    setHasBackDesign(e);
  }

  function handleOnScrollToSelectedItem(id: string) {
    sidebarScrollRef.current?.scrollTo(id);
  }

  function handleOnCloseMobile() {
    if (!isMobile) {
      return;
    }
    configuratorRef.current?.fabricCanvas.current?.discardActiveObject();
    configuratorRef.current?.fabricCanvas.current?.requestRenderAll();
    configuratorRef.current?.updateCanvasTextureMap();
    setSelectTab(null);
  }

  function delay(time: number) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  function getDefaultScreenShot(isSaveBeforeSubmitAddToCart?: boolean): Promise<string[]> {
    setIsLoadingInitial(true);
    setIsSavingScreenshot(true);
    const isLongarm = cuttingOptions.current.length && cuttingOptions.current.find((item) => item.code === '3D_SCHNITTFORM' && item.value === 'LANGARM');

    if (!configuratorRef.current) {
      return Promise.resolve(['']);
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return Promise.resolve(['']);
    }
    if (!currentCanvasGLRef.current) {
      return Promise.resolve(['']);
    }

    configuratorRef.current.fabricCanvas.current.discardActiveObject().renderAll();
    const canvasEl = currentCanvasGLRef.current.domElement;
    if (isSaveBeforeSubmitAddToCart) {
      configuratorRef.current?.fabricCanvas.current?.requestRenderAll();
      configuratorRef.current?.updateCanvasTextureMap();
    }
    cameraControlsRef.current?.setPosition(-0.06512612834137524, 0.866525151725151, 4.16553710195109, false);
    cameraControlsRef.current?.setTarget(0, 0.35, 0, false);
    if (isLongarm) {
      cameraControlsRef.current?.dolly(-1, false);
    } else {
      cameraControlsRef.current?.dolly(0, false);
    }
    cameraControlsRef.current?.truck(0, 0, false);

    const results: Promise<string[]> = new Promise((resolve, reject) => {
      delay(1500).then(() =>{
        const front = canvasEl.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        cameraControlsRef.current?.rotate(180 * MathUtils.DEG2RAD, 0, false);
        delay(500).then(() =>{
          const back = canvasEl.toDataURL('image/png').replace('image/png', 'image/octet-stream');
          cameraControlsRef.current?.rotate(90 * MathUtils.DEG2RAD, 0, false);
          delay(500).then(() =>{
            const right = canvasEl.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            cameraControlsRef.current?.rotate(180 * MathUtils.DEG2RAD, 0, false);
            delay(500).then(() =>{
              const left = canvasEl.toDataURL('image/png').replace('image/png', 'image/octet-stream');
              resolve([front, back, left, right]);
            });
          });
        });
      });
    });

    return results.finally(() => {
      setIsSavingScreenshot(false);
      setTimeout(() => {
        cameraControlsRef.current?.reset();
        setTimeout(() => {
          setIsLoadingInitial(false);
        }, 500);
      }, 500);
    });
  }

  async function createJakoIndividualisation(configurationName: string, isSaveBeforeSubmitAddToCart?: boolean) {
    try {
      let payloadTexts: any[] = [];
      let payloadLogos: any[] = [];
      if (Object.keys(addedFabricTextObjectsRef.current).length) {
        Object.keys(addedFabricTextObjectsRef.current).forEach((textKey) => {
          if (!(addedFabricTextObjectsRef.current[textKey].x === 0 || addedFabricTextObjectsRef.current[textKey].y === 0)) {
            if (addedFabricTextObjectsRef.current[textKey].foundedPositionPart && addedFabricTextObjectsRef.current[textKey].foundedPositionPart.toLowerCase().includes('front')) {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.Front,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill.replace('#', ''),
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            } else if (addedFabricTextObjectsRef.current[textKey].foundedPositionPart && addedFabricTextObjectsRef.current[textKey].foundedPositionPart.toLowerCase().includes('back')) {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.Back,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill.replace('#', ''),
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            } else if (addedFabricTextObjectsRef.current[textKey].foundedPositionPart && addedFabricTextObjectsRef.current[textKey].foundedPositionPart.toLowerCase().includes('left')) {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.SideLeft,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill.replace('#', ''),
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            } else if (addedFabricTextObjectsRef.current[textKey].foundedPositionPart && addedFabricTextObjectsRef.current[textKey].foundedPositionPart.toLowerCase().includes('right')) {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.SideRight,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill.replace('#', ''),
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            } else {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.Front,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill.replace('#', ''),
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            }
          }
        });
      }
      if (Object.keys(addedFabricLogoObjectsRef.current).length) {
        Object.keys(addedFabricLogoObjectsRef.current).forEach((logoKey) => {
          if (
            !(addedFabricLogoObjectsRef.current[logoKey].x === 0 || addedFabricLogoObjectsRef.current[logoKey].y === 0)
              && addedFabricLogoObjectsRef.current[logoKey].reference
          ) {
            if (addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart && addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart.toLowerCase().includes('front')) {
              payloadLogos.push({
                viewCode: IndividualisationViewCode.Front,
                fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                value: addedFabricLogoObjectsRef.current[logoKey].src,
                size: [
                  {
                    width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: '',
                fontFamily: '',
                imageColors: addedFabricLogoObjectsRef.current[logoKey].possibleImageColors
              });
            } else if (addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart && addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart.toLowerCase().includes('back')) {
              payloadLogos.push({
                viewCode: IndividualisationViewCode.Back,
                fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                value: addedFabricLogoObjectsRef.current[logoKey].src,
                size: [
                  {
                    width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: '',
                fontFamily: '',
                imageColors: addedFabricLogoObjectsRef.current[logoKey].possibleImageColors
              });
            } else if (addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart && addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart.toLowerCase().includes('left')) {
              payloadLogos.push({
                viewCode: IndividualisationViewCode.SideLeft,
                fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                value: addedFabricLogoObjectsRef.current[logoKey].src,
                size: [
                  {
                    width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: '',
                fontFamily: '',
                imageColors: addedFabricLogoObjectsRef.current[logoKey].possibleImageColors
              });
            } else if (addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart && addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart.toLowerCase().includes('right')) {
              payloadLogos.push({
                viewCode: IndividualisationViewCode.SideRight,
                fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                value: addedFabricLogoObjectsRef.current[logoKey].src,
                size: [
                  {
                    width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: '',
                fontFamily: '',
                imageColors: addedFabricLogoObjectsRef.current[logoKey].possibleImageColors
              });
            } else {
              payloadLogos.push({
                viewCode: IndividualisationViewCode.Front,
                fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                value: addedFabricLogoObjectsRef.current[logoKey].src,
                size: [
                  {
                    width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: '',
                fontFamily: '',
                imageColors: addedFabricLogoObjectsRef.current[logoKey].possibleImageColors
              });
            }
          }
        });
      }

      let payloadCuttingFormElements: {
        viewCode: IndividualisationViewCode | string,
        colorCode: string,
      }[] = [
        {
          viewCode: IndividualisationViewCode.Front,
          colorCode: selectedJerseySectionColor[JerseySection.Front],
        },
        {
          viewCode: IndividualisationViewCode.Back,
          colorCode: selectedJerseySectionColor[JerseySection.Back],
        },
        {
          viewCode: IndividualisationViewCode.SideLeft,
          colorCode: selectedJerseySectionColor[JerseySection.Left],
        },
        {
          viewCode: IndividualisationViewCode.SideRight,
          colorCode: selectedJerseySectionColor[JerseySection.Right],
        }
      ];

      if (selectedJerseySectionColor[JerseySection.Collar]) {
        payloadCuttingFormElements = [
          ...payloadCuttingFormElements,
          {
            viewCode: 'Collar',
            colorCode: selectedJerseySectionColor[JerseySection.Collar],
          }
        ];
      }
      if (selectedJerseySectionColor[JerseySection.CuffLeft]) {
        payloadCuttingFormElements = [
          ...payloadCuttingFormElements,
          {
            viewCode: 'CuffLeft',
            colorCode: selectedJerseySectionColor[JerseySection.CuffLeft],
          }
        ];
      }
      if (selectedJerseySectionColor[JerseySection.CuffRight]) {
        payloadCuttingFormElements = [
          ...payloadCuttingFormElements,
          {
            viewCode: 'CuffRight',
            colorCode: selectedJerseySectionColor[JerseySection.CuffRight],
          }
        ];
      }
      if (selectedJerseySectionColor[JerseySection.CuffRight]) {
        payloadCuttingFormElements = [
          ...payloadCuttingFormElements,
          {
            viewCode: 'CuffRight',
            colorCode: selectedJerseySectionColor[JerseySection.CuffRight],
          }
        ];
      }

      // shirt design possible color
      if (onlyPossibleDesignAreas) {
        Object.keys(onlyPossibleDesignAreas).forEach((designKey) => {
          payloadCuttingFormElements = [
            ...payloadCuttingFormElements,
            {
              viewCode: designKey,
              colorCode: selectedJerseySectionColor[designKey],
            }
          ];
        });
      }

      // const mainWhite =  sortedColorListRef.current.find((item) => item.code === '3D_WEISS');
      // const mainJakoBlue = sortedColorListRef.current.find((item) => item.code === '3D_JAKO_BLAU');
      // const mainMarine = sortedColorListRef.current.find((item) => item.code === '3D_MARINE');
      const mainSchwarz = sortedColorListRef.current.find((item) => item.code === '3D_SCHWARZ');
      Object.keys(selectedJerseySectionPattern).forEach((itemKey) => {
        payloadCuttingFormElements = [
          ...payloadCuttingFormElements,
          {
            viewCode: 'Pattern_' + itemKey,
            colorCode: selectedJerseySectionPattern[itemKey].fill || (mainSchwarz ? '#'+mainSchwarz.colorHexCode : '#26282D'),
          }
        ];
      });

      const correlationId = contextCorrelationId  || '111';
      const wc = document.querySelector('jako-configurator');
      const wcVisitorId = wc
        ? wc.getAttribute('visitorId')
        : null;
      const visitorId = wcVisitorId
        ? wcVisitorId
        : contextVisitorId || '222';
      const company = contextCompany || 'JAKO AG';
      const shopCode = contextShopCode || 'B2C';
      const languageCode = selectedLang.current.name || contextLanguageCode;
      const screenshots = await getDefaultScreenShot(isSaveBeforeSubmitAddToCart);
      return JakoServices.putIndividualisation({
        correlationId,
        visitorId: parseInt(visitorId),
        itemNo: currentCuttingFormItemNoRef.current,
        cuttingFormCode: jakoCurrentCuttingFormCode,
        configurationName,
        company,
        shopCode,
        languageCode,
        isPrintable: !hasUnprocessableImage,
        designLines: [
          ...payloadTexts,
          ...payloadLogos,
          // ...payloadPatterns,
          // ...payloadShirtColors,
        ],
        previewImages: [
          {
            viewCode: IndividualisationViewCode.Front,
            value: screenshots[0]
          },
          {
            viewCode: IndividualisationViewCode.Back,
            value: screenshots[1]
          },
          {
            viewCode: IndividualisationViewCode.SideLeft,
            value: screenshots[2]
          },
          {
            viewCode: IndividualisationViewCode.SideRight,
            value: screenshots[3]
          }
        ],
        cuttingFormElements: payloadCuttingFormElements
      });
    } catch (err) {
      setIsLoading(false);
      return undefined;
    }
  }

  async function updateJakoIndividualisation(designHeaderId: string, isSaveBeforeSubmitAddToCart?: boolean) {
    try {
      if (currentCanvasGLRef.current) {
        let payloadTexts: any[] = [];
        let payloadLogos: any[] = [];
        if (Object.keys(addedFabricTextObjectsRef.current).length) {
          Object.keys(addedFabricTextObjectsRef.current).forEach((textKey, index) => {
            if (addedFabricTextObjectsRef.current[textKey].foundedPositionPart && addedFabricTextObjectsRef.current[textKey].foundedPositionPart.toLowerCase().includes('front')) {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.Front,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill.replace('#', ''),
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            } else if (addedFabricTextObjectsRef.current[textKey].foundedPositionPart && addedFabricTextObjectsRef.current[textKey].foundedPositionPart.toLowerCase().includes('back')) {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.Back,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill.replace('#', ''),
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            } else if (addedFabricTextObjectsRef.current[textKey].foundedPositionPart && addedFabricTextObjectsRef.current[textKey].foundedPositionPart.toLowerCase().includes('left')) {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.SideLeft,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill.replace('#', ''),
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            } else if (addedFabricTextObjectsRef.current[textKey].foundedPositionPart && addedFabricTextObjectsRef.current[textKey].foundedPositionPart.toLowerCase().includes('right')) {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.SideRight,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill.replace('#', ''),
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            } else {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.Front,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill.replace('#', ''),
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            }
          });
        }
        if (Object.keys(addedFabricLogoObjectsRef.current).length) {
          Object.keys(addedFabricLogoObjectsRef.current).forEach((logoKey) => {
            if (
              !(addedFabricLogoObjectsRef.current[logoKey].x === 0 || addedFabricLogoObjectsRef.current[logoKey].y === 0)
                && addedFabricLogoObjectsRef.current[logoKey].reference
            ) {
              if (addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart && addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart.toLowerCase().includes('front')) {
                payloadLogos.push({
                  viewCode: IndividualisationViewCode.Front,
                  fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                  value: addedFabricLogoObjectsRef.current[logoKey].src,
                  size: [
                    {
                      width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                      height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                    }
                  ],
                  fontColorCode: '',
                  fontFamily: '',
                  imageColors: addedFabricLogoObjectsRef.current[logoKey].possibleImageColors
                });
              } else if (addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart && addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart.toLowerCase().includes('back')) {
                payloadLogos.push({
                  viewCode: IndividualisationViewCode.Back,
                  fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                  value: addedFabricLogoObjectsRef.current[logoKey].src,
                  size: [
                    {
                      width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                      height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                    }
                  ],
                  fontColorCode: '',
                  fontFamily: '',
                  imageColors: addedFabricLogoObjectsRef.current[logoKey].possibleImageColors
                });
              } else if (addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart && addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart.toLowerCase().includes('left')) {
                payloadLogos.push({
                  viewCode: IndividualisationViewCode.SideLeft,
                  fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                  value: addedFabricLogoObjectsRef.current[logoKey].src,
                  size: [
                    {
                      width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                      height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                    }
                  ],
                  fontColorCode: '',
                  fontFamily: '',
                  imageColors: addedFabricLogoObjectsRef.current[logoKey].possibleImageColors
                });
              } else if (addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart && addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart.toLowerCase().includes('right')) {
                payloadLogos.push({
                  viewCode: IndividualisationViewCode.SideRight,
                  fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                  value: addedFabricLogoObjectsRef.current[logoKey].src,
                  size: [
                    {
                      width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                      height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                    }
                  ],
                  fontColorCode: '',
                  fontFamily: '',
                  imageColors: addedFabricLogoObjectsRef.current[logoKey].possibleImageColors
                });
              } else {
                payloadLogos.push({
                  viewCode: IndividualisationViewCode.Front,
                  fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                  value: addedFabricLogoObjectsRef.current[logoKey].src,
                  size: [
                    {
                      width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                      height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                    }
                  ],
                  fontColorCode: '',
                  fontFamily: '',
                  imageColors: addedFabricLogoObjectsRef.current[logoKey].possibleImageColors
                });
              }
            }
          });
        }
        let payloadCuttingFormElements: {
          viewCode: IndividualisationViewCode | string,
          colorCode: string,
        }[] = [
          {
            viewCode: IndividualisationViewCode.Front,
            colorCode: selectedJerseySectionColor[JerseySection.Front],
          },
          {
            viewCode: IndividualisationViewCode.Back,
            colorCode: selectedJerseySectionColor[JerseySection.Back],
          },
          {
            viewCode: IndividualisationViewCode.SideLeft,
            colorCode: selectedJerseySectionColor[JerseySection.Left],
          },
          {
            viewCode: IndividualisationViewCode.SideRight,
            colorCode: selectedJerseySectionColor[JerseySection.Right],
          }
        ];
        if (selectedJerseySectionColor[JerseySection.Collar]) {
          payloadCuttingFormElements = [
            ...payloadCuttingFormElements,
            {
              viewCode: 'Collar',
              colorCode: selectedJerseySectionColor[JerseySection.Collar],
            }
          ];
        }
        if (selectedJerseySectionColor[JerseySection.CuffLeft]) {
          payloadCuttingFormElements = [
            ...payloadCuttingFormElements,
            {
              viewCode: 'CuffLeft',
              colorCode: selectedJerseySectionColor[JerseySection.CuffLeft],
            }
          ];
        }
        if (selectedJerseySectionColor[JerseySection.CuffRight]) {
          payloadCuttingFormElements = [
            ...payloadCuttingFormElements,
            {
              viewCode: 'CuffRight',
              colorCode: selectedJerseySectionColor[JerseySection.CuffRight],
            }
          ];
        }
        if (selectedJerseySectionColor[JerseySection.CuffRight]) {
          payloadCuttingFormElements = [
            ...payloadCuttingFormElements,
            {
              viewCode: 'CuffRight',
              colorCode: selectedJerseySectionColor[JerseySection.CuffRight],
            }
          ];
        }
        // shirt design possible color
        if (onlyPossibleDesignAreas) {
          Object.keys(onlyPossibleDesignAreas).forEach((designKey) => {
            payloadCuttingFormElements = [
              ...payloadCuttingFormElements,
              {
                viewCode: designKey,
                colorCode: selectedJerseySectionColor[designKey],
              }
            ];
          });
        }
        // const mainWhite =  sortedColorListRef.current.find((item) => item.code === '3D_WEISS');
        // const mainJakoBlue = sortedColorListRef.current.find((item) => item.code === '3D_JAKO_BLAU');
        // const mainMarine = sortedColorListRef.current.find((item) => item.code === '3D_MARINE');
        const mainSchwarz = sortedColorListRef.current.find((item) => item.code === '3D_SCHWARZ');
        Object.keys(selectedJerseySectionPattern).forEach((itemKey) => {
          payloadCuttingFormElements = [
            ...payloadCuttingFormElements,
            {
              viewCode: 'Pattern_' + itemKey,
              colorCode: selectedJerseySectionPattern[itemKey].fill || (mainSchwarz ? '#'+mainSchwarz.colorHexCode : '#26282D'),
            }
          ];
        });
        const correlationId = contextCorrelationId  || '111';
        const screenshots = await getDefaultScreenShot(isSaveBeforeSubmitAddToCart);
        const wc = document.querySelector('jako-configurator');
        const wcVisitorId = wc
          ? wc.getAttribute('visitorId')
          : null;
        const visitorId = wcVisitorId
          ? wcVisitorId
          : contextVisitorId || '222';
        return JakoServices.postIndividualisation({
          correlationId,
          designHeaderId: parseInt(designHeaderId),
          cuttingFormCode: jakoCurrentCuttingFormCode,
          itemNo: currentCuttingFormItemNoRef.current,
          configurationName: updateSaveConfigDataRef.current ? updateSaveConfigDataRef.current.configurationName : 'unknown_configurationName_testjfnet',
          isPrintable: !hasUnprocessableImage,
          visitorId: parseInt(visitorId),
          designLines: [
            ...payloadTexts,
            ...payloadLogos,
          ],
          previewImages: [
            {
              viewCode: IndividualisationViewCode.Front,
              value: screenshots[0]
            },
            {
              viewCode: IndividualisationViewCode.Back,
              value: screenshots[1]
            },
            {
              viewCode: IndividualisationViewCode.SideLeft,
              value: screenshots[2]
            },
            {
              viewCode: IndividualisationViewCode.SideRight,
              value: screenshots[3]
            }
          ],
          cuttingFormElements: payloadCuttingFormElements
        });
      }
    } catch {
      setIsLoading(false);
    }
  }

  async function saveConfiguration({
    configurationName,
    designHeaderId,
    isSaveBeforeSubmitAddToCart,
    isTestSave
  }: {
    configurationName: string,
    designHeaderId: string,
    isSaveBeforeSubmitAddToCart?: boolean | undefined,
    isTestSave?: boolean,
  }) {
    const fabricObjects = configuratorRef.current
      ? configuratorRef.current.fabricCanvas.current
        ? configuratorRef.current.fabricCanvas.current._objects
        : []
      : [];
    let tmpLogoObjects = {} as AddedLogoItems;
    Object.keys(addedFabricLogoObjectsRef.current).forEach((itemKey) => {
      if (addedFabricLogoObjectsRef.current[itemKey].x !== 0
        && addedFabricLogoObjectsRef.current[itemKey].y !== 0
        && addedFabricLogoObjectsRef.current[itemKey].reference
      ) {
        // Clear src to empty when saving. When we load the save it will be set again in onAddImageLogoInitSrc()
        tmpLogoObjects = {
          ...tmpLogoObjects,
          [itemKey]: {
            ...addedFabricLogoObjectsRef.current[itemKey],
            src: '',
            indexLayer: fabricObjects.findIndex((item) => item.name === itemKey)
          }
        };
      }
    });

    let tmpTextObjects = {} as AddedTextItems;
    for (const itemKey of Object.keys(addedFabricTextObjectsRef.current)) {
      const textObj = addedFabricTextObjectsRef.current[itemKey];
      if (textObj.x !== 0 && textObj.y !== 0) {
        if (textObj.reference) {
          tmpTextObjects = {
            ...tmpTextObjects,
            [itemKey]: {
              ...textObj,
              indexLayer: fabricObjects.findIndex((item) => item.name === itemKey),
              svgString: ''
            },
          };
        } else {
          const file = new File([textObj.svgString], 'tmptext.svg', {
            type: 'image/svg+xml'
          });
          const response = await JfnetServices.checkLogoUpload(file);
          if (response.status === 200 && response.data) {
            tmpTextObjects = {
              ...tmpTextObjects,
              [itemKey]: {
                ...textObj,
                indexLayer: fabricObjects.findIndex((item) => item.name === itemKey),
                svgString: '',
                reference: response.data.reference || '',
              },
            };
          }
        }
      }
    }
    let selectedJerseySectionPatternPayload = {};
    Object.keys(selectedJerseySectionPattern).forEach((key) => {
      if (selectedJerseySectionPattern[key].filename) {
        selectedJerseySectionPatternPayload = {
          ...selectedJerseySectionPatternPayload,
          [key]: {
            ...selectedJerseySectionPattern[key],
            svg: ''
          }
        };
      }
    });
    const wc = document.querySelector('jako-configurator');
    const wcVisitorId = wc
      ? wc.getAttribute('visitorId')
      : null;
    const visitorId = wcVisitorId
      ? wcVisitorId
      : contextVisitorId || '222';
    const payload: SaveConfigState = {
      designHeaderId,
      visitorId,
      configurationName,
      selectedModel: {
        cuttingFormCode: jakoCurrentCuttingFormCode,
        templateCode: jfnetCuttingFormCode,
        glbFile: cuttingFileModelData ? cuttingFileModelData.glbFile : '',
        glbSeparateFile: cuttingFileModelData ? cuttingFileModelData.glbSeparateFile : '',
        normalMapFile: cuttingFileModelData ? cuttingFileModelData.normalMapFile : '',
        dotsLeftFile: cuttingFileModelData ? cuttingFileModelData.dotsLeftFile : '',
        dotsRightFile: cuttingFileModelData ? cuttingFileModelData.dotsRightFile : '',
        logoLeftFile: cuttingFileModelData ? cuttingFileModelData.logoLeftFile : '',
        logoRightFile: cuttingFileModelData ? cuttingFileModelData.logoRightFile : '',
        logoCenterFile: cuttingFileModelData ? cuttingFileModelData.logoCenterFile : '',
      },
      selectedDesign: selectedDesign,
      colorLogoHexRef: colorLogoHexRef.current,
      colorDotsLeftHexRef: colorDotsLeftHexRef.current,
      colorDotsRightHexRef: colorDotsRightHexRef.current,
      colorInvertedBgRef: colorInvertedBgRef.current,
      selectedJerseySectionColor,
      selectedJerseySectionPattern: selectedJerseySectionPatternPayload,
      addedFabricLogoObjects: tmpLogoObjects,
      addedFabricTextObjects: tmpTextObjects,
      jakoLogoPosition: jakoLogoPositionRef.current,
      hasBackDesign: hasBackDesign,
      activateDesignOnBack: activateDesignOnBack,
    };
    const jfnetSavePayloadString = JSON.stringify(payload);
    const base64Payload = Base64.encode(jfnetSavePayloadString);

    console.log('payload', payload);
    if (window.location.hostname.includes('localhost')) return;

    // if (isTestSave) {
    //   if (designHeaderIdJWTRef.current) {
    //     return JfnetServices.saveConfigsWithJwt('0', base64Payload, designHeaderIdJWTRef.current);
    //   } else {
    //     return JfnetServices.saveConfigs('0', base64Payload);
    //   }
    // }

    const saveRequest = designHeaderIdJWTRef.current
      ? JfnetServices.saveConfigsWithJwt(designHeaderId.toString(), base64Payload, designHeaderIdJWTRef.current)
      : JfnetServices.saveConfigs(designHeaderId.toString(), base64Payload);

    return new Promise((resolve, reject) => {
      saveRequest
        .then(() => {
          updateSaveConfigDataRef.current = payload;
          initialSaveConfigDataRef.current = payload;
          // save SVG result file to jfnet API
          getSvgResultFile()
            .then(async (blobRes) => {
              if (blobRes) {
                if (!configuratorRef.current) {
                  return Promise.resolve();
                }
                if (!configuratorRef.current.fabricCanvas.current) {
                  return Promise.resolve();
                }
                let configElementsOrmPayload = {} as ConfigElementsOrm;
                const patternConfigs = await configuratorRef.current.getPatternConfigs(selectedJerseySectionPattern);
                const designSvgPatternObjects = configuratorRef.current.designSvgPatternObject.current._objects;
                const onlyMainAreaKeys = designSvgPatternObjects.filter((object: any) => !object.id.includes('bg-') && !object.id.includes('copy-')).map((item: any) => item.id);
                let areas: AreaSurface[] = [];
                for (const areaKey of onlyMainAreaKeys) {
                  if (!configuratorRef.current) {
                    return Promise.resolve();
                  }
                  if (!configuratorRef.current.fabricCanvas.current) {
                    return Promise.resolve();
                  }
                  const sectionObject = configuratorRef.current.designSvgPatternObject.current._objects.find((item: any) => item.id === areaKey);
                  if (sectionObject) {
                    const sectionObjectTop = sectionObject.pathOffset.y - sectionObject.height / 2;
                    const sectionObjectWidth = sectionObject.width as number;
                    const sectionObjectLeft = sectionObject.pathOffset.x - sectionObject.width / 2;
                    const sectionObjectHeight = sectionObject.height as number;
                    const fabricObjects = configuratorRef.current.fabricCanvas.current._objects;
                    let elements: AreaElement[] = [];
                    let pattern: AreaElementPattern | null = {
                      content: '',
                      scale: 1,
                      top: 0,
                      left: 0,
                      tileable: true,
                      color: '',
                      colorCode: '',
                      offsetX: 0,
                      offsetY: 0,
                      width: 0,
                      height: 0,
                      offsetXBackendCalculated: 0,
                      offsetYBackendCalculated: 0,
                      widthBackendCalculated: 0,
                      heightBackendCalculated: 0,
                    };
                    let index = 0;
                    for (const obj of fabricObjects) {
                      const object = obj as any;
                      if (object.text) {
                        const textObj = payload.addedFabricTextObjects[object.name];
                        if (textObj.foundedPositionPart === areaKey) {
                          const foundedColorItem = sortedColorListRef.current.find((colorItem) => textObj.fill && textObj.fill.includes(colorItem.colorHexCode));
                          const colorCode = foundedColorItem
                            ? foundedColorItem.code_internal
                            : '';
                          const textType = ALL_ELEMENT_TABS.find((item) => item.functionTab === textObj.functionTab);
                          const pointFromLeftTopOrigin = object.getPointByOrigin('left', 'top');
                          const pointFromCenterCenterOrigin = object.getPointByOrigin('center', 'center');
                          if (textObj.reference && textObj.reference.length) {
                            elements.push({
                              order: index - 1,
                              type: 'text',
                              subType: textType
                                ? textType.fieldCode || ''
                                : '',
                              content: textObj.text,
                              font: textObj.fontFamily || '',
                              width: textObj.scaledWidth * basedCmFactor,
                              height: textObj.scaledHeight * basedCmFactor,
                              widthCm: textObj.scaledWidth,
                              heightCm: textObj.scaledHeight,
                              top: pointFromLeftTopOrigin.y,
                              left: pointFromLeftTopOrigin.x,
                              cy: pointFromCenterCenterOrigin.y,
                              cx: pointFromCenterCenterOrigin.x,
                              color: textObj.fill,
                              colorCode,
                              reference: textObj.reference,
                              rotation: textObj.rotation
                            });
                          }
                        }
                      } else if (object.name) {
                        const getFunctionTab = parseInt(object.name.split('tab')[1]) as FunctionTab;
                        if (getFunctionTab === FunctionTab.Logo) {
                          const logoObj = payload.addedFabricLogoObjects[object.name];
                          if (logoObj.foundedPositionPart === areaKey && logoObj.reference) {
                            const pointFromLeftTopOrigin = object.getPointByOrigin('left', 'top');
                            const pointFromCenterCenterOrigin = object.getPointByOrigin('center', 'center');
                            elements.push({
                              order: index - 1,
                              type: 'logo',
                              subType: '3D_LOGO',
                              font: null,
                              content: logoObj.fileName,
                              width: logoObj.scaledWidth * basedCmFactor,
                              height: logoObj.scaledHeight * basedCmFactor,
                              widthCm: logoObj.scaledWidth,
                              heightCm: logoObj.scaledHeight,
                              top: pointFromLeftTopOrigin.y,
                              left: pointFromLeftTopOrigin.x,
                              cy: pointFromCenterCenterOrigin.y,
                              cx: pointFromCenterCenterOrigin.x,
                              reference: logoObj.reference,
                              rotation: logoObj.rotation,
                              color: '',
                              colorCode: '',
                            });
                          }
                        }
                      }
                      index++;
                    };
                    const patternAreaItem = payload.selectedJerseySectionPattern[areaKey];
                    const configPatternAreaItem = patternConfigs ? patternConfigs[areaKey] : null;
                    if (patternAreaItem) {
                      if (patternAreaItem.filename) {
                        const foundedColorItem = sortedColorListRef.current.find((colorItem) => patternAreaItem.fill && patternAreaItem.fill.includes(colorItem.colorHexCode));
                        const colorCode = foundedColorItem
                          ? foundedColorItem.code_internal
                          : '';
                        pattern = {
                          width: configPatternAreaItem ? (configPatternAreaItem.width || 0) : 0,
                          height: configPatternAreaItem ? (configPatternAreaItem.height || 0) : 0,
                          offsetX: configPatternAreaItem ? (configPatternAreaItem.offsetX || 0) : 0,
                          offsetY: configPatternAreaItem ? (configPatternAreaItem.offsetY || 0) : 0,
                          offsetXBackendCalculated: configPatternAreaItem ? (configPatternAreaItem.offsetXBackendCalculated || 0) : 0,
                          offsetYBackendCalculated: configPatternAreaItem ? (configPatternAreaItem.offsetYBackendCalculated || 0) : 0,
                          widthBackendCalculated: configPatternAreaItem ? (configPatternAreaItem.widthBackendCalculated || 0) : 0,
                          heightBackendCalculated: configPatternAreaItem ? (configPatternAreaItem.heightBackendCalculated || 0) : 0,
                          content: patternAreaItem.filename || '',
                          scale: patternAreaItem.scale || 1,
                          top: patternAreaItem.top || 0,
                          left: patternAreaItem.left || 0,
                          tileable: patternAreaItem.tileable,
                          color: patternAreaItem.fill || '',
                          colorCode
                        };
                      } else {
                        pattern = null;
                      }
                    }
                    const foundedColorItem = sortedColorListRef.current.find((colorItem) => payload.selectedJerseySectionColor[areaKey] && payload.selectedJerseySectionColor[areaKey].includes(colorItem.colorHexCode));
                    const colorCode = foundedColorItem
                      ? foundedColorItem.code_internal
                      : '';
                    areas = [
                      ...areas,
                      {
                        name: areaKey,
                        top: sectionObjectTop,
                        left: sectionObjectLeft,
                        width: sectionObjectWidth,
                        height: sectionObjectHeight,
                        widthCm: sectionObjectWidth / basedCmFactor,
                        heightCm: sectionObjectHeight/ basedCmFactor,
                        color: payload.selectedJerseySectionColor[areaKey],
                        colorCode,
                        elements,
                        pattern,
                      }
                    ];
                  }
                };

                const designSurfaces = areas.slice().filter((item) => item.name.includes('design-'));
                let frontDesigns: AreaSurface[] | null = null;
                let backDesigns: AreaSurface[] | null = null;
                if (designSurfaces.length > 0) {
                  frontDesigns = designSurfaces.slice().filter((item) => item.name.includes('_front'));
                  backDesigns = designSurfaces.slice().filter((item) => item.name.includes('_back'));
                }
                let surfaces: DesignSurfaceDefinition[] = [];
                if (frontDesigns && frontDesigns.length) {
                  frontDesigns.forEach((item) => {
                    const foundedColorItem = sortedColorListRef.current.find((colorItem) => item.color && item.color.includes(colorItem.colorHexCode));
                    const colorCode = foundedColorItem
                      ? foundedColorItem.code_internal
                      : '';
                    surfaces.push({
                      name: item.name,
                      color: item.color,
                      colorCode,
                      position: "front",
                      pattern: item.pattern,
                    });
                  });
                }
                if (backDesigns && backDesigns.length) {
                  backDesigns.forEach((item) => {
                    const foundedColorItem = sortedColorListRef.current.find((colorItem) => item.color && item.color.includes(colorItem.colorHexCode));
                    const colorCode = foundedColorItem
                      ? foundedColorItem.code_internal
                      : '';
                    surfaces.push({
                      name: item.name,
                      color: item.color,
                      position: "back",
                      colorCode,
                      pattern: item.pattern,
                    });
                  });
                }

                let jakoLogoPositionText;
                let jakoLogoPositionFile;
                if (payload.jakoLogoPosition === JakoLogoPosition.Left) {
                  jakoLogoPositionText = 'left';
                  jakoLogoPositionFile = payload.selectedModel.logoLeftFile;
                } else if (payload.jakoLogoPosition === JakoLogoPosition.Center) {
                  jakoLogoPositionText = 'center';
                  jakoLogoPositionFile = payload.selectedModel.logoCenterFile;
                } else {
                  jakoLogoPositionText = 'right';
                  jakoLogoPositionFile = payload.selectedModel.logoRightFile;
                }

                const foundedColorItemJakoLogoFill = sortedColorListRef.current.find((colorItem) => payload.colorLogoHexRef && payload.colorLogoHexRef.includes(colorItem.colorHexCode));
                const fillColorCodeJakoLogo = foundedColorItemJakoLogoFill
                  ? foundedColorItemJakoLogoFill.code_internal
                  : '';
                const foundedColorItemJakoLogoStroke = sortedColorListRef.current.find((colorItem) => payload.selectedJerseySectionColor[JerseySection.Front] && payload.selectedJerseySectionColor[JerseySection.Front].includes(colorItem.colorHexCode));
                const strokeColorCodeJakoLogo = foundedColorItemJakoLogoStroke
                  ? foundedColorItemJakoLogoStroke.code_internal
                  : '';
                const foundedColorItemJakoDotsLeftFill = sortedColorListRef.current.find((colorItem) => payload.colorDotsLeftHexRef && payload.colorDotsLeftHexRef.includes(colorItem.colorHexCode));
                const fillColorCodeJakoDotsLeft = foundedColorItemJakoDotsLeftFill
                  ? foundedColorItemJakoDotsLeftFill.code_internal
                  : '';
                const foundedColorItemJakoDotsRightFill = sortedColorListRef.current.find((colorItem) => payload.colorDotsRightHexRef && payload.colorDotsRightHexRef.includes(colorItem.colorHexCode));
                const fillColorCodeJakoDotsRight = foundedColorItemJakoDotsRightFill
                  ? foundedColorItemJakoDotsRightFill.code_internal
                  : '';
                const nonDesignSurfaces = areas.slice().filter((item) => !item.name.includes('design-'));

                configElementsOrmPayload = {
                  designHeaderId: parseInt(payload.designHeaderId),
                  templateCode: payload.selectedModel.templateCode,
                  cuttingFormCode: payload.selectedModel.cuttingFormCode,
                  jakoLogo: {
                    position: jakoLogoPositionText,
                    fillColor: payload.colorLogoHexRef,
                    fillColorCode: fillColorCodeJakoLogo,
                    file: jakoLogoPositionFile,
                    strokeWidth: 0.4,
                    strokeColor: payload.selectedJerseySectionColor[JerseySection.Front],
                    strokeColorCode: strokeColorCodeJakoLogo,
                  },
                  jakoDots: payload.selectedModel.dotsLeftFile && payload.selectedModel.dotsRightFile
                    ? {
                      left: {
                        file: payload.selectedModel.dotsLeftFile,
                        fillColor: payload.colorDotsLeftHexRef,
                        fillColorCode: fillColorCodeJakoDotsLeft,
                      },
                      right: {
                        file: payload.selectedModel.dotsRightFile,
                        fillColor: payload.colorDotsRightHexRef,
                        fillColorCode: fillColorCodeJakoDotsRight,
                      }
                    }
                    : null,
                  // if it is not a Blanko then it should add the surfaces in the design
                  design: frontDesigns && frontDesigns.length && payload.selectedDesign
                    ? {
                      name: payload.selectedDesign.name || '',
                      file: payload.selectedDesign.file,
                      surfaces,
                    }
                    : payload.selectedDesign
                      ? {
                        name: payload.selectedDesign.name || '',
                        file: payload.selectedDesign.file,
                        surfaces: [],
                      }
                      : {
                        name: 'ERROR',
                        file: 'ERROR',
                        surfaces: [],
                      },
                  surfaces: nonDesignSurfaces,
                };

                if (designHeaderIdJWTRef.current) {
                  // Request print-config API
                  JfnetServices.printConfigWithJwt(configElementsOrmPayload, designHeaderIdJWTRef.current)
                  .then(() => {
                    resolve('');
                  })
                  .catch(() => {
                    reject();
                  });
                } else {
                  // Request print-config API
                  JfnetServices.printConfig(configElementsOrmPayload)
                    .then(() => {
                      resolve('');
                    })
                    .catch(() => {
                      reject();
                    });
                }
              }
            })
            .catch((err) => {
              reject();
            });
        }).catch((err) => {
          reject();
        });
    });
  }

  async function handleSaveConfiguration(isSaveBeforeSubmitAddToCart?: boolean, isRefresh?: boolean) {
    function onCloseCallback(closeFn: () => void) {
      if (isRefresh) {
        window.location.reload();
      } else {
        closeFn();
      }
    }

    function onSubmitSaveWithName(e: any, closeFn: () => void, isSaveCopy?: boolean) {
      if (e) {
        e.preventDefault();
      }
      const shadowRootContainer = document.querySelector('jako-configurator');
      const shadowRoot = shadowRootContainer ? shadowRootContainer.shadowRoot : null;
      const el = shadowRoot
        ? shadowRoot.getElementById('confirmAddName') || document.getElementById('confirmAddName')
        : document.getElementById('confirmAddName') as any;
      const name: string = el ? el.value : '';
      if (!name) {
        return;
      }

      onSetLoading(true);
      closeFn();

      // remove save JWT because it will can't verify a new one. It's for the first open one
      designHeaderIdJWTRef.current = null;
      const adjustedName = isSaveCopy ? name + '_(1)' : name;
      const response = createJakoIndividualisation(adjustedName, isSaveBeforeSubmitAddToCart);
      if (response) {
        response.then((res) => {
          if (!res) {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                    <h5 className="confirm-popup-container__title">
                      { t('configurator_3d_error_something_wrong') }
                    </h5>
                    <button className="button_primary" onClick={() => onCloseCallback(onClose)}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
            });
            onSetLoading(false);
            return;
          }
          saveConfiguration({
            designHeaderId: res.data.designHeaderId,
            configurationName: adjustedName,
            isSaveBeforeSubmitAddToCart
          }).then(() => {
            if (isRefresh) {
              window.location.reload();
            } else if (isSaveBeforeSubmitAddToCart) {
              onCompleteConfiguration();
            } else {
              onSetLoading(false);
            }
          }).catch(() => {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                    <h5 className="confirm-popup-container__title">
                      { t('configurator_3d_error_something_wrong') }
                    </h5>
                    <button className="button_primary" onClick={onClose}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
            });
            onSetLoading(false);
          });
        })
        .catch((err) => {
          closeFn();
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                  <h5 className="confirm-popup-container__title">
                    { t('configurator_3d_error_something_wrong') }
                  </h5>
                  <button className="button_primary" onClick={() => onCloseCallback(onClose)}>
                    { t('configurator_3d_ok') }
                  </button>
                </div>
              );
            }
          });
          onSetLoading(false);
        });
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                <h5 className="confirm-popup-container__title">
                  { t('configurator_3d_error_something_wrong') }
                </h5>
                <button className="button_primary" onClick={() => onCloseCallback(onClose)}>
                  { t('configurator_3d_ok') }
                </button>
              </div>
            );
          }
        });
        onSetLoading(false);
      }
    }

    function confirmAddNameHandlerSaveNew() {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="confirm-popup-container">
              <div className="confirm-popup__save-container">
                <i className="save-popup__icon">
                  <FontAwesomeIcon icon={faSave}  />
                </i>
                <h5 className="save-popup__title">
                  { t('configurator_3d_confirm_save_add_name_title') }
                </h5>
                <p className="text-[#141414] mb-5" dangerouslySetInnerHTML={{__html: t('configurator_3d_confirm_save_add_name_description').toString() || '' }} />
              </div>
              <form onSubmit={(e) => onSubmitSaveWithName(e, onClose)}>
                <input id="confirmAddName" type="text" className="mb-4" placeholder={t('configurator_3d_please_enter_name').toString()} />
                <div className="confirm-popup__func-container">
                  <button className="outlineButton" type="button" onClick={() => onCloseCallback(onClose)}>{ t('configurator_3d_cancel') }</button>
                  <button type="submit" className="button_primary">
                  { t('configurator_3d_submit') }
                  </button>
                </div>
              </form>
            </div>
          );
        },
        closeOnClickOutside: false,
        closeOnEscape: false
      });
    }

    function onSaveCurrent(e: any, closeFn: () => void, initialSaveConfigData: SaveConfigState | null, updateSaveConfigData: SaveConfigState | null) {
      if (!initialSaveConfigData) {
        closeFn();
        onSetLoading(false);
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                <h5 className="confirm-popup-container__title">
                  { t('configurator_3d_error_something_wrong') }
                </h5>
                <button className="button_primary" onClick={() => onCloseCallback(onClose)}>
                  { t('configurator_3d_ok') }
                </button>
              </div>
            );
          }
        });
        return;
      }
      if (!updateSaveConfigData) {
        closeFn();
        onSetLoading(false);
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                <h5 className="confirm-popup-container__title">
                  { t('configurator_3d_error_something_wrong') }
                </h5>
                <button className="button_primary" onClick={() => onCloseCallback(onClose)}>
                  { t('configurator_3d_ok') }
                </button>
              </div>
            );
          }
        });
        return;
      }

      e.preventDefault();
      onSetLoading(true);
      closeFn();

      let adjustedDesignHeaderId = updateSaveConfigData.designHeaderId;
      if (updateSaveConfigData.isFromCustomDesign) {
        adjustedDesignHeaderId = initialSaveConfigData.designHeaderId;
      }

      try {
        const response = updateJakoIndividualisation(adjustedDesignHeaderId, isSaveBeforeSubmitAddToCart);
        if (response) {
          response.then(async (res) => {
            if (res && res.data && res.data.designHeaderId && updateSaveConfigData) {
              const newUpdateDesignHeaderId = res.data.designHeaderId.toString();
              try {
                await saveConfiguration({
                  designHeaderId: newUpdateDesignHeaderId,
                  configurationName: updateSaveConfigData.configurationName,
                  isSaveBeforeSubmitAddToCart
                });
                if (isRefresh) {
                  window.location.reload();
                } else if (isSaveBeforeSubmitAddToCart) {
                  onCompleteConfiguration();
                } else {
                  onSetLoading(false);
                }
                closeFn();
              } catch {
                closeFn();
                onSetLoading(false);
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                        <h5 className="confirm-popup-container__title">
                          { t('configurator_3d_error_something_wrong') }
                        </h5>
                        <button className="button_primary" onClick={() => onCloseCallback(onClose)}>
                          { t('configurator_3d_ok') }
                        </button>
                      </div>
                    );
                  }
                });
              }
            } else if (updateSaveConfigData) {
              try {
                await saveConfiguration({
                  designHeaderId: adjustedDesignHeaderId,
                  configurationName: updateSaveConfigData.configurationName,
                  isSaveBeforeSubmitAddToCart
                });
                if (isRefresh) {
                  window.location.reload();
                } else if (isSaveBeforeSubmitAddToCart) {
                  onCompleteConfiguration();
                } else {
                  onSetLoading(false);
                }
                closeFn();
              } catch {
                onSetLoading(false);
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                        <h5 className="confirm-popup-container__title">
                          { t('configurator_3d_error_something_wrong') }
                        </h5>
                        <button className="button_primary" onClick={() => onCloseCallback(onClose)}>
                          { t('configurator_3d_ok') }
                        </button>
                      </div>
                    );
                  }
                });
                closeFn();
              }
            }
          });
        } else {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                  <h5 className="confirm-popup-container__title">
                    { t('configurator_3d_error_something_wrong') }
                  </h5>
                  <button className="button_primary" onClick={() => onCloseCallback(onClose)}>
                    { t('configurator_3d_ok') }
                  </button>
                </div>
              );
            }
          });
          onSetLoading(false);
        }
      } catch (err) {
        closeFn();
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                <h5 className="confirm-popup-container__title">
                  { t('configurator_3d_error_something_wrong') }
                </h5>
                <button className="button_primary" onClick={() => onCloseCallback(onClose)}>
                  { t('configurator_3d_ok') }
                </button>
              </div>
            );
          }
        });
        onSetLoading(false);
      }
    }

    function confirmAddNameHandlerSaveNewOrCopy() {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="confirm-popup-container">
              <div className="confirm-popup__save-container">
                <i className="save-popup__icon">
                  <IconClone />
                </i>
                <h5 className="save-popup__title">
                  { t('configurator_3d_confirm_save_add_name_title') }
                </h5>
                <p className="text-[#141414] mb-5" dangerouslySetInnerHTML={{__html: t('configurator_3d_confirm_save_add_name_description').toString() || '' }} />
              </div>
              <form onSubmit={(e) => onSaveCurrent(e, onClose, initialSaveConfigDataRef.current, updateSaveConfigDataRef.current)}>
                <input
                  id="confirmAddName"
                  type="text"
                  className="mb-4"
                  placeholder={t('configurator_3d_please_enter_name').toString()}
                  defaultValue={updateSaveConfigDataRef.current ? updateSaveConfigDataRef.current.configurationName : ''}
                />
                <div className="confirm-popup__func-container">
                  <button className="outlineButton" type="button" onClick={() => onCloseCallback(onClose)}>{ t('configurator_3d_cancel') }</button>
                  <div className="confirm-popup__func-save-copy-container">
                    <button type="submit" className="button_primary">
                      { t('configurator_3d_submit') }
                    </button>
                    <button
                      type="button"
                      className="button_primary"
                      onClick={() => onSubmitSaveWithName(null, onClose, true)}
                    >
                      { t('configurator_3d_save_copy') }
                    </button>
                  </div>
                </div>
              </form>
            </div>
          );
        },
        closeOnClickOutside: false,
        closeOnEscape: false
      });
    }

    // initialSaveConfigDataRef is the loaded save data at the initial step
    // If it's null it means that this is a new config
    if (!initialSaveConfigDataRef.current || !updateSaveConfigDataRef.current) {
      confirmAddNameHandlerSaveNew();
      return;
    }

    confirmAddNameHandlerSaveNewOrCopy();
  }

  function getFormattedPrice(prices: {
    sizeCode: string,
    unitPrice: number,
    crossPrice: number,
  }[]) {
    if (prices.length === 0) {
      return '';
    }
    const foundedDisplayPrice = prices.find((item) => item.sizeCode === '104');
    const value = foundedDisplayPrice ? foundedDisplayPrice.unitPrice : prices[0].unitPrice;
    if (currencycode && currencycode.toString().toLowerCase() === 'chf') {
      return new Intl.NumberFormat('ch-CH', {
        style: 'currency',
        currency: 'CHF'
      }).format(value);
    }
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR'
    }).format(value);
  }

  async function localDevelopmentTest_handleSaveConfiguration(isSaveBeforeSubmitAddToCart?: boolean, isRefresh?: boolean) {
    function onSubmitSaveWithName(e: any, closeFn: () => void, isSaveCopy?: boolean) {
      if (e) {
        e.preventDefault();
      }

      const el = document.getElementsByTagName('jako-configurator')[0]
        ? document.getElementsByTagName('jako-configurator')[0].shadowRoot?.getElementById('confirmAddName')
          ? document.getElementsByTagName('jako-configurator')[0].shadowRoot?.getElementById('confirmAddName')
          : document.getElementById('confirmAddName')
        : document.getElementById('confirmAddName') as any;
      const name: string = el ? el.value : '';
      if (!name) {
        return;
      }

      onSetLoading(true);
      closeFn();
      const adjustedName = isSaveCopy ? name + '_(1)' : name;
      // const response = createJakoIndividualisation(adjustedName, isSaveBeforeSubmitAddToCart);
      const response = Promise.resolve();
      if (response) {
        response.then((res) => {
          saveConfiguration({
            designHeaderId: '111222333',
            configurationName: adjustedName,
            isSaveBeforeSubmitAddToCart
          }).then(() => {
            if (isRefresh) {
              window.location.reload();
            } else if (isSaveBeforeSubmitAddToCart) {
              localDevelopmentTest_onCompleteConfiguration();
            } else {
              onSetLoading(false);
            }
          });
        })
        .catch((err) => {
          console.log(err);
          closeFn();
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                  <h5 className="confirm-popup-container__title">
                    { t('configurator_3d_error_something_wrong') }
                  </h5>
                  <button className="button_primary" onClick={onClose}>
                    { t('configurator_3d_ok') }
                  </button>
                </div>
              );
            }
          });
          onSetLoading(false);
        });
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                <h5 className="confirm-popup-container__title">
                  { t('configurator_3d_error_something_wrong') }
                </h5>
                <button className="button_primary" onClick={onClose}>
                  { t('configurator_3d_ok') }
                </button>
              </div>
            );
          }
        });
        onSetLoading(false);
      }
    }

    function confirmAddNameHandlerSaveNew() {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="confirm-popup-container">
              <div className="confirm-popup__save-container">
                <i className="save-popup__icon">
                  <FontAwesomeIcon icon={faSave} />
                </i>
                <h5 className="save-popup__title">
                  { t('configurator_3d_confirm_save_add_name_title') }
                </h5>
                <p className="text-[#141414] mb-5" dangerouslySetInnerHTML={{__html: t('configurator_3d_confirm_save_add_name_description').toString() || '' }} />
              </div>
              <form onSubmit={(e) => onSubmitSaveWithName(e, onClose)}>
                <input id="confirmAddName" type="text" className="mb-4" placeholder={t('configurator_3d_please_enter_name').toString()} />
                <div className="confirm-popup__func-container">
                  <button className="outlineButton" type="button" onClick={onClose}>{ t('configurator_3d_cancel') }</button>
                  <button type="submit" className="button_primary">
                  { t('configurator_3d_submit') }
                  </button>
                </div>
              </form>
            </div>
          );
        },
        closeOnClickOutside: false,
        closeOnEscape: false
      });
    }

    confirmAddNameHandlerSaveNew();
  }

  async function localDevelopmentTest_automateGetDesignScreenShots() {
    setIsLoadingInitial(true);
    setIsSavingScreenshot(true);
    const urlParams = new URLSearchParams(window.location.search);
    const isLongarm = urlParams.get('testCutting')
      ? urlParams.get('testCutting')?.slice(0, 10).includes('LS')
      : cuttingOptions.current.length && cuttingOptions.current.find((item) => item.code === '3D_SCHNITTFORM' && item.value === 'LANGARM');

    if (!configuratorRef.current) {
      return Promise.resolve(['']);
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return Promise.resolve(['']);
    }
    if (!currentCanvasGLRef.current) {
      return Promise.resolve(['']);
    }

    const canvasEl = currentCanvasGLRef.current.domElement;
    cameraControlsRef.current?.setPosition(-0.06512612834137524, 0.866525151725151, 4.16553710195109, false);
    cameraControlsRef.current?.setTarget(0, 0.35, 0, false);
    // cameraControlsRef.current?.rotate(0 * MathUtils.DEG2RAD, 0, false);
    if (isLongarm) {
      cameraControlsRef.current?.dolly(-1, false);
    } else {
      cameraControlsRef.current?.dolly(0, false);
    }
    cameraControlsRef.current?.truck(0, 0, false);
    if (!cuttingFileModelData) {
      return Promise.resolve();
    }
    const sortedDesigns = cuttingFileModelData.designs.slice()
      .sort((a, b) => a.name.toString().localeCompare(b.name.toString()));

    let i = 0;
    for (const design of sortedDesigns) {
      setIsLoadingInitial(true);
      console.log(i+1, sortedDesigns.length);
      console.log(design.name);
      if (i === 0) {
        await delay(4000);
      }
      if (design) {
        await onSelectDesign(design);
        await delay(4000);
        if (ModelStyle.Short === selectModelStyle) {
          if (design.name.toLowerCase().includes('side')) {
            cameraControlsRef.current?.rotate(90 * MathUtils.DEG2RAD, 0, false);
            await delay(1000);
          }
        }
        const locFileSrc = canvasEl.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        const dwn = document.createElement('a');
        dwn.download = urlParams.get('testCutting') ? urlParams.get('testCutting') + '_' + design.name + '.png' : design.name + '.png';
        dwn.href = locFileSrc;
        dwn.click();
        console.log('Download', urlParams.get('testCutting') ? urlParams.get('testCutting') + '_' + design.name + '.png' : design.name + '.png');
        if (ModelStyle.Short === selectModelStyle) {
          if (design.name.toLowerCase().includes('side')) {
            cameraControlsRef.current?.rotate(-90 * MathUtils.DEG2RAD, 0, false);
            await delay(1000);
          }
        }
      }
      i++;
    }
    console.log('Screenshot End');
    return delay(2000).then(() => {
      window.location.href = '/?testCutting='+urlParams.get('testCutting');
      setIsLoadingInitial(false);
      setIsSavingScreenshot(false);
    });
  }

  // ---- End of Left side UI handler ----------
  return <main id="jako-configurator-3d-wc" className="jako configurator3D__wrapper">
    <div id="react-confirm-alert" />
    <div className="configurator__container">
      {/* <!-- Sidebar Steps --> */}
      {
        isLoadingCustomFields
          ? <></>
          : isMobile
            ? <div className="configurator__sidebarSteps">
                <SimpleBar
                  style={{
                    height: 84,
                    width: '100%',
                  }}
                  autoHide={false}
                >
                {
                  menuTabFiltered.map((tabItem, index) =>
                    tabItem.fieldCode
                      ? customFields.length && customFields.find((field) => field.code === tabItem.fieldCode)
                        ? <div
                          key={'sidebarStepCustomField' + index + tabItem.fieldCode}
                          className={
                            "sidebarStep__item"
                            + (selectTab === tabItem.functionTab ? ' active' : '')
                          }
                          onClick={() => onSetSelectTabHandler(tabItem.functionTab, index)}
                        >
                          <div className="sidebarStep__icon">
                            <FontAwesomeIcon icon={tabItem.icon}/>
                          </div>
                          <div className="sidebarStep__description">
                            { t(tabItem.label) }
                          </div>
                        </div>
                        : <></>
                      : <div
                        key={'sidebarStep' + index + tabItem.label}
                        className={
                          "sidebarStep__item"
                          + (selectTab === tabItem.functionTab ? ' active' : '')
                        }
                        onClick={() => onSetSelectTabHandler(tabItem.functionTab, index)}
                      >
                        <div className="sidebarStep__icon">
                          <FontAwesomeIcon icon={tabItem.icon}/>
                        </div>
                        <div className="sidebarStep__description">
                          { t(tabItem.label) }
                        </div>
                      </div>
                  )
                }
              </SimpleBar>
            </div>
            : <div className="configurator__sidebarSteps">
              <DesktopCustomScrollbar>
                {
                  menuTabFiltered.map((tabItem, index) =>
                    tabItem.fieldCode
                      ? customFields.length && customFields.find((field) => field.code === tabItem.fieldCode)
                        ? <div
                          key={'sidebarStepCustomField' + index + tabItem.fieldCode}
                          className={
                            "sidebarStep__item"
                            + (selectTab === tabItem.functionTab ? ' active' : '')
                          }
                          onClick={() => onSetSelectTabHandler(tabItem.functionTab, index)}
                        >
                          <div className="sidebarStep__icon">
                            <FontAwesomeIcon icon={tabItem.icon}/>
                          </div>
                          <div className="sidebarStep__description">
                            { t(tabItem.label) }
                          </div>
                        </div>
                        : <></>
                      : <div
                        key={'sidebarStep' + index + tabItem.label}
                        className={
                          "sidebarStep__item"
                          + (selectTab === tabItem.functionTab ? ' active' : '')
                        }
                        onClick={() => onSetSelectTabHandler(tabItem.functionTab, index)}
                      >
                        <div className="sidebarStep__icon">
                          <FontAwesomeIcon icon={tabItem.icon}/>
                        </div>
                        <div className="sidebarStep__description">
                          { t(tabItem.label) }
                        </div>
                      </div>
                  )
                }
              </DesktopCustomScrollbar>
            </div>
      }
      {/* <!-- End of Sidebar Steps --> */}
      {
        selectTab === FunctionTab.Cut
          ? <SidebarContentWrapper
            scrollBarRef={sidebarScrollRef}
            showScrollDown={showScrollDownSidebar}
            sidebarScrollTopPos={sidebarScrollTopPos}
            sidebarScrollBoundingRect={sidebarScrollBoundingRect}
            isMobile={!!isMobile}
            setSelectTabBack={setSelectTabBack}
            setSelectTabNext={setSelectTabNext}
          >
            {
              canShowCuttingTab
                ? <JakoCuttingTab
                  cuttingOptions={cuttingOptions.current}
                  cuttingFormLinksResponse={cuttingFormLinksResponseRef.current}
                  cuttingFormCode={jakoCurrentCuttingFormCode}
                  isEdited={isEditedMemo}
                  onSelectCut={onSelectCut}
                  onSelectMaterial={onSelectCutMaterial}
                  isMobile={!!isMobile}
                  onCloseMobile={handleOnCloseMobile}
                />
                : <></>
            }
          </SidebarContentWrapper>
          : <></>
      }
      {
        cuttingFileModelData && selectTab === FunctionTab.Design
          ? <SidebarContentWrapper
            scrollBarRef={sidebarScrollRef}
            showScrollDown={showScrollDownSidebar}
            sidebarScrollTopPos={sidebarScrollTopPos}
            sidebarScrollBoundingRect={sidebarScrollBoundingRect}
            isMobile={!!isMobile}
            setSelectTabBack={setSelectTabBack}
            setSelectTabNext={setSelectTabNext}
          >
            <JakoDesignTab
              designOptions={cuttingFileModelData.designs}
              selectedDesign={selectedDesign}
              onSelectDesign={onSelectDesign}
              onSelectCustomerDesign={onSelectCustomerDesign}
              isMobile={!!isMobile}
              activateDesignOnBack={activateDesignOnBack}
              hasBackDesign={hasBackDesign}
              onCloseMobile={handleOnCloseMobile}
              onTriggerBackDesign={() => handleTriggerDesignOnBack(!activateDesignOnBack)}
              selectedCuttingSportType={selectedCuttingSportType}
            />
          </SidebarContentWrapper>
          : <></>
      }
      {
        selectTab === FunctionTab.Pattern
        ? <SidebarContentWrapper
          scrollBarRef={sidebarScrollRef}
          showScrollDown={showScrollDownSidebar}
          sidebarScrollTopPos={sidebarScrollTopPos}
          sidebarScrollBoundingRect={sidebarScrollBoundingRect}
          isMobile={!!isMobile}
          setSelectTabBack={setSelectTabBack}
          setSelectTabNext={setSelectTabNext}
        >
          <JakoPatternTab
            modelStyle={selectModelStyle}
            jerseySection={patternJerseySection}
            possibleDesignAreaId={possibleDesignAreaId}
            selectedJerseySectionColor={selectedJerseySectionColor}
            selectedJerseySectionPattern={selectedJerseySectionPattern}
            patternList={patternList}
            colorList={sortedColorListRef.current}
            onSelectPatternSvg={onSelectPatternSvg}
            onSelectPatternFill={onSelectPatternFill}
            onSelectBgColor={onSelectColor}
            onSetJerseySection={onSetPatternJerseySection}
            onPatternScale={onPatternScale}
            onPatternMoveOffset={onPatternMoveOffset}
            onPatternRotate={onPatternRotate}
            isMobile={!!isMobile}
            onCloseMobile={handleOnCloseMobile}
            onCopyPatternSvg={onCopyPatternSvg}
          />
        </SidebarContentWrapper>
        : <></>
      }
      {
        selectTab === FunctionTab.Color
          ? <SidebarContentWrapper
            scrollBarRef={sidebarScrollRef}
            showScrollDown={showScrollDownSidebar}
            sidebarScrollTopPos={sidebarScrollTopPos}
            sidebarScrollBoundingRect={sidebarScrollBoundingRect}
            isMobile={!!isMobile}
            setSelectTabBack={setSelectTabBack}
            setSelectTabNext={setSelectTabNext}
          >
            { jfnetCuttingFormCode === 'K3800' ?
              <JakoColorTabSocks
                modelStyle={selectModelStyle}
                colorList={sortedColorList}
                possibleDesignAreaId={possibleDesignAreaId}
                selectedJerseySectionColor={selectedJerseySectionColor}
                selectedDesign={selectedDesign}
                onSelectColor={onSelectColor}
                isMobile={!!isMobile}
                onCloseMobile={handleOnCloseMobile}
              />
              : 
              <JakoColorTab
                modelStyle={selectModelStyle}
                colorList={sortedColorList}
                possibleDesignAreaId={possibleDesignAreaId}
                selectedJerseySectionColor={selectedJerseySectionColor}
                selectedDesign={selectedDesign}
                onSelectColor={onSelectColor}
                isMobile={!!isMobile}
                onCloseMobile={handleOnCloseMobile}
              />
            }
          </SidebarContentWrapper>
          : <></>
      }
      {
        selectTab === FunctionTab.Logo
          ? <SidebarContentWrapper
            scrollBarRef={sidebarScrollRef}
            showScrollDown={showScrollDownSidebar}
            sidebarScrollTopPos={sidebarScrollTopPos}
            sidebarScrollBoundingRect={sidebarScrollBoundingRect}
            isMobile={!!isMobile}
            setSelectTabBack={setSelectTabBack}
            setSelectTabNext={setSelectTabNext}
          >
            <JakoLogoTab
              ref={jakoLogoTabRef}
              modelStyle={selectModelStyle}
              currentCuttingFormCode={jakoCurrentCuttingFormCode}
              selectedAddedLogoKey={selectedAddedLogoKey}
              addedLogoItems={addedFabricLogoObjects}
              jakoLogoPosition={jakoLogoPosition}
              colorList={sortedColorList}
              cmFactor={basedCmFactor}
              onChangeLogoColor={onChangeLogoColor}
              onClickLogoLock={onClickLogoLock}
              onClickDeleteLogo={onClickDeleteLogo}
              onSelectLogo={onSelectLogo}
              onChangeLogoPosition={onChangeLogoPosition}
              onChangeWidthSize={onChangeLogoWidthSize}
              onChangeHeightSize={onChangeLogoHeightSize}
              onSelectAddLogoPosition={onSelectAddLogoPosition}
              onSelectJakoLogoPosition={onSelectJakoLogoPosition}
              onSendLogoToFront={onSendToFront}
              onSendLogoToBack={onSendToBack}
              onAddLogoInput={onAddLogoInput}
              onSetLoading={onSetIsLoadingOverlayUI}
              isMobile={!!isMobile}
              onCloseMobile={handleOnCloseMobile}
              onScrollToSelectedItem={handleOnScrollToSelectedItem}
              copyLogo={copyLogo}
            />
          </SidebarContentWrapper>
          : <></>
      }
      {
        selectTab === FunctionTab.Number
          ? <SidebarContentWrapper
            scrollBarRef={sidebarScrollRef}
            showScrollDown={showScrollDownSidebar}
            sidebarScrollTopPos={sidebarScrollTopPos}
            sidebarScrollBoundingRect={sidebarScrollBoundingRect}
            isMobile={!!isMobile}
            setSelectTabBack={setSelectTabBack}
            setSelectTabNext={setSelectTabNext}
          >
            <JakoNumberTab
              ref={jakoNumberTabRef}
              modelStyle={selectModelStyle}
              functionTab={FunctionTab.Number}
              selectedAddedTextKey={selectedAddedTextKey}
              addedTextItems={addedFabricTextObjects}
              showAddTextInputRef={showAddTextInputRef}
              showEditTextInputRef={showEditTextInputRef}
              colorList={sortedColorList}
              cmFactor={basedCmFactor}
              onClickTextLock={onClickTextLock}
              onClickDeleteText={onClickDeleteText}
              onChangeTextColor={onChangeTextColor}
              onChangeFontFamily={onChangeFontFamily}
              onChangeFontSizeWidth={onChangeFontSizeWidth}
              onChangeFontSizeHeight={onChangeFontSizeHeight}
              onChangePosition={onChangeTextPosition}
              onSelectText={onSelectText}
              onSubmitAddTextInput={onSubmitAddTextInputFromTab}
              onSubmitEditTextInput={onSubmitEditTextInput}
              onSelectAddNumberPosition={onSelectAddNumberPosition}
              onSendTextToFront={onSendToFront}
              onSendTextToBack={onSendToBack}
              onBack={onTextTabBack}
              isMobile={!!isMobile}
              onCloseMobile={handleOnCloseMobile}
              onScrollToSelectedItem={handleOnScrollToSelectedItem}
              copyText={copyText}
            />
          </SidebarContentWrapper>
          : <></>
      }

      {
        (selectTab === FunctionTab.Name
          || selectTab === FunctionTab.Initial)
          ? <SidebarContentWrapper
            scrollBarRef={sidebarScrollRef}
            showScrollDown={showScrollDownSidebar}
            sidebarScrollTopPos={sidebarScrollTopPos}
            sidebarScrollBoundingRect={sidebarScrollBoundingRect}
            isMobile={!!isMobile}
            setSelectTabBack={setSelectTabBack}
            setSelectTabNext={setSelectTabNext}
          >
              <JakoNameTab
                ref={jakoNameTabRef}
                modelStyle={selectModelStyle}
                functionTab={selectTab}
                selectedAddedTextKey={selectedAddedTextKey}
                addedTextItems={addedFabricTextObjects}
                showAddTextInputRef={showAddTextInputRef}
                showEditTextInputRef={showEditTextInputRef}
                colorList={sortedColorList}
                cmFactor={basedCmFactor}
                onClickTextLock={onClickTextLock}
                onClickDeleteText={onClickDeleteText}
                onChangeTextColor={onChangeTextColor}
                onSelectAddNamePosition={onSelectAddNamePosition}
                onChangeFontFamily={onChangeFontFamily}
                onChangeFontSizeWidth={onChangeFontSizeWidth}
                onChangeFontSizeHeight={onChangeFontSizeHeight}
                onChangePosition={onChangeTextPosition}
                onSelectText={onSelectText}
                onSubmitAddTextInput={onSubmitAddTextInputFromTab}
                onSubmitEditTextInput={onSubmitEditTextInput}
                onSendTextToFront={onSendToFront}
                onSendTextToBack={onSendToBack}
                onBack={onTextTabBack}
                onSetTabName={onSetTabName}
                isMobile={!!isMobile}
                onCloseMobile={handleOnCloseMobile}
                onScrollToSelectedItem={handleOnScrollToSelectedItem}
                copyText={copyText}
              />
            </SidebarContentWrapper>
          : <></>
      }

      {
        selectTab === FunctionTab.Teamname
          ? <SidebarContentWrapper
            scrollBarRef={sidebarScrollRef}
            showScrollDown={showScrollDownSidebar}
            sidebarScrollTopPos={sidebarScrollTopPos}
            sidebarScrollBoundingRect={sidebarScrollBoundingRect}
            isMobile={!!isMobile}
            setSelectTabBack={setSelectTabBack}
            setSelectTabNext={setSelectTabNext}
          >
              <JakoTeamnameTab
                ref={jakoTeamnameTabRef}
                modelStyle={selectModelStyle}
                functionTab={FunctionTab.Teamname}
                selectedAddedTextKey={selectedAddedTextKey}
                addedTextItems={addedFabricTextObjects}
                showAddTextInputRef={showAddTextInputRef}
                showEditTextInputRef={showEditTextInputRef}
                colorList={sortedColorList}
                cmFactor={basedCmFactor}
                onClickTextLock={onClickTextLock}
                onClickDeleteText={onClickDeleteText}
                onChangeTextColor={onChangeTextColor}
                onChangeFontFamily={onChangeFontFamily}
                onChangeFontSizeWidth={onChangeFontSizeWidth}
                onChangeFontSizeHeight={onChangeFontSizeHeight}
                onChangePosition={onChangeTextPosition}
                onSelectText={onSelectText}
                onSubmitAddTextInput={onSubmitAddTextInputFromTab}
                onSubmitEditTextInput={onSubmitEditTextInput}
                onSelectAddTeamnamePosition={onSelectAddTeamnamePosition}
                onSendTextToFront={onSendToFront}
                onSendTextToBack={onSendToBack}
                onBack={onTextTabBack}
                isMobile={!!isMobile}
                onCloseMobile={handleOnCloseMobile}
                onScrollToSelectedItem={handleOnScrollToSelectedItem}
                copyText={copyText}
              />
            </SidebarContentWrapper>
          : <></>
      }

      {
        selectTab === FunctionTab.Text
          ? <SidebarContentWrapper
            scrollBarRef={sidebarScrollRef}
            showScrollDown={showScrollDownSidebar}
            sidebarScrollTopPos={sidebarScrollTopPos}
            sidebarScrollBoundingRect={sidebarScrollBoundingRect}
            isMobile={!!isMobile}
            setSelectTabBack={setSelectTabBack}
            setSelectTabNext={setSelectTabNext}
          >
            <JakoTextTab
              ref={jakoTextTabRef}
              functionTab={FunctionTab.Text}
              modelStyle={selectModelStyle}
              selectedAddedTextKey={selectedAddedTextKey}
              addedTextItems={addedFabricTextObjects}
              showAddTextInputRef={showAddTextInputRef}
              showEditTextInputRef={showEditTextInputRef}
              colorList={sortedColorList}
              cmFactor={basedCmFactor}
              onClickTextLock={onClickTextLock}
              onClickDeleteText={onClickDeleteText}
              onChangeTextColor={onChangeTextColor}
              onChangeFontFamily={onChangeFontFamily}
              onChangeFontSizeWidth={onChangeFontSizeWidth}
              onChangeFontSizeHeight={onChangeFontSizeHeight}
              onChangeTextCurved={onChangeTextCurved}
              onSetNewTextCurved={onSetNewTextCurved}
              onChangePosition={onChangeTextPosition}
              onSelectText={onSelectText}
              onSubmitAddTextInput={onSubmitAddTextInputFromTab}
              onSubmitEditTextInput={onSubmitEditTextInput}
              onSelectAddTextPosition={onSelectAddTextPosition}
              onSendTextToFront={onSendToFront}
              onSendTextToBack={onSendToBack}
              onBack={onTextTabBack}
              isMobile={!!isMobile}
              onCloseMobile={handleOnCloseMobile}
              onScrollToSelectedItem={handleOnScrollToSelectedItem}
              copyText={copyText}
            />
          </SidebarContentWrapper>
          : <></>
      }

      {/* <!-- Main Area --> */}
      <div
        className="configurator__mainArea z-10"
      >
        {
          isMobile && jakoPrices && jakoPrices.prices.length && !isLoadingPrices
            ? <div className="absolute top-[65px] right-0 px-4 py-2 bg-theme-primary">
              <span className="font-semibold text-white text-[1.4rem]">
                { t('configurator_3d_ab') } {getFormattedPrice(jakoPrices.prices)}
              </span>
            </div>
            : <></>
        }
        <Transition
          show={isLoading}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0 invisible"
          enterTo="opacity-100 visible"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100 invisible"
          leaveTo="opacity-0 invisible"
        >
          <div
            id="hideDiv"
            className="loadingAnimation--full !bg-white"
          >
            <div className="loadingAnimation__spinner jako-blue">
              <div className="loadingAnimation_ring ">
                <div/>
                <div/>
                <div/>
                <div/>
                <div className="icon icon-3d-loading"/>
                <div className="ringPlaceholder"/>
              </div>
            </div>
          </div>
        </Transition>
        <div className="mainArea__logo">
          <a href="/">
            <img alt="Logo JAKO" src={require('../assets/jakoImages/logoBlue.svg')} style={{height: '40px', width: '140px'}} />
          </a>
        </div>
        <div className="mainArea__menuControl flex flex-col">
          {
            selectTab === FunctionTab.Logo && selectedAddedLogoKey
              ? <>
                {
                  addedFabricLogoObjects[selectedAddedLogoKey]
                    && (addedFabricLogoObjects[selectedAddedLogoKey].x !== addedFabricLogoObjects[selectedAddedLogoKey].initialX || addedFabricLogoObjects[selectedAddedLogoKey].y !== addedFabricLogoObjects[selectedAddedLogoKey].initialY)
                    ? <button
                      className="outlineButton max-w-[110px] text-[11px] md:text-[14px] md:max-w-[150px] mt-4"
                      onClick={resetDefaultPositionLogo}
                    >
                      { t('configurator_3d_reset_default_position') }
                    </button>
                    : <></>
                }
              </>
              : selectedAddedTextKey
                ? <>
                  {
                    addedFabricTextObjects[selectedAddedTextKey]
                      && (addedFabricTextObjects[selectedAddedTextKey].x !== addedFabricTextObjects[selectedAddedTextKey].initialX || addedFabricTextObjects[selectedAddedTextKey].y !== addedFabricTextObjects[selectedAddedTextKey].initialY)
                      ? <button
                        className="outlineButton max-w-[110px] text-[11px] md:text-[14px] md:max-w-[150px] mt-4"
                        onClick={resetDefaultPositionText}
                      >
                        { t('configurator_3d_reset_default_position') }
                      </button>
                      : <></>
                  }
                </>
                : <></>
          }
        </div>

        <div className="jfnet-mainArea__controls circle">
          {
            isMobile
              ? <>
                <button className="absolute top-1/2 transform -translate-y-1/2 left-[8%] z-30" onClick={() => onControlAreaClick(ControlArea.RotateLeft)}>
                  <FontAwesomeIcon icon={faAngleLeft} className="h-[27px] md:h-[40px] text-[#C3C3C3]" />
                </button>
                <button className="absolute top-1/2 transform -translate-y-1/2 right-[8%] z-30" onClick={() => onControlAreaClick(ControlArea.RotateRight)}>
                  <IconAngle className="transform rotate-180 h-[27px] md:h-[40px] text-[#C3C3C3]" />
                </button>
                <IconCircleArea className="absolute top-3/4 transform -translate-y-[15px] md:h-[100px]" />
                <button
                  className="absolute top-3/4 transform translate-y-[23px] py-4 px-8 bg-white min-w-[110px] text-[#C3C3C3] z-30 md:text-[22px] md:translate-y-[55px]"
                  onClick={() => moveCameraToFrontSide()}
                >
                  { t('configurator_3d_front_side') }
                </button>
              </>
              : <>
                <div className="controlsCircle" />
                <div className="controlBar">
                  <button
                    className="icon flex items-center h-[32px] px-4"
                    onClick={() => onControlAreaClick(ControlArea.RotateLeft)}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className="h-[24px]" />
                  </button>
                  <button
                    className="icon flex items-center h-[32px] px-2"
                    onClick={() => onControlAreaClick(ControlArea.ZoomOut)}
                  >
                    <FontAwesomeIcon icon={faCircleMinus} className="h-[24px]" />
                  </button>
                  <button
                    className="icon flex items-center h-[32px] px-2"
                    onClick={() => onControlAreaClick(ControlArea.ZoomIn)}
                  >
                    <FontAwesomeIcon icon={faCirclePlus} className="h-[24px]" />
                  </button>
                  <button
                    className="icon flex items-center h-[32px] px-4"
                    onClick={() => onControlAreaClick(ControlArea.RotateRight)}
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="h-[24px]" />
                  </button>
                  <div className="absolute pt-5 top-full left-1/2 transform -translate-x-1/2">
                    {
                      isMobile
                        ? <button className="absolute bottom-[100px] left-0">
                          <IconAngle className="h-[27px] text-[#BFBFBF]" />
                        </button>
                        : <></>
                    }
                    <button
                      className="control-front-btn button_primary"
                      onClick={() => moveCameraToFrontSide()}
                    >
                      { t('configurator_3d_front_side') }
                    </button>
                  </div>
                </div>
              </>
            }
        </div>

        <div
          ref={canvasRef}
          id="configurator-container"
          className="relative mainArea__canvas !z-[9]"
          style={{
            width: isSavingScreenshot ? 800 : '100%',
            height: isSavingScreenshot ? 800 : '100%',
            minHeight: isSavingScreenshot ? 800 : '100%',
            pointerEvents: isSavingScreenshot ? 'none' : undefined
          }}
        >
          {/* <!-- JFNET WebComponent --> */}
          {/* Canvas is a React Three.js 3D wrapper */}
          { cuttingFileModelData
            && selectedDesign
            && initialized
            && <Canvas
            className="w-full h-full absolute"
            onCreated={onCanvasCreated}
            gl={{
              toneMapping: NoToneMapping,
              preserveDrawingBuffer: true,
            }}
            // dpr={[1,2]}
            linear
          >
            <Suspense fallback={null}>
              <group position={[0, 0.49 + yPosition, 0]}>
                {/* This is the main scene 3D jersey configurator component */}
                {/* You can see that there is no API at all. All you have to do is to pass the data into this component */}
                <ConfiguratorModelFabric
                  ref={configuratorRef}

                  isMobile={!!isMobile}

                  activateStripe={activateStripe}
                  activateDesignOnBack={activateDesignOnBack}
                  modelStyle={selectModelStyle}
                  currentCuttingFormCode={jfnetCuttingFormCode}
                  text={addedText} // On adding text from input
                  addingTextCurved={addingTextCurved} // On adding text curve from copying
                  logo={addedLogo} // On adding logo from input
                  addedTextItems={addedFabricTextObjects} // Added texts data
                  addedLogoItems={addedFabricLogoObjects}
                  jakoLogoPosition={jakoLogoPosition}
                  isCopyingTextState={isCopyingTextState}
                  isCopyingLogoState={isCopyingLogoState}
                  isAddingState={isAddingState}
                  addSectionPositionRef={addSectionPositionRef.current}
                  addSectionPart={addSectionPartRef.current}
                  designSvg={selectedDesign.file} // selected design svg
                  colorLogoHex={colorLogoHexRef.current}
                  colorDotsLeftHex={colorDotsLeftHexRef.current}
                  colorDotsRightHex={colorDotsRightHexRef.current}
                  colorInvertedBg={colorInvertedBgRef.current}
                  selectedJerseySectionColor={selectedJerseySectionColor} // selected color for each section (section is the layer id of each partial part of the design)
                  selectedJerseySectionPattern={selectedJerseySectionPattern}
                  selectedFontFamily={selectedFontFamily}
                  selectedTextFill={selectedTextFill}
                  cmFactor={basedCmFactor}

                  modelFile={cuttingFileModelData.glbFile}
                  modelSeparateFile={cuttingFileModelData.glbSeparateFile}
                  normalMap={cuttingFileModelData.normalMapFile}
                  dotsLeft={cuttingFileModelData.dotsLeftFile}
                  dotsRight={cuttingFileModelData.dotsRightFile}
                  jakoLogoLeft={cuttingFileModelData.logoLeftFile}
                  jakoLogoRight={cuttingFileModelData.logoRightFile}
                  jakoLogoCenter={cuttingFileModelData.logoCenterFile}
                  mainSchwarz={mainSchwarzMemo}
                  printcutCoords={printcutCoords}

                  onAddTextPosition={onAddTextPositionStart}
                  onAddImagePosition={onAddImagePositionStart}
                  onAddImageLogoInitSrc={onAddImageLogoInitSrc}
                  onEnableCameraControl={onConfiguratorEnableCameraControl}
                  onDisableCameraControl={onConfiguratorDisableCameraControl}
                  onTextTabBack={onTextTabBack}
                  onSetPossibleDesignAreaId={onSetPossibleDesignAreaId}
                  onSetLoading={onSetLoading}
                  onSetLoadingInitial={onSetLoadingInitial}
                  onSetBaseCmFactor={onSetBaseCmFactor}
                  onSetEndEdgeSleevePos={onSetEndEdgeSleevePos}
                  onSetLogoLeftPosition={onSetLogoLeftPosition}
                  onSetLogoRightPosition={onSetLogoRightPosition}
                  onUpdateTextFontSize={onUpdateTextFontSize}
                  onUpdateLogoSize={onUpdateLogoSize}
                  onDeleteFromControl={onDeleteFromFabric}
                  onSelectObjectItem={onSelectObjectItemFromFabric}
                  onFocusCamera={onFocusCamera}
                  onObjectModified={onConfiguratorModified}
                  onCloseMobileTab={handleOnCloseMobile}
                  onSetHasBackDesign={handleOnSetHasBackDesign}
                  onInit={onConfiguratorInit}
                />
              </group>
            </Suspense>
            <PerspectiveCamera
              fov={30}
              position={
                isMobile
                  ? selectModelStyle === ModelStyle.Short
                    ? [-0.22215543323018355, 0.4619976595384762, 6.859830184793832]
                    : [-0.22215543323018355, 1.3760002904351893, 6.859830184793832]
                  : selectModelStyle === ModelStyle.Short
                    ? [-0.18716965860240095, 0.33, 5.89680653016052]
                    : [-0.18716965860240095, 1.1828048040289152, 5.89680653016052]
              }
              getObjectsByProperty={undefined}
              makeDefault
            />
            <CameraControls
              ref={cameraControlsRef}
              minPolarAngle={1}
              maxPolarAngle={Math.PI / 2}
              minDistance={isMobile ? 2.2 : 2.6}
              maxDistance={isMobile ? 7 : 6}
              dollyToCursor={true}
              dollySpeed={0.5}
              smoothTime={0.09}
              draggingSmoothTime ={0.15}
              truckSpeed={0}
              onChange={onCameraUpdate}
            />
            <StudioSoLights />
            {/* <Perf position="bottom-left" /> */}
          </Canvas>
          }
        </div>

        <div className="mainArea__background" />
      </div>
      {/* <!-- End of Main Area --> */}
      <div className="configurator__bottomArea">
        <div className="bottomArea--left">
          <div
            className="bottomBar__item saveItem"
            onClick={localDevelopmentTest_onClickSaveConfiguration}
          >
            <i className='icon'>
              <FontAwesomeIcon icon={faFloppyDisk} className='w-10 h-10' />
            </i>
            <span className="textMedium">TEST {t('configurator_3d_save')}</span>
          </div>
        </div>
        <div className="relative bottomArea--right flex items-center gap-5">
          {
            !isMobile && jakoPrices && jakoPrices.prices.length && !isLoadingPrices
              ? <p className="font-semibold text-[#141414] text-[18px]">
                { t('configurator_3d_ab') } {getFormattedPrice(jakoPrices.prices)}
              </p>
              : <></>
          }
          <button
            className="bottomBar__item button_primary"
            onClick={localDevelopmentTest_onCompleteConfiguration}
          >
            <i className='icon'>
              <FontAwesomeIcon icon={faCartShopping} className='w-10 h-10' />
            </i>
            <span className="textMedium">{ t('configurator_3d_complete_configuration') }</span>
          </button>
        </div>
      </div>
    </div>

    <Transition
      show={isLoadingOverlayUI}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0 invisible"
      enterTo="opacity-100 visible"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100 invisible"
      leaveTo="opacity-0 invisible"
    >
      <div
        id="hideDiv"
        className="loadingAnimation--full filter backdrop-blur !bg-transparent"
      >
        <div className="loadingAnimation__spinner">
          <div className="loadingAnimation_ring">
            <div/>
            <div/>
            <div/>
            <div/>
            <div className="icon icon-3d-loading"/>
            <div className="ringPlaceholder"/>
          </div>
        </div>
      </div>
    </Transition>
    <Transition
      show={isLoadingInitial || isLoadingCustomFields}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0 invisible"
      enterTo="opacity-100 visible"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100 invisible"
      leaveTo="opacity-0 invisible"
    >
      <div
        id="hideDiv"
        className="loadingAnimation--full"
      >
        <div className="loadingAnimation__spinner">
          <div className="loadingAnimation_ring">
            <div/>
            <div/>
            <div/>
            <div/>
            <div className="icon icon-3d-loading"/>
            <div className="ringPlaceholder"/>
          </div>
        </div>
      </div>
    </Transition>
  </main>;
}
export default JakoReactComponent;
