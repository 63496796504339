export default function IconForward(props: any) {
  return <svg version="1.1"xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" fill="currentColor" {...props}>
	<path d="M538.8,616.3H267.5c-42.7,0-77.5-34.8-77.5-77.5V267.5c0-42.7,34.8-77.5,77.5-77.5h271.3c42.7,0,77.5,34.8,77.5,77.5v271.3
		C616.3,581.5,581.5,616.3,538.8,616.3z M267.5,577.5h271.3c21.4,0,38.8-17.3,38.8-38.8V267.5c0-21.4-17.3-38.8-38.8-38.8H267.5
		c-21.4,0-38.8,17.3-38.8,38.8v271.3C228.7,560.2,246.1,577.5,267.5,577.5z M538.8,500c0,21.4-17.3,38.8-38.8,38.8H306.2
		c-21.4,0-38.8-17.3-38.8-38.8V306.2c0-21.4,17.3-38.8,38.8-38.8H500c21.4,0,38.8,17.3,38.8,38.8V500z M500,500V306.2H306.2V500H500
		z M461.2,771.3h271.3c21.4,0,38.8-17.3,38.8-38.8V461.2c0-21.4-17.3-38.8-38.8-38.8H655v-38.8h77.5c42.7,0,77.5,34.8,77.5,77.5
		v271.3c0,42.7-34.8,77.5-77.5,77.5H461.2c-42.7,0-77.5-34.8-77.5-77.5V655h38.8v77.5C422.5,753.9,439.8,771.3,461.2,771.3z"/>
</svg>;
}
