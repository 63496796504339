export default function IconFonts(props: any) {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px" y="0px"
    viewBox="0 0 1000 1000"
    fill="currentColor"
    {...props}
  >
    <path d="M343.8,241l193.8,503.8c3.9,10.1-1.1,21.2-11.1,25.1c-10.1,3.9-21.2-1.2-25.1-11.1l-54.9-142.5H204.8L150,758.8
      c-3.9,10.1-15,15-25.1,11.1c-10.1-3.9-15-15.1-11.1-25.1l193.8-503.8c2.8-7.5,10.1-12.5,18-12.5s15.3,5,18,12.5L343.8,241z
        M325.6,302.1l-106,275.4h211.9L325.6,302.1z M887.5,403.1v348.8c0,10.7-8.7,19.4-19.4,19.4s-19.4-8.7-19.4-19.4v-42
      c-27.4,37.3-71.4,61.4-121.1,61.4c-83,0-150.2-67.2-150.2-150.2v-87.2c0-82.9,67.2-150.2,150.2-150.2c49.6,0,93.7,24.1,121.1,61.4
      v-42c0-10.7,8.7-19.4,19.4-19.4S887.5,392.4,887.5,403.1z M616.2,621c0,61.5,49.9,111.4,111.4,111.4S839.1,682.6,839.1,621v-87.2
      c0-61.5-49.9-111.4-111.4-111.4s-111.4,49.9-111.4,111.4V621z"/>
  </svg>;
}
