import axios from 'axios';

// const wc = document.querySelector('web-configurator');

// let baseapiurl: string = 'https://jako-api.dc-test.de/public';
// let uiapiurl: string = 'https://jako-test.jfnet.de/api/v1';

// if (wc) {
//   baseapiurl = wc.getAttribute('baseapiurl') || ' https://jako-api.dc-test.de/public';
//   uiapiurl = wc.getAttribute('uiapiurl') || 'https://jako-test.jfnet.de/api/v1';
// }

// Local dev AVOID CORS
let baseapiurl: string = 'http://localhost:8010/proxy/public';
let uiapiurl: string = 'http://localhost:5000/proxy/api/v1';

export const apiClient = axios.create({
  baseURL: baseapiurl,
});
export const apiJfnet = axios.create({
  baseURL: uiapiurl,
});
export const newApiJfnet = axios.create({
  baseURL: uiapiurl,
});